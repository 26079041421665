<app-loading-wrapper-overlay *ngIf="loading"></app-loading-wrapper-overlay>
<!-- <div class="spinner-wrapper" *ngIf="showThis">
  <div class="col-sm-12">
    <div class="container">
      <div class="row">
        <div class="col-sm-12">
          <span style="width: 100%; padding-left: 1280px;" (click)="closeW()">
            <i class="fas fa-times" style="color: white; height: 29px; width: 29px; text-align: right; cursor: pointer;"></i>
          </span>
        </div>
        <div class="col-sm-12" style="width: 100%; display: flex; max-height: 400px; padding-left: 200px;">
               <span style="width: 100%; display: flex; margin: 0 auto;">
                <img src="../../../assets/images/core/Inicio_CIGmatchReload-min.jpg" width="65%" style="margin: 0 auto;">
               </span>
        </div>
      </div>
    </div>
  </div>
</div> -->


<!-- <div style="background-repeat:no-repeat;width: 100%;height:100%;" *ngIf="userLog">
  <img  src="../../../assets/images/core/Loader CIG Match.gif" style="width: 100%!important;height: 100%!important;">
</div> -->
<div style="max-width: 100%!important;">

<app-public-carousel *ngIf="userLog"></app-public-carousel>

<div class="search" *ngIf="userLog">
    <div class="col-md-6" style="padding-top: 10px!important">
        <span class="hoverhover top" style="width: 100%;">
          <div class="form"> 
          <input type="text" class="form-control form-input" [(ngModel)]="busqueda" placeholder="Buscar productos por nombre"  on-keyup="$event.keyCode == 13 && this.search()">
          <span (click)="search()" style="width: 40px; height: 40px; display: flex; border-left: transparent;">
            <i class="fa fa-search" style="margin-top:-10px; margin-left:-20px ;"></i>
          </span></div>

        <span class="text">Buscar productos por nombre o categoría</span>
      </span>
    
    
      </div>
</div>

<mat-card class="targetProduct" style="background-color: #0078D7;border-radius: 10px;margin-left: 10%;margin-right: 10%;width: 80%;margin-top: 5%;" *ngIf="!userLog && pre">
  <mat-card-content>
      <div class="row">
        <div class="col-sm-6" style="padding: 3rem;padding-left: 3.5rem;">
          <div class="row">
            <div class="col-sm-12">
              <h1 style="color: #FFFFFF;font-size: 2.1rem;font-weight: 700;">
                Recién llegados a la plataforma
              </h1>
            </div>
            <div class="col-sm-12">
              <span style="color: #FFFFFF;font-size: 1.5rem;font-family: Sans-serif;line-height: 1.5;margin-top: 3px;font-weight:lighter;">
                Conoce nuevos productos y servicios que pueda fortalecer tu empresa
            </span>
            </div>
            <div class="col-sm-12">
              <mat-card  style="background-color: #FFFFFF;border-radius: 20px;margin-left: 0%;margin-right: 0%;width: 11rem;margin-top: 5%;text-align: center; padding: 0.5rem!important; cursor: pointer;">
                <h1 style="color: #004F9F;font-size: 1.4rem;font-weight: 500;" (click)="goLogin()">
                  Ver mas
             </h1>
              </mat-card>
            </div>

          </div>
            
           

        </div>
        <div class="col-sm-6">
            <div class="row">
              <div class="col-md-6" *ngFor="let products of productsList" style="padding: 1.5rem!important;height: auto">
                <div class="card h-100"  style="border: none;padding: 0.5rem;cursor: pointer;box-shadow: 0 6px 20px rgba(71, 71, 71, 0.08), 0 0 6px rgba(71, 71, 71, 0.08);padding-bottom: 0%!important;"  (click)="viewProduct(products.productUUID)" >
                  <div style="width: 100%;padding: 1rem;height: 10rem;">
                    <img [src]='products.productMainPicture' alt="..." style="width: 100%;height: 10rem;">
                  
                  </div>
                    <div class="card-body" style="margin-bottom: 0%!important;margin-top: 1rem;padding-right: 1rem;">
                      <div class="row">
                        
                        <div class="col-sm-7" style="padding-left: 12px;">
                          <span style="color: #004F9F;font-size: 0.8rem;font-weight: 600;line-height: 0!important;font-family: 'Arial'!important;">{{products.productName}}</span>
                        </div>
                        <div class="col-sm-5">
                          <span class="float-end" style="font-weight: 700;" *ngIf="1 * products.productPrice > 0 ">{{products.unitPriceType}} {{products.productPrice}}</span> 
                        </div>
                        
                        
                      </div>
                    </div>
                    <div class="col-sm-12" style="text-align: right;color: #9c9c9c;font-size: 0.8rem;bottom: 0;margin-bottom: 1rem;padding-right: 1rem;font-family: 'Arial'!important; margin-top: 1rem;padding-left: 1rem;">
                      <small *ngIf="products.newCategory == null">{{products.productCategory}}</small>
                      <span class="hover top" (mouseenter)="mouseEvent(products.newCategory)" (mouseleave)="mouseEvent2()" *ngIf="products.newCategory != null"><small>{{products.productCategory}}<span *ngIf="products.newCategory!= null"> +1</span></small>
                        <span class="text">{{categoryToShow}}</span>
                      </span>
                    </div>
                </div>
            </div>

            </div>
        </div>

      </div>
  </mat-card-content>
</mat-card>

<div class="row" *ngIf="!userLog && pre">
    <div class="col-sm-12 publicity" >
      <!-- publicidad -->
      <!-- <div style="margin: 0 auto;">
        <div id="my" class="carousel 1carousel-fade img-details" data-bs-ride="carousel" data-bs-interval="3000" style="height:auto">
          <div class="carousel-inner">
              <div class="carousel-item {{publicity1.active}}" style="text-align: center;" *ngFor="let publicity1 of publicityListLogout"> <img [src]="publicity1.publicityBanner" class="imgPublicity" (click)="openPublicity(publicity1.publicityLink)"> </div>
            </div>   
        </div>
      </div> -->
      <!-- fin de publicidad -->
      <div class="carousel">
        <ng-container *ngFor="let image of publicityListLogout; index as i">
          <div class="carousel-item" [ngClass]="{ 'active': i === activeIndex }">
            <img [src]="image.publicityBanner" (click)="openPublicity(image.publicityLink)">
          </div>
        </ng-container>
      </div>

    </div>
      <div class="col-sm-10 title" id="productos" >
        <h1 class="featuredProducts">Productos y Servicios CIGMatch</h1>
      </div>
      <div class="col-sm-11 lineP">
        <hr style="height: 2.5px;color: #0078D7;background-color: #0078D7;opacity: 70%;">
      </div>
</div>

<app-products-cards *ngIf="!userLog && pre"></app-products-cards>
</div>


<!-- Pantalla Principal del Usuario Loggeado -->
<div class="row" *ngIf="userLog" style="height: 100%!important;">
  
    <div class="col-sm-12 titleLog" style="text-align: center;">
      <span style="cursor:pointer;" (click)="productsD()">Destacados</span>
    </div>
    
</div>

<mat-card class="targetProduct cardLog"  *ngIf="userLog">
  <mat-card-content>
      <div class="row">
        <div class="col-sm-12">
            <div class="row">
              <div class="col-md-3 col-6 targetLog" *ngFor="let products of productsListLog" >
                <div class="card h-100"  style="border: none;padding: 0.5rem;cursor: pointer;box-shadow: 0 6px 20px rgba(71, 71, 71, 0.08), 0 0 6px rgba(71, 71, 71, 0.08);padding-bottom: 0%!important;"  (click)="viewProduct(products.productUUID)" >
                  <div style="width: 100%;padding: 1rem;height: 10rem;">
                    <img [src]='products.productMainPicture' alt="..." style="width: 100%;height: 10rem;">
                  
                  </div>
                    <div class="card-body" style="margin-bottom: 0%!important;margin-top: 1.5rem!important;padding-left: 1rem; padding-right: 1rem;">
                      <div class="row">
                        
                        <div class="col-sm-7">
                          <span style="color: #004F9F;font-size: 0.8rem;font-weight: 500;line-height: 0!important;">{{products.productName}}</span>
                        </div>
                        <div class="col-sm-5">
                          <span class="float-end" style="font-weight: 700;" *ngIf="1 * products.productPrice > 0 ">{{products.unitPriceType}} {{products.productPrice}}</span> 
                        </div>
                        
                        
                      </div>
                    </div>
                    <div class="col-sm-12" style="text-align: right;color: #9c9c9c;font-size: 0.7rem;bottom: 0;margin-bottom: 1rem;padding-right: 1rem;font-family: 'Arial';padding-left: 1rem;">
                      <small *ngIf="products.newCategory == null">{{products.productCategory}}</small>
                      <span class="hover top" (mouseenter)="mouseEvent(products.newCategory)" (mouseleave)="mouseEvent2()" *ngIf="products.newCategory != null"><small>{{products.productCategory}}<span *ngIf="products.newCategory!= null"> +1</span></small>
                        <span class="text">{{categoryToShow}}</span>
                      </span>
                    </div>
                </div>
            </div>

            </div>
        </div>

      </div>
  </mat-card-content>
</mat-card>

<div class="row" *ngIf="userLog">
  <div class="col-sm-12 publicity" >
       <!-- publicidad -->
       <div class="publicityLog">
        <!-- <div id="my" class="carousel 1carousel-fade img-details" data-bs-ride="carousel" data-bs-interval="3000" style="height:auto">
          <div class="carousel-inner">
              <div class="carousel-item {{publicity2.active}}" style="text-align: center;" *ngFor="let publicity2 of publicityListLoggin1"> <img [src]="publicity2.publicityBanner" class="imgPublicity" (click)="openPublicity(publicity2.publicityLink)"> </div>
            </div>   
        </div> -->
        <div class="carousel"  >
          <ng-container *ngFor="let image of publicityListLoggin1; index as i">
            <div class="carousel-item" [ngClass]="{ 'active': i === activeIndexLogin }">
              <img [src]="image.publicityBanner" (click)="openPublicity(image.publicityLink)">
            </div>
          </ng-container>
        </div>
    
      </div>
      <!-- fin de publicidad -->
    </div>
</div>

<!-- Lo mas Buscado -->
<div class="row" *ngIf="userLog">
  
  <div class="col-sm-12 titleLogLeft" style="text-align: left;">
    <span style="cursor: pointer;" (click)="productsMasBuscados()">Lo más buscado</span>
  </div>
  
</div>
<mat-card class="targetProduct moreSearch"  *ngIf="userLog">
  <mat-card-content>
      <div class="row">
        <div class="col-sm-12">
            <div class="row">
              <div style="background-color: #FFFFFF;height: 5rem;position: absolute;width: 5%;left: 97%;margin-top: 10%;text-align: center;" class="mobileView" (click)="nextProductoSimilar()" *ngIf="this.productsListFinishedEnableSimilar">
                <i class="fa fa-chevron-right" aria-hidden="true" id="nextPE"></i>
              </div>
              <div style="background-color: #FFFFFF;height: 5rem;position: absolute;width: 5%;left: -2%;margin-top: 10%;text-align: center;" id="prevPE" class="mobileView" (click)="prevProductoSimilar()" *ngIf="this.productsListStartEnableSimilar">
                <i class="fa fa-chevron-left" aria-hidden="true" id="prevPE"></i>
              </div>
              <div class="col-md-3 col-6 targetLog" *ngFor="let products of productListSearch">
                <div class="card h-100"  style="border: none;padding: 0.5rem;cursor: pointer;box-shadow: 0 6px 20px rgba(71, 71, 71, 0.08), 0 0 6px rgba(71, 71, 71, 0.08);padding-bottom: 0%!important;"  (click)="viewProduct(products.productUUID)" >
                  <div style="width: 100%;padding: 1rem;height: 10rem;">
                    <img [src]='products.productMainPicture' alt="..." style="width: 100%;height: 10rem;">
                  
                  </div>
                    <div class="card-body" style="margin-bottom: 0%!important;margin-top: 1.5rem!important;padding-left: 1rem; padding-right: 1rem;">
                      <div class="row">
                        
                        <div class="col-sm-7">
                          <span style="color: #004F9F;font-size: 0.8rem;font-weight: 500;line-height: 0!important;">{{products.productName}}</span>
                        </div>
                        <div class="col-sm-5">
                          <span class="float-end" style="font-weight: 700;" *ngIf="1 * products.productPrice > 0 ">{{products.unitPriceType}}{{products.productPrice}}</span> 
                        </div>
                        
                        
                      </div>
                    </div>
                    <div class="col-sm-12" style="text-align: right;color: #9c9c9c;font-size: 0.7rem;bottom: 0;margin-bottom: 1rem;padding-right: 1rem;font-family: 'Arial';padding-left: 1rem;">
                      <small *ngIf="products.newCategory == null">{{products.productCategory}}</small>
                      <span class="hover top" (mouseenter)="mouseEvent(products.newCategory)" (mouseleave)="mouseEvent2()" *ngIf="products.newCategory != null"><small>{{products.productCategory}}<span *ngIf="products.newCategory!= null"> +1</span></small>
                        <span class="text">{{categoryToShow}}</span>
                      </span>
                    </div>
                </div>
            </div>

            </div>
        </div>

      </div>
  </mat-card-content>
</mat-card>
<!-- Fin de lo mas Buscado -->


<!-- Agregados Recientemente -->
<div class="row" *ngIf="userLog">
  
  <div class="col-sm-12 titleLogLeft1" style="text-align: left;">
    <span  style="cursor: pointer;" (click)="productsAdd()">Agregados Recientemente</span>
  </div>
  
</div>
<mat-card class="targetProduct moreSearch" *ngIf="userLog">
  <mat-card-content>
      <div class="row">
        <div class="col-sm-12">
            <div class="row">
              <div style="background-color: #FFFFFF;height: 5rem;position: absolute;width: 5%;left: 97%;margin-top: 10%;text-align: center;" class="mobileView"  (click)="nextProducto()" *ngIf="this.productsListFinishedEnable">
                <i class="fa fa-chevron-right" aria-hidden="true" id="nextPE"></i>
              </div>
              <div style="background-color: #FFFFFF;height: 5rem;position: absolute;width: 5%;left: -2%;margin-top: 10%;text-align: center;" id="prevPE" class="mobileView" (click)="prevProducto()" *ngIf="this.productsListStartEnable">
                <i class="fa fa-chevron-left" aria-hidden="true" id="prevPE"></i>
              </div>
              <div class="col-md-3 col-6 targetLog" *ngFor="let products of productListAdd">
                <div class="card h-100"  style="border: none;padding: 0.5rem;cursor: pointer;box-shadow: 0 6px 20px rgba(71, 71, 71, 0.08), 0 0 6px rgba(71, 71, 71, 0.08);padding-bottom: 0%!important;"  (click)="viewProduct(products.productUUID)" >
                  <div style="width: 100%;padding: 1rem;height: 10rem;">
                    <img [src]='products.productMainPicture' alt="..." style="width: 100%;height: 10rem;">
                  
                  </div>
                    <div class="card-body" style="margin-bottom: 0%!important;margin-top: 1.5rem!important; padding-left: 1rem; padding-right: 1rem;">
                      <div class="row">
                        
                        <div class="col-sm-7">
                          <span style="color: #004F9F;font-size: 0.8rem;font-weight: 500;line-height: 0!important;">{{products.productName}}</span>
                        </div>
                        <div class="col-sm-5" style="overflow-x: hidden;">
                          <span class="float-end" style="font-weight: 700;" *ngIf="1 * products.productPrice > 0 ">{{products.unitPriceType}} {{products.productPrice}}</span> 
                        </div>
                        
                        
                      </div>
                    </div>
                    <div class="col-sm-12" style="text-align: right;color: #9c9c9c;font-size: 0.7rem;bottom: 0;margin-bottom: 1rem;padding-right: 1rem;font-family: 'Arial';padding-left: 1rem;">
                      <small *ngIf="products.newCategory == null">{{products.productCategory}}</small>
                      <span class="hover top" (mouseenter)="mouseEvent(products.newCategory)" (mouseleave)="mouseEvent2()" *ngIf="products.newCategory != null"><small>{{products.productCategory}}<span *ngIf="products.newCategory!= null"> +1</span></small>
                        <span class="text">{{categoryToShow}}</span>
                      </span>
                    </div>
                </div>
            </div>

            </div>
        </div>
        <div class="col-sm-12" *ngIf="userRole == 'Admin' || userRole == 'Master'">
          <div class="container">
            <div class="col-sm-12" style="font-weight: lighter;">
               <div class="row">
                    <span class="showItems" style="font-weight: lighter; font-family: Arial, Helvetica, sans-serif;">
                      Total productos: {{totalProducts}}
                    </span>
               </div>
            </div>
          </div>
        </div>
        

      </div>
  </mat-card-content>
</mat-card>
<!-- Fin de Agregados recientemente -->
<div class="row" *ngIf="userLog" style="margin-bottom: 2rem!important;">
  <div class="col-sm-12 publicity" >
       <!-- publicidad -->
       <div class="publicityLog">
        <!-- <div id="my" class="carousel 1carousel-fade img-details" data-bs-ride="carousel" data-bs-interval="4000" style="height:auto">
          <div class="carousel-inner">
              <div class="carousel-item {{publicity3.active}}" style="text-align: center;" *ngFor="let publicity3 of publicityListLoggin2"> <img [src]="publicity3.publicityBanner" class="imgPublicity" (click)="openPublicity(publicity3.publicityLink)"> </div>
            </div>   
        </div> -->
        <div class="carousel">
          <ng-container *ngFor="let image of publicityListLoggin2; index as i">
            <div class="carousel-item" [ngClass]="{ 'active': i === activeIndexLogin2 }">
              <img [src]="image.publicityBanner" (click)="openPublicity(image.publicityLink)">
            </div>
          </ng-container>
        </div>
      </div>
    </div>
          <!-- fin de publicidad -->
</div>


