<app-loading-wrapper-overlay *ngIf="loading"></app-loading-wrapper-overlay>
<mat-card class="cardMain">
    <div class="row">
      <div class="col-sm-12 col-md-12 col-lg-12" id="main-title--container" style="text-align: center; margin-top: 55px;">
        <h1 class="maintittle">Directorio de proveedores</h1>
      </div>
      <div class="col-sm-2 col-md-2 col-lg-2" style="padding: 0rem 0rem !important; margin-top: 20px;">
      <div style=" border-radius:10px!important;">
        <div class="carousel 1carousel-fade img-details" data-bs-ride="carousel" data-bs-interval="4000" style="height:auto; max-width: 100%!important;">
            <div class="carousel-item {{publicity.active}}" id="carousel-item-v" style="text-align: center; " *ngFor="let publicity of publicityListProduct"><img [src]="publicity.publicityBanner" class="imgPublicity-v" style="border-radius:10px;" width="100%" height="auto" (click)="openPublicity(publicity.publicityLink)" ></div>
        </div>
      </div>
      </div>
      <div class="col-sm-10 col-md-10 col-lg-10">
      
        <div class="col-sm-12 col-md-12 col-lg-12" style="text-align: center; padding: 20px 0 20px 0;">
        <div class="d-flex justify-content-center h-100">
            <div class="search"> <input class="search_input" #search type="text" name="" placeholder="¿Qué estás buscando?" (keyup.enter)="searchCompany(search.value)" > <a  class="search_icon" (click)="searchCompany(search.value)"><i class="fa fa-search"></i></a> </div>
        </div>
        </div>
        <div class="row parent" style="margin-left: 0rem;margin-right: 6rem; width: 100%;">
          <div class="col-sm-10" style="width: 100%;">
              <div class="row" style="justify-content: center!important;">
                <mat-card class="col-sm-4 card-container" *ngFor="let masterCat of masterCatList" style="box-shadow: none!important;">
                  <div class="col-sm-12 col-md-12 col-lg-12">
                    <div class="row">
                      <div class="col-sm-4 col-md-4 col-lg-4" style="box-shadow: 0 6px 25px rgba(15, 15, 15, 0.08), 0 0 10px rgba(71, 71, 71, 0.08);border-radius: 50%;width: 6.6rem;height: 6.6rem;text-align: center; padding: 16px;justify-content: center; cursor: pointer;" (click)="getCompanies(masterCat)">
                        <img [src]="masterCat.masterCatIcon" style="width: 4rem !important;opacity: 70%;">
                      </div>
                      <div class="col-sm-1 col-md-1 col-lg-1">
                      </div>
                      <div class="col-sm-7 col-md-7 col-lg-7 categorias">
                        <span style="line-height: 1.3; font-weight: 600;color: #898989; cursor: pointer;" (click)="getCompanies(masterCat)">{{masterCat.masterCatName}}</span>
                      </div>
                    </div>
                  </div>
                </mat-card>
              </div>
          </div>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-12" style="text-align: center; align-self: flex-end;margin-top: 2rem !important; margin-bottom: 90px!important;">
          <div class="carousel" id="carousel-h" >
            <ng-container *ngFor="let image of publicityListProduct1; index as i">
              <div class="carousel-item" id="carousel-item--h" [ngClass]="{ 'active': i === activeIndexP1 }">
                <img [src]="image.publicityBanner" (click)="openPublicity(image.publicityLink)">
              </div>
            </ng-container>
          </div>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-12" style="text-align: center;" id="companyContainer">
          <h1 class="subtitle">Empresas destacadas<span style="width: 40px;  height:40px;" class="autorenew-icon" ></span></h1>
          <div class="col-sm-12">
             <div class="container" id="featured-companies" style="margin-top: 60px !important">
              <div class="row">
                 <div class="col-sm-12">
                   <div class="card-container">
                    <div class="row mt-2 g-2" id="cards-featured--container">
                      <div class="col-md-3" *ngFor="let companies of filterFeatured" style="padding: 20px;">
                          <div class="card p-2" id="company-cards" (click)="navigateToCompanyInfo(companies.companyBussinesCode)">
                           
                            <div class="d-flex p-1 px-4 align-items-center" style="align-self: flex-end;"> <span class="square" style="position: absolute; top:40px; right: 40px;"><img *ngIf="companies.companyBadge === 1" src="../../../../assets/images/core/Insignia.png" height="40" width="40" /></span> </div>
                            <div class="image-container" style="height:200px; padding-top:40px"> 
                            <div class="email mt-1" style="text-align: center; width:100%;" *ngIf="companies.companyIcon == null">
                                <img src="../../../assets/images/core/Imagen_ProductoAvatar.jpg" style="max-width: 100% !important;height: 100% !important; margin-top: -50px !important;" (click)="navigateToCompanyInfo(companies.companyBussinesCode)">
                              </div>
                              
                              <div class="email mt-1" style="text-align: center; width:100%;" *ngIf="companies.companyIcon !== null">
                                <img [src]="companies.companyIcon" style="max-width: 70% !important;height: auto !important;" (click)="navigateToCompanyInfo(companies.companyBussinesCode)">
                              </div>
                            </div>
                              <div class="d-flex flex row align-items-center mr-2 mt-2" style="padding: 12px;">
                                     
                                <div class="icons" ><span style="font-size: larger; color: #004F9F; font-weight: 600;"> {{companies.companyName}}</span> </div>  
                                <div class="icons"> <i *ngFor="let masterCat of masterCatList  | filter2: companies" ><img src="{{masterCat.masterCatIcon}}" class="company-icon" ></i><small *ngIf="companies.newCategory == null"> {{companies.companyCategory}}</small></div>
                                <span class="hover top" (mouseenter)="mouseEvent(companies.newCategory)" (mouseleave)="mouseEvent2()" *ngIf="companies.newCategory != null"><small>{{companies.companyCategory}}<span *ngIf="companies.newCategory!= null"> +1</span></small>
                                  <span class="text">{{categoryToShow}}</span>
                                </span>
                              </div>  
                        </div>
                    </div>
                  </div>
                   </div>
                 </div>
              </div>
             </div>
          </div>
          <i (click)="getCompanies(' ')" class="reload-icon" (click)="checkCompany()"><mat-icon >autorenew</mat-icon></i>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-12" *ngIf="!getCompany">
          <div class="d-flex flex-row align-items-center"></div>
          <div class="row mt-1 g-4" id="cards-container" *ngIf="showTable">
              <div class="col-md-3" *ngFor="let companies of companiesList  | paginate: { itemsPerPage: pagination, currentPage: companies,  status: false } | filter:masterCat " style="padding: 20px;">
                  <div class="card p-2" id="company-cards" (click)="navigateToCompanyInfo(companies.companyBussinesCode)">
                   
                    <div class="d-flex p-1 px-4 align-items-center" style="align-self: flex-end;"> <span class="square" style="position: absolute; top:40px; right: 40px;"><img *ngIf="companies.companyBadge === 1" src="../../../../assets/images/core/Insignia.png" height="40" width="40" /></span> </div>
                    <div class="image-container" style="height:200px; padding-top:40px"> 
                    <div class="email mt-1" style="text-align: center; width:100%;" *ngIf="companies.companyIcon == null">
                        <img src="../../../assets/images/core/Imagen_ProductoAvatar.jpg" style="max-width: 100% !important;height: 100% !important; margin-top: -50px !important;" (click)="navigateToCompanyInfo(companies.companyBussinesCode)">
                      </div>
                      
                      <div class="email mt-1" style="text-align: center; width:100%;" *ngIf="companies.companyIcon !== null">
                        <img [src]="companies.companyIcon" style="max-width: 70% !important;height: auto !important;" (click)="navigateToCompanyInfo(companies.companyBussinesCode)">
                      </div>
                    </div>
                      <div class="d-flex flex row align-items-center mr-2 mt-2" style="padding: 12px;">
                             
                        <div class="icons" ><span style="font-size: larger; color: #004F9F; font-weight: 600;"> {{companies.companyName}}</span> </div>  
                        <div class="icons"> <i *ngFor="let masterCat of masterCatList  | filter2: companies" ><img src="{{masterCat.masterCatIcon}}" class="company-icon" ></i><small *ngIf="companies.newCategory == null"> {{companies.companyCategory}}</small></div>
                        <span class="hover top" (mouseenter)="mouseEvent(companies.newCategory)" (mouseleave)="mouseEvent2()" *ngIf="companies.newCategory != null"><small>{{companies.companyCategory}}<span *ngIf="companies.newCategory!= null"> +1</span></small>
                          <span class="text">{{categoryToShow}}</span>
                        </span>
                      </div>  
                </div>
            </div>
          </div>
          <div class="row mt-1 g-4" id="cards-container" *ngIf="!showTable">
            <div class="col-md-3" *ngFor="let companies of companySearch " style="padding: 20px;">
                <div class="card p-2" id="company-cards" (click)="navigateToCompanyInfo(companies.companyBussinesCode)">
                 
                  <div class="d-flex p-1 px-4 align-items-center" style="align-self: flex-end;"> <span class="square" style="position: absolute; top:40px; right: 40px;"><img *ngIf="companies.companyBadge === 1" src="../../../../assets/images/core/Insignia.png" height="40" width="40" /></span> </div>
                  <div class="image-container" style="height:200px; padding-top:40px"> 
                  <div class="email mt-1" style="text-align: center; width:100%;" *ngIf="companies.companyIcon == null">
                      <img src="../../../assets/images/core/Imagen_ProductoAvatar.jpg" style="max-width: 100% !important;height: 100% !important; margin-top: -50px !important;" (click)="navigateToCompanyInfo(companies.companyBussinesCode)">
                    </div>
                    
                    <div class="email mt-1" style="text-align: center; width:100%;" *ngIf="companies.companyIcon !== null">
                      <img [src]="companies.companyIcon" style="max-width: 70% !important;height: auto !important;" (click)="navigateToCompanyInfo(companies.companyBussinesCode)">
                    </div>
                  </div>
                    <div class="d-flex flex row align-items-center mr-2 mt-2" style="padding: 12px;">
                           
                        <div class="icons" ><span style="font-size: larger; color: #004F9F; font-weight: 600;"> {{companies.companyName}}</span> </div>  
                        <div class="icons"> <i *ngFor="let masterCat of masterCatList  | filter2: companies" ><img src="{{masterCat.masterCatIcon}}" class="company-icon" ></i><small *ngIf="companies.newCategory == null"> {{companies.companyCategory}}</small></div>
                        <span class="hover top" (mouseenter)="mouseEvent(companies.newCategory)" (mouseleave)="mouseEvent2()" *ngIf="companies.newCategory != null"><small>{{companies.companyCategory}}<span *ngIf="companies.newCategory!= null"> +1</span></small>
                          <span class="text">{{categoryToShow}}</span>
                        </span>
                    
                      </div>  
              </div>
          </div>
        </div>
          <div class="container">
            <div class="row align-items-start">
              <div class="col">

              </div>
        
          <div class="col-sm-12">
            <div class="container">
              <div class="row">
                <div class="col">
                  <div style="font-weight: lighter; width: 100%; display: flex;">
                    <pagination-controls class="pagination"(pageChange)="companies = $event" previousLabel="Anterior" nextLabel="Siguiente" style="margin: 0 auto;">  </pagination-controls>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-12">
            <div class="container">
              <div class="row">
                <div class="col-sm-12" style="text-align: end; font-weight: lighter; margin-right: 30px;">
                  <div class="row">
                  <div class="col-sm-6" style="text-align: left;" >
                    <span style="width: 100%;" *ngIf="userRole == 'Admin' || userRole == 'Master'">Total: {{companiesCount}}</span>
                  </div>
                  <div class="col-sm-6">
                    <section class="pagination-container" style="padding-right: 45px;">
                  <span>Mostrar </span>
                  <span  (click)="changePagination(12)" style="cursor: pointer;"><span class="first-number"> 12 </span></span>
                  <span  (click)="changePagination(24)" style="cursor: pointer;">|<span class="second-number"> 24 </span></span>
                  <span  (click)="changePagination(36)" style="cursor: pointer;">|<span class="third-number"> 36 </span></span>
                </section>
                </div>
              </div>
              </div>
                </div>
            </div>
          </div>
       
          </div>
        </div>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-12" *ngIf="getCompany && showTable">
          <div class="d-flex flex-row align-items-center"></div>
          <div class="row mt-1 g-4" id="cards-container">
              <div class="col-md-3" *ngFor="let companies of categoryToFilter " style="padding: 20px;">
                  <div class="card p-2" id="company-cards">
                   
                    <div class="d-flex p-1 px-4 align-items-center" style="align-self: flex-end;"> <span class="square" style="position: absolute; top:40px; right: 40px;"><img *ngIf="companies.companyBadge === 1" src="../../../../assets/images/core/Insignia.png" height="40" width="40" /></span> </div>
                    <div class="image-container" style="height:200px; padding-top:40px"> 
                    <div class="email mt-1" style="text-align: center; width:100%;" *ngIf="companies.companyIcon == null">
                        <img src="../../../assets/images/core/Imagen_ProductoAvatar.jpg" style="max-width: 100% !important;height: 100% !important; margin-top: -50px !important;" (click)="navigateToCompanyInfo(companies.companyBussinesCode)">
                      </div>
                      
                      <div class="email mt-1" style="text-align: center; width:100%;" *ngIf="companies.companyIcon !== null">
                        <img [src]="companies.companyIcon" style="max-width: 70% !important;height: auto !important;" (click)="navigateToCompanyInfo(companies.companyBussinesCode)">
                      </div>
                    </div>
                      <div class="d-flex flex row align-items-center mr-2 mt-2" style="padding: 12px;">
                             
                        <div class="icons" ><span style="font-size: larger; color: #004F9F; font-weight: 600;"> {{companies.companyName}}</span> </div>  
                        <div class="icons"> <i *ngFor="let masterCat of masterCatList  | filter2: companies" ><img src="{{masterCat.masterCatIcon}}" class="company-icon" ></i><small *ngIf="companies.newCategory == null"> {{companies.companyCategory}}</small></div>
                        <span class="hover top" (mouseenter)="mouseEvent(companies.newCategory)" (mouseleave)="mouseEvent2()" *ngIf="companies.newCategory != null"><small>{{companies.companyCategory}}<span *ngIf="companies.newCategory!= null"> +1</span></small>
                          <span class="text">{{categoryToShow}}</span>
                        </span>
                      </div>  
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    
</mat-card>




