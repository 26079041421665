
<div class="col-sm-12">
    <div class="row">
        <div class="col-sm-6">
            <img src="../../../assets/images/core/help-section.jpeg" class="help-image">
        </div>
        <div class="col-sm-6">
            <div class="space-without" style="height: 50px;"></div>
             <section class="tutorial-section">
                <div class="row">
                    <div class="col-sm-12">
                        <section class="tutorial-container">
                        <div class="row">
                            <div class="col-sm-4">
                                <div class="img-container"><img src="../../../assets/images/core/CIG_M-lanczos3.png"> </div>
                            </div>
                            <div class="col-sm-6">
                                <div class="title">Tutoriales</div>
                            </div>
                        </div>
                        </section>
                      
                    </div>
                    <div class="col-sm-12">
                        <section class="description-container">
                            <div class="description">Aprende más por medio de video tutoriales</div>
                        </section>
                    </div>
                    <div class="col-sm-12">
                        <section class="button-container">
                           <a href="/VideoTutoriales">
                            <button class="button">Conoce más</button>
                           </a>
                        </section>
                       
                    </div>
                </div>
             </section>
    </div>
    </div>
    </div>
    <div class="row">
        <div class="col-sm-6">
            <section class="tutorial-section">
                <div class="row">
                    <div class="col-sm-12">
                        <section class="tutorial-container">
                        <div class="row">
                            <div class="col-sm-4">
                                <div class="img-container"><img src="../../../assets/images/core/CIG_M-lanczos3.png"> </div>
                            </div>
                            <div class="col-sm-6">
                                <div class="title-info">Infografías</div>
                            </div>
                        </div>
                        </section>
                      
                    </div>
                    <div class="col-sm-12">
                        <section class="description-container">
                            <div class="description">Aprende más por medio de material didáctico</div>
                        </section>
                    </div>
                    <div class="col-sm-12">
                        <section class="button-container">
                            <button class="button">Próximamente</button>
                        </section>
                       
                    </div>
                </div>
             </section>
        </div>
        <div class="col-sm-6">
              <img src="../../../assets/images/core/help-section2.jpeg" width="100%" class="info-image">
        </div>
    </div>