<app-loading-wrapper-overlay *ngIf="loading"></app-loading-wrapper-overlay>
<mat-card class="masterCard">
  <div class="col-sm-12">
    <div class="row">
      <div class="example-button-container">
        <button mat-fab style="background-color: #cab337;" aria-label="Habilitar columna de administración" (click)="adminActiveToggle()">
          <mat-icon>admin_panel_settings</mat-icon>
        </button>
      </div>
      <div class="example-button-container">
        <button mat-fab style="background-color: green;" aria-label="Nueva publicidad" (click)="createNewPublicity()">
          <mat-icon>add_comment</mat-icon>
        </button>
      </div>
    </div>
  </div>
  <div class="col-sm-12">
    <div class="container" style="min-width: 100% !important;">
       <div class="col" style="width:100% !important; height: 30px!important; text-align: start; margin-bottom: 50px!important;">
           <mat-form-field class="ub-form" appearance = "none" style="background-color: #f8f9fa; width: 20%; padding-left: 12px!important;" >
            <mat-label style="padding: 12px !important;">Filtrar por ubicación</mat-label>
             <mat-select (selectionChange) ="fillUbication($event)">
               <mat-option [value] ="place.code" *ngFor = "let place of placeData">
                    {{place.name}}
               </mat-option>
             </mat-select>
           </mat-form-field>
           <i (click)="reload()" class="reload-icon"><mat-icon style="margin-left: 20px;">autorenew</mat-icon></i>
       </div>
      <div class="table-responsive">
          <table class="table table-responsive table-borderless" style="background-color: white; margin-bottom: 0.5px;">
              <thead>
                  <tr class="bg-light" style="text-align: -webkit-center;">
                      <th scope="col" width="20%">Nombre</th>
                      <th scope="col" width="10%">Fecha de publicación</th>
                      <th scope="col" width="20%">Creado por: </th>
                      <th scope="col" width="10%">Estado</th>
                      <th scope="col" width="20%">Ubicación</th>
                      <th *ngIf="adminActive" scope="col" width="5%">Editar</th>
                      <th *ngIf="adminActive" scope="col" width="5%">Eliminar</th>
                      <th *ngIf="adminActive" scope="col" width="20%">Estado</th>
                      
                      
                  </tr>
              </thead>
              <tbody *ngIf = "ubicationSearch == undefined">
                  <tr style="text-align: -webkit-center;border-bottom: 0.5px solid rgb(202, 202, 202);" *ngFor="let publicity of publicityList">
                      <td>{{publicity.publicityName}}</td>
                      <td>{{publicity.publicityCreationDate | date : 'dd-MM-yy'}}</td>
                      <td><img src="https://i.imgur.com/VKOeFyS.png" width="25"> {{publicity.publicityUserCreation}}</td>
                      <td *ngIf="publicity.publicityStatus === 'Activo'"><i class="fas fa-check" style="font-size: 1rem; color: green;" ></i>{{publicity.publicityStatus}}</td>
                      <td *ngIf="publicity.publicityStatus !== 'Activo'"><i class="fas fa-times" style="font-size: 1rem; color: red;" ></i>{{publicity.publicityStatus}}</td>
                      <td *ngFor="let place of placeData | filter3: publicity">{{place}}</td>
                      <td *ngIf="adminActive" ><img src="../../../assets/images/editPublicity.png" style="width: 1.6rem;cursor: pointer;opacity: 80%;" (click)="editPublicity(publicity)"></td>
                      <td *ngIf="adminActive"><img src="../../../assets/images/deletePublicity.png" style="width: 1.8rem;cursor: pointer;opacity: 80%;"  (click)="deletePublicity(publicity)"></td>
                      <td *ngIf="publicity.publicityStatus === 'Activo' && adminActive"><mat-slide-toggle (change)="inactivatePublicity(publicity)">Desactivar</mat-slide-toggle></td>
                      <td *ngIf="publicity.publicityStatus !== 'Activo' && adminActive"><mat-slide-toggle (change)="activatePublicity(publicity)">Activar</mat-slide-toggle></td>
                      
                  </tr>
              </tbody>
              <tbody *ngIf = "ubicationSearch != undefined">
                <tr style="text-align: -webkit-center;border-bottom: 0.5px solid rgb(202, 202, 202);" *ngFor="let publicity of publicityList2">
                    <td>{{publicity.publicityName}}</td>
                    <td>{{publicity.publicityCreationDate | date : 'dd-MM-yy'}}</td>
                    <td><img src="https://i.imgur.com/VKOeFyS.png" width="25"> {{publicity.publicityUserCreation}}</td>
                    <td *ngIf="publicity.publicityStatus === 'Activo'"><i class="fas fa-check" style="font-size: 1rem; color: green;" ></i>{{publicity.publicityStatus}}</td>
                    <td *ngIf="publicity.publicityStatus !== 'Activo'"><i class="fas fa-times" style="font-size: 1rem; color: red;" ></i>{{publicity.publicityStatus}}</td>
                    <td *ngFor="let place of placeData | filter3: publicity">{{place}}</td>
                    <td *ngIf="adminActive" ><img src="../../../assets/images/editPublicity.png" style="width: 1.6rem;cursor: pointer;opacity: 80%;" (click)="editPublicity(publicity)"></td>
                      <td *ngIf="adminActive"><img src="../../../assets/images/deletePublicity.png" style="width: 1.8rem;cursor: pointer;opacity: 80%;"  (click)="deletePublicity(publicity)"></td>
                    <td *ngIf="publicity.publicityStatus === 'Activo' && adminActive"><mat-slide-toggle (change)="inactivatePublicity(publicity)">Desactivar</mat-slide-toggle></td>
                    <td *ngIf="publicity.publicityStatus !== 'Activo' && adminActive"><mat-slide-toggle (change)="activatePublicity(publicity)">Activar</mat-slide-toggle></td>
                 </tr>
              </tbody>
          </table>
      </div>
  </div>
  </div>
</mat-card>
