<mat-dialog-content>
    <app-loading-wrapper-overlay *ngIf="loading"></app-loading-wrapper-overlay>
    <mat-card style="background-color: #FFFFFF; margin: 15px !important; box-shadow: 0px !important;" class="mat-elevation-z0">
      <div class="row" style="align-items: center;">
        <div class="col-sm-6 col-md-6 col-lg-6" style="text-align: left !important;" id="col-sm--info">
          <h2>Editar producto</h2>
        </div>
      </div>
      <table class="example-full-width" cellspacing="0"><tr>
        <td><mat-form-field class="example-full-width" appearance="none">
          <mat-label><div style="margin-top: -8px !important; margin-left: 15px;">NOMBRE DE PRODUCTO</div></mat-label>
          <input matInput [(ngModel)]="products.productName">
          </mat-form-field></td>
          <td><mat-form-field class="example-full-width" appearance="none">
            <mat-label><div style="margin-top: -8px !important; margin-left: 15px;">EMPRESA</div></mat-label>
            <input style="color: black; font-weight: bold;" matInput [(ngModel)]="userDataToShow.userCompanyName" disabled>
          </mat-form-field></td>
          <td><mat-form-field class="example-full-width" appearance="none">
            <mat-label><div style="margin-top: -8px !important; margin-left: 15px;">CONTACTO SOLICITANTE</div></mat-label>
            <input style="color: black; font-weight: bold;" matInput [(ngModel)]="userDataToShow.userEmail" disabled>
          </mat-form-field></td>
        </tr>
      </table>
      <table class="example-full-width" cellspacing="0" style="width: 93.2% !important;"><tr>
        <td>
          <mat-form-field class="example-full-width" appearance="none">
            <mat-label>Categoría</mat-label>
            <mat-select [formControl]="categoria" (selectionChange)="listCategories($event)" [(ngModel)]="products.productCategoryUUID" required>
              <mat-option [value]="masterCat.masterCatUUID" *ngFor="let masterCat of masterCatList">
                {{masterCat.masterCatName}}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="categoria.hasError('required')">Seleccione una categoría para continuar</mat-error>
            <mat-hint>{{categoria.value?.sound}}</mat-hint>
          </mat-form-field>
        </td>
        <td>
          <mat-form-field class="example-full-width" appearance="none">
            <mat-label>Sub-categoría</mat-label>
            <mat-select [formControl]="subcategoria" [disabled]="subCatStatus" (selectionChange)="listSubCat($event)" [(ngModel)]="products.productSubCatUUID" required>
              <mat-option [value]="subcat.catUUID" *ngFor="let subcat of categoriesList">
                {{subcat.catName}}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="subcategoria.hasError('required')">Seleccione una subcategoría para continuar</mat-error>
            <mat-hint>{{subcategoria.value?.sound}}</mat-hint>
          </mat-form-field>
        </td>
        <td>
          <mat-form-field class="example-full-width" appearance="none">
            <mat-label>Familia de sub-categoria</mat-label>
            <mat-select [formControl]="items" [disabled]="familyCatStatus" [(ngModel)]="products.itemFamilyUUID" (selectionChange)="itemFamily($event)" required>
              <mat-option [value]="families.subCatUUID" *ngFor="let families of subcategoryList" (click)="changeFamilyItem(families.subCatDescription)">
                {{families.subCatName}}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="items.hasError('required')">Relacione un grupo a la subcategoría</mat-error>
            <mat-hint>{{items.value?.sound}}</mat-hint>
          </mat-form-field>
        </td>
        <!-- <td style= "padding-bottom: 24px; width: 1px !important;" (click)="addCategory()">
          <span class="hover top">
            <span class="addButton" style="cursor: pointer; width: 40px; height: 90px;">
              <i class="fas fa-plus" style="width: 20px; height: 20px;"></i>
            </span>
            <span class="text">
              Agregar otra categoría.
            </span>
          </span>
        </td> -->
        </tr>
      </table>
      <section class="new-table" *ngIf="categoryCant">
      <table class="example-full-width" cellspacing ="0">
        <tr>
          <td >
            <mat-form-field class="example-full-width" appearance="none">
              <mat-label>Categoría</mat-label>
              <mat-select [formControl]="categoriaTwo" (selectionChange)="listOtherCategories($event)" [(ngModel)]="newCategory.productCategoryUUID" required>
                <mat-option [value]="masterCat.masterCatUUID" *ngFor="let masterCat of masterCatList" (click)="addExtraCategory(masterCat.masterCatName)">
                  {{masterCat.masterCatName}}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="categoriaTwo.hasError('required')">Seleccione una categoría para continuar</mat-error>
              <mat-hint>{{categoria.value?.sound}}</mat-hint>
            </mat-form-field>
          </td>
          <td>
            <mat-form-field class="example-full-width" appearance="none">
              <mat-label>Sub-categoría</mat-label>
              <mat-select [formControl]="subcategoriaTwo" [disabled]="subCatStatusTwo" (selectionChange)="listOtherSubCat($event)" [(ngModel)]="newCategory.productSubCatUUID" required>
                <mat-option [value]="subcat.catUUID" *ngFor="let subcat of categoriesListTwo" (click)="addExtraSubCat(subcat.catDescription)">
                  {{subcat.catName}}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="subcategoriaTwo.hasError('required')">Seleccione una subcategoría para continuar</mat-error>
              <mat-hint>{{subcategoria.value?.sound}}</mat-hint>
            </mat-form-field>
          </td>
          <td>
            <mat-form-field class="example-full-width" appearance="none">
              <mat-label>Familia de sub-categoria</mat-label>
              <mat-select [formControl]="itemsTwo" [disabled]="familyCatStatusTwo" [(ngModel)]="newCategory.itemFamilyUUID" (selectionChange)="itemOtherFamily($event)" required>
                <mat-option [value]="families.subCatUUID" *ngFor="let families of subcategoryListTwo" (click)="addExtraFamily(families.subCatName)" (click)="changeFamilyItem2(families.subCatDescription)">
                  {{families.subCatName}}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="itemsTwo.hasError('required')">Relacione un grupo a la subcategoría</mat-error>
              <mat-hint>{{items.value?.sound}}</mat-hint>
            </mat-form-field>
          </td>
      </table>
    </section>
      <table class="example-full-width" cellspacing="0"><tr>
        <td>
          <mat-form-field class="example-full-width" appearance="none" id="text-area">
            <mat-label>Descripción del producto</mat-label>
            <textarea matInput placeholder="Descripción del producto" [(ngModel)]="products.productDescription" style="min-height: 110px;max-height: 110px;" maxlength="400"></textarea>
            <span style="display: block;" class="char-counter"*ngIf="products.productDescription != null" >{{charCounter | charCount: products.productDescription}}/400</span>
          </mat-form-field>
        </td>
        </tr>
      </table>
      <div class="col-sm-6 col-md-6 col-lg-6" style="text-align: left !important;" id="col-sm--precios">
        <h2>Precio(s)</h2>
      </div>
      <div class="col-sm-4 col-md-4 col-lg-4" style="margin: 1rem 0 1rem 0;">
        <div class="row">
          <div class="col-sm-2 col-md-2 col-lg-2" id="price">
            <mat-label>Precio unitario</mat-label> 
            <mat-slide-toggle [(ngModel)]="unitaryPrice" (change)="checkValue($event)" [disabled]="disabled" ></mat-slide-toggle>
          </div>
          <div class="col-sm-2 col-md-2 col-lg-2">
            <mat-label>Precio mayoreo</mat-label>
            <mat-slide-toggle [(ngModel)]="mayorPrice" (change)="checkValue($event)"></mat-slide-toggle>
          </div>
        </div>
      </div>
      <div class="example-container" *ngIf="unitaryPrice">
    
        <mat-form-field appearance="none" floatLabel="always">
          <mat-label><div style="margin-top: -8px !important; margin-left: 15px;">Precio unitario</div></mat-label>
          <input  matInput type="number" class="example-right-align" id="input-price" placeholder="0" [(ngModel)]="products.productPrice" >
          <span matPrefix>{{this.priceCurrency}}&nbsp;</span>
        </mat-form-field>
    
        <mat-form-field appearance="none">
          <mat-label>Unidad</mat-label>
          <mat-select [(ngModel)]="products.unitSystemValue">
            <mat-option [value]="units.name" *ngFor="let units of unitList">{{units.name}}</mat-option>
          </mat-select>
        </mat-form-field>
    
        <mat-form-field appearance="none" class="currency">
          <mat-label>Moneda</mat-label> 
          <mat-select [(ngModel)]="products.unitPriceType" (selectionChange) ="currency($event)">
            <mat-option [value]="prices.name" *ngFor="let prices of priceList" >{{prices.name}}</mat-option>
          </mat-select>
          </mat-form-field>
    
      </div>
      <div class="example-container" *ngIf="mayorPrice" id="mayor-price">
        <mat-form-field appearance="none">
          <mat-label>DESDE</mat-label>
          <input matInput [(ngModel)]="products.priceRange11">
        </mat-form-field>
    
        <mat-form-field appearance="none">
          <mat-label>HASTA</mat-label>
          <input matInput [(ngModel)]="products.priceRange12">
        </mat-form-field>
    
        <mat-form-field appearance="none" floatLabel="always">
          <mat-label>Precio</mat-label>
          <input matInput type="number" class="example-right-align" placeholder="0" [(ngModel)]="products.priceRangeValue1">
          <span matPrefix>{{this.priceCurrency}}&nbsp;</span>
        </mat-form-field>
      </div>
    
      <div class="example-container" *ngIf="mayorPrice">
        <mat-form-field appearance="none">
          <mat-label>DESDE</mat-label>
          <input matInput [(ngModel)]="products.priceRange21">
        </mat-form-field>
    
        <mat-form-field appearance="none">
          <mat-label>HASTA</mat-label>
          <input matInput [(ngModel)]="products.priceRange22">
        </mat-form-field>
    
        <mat-form-field appearance="none" floatLabel="always">
          <mat-label>Precio</mat-label>
          <input matInput type="number" class="example-right-align" placeholder="0" [(ngModel)]="products.priceRangeValue2">
          <span matPrefix>{{this.priceCurrency}}&nbsp;</span>
        </mat-form-field>
      </div>
    
      <div class="example-container" *ngIf="mayorPrice">
        <mat-form-field appearance="none">
          <mat-label>DESDE</mat-label>
          <input matInput [(ngModel)]="products.priceRange31">
        </mat-form-field>
    
        <mat-form-field appearance="none">
          <mat-label>HASTA</mat-label>
          <input matInput [(ngModel)]="products.priceRange32">
        </mat-form-field>
    
        <mat-form-field appearance="none" floatLabel="always">
          <mat-label>Precio</mat-label>
          <input matInput type="number" class="example-right-align" placeholder="0" [(ngModel)]="products.priceRangeValue3">
          <span matPrefix>{{this.priceCurrency}}&nbsp;</span>
        </mat-form-field>
      </div>
    
      <div class="col-sm-6 col-md-6 col-lg-6" style="text-align: left !important;">
        <h2>Imágenes</h2>
      </div>
      <div class="row">
        <div class="col-sm-6">
  
 
              <img [src]="this.data.value.productMainPicture" width="12%">

          <label class="custom-file-label" #labelImportPrincipal for="imagenPrincipal" *ngIf="this.data.value.productMainPicture == null">Subir imagen</label>
          <button  mat-icon-button>
            <mat-icon  for="imagenPrincipal" (click)="imagenPrincipal.click()" style="font-size: 4rem;"><div class="upload_arrow"></div></mat-icon>
            <input nbInput type="file" class="custom-file-input" #imagenPrincipal id="imagenPrincipal" accept="image/x-png,image/gif,image/jpeg" (change)="principalImage($event.target.files)" hidden>
          </button>
             <mat-icon *ngIf ="principalimage != null || this.data.value.productMainPicture != null" class="cancel-button" (click)="principalimage = null; principalImage(' ')">close</mat-icon>
        </div>
        <div class="col-sm-6">
          <img [src]="this.data.value.productP1" width="12%">
          <label class="custom-file-label" #labelImportP1 for="importFile1" *ngIf="this.data.value.productP1 == null"> Subir imagen</label>
          <button  mat-icon-button>
            <mat-icon  for="importFile1" (click)="importFile1.click()" style="font-size: 4rem;"><div class="upload_arrow"></div></mat-icon>
            <input nbInput type="file" class="custom-file-input" #importFile1 id="importFile1" accept="image/x-png,image/gif,image/jpeg" (change)="image1($event.target.files)" hidden>
          </button>
          <mat-icon *ngIf ="p1 != null ||this.data.value.productP1 != null" class="cancel-button" (click)="p1 = null; image1(' ')">close</mat-icon>
        </div>
        <div class="col-sm-6">
          <img [src]="this.data.value.productP2" width="12%">
          <label class="custom-file-label" #labelImportP2 for="importFile2" *ngIf="this.data.value.productP2 == null"> Subir imagen</label>
          <button  mat-icon-button>
            <mat-icon  for="importFile2" (click)="importFile2.click()" style="font-size: 4rem;"><div class="upload_arrow"></div></mat-icon>
            <input nbInput type="file" class="custom-file-input" #importFile2 id="importFile2" accept="image/x-png,image/gif,image/jpeg" (change)="image2($event.target.files)" hidden>
            <mat-icon *ngIf ="p2 != null ||this.data.value.productP2 != null" class="cancel-button" (click)="p2 = null; image2(' ')">close</mat-icon>
          </button>
        </div>
        <div class="col-sm-6">
          <img [src]="this.data.value.productP3" width="12%">
          <label class="custom-file-label" #labelImportP3 for="importFile3" *ngIf="this.data.value.productP3 == null"> Subir imagen</label>
          <button  mat-icon-button>
            <mat-icon  for="importFile3" (click)="importFile3.click()" style="font-size: 4rem;"><div class="upload_arrow"></div></mat-icon>
            <input nbInput type="file" class="custom-file-input" #importFile3 id="importFile3" accept="image/x-png,image/gif,image/jpeg" (change)="image3($event.target.files)" hidden>
            <mat-icon *ngIf ="p3 != null ||this.data.value.productP3 != null" class="cancel-button" (click)="p3 = null; image3(' ')">close</mat-icon>
          </button>
        </div>
         <div class="col-sm-7">
           <div class="instructions">
             <p class="ins-re"><br>Recomendaciones:<br>
             - Subir las fotografías de manera horizontal para una mejor presentación.<br>
             - Colocar de preferencia las 4 imágenes de distintos ángulos o en donde se vean de mejor manera el producto/servicio.<br>
             - Formatos no admisibles BMP, ICO, TIFF, RAW.<br>
             - Dimensiones de imagen ideal de 800x640 pixel.<br>
             - Evitar utilizar imágenes o fotografías con mucho texto, no saturadas y que muestren tu producto/servicio de manera clara.</p>
            </div>
         </div>
      </div>
      <div class="col-sm-12" style="margin: 2rem 0 0 0; text-align: center;"
      *ngIf="userDataToShow.userCompanyName
      && userDataToShow.userEmail
      && products.productCategoryUUID
      && products.productDescription">
        <button mat-raised-button style="background-color: green; color: white;  width: 30%;" (click)="uploadImage()"><mat-icon>save</mat-icon>Actualizar</button>
        <button mat-raised-button style="background-color: rgb(117, 1, 1); color: white; margin-left: 40px; width: 30%;" (click)="closeWindow()"><mat-icon>close</mat-icon>Cerrar</button>
      </div>

    </mat-card>

</mat-dialog-content>