import { Injectable, NgZone } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AuthenticationDetails, CognitoUser, CognitoUserAttribute, CognitoUserPool } from 'amazon-cognito-identity-js';
import { ProductsModel } from '../models/productsModel';
import { newCategoryModel } from '../models/newCatModel';
import * as S3 from 'aws-sdk/clients/s3';
import { AuthServicesService } from './auth-services.service';

const POOL_DATA = {
  UserPoolId: environment.UserPoolId,
  ClientId: environment.ClientId
};

const userPool = new CognitoUserPool(POOL_DATA);
@Injectable({
  providedIn: 'root'
})

export class ProductsService {
  AWS = require('aws-sdk');
  constructor(
    private http: HttpClient,
    private getAccess: AuthServicesService
  ) {}
  apiurl = environment.EnvironmentName;
  saveProductOnTable(product: ProductsModel): Promise<any> {
    let params = JSON.stringify(product);
    const headers = new HttpHeaders().set('Content-Type', 'application/json');

    return this.http.post(environment.API_URL + this.apiurl+'-product', params, { headers}).toPromise();
  }

  saveNewCategoryProduct(category: newCategoryModel ): Promise<any>{
     let params = JSON.stringify(category);
     const headers = new HttpHeaders().set('Content-Type', 'application/json');
     return this.http.post(environment.API_URL + this.apiurl+'-insertNewCategory', params, {headers}).toPromise();
  }
  getOneProductCategory(categoryUUID){
    const filter = categoryUUID;
    return this.http.get(environment.API_URL + this.apiurl+'-getOneProductCategory' + '/one/' + filter).toPromise();
  }

  // getProductsByFamilyItem(familyItem){
  //   const param = JSON.stringify(familyItem);
  //   const headers = new HttpHeaders().set('Content-Type', 'application/json');
  //   return this.http.get(environment.API_URL + this.apiurl+'productByFamilyItem' + param, {headers}).toPromise();
  // }

  getProductsByFamilyItem(familyItem){
    const filter = familyItem;
    return this.http.get(environment.API_URL + this.apiurl+'-getProductsByFamilyItem' + '/one/' + filter).toPromise();
  }

  updatePendingProduct(product):Promise<any>{
    let params = JSON.stringify(product);
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.post(environment.API_URL + this.apiurl +'-productPendingUpdate', params, { headers}).toPromise();

}

  insertProductSearch(product: ProductsModel): Promise<any> {
    let params = JSON.stringify(product);
    const headers = new HttpHeaders().set('Content-Type', 'application/json');

    return this.http.post(environment.API_URL + this.apiurl+'-productInsertSearch', params, { headers}).toPromise();
  }
  productInsertFavorite(product): Promise<any> {
    let params = JSON.stringify(product);
    const headers = new HttpHeaders().set('Content-Type', 'application/json');

    return this.http.post(environment.API_URL + this.apiurl+'-productInsertFavorite', params, { headers}).toPromise();
  }

  productDelete(product): Promise<any> {
    let params = JSON.stringify(product);
    const headers = new HttpHeaders().set('Content-Type', 'application/json');

    return this.http.post(environment.API_URL + this.apiurl+'-productDelete', params, { headers}).toPromise();
  }

  productDeleteFavorite(product): Promise<any> {
    let params = JSON.stringify(product);
    const headers = new HttpHeaders().set('Content-Type', 'application/json');

    return this.http.post(environment.API_URL + this.apiurl+'-productDeleteFavorite', params, { headers}).toPromise();
  }

  productGetAll(): Promise<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');

    return this.http.get(environment.API_URL + this.apiurl+'-product', { headers}).toPromise();
  }

  productGetAllPending(): Promise<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');

    return this.http.get(environment.API_URL + this.apiurl+'-productsGetAllPending', { headers}).toPromise();
  }

  productGetSearch(): Promise<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');

    return this.http.get(environment.API_URL + this.apiurl+'-productsGetProductSearch', { headers}).toPromise();
  }

  productGetOne(productUUID){
    const filter = productUUID;
    return this.http.get(environment.API_URL + this.apiurl+'-productGetOne' + '/one/' + filter).toPromise();
  }

  productGetFavorite(userUUID){
    const filter = userUUID;
    return this.http.get(environment.API_URL + this.apiurl+'-productGetFavorite' + '/one/' + filter).toPromise();
  }

  getProductWanted(productSearch){
    const filter = productSearch;
    return this.http.get(environment.API_URL + this.apiurl+ '-getProductWanted' + '/one/' + filter).toPromise();
  }

  updateProduct(productList): Promise<any>{
    let params = JSON.stringify(productList);
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.post(environment.API_URL + this.apiurl+'-updateProduct', params, { headers}).toPromise();
  }

 async uploadFileS3(file, companyUUID, productUUID): Promise<any> {
  try {
    const response: any = await this.getAccess.getAccess();
    const access = response.accessKeyId;
    const secret = response.secretAccessKey;
    const session = response.sessionToken;
    const contentType = file.type;

    this.AWS.config.update({
        region: environment.region,
        accessKeyId: access,
        secretAccessKey: secret,
        sessionToken: session,
    });

    const s3 = new this.AWS.S3();
      
    const params = {
        Bucket: environment.bucketFiles,
        Key: 'products' + '/' + companyUUID + '/' + productUUID + '/' + file.name,
        Body: file,
        ContentType: contentType
    };
    
    return new Promise((resolve, reject) => {
        s3.putObject(params, (err, data) =>{
            if(err){
                console.log(err.message);
                return reject(err);
            } else {
                data.Location = environment.cloudFrontUrl + params.Key.replace(' ', '+');
                return resolve(data);
            }
        });
    });
} catch (error) {
    console.log(error);
    throw error;
}
  }

 async uploadFileS3publicity(file, companyUUID, productUUID): Promise<any> {
    try {
      const response: any = await this.getAccess.getAccess();
      const access = response.accessKeyId;
      const secret = response.secretAccessKey;
      const session = response.sessionToken;
      const contentType = file.type;
  
      this.AWS.config.update({
          region: environment.region,
          accessKeyId: access,
          secretAccessKey: secret,
          sessionToken: session,
      });
  
      const s3 = new this.AWS.S3();
        
      const params = {
          Bucket: environment.bucketFiles,
          Key: 'products' + '/' + companyUUID + '/' + productUUID + '/' + file.name,
          Body: file,
          ContentType: contentType
      };
      
      return new Promise((resolve, reject) => {
          s3.putObject(params, (err, data) =>{
              if(err){
                  console.log(err.message);
                  return reject(err);
              } else {
                  data.Location = environment.cloudFrontUrl + params.Key.replace(' ', '+');
                  return resolve(data);
              }
          });
      });
  } catch (error) {
      console.log(error);
      throw error;
  }
  }

  async sendEmail(funcionArgs: any) {
    try {
      const response: any = await this.getAccess.getAccess();
      const access = response.accessKeyId;
      const secret = response.secretAccessKey;
      const session = response.sessionToken;

    this.AWS.config.update({
      accessKeyId: access,
      secretAccessKey: secret,
      sessionToken: session,
      region: environment.regionSES
    });
    const lambda = new this.AWS.Lambda({apiVersion: '2015-03-31'});

    var params = {
      // FunctionName: 'arn:aws:lambda:us-east-1:405522164029:function:cigmatchbackend-qa-userSendCredentials',
      // FunctionName: 'arn:aws:lambda:us-east-1:405522164029:function:cigmatchbackend-dev-sendEmailPending',
      FunctionName: 'arn:aws:lambda:us-west-2:120259615165:function:cigmatchbackendprod-prod-sendEmailPending',
      // FunctionName: 'arn:aws:lambda:us-west-2:120259615165:function:cigmatchbackendprod-prod-userSendCredentials',
      Payload: JSON.stringify(funcionArgs),
      InvocationType: 'RequestResponse'
      // Qualifier: "1"
     };
     
     return new Promise(async (resolve, reject)=>{
      await lambda.invoke(params,  (err, data) => {
        if(err){
          reject(err);
          return;
        }
        resolve(data);
      });
    });
  }  catch (error) {
      console.log(error);
      throw error;
  }
   }
}
