<div class="col-sm-12">
    <div class="container" id="video-container">
        <div class="row">
            <div class="col-sm-12">
                <div class="title-container">
                    <span class="title"> {{titleVideo}}</span>
                </div>
              
            </div>
            <div class="col-sm-12">
                <div class="container">
                    <div class="row">
                        <div class="col-sm-3">
                            <div class="description-container">
                                <span class="description">{{videoDescription}}</span>
                            <div class="text-decoration"> </div>
                        </div>
                        </div>
                        <div class="col-sm-9" *ngIf="videoUrl == 'Directorio'">
                            <div class="video-container">
                                <iframe width="800" height="415" src="https://www.youtube.com/embed/sdLZ-NvvF5Y" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen class="video"></iframe>
                            </div>
                        </div>
                        <div class="col-sm-9" *ngIf="videoUrl == 'categorias'">
                            <div class="video-container">
                                <iframe width="800" height="415" src="https://www.youtube.com/embed/6mDinkf96pY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen class="video"></iframe>
                            </div>
                        </div>
                        <div class="col-sm-9" *ngIf="videoUrl == 'portal'">
                            <div class="video-container">
                                <iframe width="800" height="415" src="https://www.youtube.com/embed/U9eg-B4ol-c" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen class="video"></iframe>
                            </div>
                        </div>
                        <div class="col-sm-9" *ngIf="videoUrl == 'publicar'">
                            <div class="video-container">
                                <iframe width="800" height="415" src="https://www.youtube.com/embed/_pjktsKYf0A" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen class="video"></iframe>
                            </div>
                        </div>
                        <div class="col-sm-9" *ngIf="videoUrl == 'mensajeria'">
                            <div class="video-container">
                                <iframe width="800" height="415" src="https://www.youtube.com/embed/jbGCVl6fI8c" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen class="video"></iframe>
                            </div>
                        </div>
                    </div>
                </div>
               
            </div>
           
               
        </div>
    </div>
</div>