import { MessageCenterService } from './../../../services/messageCenter.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CompanyModel } from 'src/app/models/companyModel';
import { ProductsModel } from 'src/app/models/productsModel';
import { CompanyService } from 'src/app/services/company.services';
import { ProductsService } from 'src/app/services/products.service';
import { MasterChatTableModel } from 'src/app/models/masterChatTableModel';
import { ChatDialogComponent } from 'src/app/core/user-profile/messageCenterViews/answer-pending/chat-dialog/chat-dialog.component';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import Drift from 'drift-zoom';
import { MatDialog } from '@angular/material/dialog';
import { PublicityService } from 'src/app/services/publicity.service';
import { v4 as uuidv4 } from 'uuid';
import { empty } from 'rxjs';
import { arrow } from '@popperjs/core';
import { EditProductDialogComponent } from '../../../core/user-profile/my-profile/edit-product-dialog/edit-product-dialog.component';

@Component({
  selector: 'app-product-one-view',
  templateUrl: './product-one-view.component.html',
  styleUrls: ['./product-one-view.component.scss']
})
export class ProductOneViewComponent implements OnInit {
  companyData = {} as CompanyModel;
  messagesModel = {} as MasterChatTableModel;
  rating3: number;
  loading = false;
  productsData = {} as ProductsModel;
  chatId = undefined;
  productsList;
  productsList1;
  productsListStart=0;
  productsListFinished=3;
  mesage="";
  productsListStartEnable=false;
  productsListFinishedEnable=false;
  //Productos Similares
  productsListSimilar;
  productsList1Similar;
  productsListStartSimilar=0;
  productsListFinishedSimilar=3;
  productsListStartEnableSimilar=false;
  productsListFinishedEnableSimilar=false;
  userLog;
  createChat:Boolean = false;
  sameUser:Boolean = true;
  charCounter:Boolean = false;
  public showImageZoom: Boolean = false;
  public image;
  public carouselImage;
  public imageState ="none";
  public activeIndexh = 0;
  public activeIndexv = 0;
  fav;
  toolTip;

  //publicity
  publicityListProduct1;
  publicityListProduct2;
  public categoryToShow: String = "...";
  constructor(
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private productsService: ProductsService,
    private router: Router,
    private companyService: CompanyService,
    private chatService: MessageCenterService,
    private publicityService:PublicityService,
    private dialogEdit: MatDialog
  ) { }

  async ngOnInit(){
    if(JSON.parse(localStorage.getItem('user'))){
      var user = JSON.parse(localStorage.getItem('user'));
      this.userLog = user;
    }else{
      this.userLog = "";
    }
    if (this.userLog == undefined || this.userLog == null) {
      // this.router.navigate(['/Login'])
      // .then(() => {
      //   window.location.reload();
      // });
    }
 //  document.location.href = "VistaProducto/:productUUID#my"

//publicidad
await this.publicityService.getAllPublicity().then(
  (result: any) => {
    const DB = result.records[0][0];
    this.publicityListProduct1 = DB.filter(x => x.publicityPlace == "PUNH" && x.publicityStatus == "Activo");
    this.publicityListProduct2 = DB.filter(x => x.publicityPlace == "PUNV" && x.publicityStatus == "Activo");

  }
)

setInterval(() => {
  this.activeIndexh = (this.activeIndexh + 1) % this.publicityListProduct1.length;
}, 3000);  
setInterval(() => {
  this.activeIndexv = (this.activeIndexv + 1) % this.publicityListProduct2.length;
}, 3120);  
//fin de publicidad
    this.route.paramMap.subscribe((params: any) => {


      this.getFavoriteProduct(params.params.productUUID);
      this.getSingleProduct(params.params.productUUID);
      this.getAllProducts();
      this.getAllProductsSimilar();
    });

    this.getOdd();
  }

  getOdd(){
    if(this.publicityListProduct1.length%2 == 1 && this.publicityListProduct1.length>1){
      let len = this.publicityListProduct1.length -1;
      let random = Math.floor((Math.random()*(len - 1+ 1))+ 1);
      this.publicityListProduct1.push(this.publicityListProduct1[random]);
      this.actv();
     }if(this.publicityListProduct2.length%2 == 1 && this.publicityListProduct2.length>1){
      let len = this.publicityListProduct2.length-1;
      let random = Math.floor((Math.random()*(len -1 +1))+1);
      this.publicityListProduct2.push(this.publicityListProduct2[random])
      this.actv();
  }
this.actv();
  }

  actv(){
    var act = true;
    this.publicityListProduct1.forEach(element => {
      if (act) {
        element.active = "active";
        act = false;
      }else{
        element.active = "";
      }
    });
    
    var act1 = true;
    this.publicityListProduct2.forEach(element => {
      if (act1) {
        element.active = "active";
        act1 = false;
      }else{
        element.active = "";
      }
    }); 
  }

  async showAument(event){
  this.showImageZoom = true;
  this.imageState = "block";
  this.image =  document.querySelector(".image-details");
   var carouselItem = document.querySelector(".carousel-item");
  this.carouselImage = await document.querySelector(event)
  var leftValue = this.carouselImage.offsetLeft;
  this.thisAument();
      
  }
 async thisAument(){
  this.image.removeAttribute("display")
   await new Drift(this.carouselImage, {
     paneContainer: this.image,
     zoomFactor: 3,
     inlinePane: false

  })
  }

  shadowImage(){
      this.imageState = "none"
      this.carouselImage = "";
  }
  getSingleProduct(productUUID){
    this.loading = true;
    this.productsService.productGetOne(productUUID).then(
      (resultUsers: any) => {
        const userDB = JSON.parse(resultUsers).records[0];
        this.productsData = userDB;
        if (this.productsData.productName != undefined || this.productsData.productName != null) {
          this.mesage = "Este Producto ya no se encuentra disponible";
        }
        this.productsData.productSearch = this.productsData.productSearch +1;
          this.productsService.insertProductSearch(this.productsData).then(
            (response: any)=> {
            }
          );
      if(this.productsData.productDescription.length>250){
         this.charCounter = true;
      }else{
        this.charCounter = false;
      }
        this.getCompanyData();
        this.verifySameUser();
      });
  }

  getFavoriteProduct(productUUID){
    if (localStorage.getItem('user')) {
      var user = JSON.parse(localStorage.getItem('user'));
      this.productsService.productGetFavorite(user.userId).then(
        (resultUsers: any) => {
          var list=[];
          const userDB = JSON.parse(resultUsers).records;
          list = userDB;
         
          var event1 = list.find(x => x.productUUID === productUUID);
          
          if (event1 == null || event1 == "" || event1== undefined) {
            
            this.fav = false;
            this.toolTip = 'Agregar a favoritos';
          }else{
            
            this.fav = true;
            this.toolTip = 'Quitar de favoritos';
          }
        });
      }else{
        this.fav = false;
        this.toolTip = 'Agregar a favoritos';
      }
    
  }

  favSelect(){
    this.loading = true;
    

    if (localStorage.getItem('user')) {
    var user = JSON.parse(localStorage.getItem('user'));
    // agregar a favoritos en la base de datos
    this.productsService.productInsertFavorite({productUUID:this.productsData.productUUID,userUUID:user.userId}).then(
      response => {
        if (response.result === true) {
          this.fav = true;
          this.toolTip = 'Agregar a favoritos';
          this.loading = false;
        } else {
          this.loading = false;
          Swal.fire({
            icon: 'error',
            title: 'Algo salio mal',
            text: response.message,
          })
          
        }
      },
      error => {
      }
    )
    }
    else{
      this.loading = false;
      Swal.fire({
        icon: 'error',
        title: 'Algo salio mal',
        text: 'Para agregar un producto a Favoritos, necesitas iniciar sesión',
      })
    }
  }

  deleteProduct(){
    this.loading = true;
    Swal.fire({
      title: 'Eliminar Producto',
      text: "¿Está Seguro de Eliminar este Producto?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Eliminar'
    }).then((result) => {
      if (result.isConfirmed) {
        if (localStorage.getItem('user')) {
          var user = JSON.parse(localStorage.getItem('user'));
          // agregar a favoritos en la base de datos
          this.productsService.productDelete({productUUID:this.productsData.productUUID,userUUID:user.userId}).then(
            response => {
              if (response.result === true) {
                Swal.fire({
                  icon: 'success',
                  title: 'Eliminado Correctamente',
                  text: response.message,
                })
                setTimeout(function(){
                  this.loading = false;
                  window.location.reload();
                },1600);
                this.loading = false;
              } else {
                this.loading = false;
                Swal.fire({
                  icon: 'error',
                  title: 'Algo salio mal',
                  text: response.message,
                })
                
              }
            },
            error => {
            }
          )
          }
      }else{
        this.loading = false;
      }
    })

   
    
  }

  unFavSelect(){
    this.loading = true;
    if (localStorage.getItem('user')) {
      var user = JSON.parse(localStorage.getItem('user'));
      // agregar a favoritos en la base de datos
      this.productsService.productDeleteFavorite({productUUID:this.productsData.productUUID,userUUID:user.userId}).then(
        response => {
          if (response.result === true) {
            this.fav = false;
            this.toolTip = 'Quitar de favoritos';
            this.loading = false;
          } else {
            Swal.fire({
              icon: 'error',
              title: 'Algo salio mal',
              text: response.message,
            })
            this.loading = false;
          }
        },
        error => {
        }
      )
      }
      else{
        Swal.fire({
          icon: 'error',
          title: 'Algo salio mal',
          text: 'Para agregar un producto a Favoritos, necesitas iniciar sesión',
        })
        this.loading = false;
      }

      
  }

  navigateToCompanyInfo(){
    try {
      this.loading = true;
      this.messagesModel.chatUser1UUID = JSON.parse(localStorage.getItem('user')).userId;
      this.router.navigate(['/VistaEmpresa/' + this.productsData.productCompanyUUID]);
    }
    catch (error) {
      this.loading = false;
      Swal.fire({
        position: 'top-end',
        icon: 'warning',
        title: 'Inicie sesión para ver el directorio',
        showConfirmButton: false,
        timer: 1500
      })
    }
  }
  getAllProducts(){
    this.loading = true;
    this.productsService.productGetAll().then(
     response=> {
      const userDB = response.records[0][0];
       const userDB1 = userDB.filter(x => x.productCompanyUUID == this.productsData.productCompanyUUID);
       userDB1.sort(function() { return Math.random() - 0.5 });
       this.productsList1=userDB1;
       const filterO=[];
       if (userDB1.length > 3) {
        this.productsListFinishedEnable = true;
         var contador=0;
         userDB1.forEach(element => {
            if (contador >= this.productsListStart && contador < this.productsListFinished) {
              filterO.push(element);
            }
          contador++;
          });
          this.productsList=filterO;
       }else{
        this.productsList=userDB1;
       }
       this.loading = false;
     }
   )

   }

   getAllProductsSimilar(){
    this.loading = true;
    this.productsService.productGetAll().then(
     response=> {
      const userDB = response.records[0][0];
       const userDB1 = userDB.filter(x => x.itemFamilyUUID == this.productsData.itemFamilyUUID);
       userDB1.sort(function() { return Math.random() - 0.5 });
       this.productsList1Similar=userDB1;
       const filterO=[];
       if (userDB1.length > 3) {
         
        this.productsListFinishedEnableSimilar = true;
         var contador=0;
         userDB1.forEach(element => {
            if (contador >= this.productsListStartSimilar && contador < this.productsListFinishedSimilar) {
              filterO.push(element);
            }
          contador++;
          });
          this.productsListSimilar=filterO;
       }else{
        this.productsListSimilar=userDB1;
       }
       
       this.loading = false;
     }
   )

   }

   verifySameUser(){
    var user = JSON.parse(localStorage.getItem('user')).userId;
     var userCreate = this.productsData.userCreateUUID;
     if (userCreate == user){
      this.sameUser = false;
     }
   }

   
  nextProductoSimilar(){

    this.productsListFinishedSimilar++;
    this.productsListStartSimilar++;
    if (this.productsListStartSimilar>0) {
      this.productsListStartEnableSimilar= true;
    }else{
      this.productsListStartEnableSimilar= true;
    }
   
    if (this.productsList1Similar.length >= this.productsListFinishedSimilar) {
      if (this.productsList1Similar.length == this.productsListFinishedSimilar) {
        this.productsListFinishedEnableSimilar = false;
      }
      var contador=0;
      const filterO=[];
      
      this.productsList1Similar.forEach(element => {
         if (contador >= this.productsListStartSimilar && contador < this.productsListFinishedSimilar) {
           filterO.push(element);
         }
       contador++;
       });
       this.productsListSimilar=filterO;
    }else{
      this.productsListFinishedEnableSimilar = false;
    }

  }
  prevProductoSimilar(){
    this.productsListFinishedSimilar--;
    this.productsListStartSimilar--;
    if (this.productsListStartSimilar>0) {
      this.productsListStartEnableSimilar= true;
    }else{
      this.productsListStartEnableSimilar= false;
    }
    if (this.productsList1Similar.length > this.productsListFinishedSimilar) {
      var contador=0;
      const filterO=[];
      this.productsList1Similar.forEach(element => {
         if (contador >= this.productsListStartSimilar && contador < this.productsListFinishedSimilar) {
           filterO.push(element);
         }
       contador++;
       });
       this.productsListSimilar=filterO;
       this.productsListFinishedEnableSimilar=true;
    }

  }


  nextProducto(){

    this.productsListFinished++;
    this.productsListStart++;
    if (this.productsListStart>0) {
      this.productsListStartEnable= true;
    }else{
      this.productsListStartEnable= true;
    }
    if (this.productsList1.length >= this.productsListFinished) {
      if (this.productsList1.length == this.productsListFinished) {
        this.productsListFinishedEnable = false;
      }
      var contador=0;
      const filterO=[];
      this.productsList1.forEach(element => {
         if (contador >= this.productsListStart && contador < this.productsListFinished) {
           filterO.push(element);
         }
       contador++;
       });
       this.productsList=filterO;
    }else{
      this.productsListFinishedEnable = false;
    }

  }
  prevProducto(){
    this.productsListFinished--;
    this.productsListStart--;
    if (this.productsListStart>0) {
      this.productsListStartEnable= true;
    }else{
      this.productsListStartEnable= false;
    }
    if (this.productsList1.length > this.productsListFinished) {
      var contador=0;
      const filterO=[];
      this.productsList1.forEach(element => {
         if (contador >= this.productsListStart && contador < this.productsListFinished) {
           filterO.push(element);
         }
       contador++;
       });
       this.productsList=filterO;
       this.productsListFinishedEnable=true;
    }

  }
  saveValue(value){
  }
  viewProduct(productUUID){
    this.loading = true;
    // this.router.navigate(['/VistaProducto/' + productUUID]);
    // document.location.href = "VistaProducto/"+ productUUID+"#productoo";
    this.router.navigate(['/VistaProducto/' + productUUID])
  .then(() => {
    window.location.reload();
  });
    
    
  }

  getCompanyData(){
    this.companyService.companyGetOne(this.productsData.productCompanyUUID).then(
      (companyData: any) => {
        const userDB: CompanyModel = JSON.parse(companyData).records[0];
        this.companyData = userDB;
        this.loading = false;
      }
    )
  }
  
  openPublicity(url){
    window.open(url+"", "_blank");
  }

  openChat(event) {
    event.empty = true;
    event.fristMessage = "";
    if(this.createChat){
      event.createChat = true
    }else{
      event.createChat= false;
    }
    event.productData=this.productsData;
    const dialogRef = this.dialog.open(ChatDialogComponent,
      {
        data: event
      });

    dialogRef.afterClosed().subscribe(result => {
      // (`Dialog result: ${result}`);
    });
  }
 async startChat(){ 

   try {

     
  await  this.chatService.mainGetChatOld(JSON.parse(localStorage.getItem('user')).userId,this.productsData.userCreateUUID, this.productsData.productUUID).then(
        (companyData: any) => {
          const arr = JSON.parse(companyData)
    
          if(arr.records[0].length >= 1){
            this.chatId = JSON.parse(companyData).records[0][0].chatUUID;
            this.messagesModel.chatUUID = this.chatId;
            this.createChat = false;
          }else{
            this.messagesModel.chatUUID=uuidv4();
            this.createChat = true;
          }
        }
      )
      this.messagesModel.chatUser1UUID = JSON.parse(localStorage.getItem('user')).userId; // Usuario que envia
      this.messagesModel.chatUser2UUID = this.productsData.userCreateUUID; // Usuario que recibe
    this.messagesModel.chatUserSender = this.productsData.productCompany; // Usuario que envia
    this.messagesModel.productName = this.productsData.productName;
    this.messagesModel.chatUserReceiver = JSON.parse(localStorage.getItem('user')).name;
       this.openChat(this.messagesModel);

    // if (this.messagesModel.chatUUID && this.messagesModel.chatUser1UUID && this.messagesModel.chatUser2UUID) {
    //   Swal.fire({
    //     title: 'Enviar Chat',
    //     text: "Usted iniciara un chat",
    //     icon: 'warning',
    //     showCancelButton: true,
    //     confirmButtonColor: '#3085d6',
    //     cancelButtonColor: '#d33',
    //     confirmButtonText: 'Iniciar chat'
    //   }).then((result) => {
    //     if (result.isConfirmed) {
    //       this.chatService.mainMessageTableInsert(this.messagesModel).then(
    //         messageResponse => {
    //           Swal.fire({
    //             position: 'top-end',
    //             icon: 'success',
    //             title: 'Mensaje creado ve a mensajes para verlo.',
    //             showConfirmButton: false,
    //             timer: 1500
    //           })
    //         }
    //       )
    //     }
    //   })
    // }
   } catch (error) {
     Swal.fire({
       position: 'top-end',
       icon: 'warning',
      title: 'Inicie sesión para contactar al vendedor',
       showConfirmButton: false,
    timer: 1500
     })
   }
  }

  openimage(event){
    (event);
  }

  mouseEvent(event){
    this.productsService.getOneProductCategory(event).then(
    (response: any)=>{
          this.categoryToShow = JSON.parse(response).records[0].productCategory;
    }
    )
  }

  mouseLeave(){
      this.categoryToShow = "...";
  }

  getHeight(element){
    element.style.visibility = "hidden";
    document.body.appendChild(element);
    var height = element.offsetHeight + 0;
    document.body.removeChild(element);
    element.style.visibility = "visible";
    return height;

  }

 async showZoom(){
    var img, lens, result, cx, cy;
    this.showImageZoom = true;
     img = document.getElementById("myimage");
     result = document.getElementById("myresult")
    lens = document.createElement("div");
    lens.setAttribute("class", "img-zoom-lens"); 
    await this.getHeight(lens);
    /*insert lens:*/
    img.parentElement.insertBefore(lens, img);
    /*calculate the ratio between result DIV and lens:*/
    cx = result.offsetWidth / lens.offsetWidth; //391
    cy = result.offsetHeight / lens.offsetHeight;
    /*set background properties for the result DIV:*/
    result.style.backgroundImage = "url('" + img.src + "')";
    result.style.backgroundSize = (img.width * cx) + "px " + (img.height * cy) + "px";
    
    /*execute a function when someone moves the cursor over the image, or the lens:*/
    lens.addEventListener("mousemove", moveLens);
    img.addEventListener("mousemove", moveLens);
    /*and also for touch screens:*/
    lens.addEventListener("touchmove", moveLens);
    img.addEventListener("touchmove", moveLens);
    function moveLens(e) {
      var pos, x, y;
      /*prevent any other actions that may occur when moving over the image:*/
      e.preventDefault();
      /*get the cursor's x and y positions:*/
      pos = getCursorPos(e);
      /*calculate the position of the lens:*/
      x = pos.x - (lens.offsetWidth / 2);
      y = pos.y - (lens.offsetHeight / 2);
      /*prevent the lens from being positioned outside the image:*/
      if (x > img.width - lens.offsetWidth) {x = img.width - lens.offsetWidth;}
      if (x < 0) {x = 0;}
      if (y > img.height - lens.offsetHeight) {y = img.height - lens.offsetHeight;}
      if (y < 0) {y = 0;}
      /*set the position of the lens:*/
      lens.style.left = x + "px";
      lens.style.top = y + "px";
      /*display what the lens "sees":*/
      result.style.backgroundPosition = "-" + (x * cx) + "px -" + (y * cy) + "px";
    }
    function getCursorPos(e) {
      var a, x = 0, y = 0;
      e = e || window.event;
      /*get the x and y positions of the image:*/
      a = img.getBoundingClientRect();
      /*calculate the cursor's x and y coordinates, relative to the image:*/
      x = e.pageX - a.left;
      y = e.pageY - a.top;
      /*consider any page scrolling:*/
      x = x - window.pageXOffset;
      y = y - window.pageYOffset;
      return {x : x, y : y};
    }
  }

  openEditWindow(value: any){
    this.dialogEdit.open(EditProductDialogComponent, {
      data: {
         value
      },
      disableClose: true
    });
  }

}
