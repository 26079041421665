
    <div class="col-sm-12">
        <div class="text-container">Solicitudes de registro</div>
        <div class="line-height"></div>
    </div>
    
    <section class="user-info--container">
    <div class="container">
        <div class="col" style="padding-top: 30px; padding-bottom: 30px;">
           <section class="user-request--info">
            <div class="col-sm-12" *ngIf="showRequest">
                <div class="card" *ngFor="let user of userList let index = index" >
                    <div class="user-name" style="width: 100%;" (click)="changeState(index, user)" ><i class="fas fa-user" style="color: #0078d7"></i> {{user.userFirstName}},  {{user.userCompanyName}}</div>
                    <div class="info-container" *ngIf="index == indexState">
                        <div class="row">
                        <div class="col">
                            <mat-form-field appearance="fill">
                                <span style="font-size: smaller; color: rgb(148, 151, 151);">Primer nombre: </span>
                                <textarea matInput placeholder="Primer nombre" class="inputs" [(ngModel)]="user.userFirstName" ></textarea>
                              </mat-form-field> 
                        </div>

                        <div class="col">
                            <mat-form-field appearance="fill">
                                <span style="font-size: smaller; color: rgb(148, 151, 151);">Apellido: </span>
                                <textarea matInput placeholder="primer apellido" class="inputs" [(ngModel)]="user.userLastName" ></textarea>
                              </mat-form-field> 
                        </div>

                        <div class="col">
                            <mat-form-field appearance="fill" >
                                <span style="font-size: smaller; color: rgb(148, 151, 151);">Correo: </span>
                                <textarea matInput placeholder="Correo electrónico" class="inputs" [(ngModel)]="user.userEmail" ></textarea>
                              </mat-form-field> 
                        </div>
                        <div class="col">
                            <mat-form-field appearance="fill">
                                <span style="font-size: smaller; color: rgb(148, 151, 151);">País: </span>
                                <textarea matInput placeholder="País" class="inputs" [(ngModel)]="user.userCountry" ></textarea>
                              </mat-form-field> 
                        </div>
                        <!-- <div class="col-sm-4" style="margin-top: 25px">
                            <mat-form-field class="example-full-width" appearance="fill">
                            <mat-label class="user-role">Rol de usuario:</mat-label><br>
                            <mat-select style="width: 90%important;" placeholder="Rol de usuario" [(ngModel)]="user.userRole" >
                                <mat-option *ngFor="let rol of rolesList" [value]="rol.name" >{{rol.name}}</mat-option>
                             </mat-select>
                            </mat-form-field>
                        </div> -->
                        <div class="col-sm-12 company-title">Datos de empresa: </div> 
                   
                        <div class="col-sm-4">
                            <mat-form-field appearance="fill" style="width: 100% !important;">
                                <span style="font-size: smaller; color: rgb(148, 151, 151);">Nombre de la empresa: </span>
                                <textarea matInput placeholder="Nombre de empresa" class="inputs" [(ngModel)]="user.userCompanyName" ></textarea>
                              </mat-form-field> 
                        </div>
                        <div class="col-sm-4">
                            <mat-form-field appearance="fill">
                                <span style="font-size: smaller; color: rgb(148, 151, 151);">NIT: </span>
                                <textarea matInput placeholder="nit de empresa" class="inputs" [(ngModel)]="user.companyNit" ></textarea>
                              </mat-form-field> 
                        </div>
                        <div class="col-sm-4">
                            <mat-form-field appearance="fill">
                                <span style="font-size: smaller; color: rgb(148, 151, 151);">Número de teléfono: </span>
                                <textarea matInput placeholder="Teléfono" class="inputs" [(ngModel)]="user.companyPhone" ></textarea>
                              </mat-form-field> 
                        </div>
                        <!-- <section class="user-company--container" *ngIf="!companyExists">
                            <div class="row">
                               
                         
                            </div>
                        </section> -->
                        <section *ngIf="companyExists">
                            <div>* La empresa del usuario ya existe en la base de datos</div>
                        </section>
                         <section *ngIf="!companyExists">
                            <div>* La empresa del usuario no existe en la base de datos, se creará una nueva</div>
                        </section>
                        
                        <!-- <section class="user-company--container" *ngIf="!companyExists">
                            <div class="col">
                                <mat-form-field >
                                    <textarea matInput placeholder="Dirección de empresa" class="inputs" [(ngModel)]="companyInfo.companyAddress" ></textarea>
                                  </mat-form-field> 
                            </div>
                            <div class="col">
                                <mat-form-field >
                                    <textarea matInput placeholder="Descripción de empresa" class="inputs" [(ngModel)]="companyInfo.companyDescription" ></textarea>
                                  </mat-form-field> 
                            </div>
                            <div class="col">
                                <mat-form-field >
                                    <textarea matInput placeholder="Correo empresarial" class="inputs" [(ngModel)]="companyInfo.companyEmail"></textarea>
                                  </mat-form-field> 
                            </div>
                            <div class="col">
                                <mat-form-field >
                                    <textarea matInput placeholder="Url empresarial" class="inputs" [(ngModel)]="companyInfo.companySite" ></textarea>
                                  </mat-form-field> 
                            </div>
                        </section> -->
                    </div>
                    <div class="button-container" style="width: 100%; text-align: center;">
                        <br> <button (click)="updateUser(user)">Aceptar registro de usuario</button>
                    </div>
                    </div>
                </div>
            </div>
            <div *ngIf="!showRequest" >
                <div class="text-container" style="font-size: medium; color: #0078d7;">
                    No se encontraron solicitudes de usuarios <i class="fas fa-exclamation"></i>
                </div>
            </div>

           </section>
    </div>
</div>
</section>
<section class="company-info--container">
    <div class="col-sm-12">
        <div class="text-container" >Empresas nuevas</div>
        <div class="line-height" style="margin-bottom: 10px!important;"></div>
        <div class="container">
            <div class="row">
                <div class="col-sm-12">
                    <div class="card" *ngFor="let company of newCompany let index = index" >
                        <div class="user-name" (click)="changeCompanyState(index, company)"><i class="fas fa-building" style="color:#0078d7"></i> <span *ngIf="company.directoryActive == 1 " style="color: green"> {{company.companyName}}</span><span *ngIf="company.directoryActive == 0 " style="color: red"> {{company.companyName}}</span></div>
                        <div class="info-container" *ngIf="companyIndexState == index">
                             <div class="container">
                                <div class="row">
                                    <div class="col">
                                        <mat-form-field class="company-info" appearance="fill">
                                            <textarea matInput placeholder="Url empresarial" class="inputs" [(ngModel)]="company.companyName" ></textarea>
                                          </mat-form-field> 
                                    </div>  
                                    <div class="col-sm-6">
                                        <mat-form-field class="company-info" appearance="fill">
                                            <textarea matInput placeholder="Dirección de empresa" class="inputs" [(ngModel)]="company.companyAddress" ></textarea>
                                          </mat-form-field> 
                                    </div>
                                    <div class="col-sm-6">
                                        <mat-form-field class="company-info" appearance="fill">
                                            <textarea matInput placeholder="Descripción de empresa" class="inputs" [(ngModel)]="company.companyDescription" ></textarea>
                                          </mat-form-field> 
                                    </div>
                                    <div class="col">
                                        <mat-form-field class="company-info" appearance="fill">
                                            <textarea matInput placeholder="Correo empresarial" class="inputs" [(ngModel)]="company.companyEmail"></textarea>
                                          </mat-form-field> 
                                    </div>
                                    <div class="col">
                                        <mat-form-field class="company-info" appearance="fill">
                                            <textarea matInput placeholder="Url empresarial" class="inputs" [(ngModel)]="company.companySite" ></textarea>
                                          </mat-form-field> 
                                    </div>  
                                    <div class="col">
                                        <mat-form-field class="company-info" appearance="fill">
                                            <textarea matInput placeholder="NIT" class="inputs" [(ngModel)]="company.companyNIT" ></textarea>
                                          </mat-form-field> 
                                    </div>  
                                    <div class="col">
                                        <mat-form-field class="company-info" appearance="fill">
                                            <textarea matInput placeholder="Teléfono" class="inputs" [(ngModel)]="company.companyPhone1" ></textarea>
                                          </mat-form-field> 
                                    </div>  
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <mat-form-field class="example-full-width" appearance="fill" >
                                            <mat-label>Categoría</mat-label>
                                            <mat-select [(ngModel)]="company.companyCategoryUUID">
                                              <mat-option (click)="listCategories(masterCat.masterCatUUID)" [value]="masterCat.masterCatUUID" *ngFor="let masterCat of masterCatList" >{{masterCat.masterCatName}}</mat-option>
                                            </mat-select>
                                          </mat-form-field>
                                    </div>
                                    <div class="col">
                                        <mat-form-field class="example-full-width" appearance="fill">
                                            <mat-label>Subcategoría</mat-label>
                                            <mat-select [disabled]="subCatStatus"  [(ngModel)]="company.subCatUUID">
                                              <mat-option (click)="listSubCat(subcat.catUUID)" [value]="subcat.catUUID" *ngFor="let subcat of categoriesList">{{subcat.catName}}</mat-option>
                                            </mat-select>
                                          </mat-form-field>
                                    </div>
                                    <div class="col">
                                        <mat-form-field class="example-full-width" appearance="fill">
                                            <mat-label>Item de subcategoría</mat-label>
                                            <mat-select [disabled]="familyCatStatus" [(ngModel)]="company.familyItemUUID">
                                              <mat-option (click)="itemFamily(families.subCatUUID)" [value]="families.subCatUUID" *ngFor="let families of subcategoryList">{{families.subCatName}}</mat-option>
                                            </mat-select>
                                          </mat-form-field>
                                    </div>
                                </div>
                                <div class="button-container" style="width: 100%; text-align: center;">
                                    <br> <button (click)="updateCompany(company)">Actualizar empresa</button>
                                </div>
                             </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="user-info--container">
    <div class="col-sm-12">
        <div class="text-container" >Usuarios nuevos</div>
        <div class="line-height" style="margin-bottom: 10px!important;"></div>
        <div class="calendar-container" style="width: 100%; text-align: center; display:flex;">
            <section class="calendar" style="margin: 0 auto; width:100%">
                <mat-form-field appearance="fill">
                    <mat-label>Búsqueda por fecha</mat-label>
                    <input matInput [matDatepicker]="picker" [formControl]="selectUser">
                    <mat-hint>MM/DD/YYYY</mat-hint>
                    <mat-datepicker-toggle matIconSuffix [for]="picker" ></mat-datepicker-toggle>
                    <mat-datepicker #picker ></mat-datepicker>
                  </mat-form-field>
                  <div class="col-button" style="width:100%;">
                        <button (click)="getUserByDatePicker()" style="background-color:#0078d7; color: white; min-width: 40%!important; margin-top: 15px; margin-bottom: 20px; height: 28px; border-radius: 10px; font-size: 1.2rem " >Buscar usuarios</button>
                  </div>
            </section>
           
        </div>
        <div class="container">
            <div class="row">
                 <div class="col-sm-12">
                    <div class="card" *ngFor="let user of newUserList let index = index">
                        <div class="user-name" (click)="changeUserState(index, user)"><i class="fas fa-user" style="color: #0078d7"></i> {{user.userFirstName}}, {{user.userCompanyName}}</div>
                        <div class="info-container">
                             <div class="container"  *ngIf="userIndexState == index">
                               <div class="row">
                                <div class="col">
                                    <mat-form-field appearance="fill">
                                        <span style="font-size: smaller; color: rgb(148, 151, 151);">Primer nombre: </span>
                                        <textarea matInput placeholder="Primer nombre" class="inputs" [(ngModel)]="user.userFirstName" ></textarea>
                                      </mat-form-field> 
                                </div>
                                <div class="col">
                                    <mat-form-field appearance="fill">
                                        <span style="font-size: smaller; color: rgb(148, 151, 151);">Apellido: </span>
                                        <textarea matInput placeholder="primer apellido" class="inputs" [(ngModel)]="user.userLastName" ></textarea>
                                      </mat-form-field> 
                                </div>
                                <div class="col">
                                    <mat-form-field appearance="fill" >
                                        <span style="font-size: smaller; color: rgb(148, 151, 151);">Correo: </span>
                                        <textarea matInput placeholder="Correo electrónico" class="inputs" [(ngModel)]="user.userEmail" ></textarea>
                                      </mat-form-field> 
                                </div>
                                <div class="col">
                                    <mat-form-field appearance="fill">
                                        <span style="font-size: smaller; color: rgb(148, 151, 151);">País: </span>
                                        <textarea matInput placeholder="País" class="inputs" [(ngModel)]="user.userCountry" ></textarea>
                                      </mat-form-field> 
                                </div>
                                <div class="col">
                                    <mat-form-field class="example-full-width" appearance="fill">
                                        <mat-label class="user-role">Rol de usuario:</mat-label><br>
                                        <mat-select style="width: 90%important;" placeholder="Rol de usuario" [(ngModel)]="user.userRole" >
                                            <mat-option *ngFor="let rol of rolesList" [value]="rol.code" >{{rol.name}}</mat-option>
                                         </mat-select>
                                        </mat-form-field>
                                </div>
                               </div>
                                <div class="button-container" style="width: 100%; text-align: center;">
                                    <br> <button (click)="updateNewUser(user)"><span style="color:white;margin-right: 12px;"><i class="fas fa-user-edit"></i></span>Actualizar usuario</button>
                                </div>
                                <div class="button-container" style="width: 100%; text-align: center;">
                                    <br> <button (click)="verificateUser(user)"><span style="color:white;margin-right: 12px;"><i class="fas fa-check"></i></span>Verificar usuario</button>
                                </div>
                                <div class="button-container" style="width: 100%; text-align: center;">
                                    <br> <button (click)="sendCredentials(user)"><span style="color:white;margin-right: 12px;"><i class="fas fa-key"></i></span>Enviar credenciales</button>
                                </div>
                             </div>
                            
                        </div>
                    </div>
                 </div>
            </div>
        </div>
    </div>
</section>
