import { PublicityModel } from './../../models/publicityModel';
import { NewPublicityComponent } from './new-publicity/new-publicity.component';
import { PublicityService } from './../../services/publicity.service';
import { Component, OnInit } from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import PlaceData from '../../../assets/data/publicityPlace.json';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-publicity-dashboard',
  templateUrl: './publicity-dashboard.component.html',
  styleUrls: ['./publicity-dashboard.component.scss']
})
export class PublicityDashboardComponent implements OnInit {
  loading = false;
  adminActive: boolean = false;
  publicityList;
  placeData = PlaceData;
  ubicationSearch  = undefined;
  publicityList2 = [];

  constructor(
    private publicityService: PublicityService,
    public dialog: MatDialog,

  ) { }

  async ngOnInit(){
    this.loading = true;
    await this.publicityService.getAllPublicity().then(
      (result: any) => {
        const DB = result.records[0][0];
        this.publicityList = DB;
        this.loading = false;
      }
    )
  }

  createNewPublicity(){
    const dialogRef = this.dialog.open(NewPublicityComponent, {
      data:null,
      minWidth: '70vh',
      maxWidth: '70vh',
      minHeight: '60vh',
      maxHeight: '60vh'
    });

    dialogRef.afterClosed().subscribe(result => {
      // (`Dialog result: ${result}`);
    });
  }

  editPublicity(event){
    const dialogRef = this.dialog.open(NewPublicityComponent, {
      data:event,
      minWidth: '70vh',
      maxWidth: '70vh',
      minHeight: '60vh',
      maxHeight: '60vh'
    });

    dialogRef.afterClosed().subscribe(result => {
      // (`Dialog result: ${result}`);
    });
  }


  adminActiveToggle(){
    this.adminActive = true;
  }

  activatePublicity(event){
    console.log(event);
    event.publicityStatus = "Activo";
    event.publicityDelete = 0;
    const params = event
    this.publicityService.updatePublicity(params).then(
      (result: any) => {
        Swal.fire({
          position: 'top-end',
          icon: 'success',
          title: 'Publicidad activada',
          showConfirmButton: false,
          timer: 1500
        })
        setTimeout(function(){
          window.location.reload();
        },1600);
      }
    )

  }

  inactivatePublicity(event){
    console.log(event);
    this.loading = true;
    var that = this;
    event.publicityStatus = "Inactivo";
    event.publicityDelete = 0;
    const params = event
    this.publicityService.updatePublicity(params).then(
      (result: any) => {
        Swal.fire({
          position: 'top-end',
          icon: 'success',
          title: 'Publicidad desactivada',
          showConfirmButton: false,
          timer: 1500
        })
        setTimeout(function(){
          that.loading = false;
          window.location.reload();
        },1600);
      }
    )

  }

  deletePublicity(event){
    Swal.fire({
      title: 'Eliminar Solicitud',
      text: "¿Está Seguro de Eliminar esta Publicidad?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Eliminar'
    }).then((result) => {
      if (result.isConfirmed) {
        this.loading = true;
    var that = this;
    event.publicityStatus = "Inactivo";
    event.publicityDelete = 1;
    const params = event
    this.publicityService.updatePublicity(params).then(
      (result: any) => {
        Swal.fire({
          position: 'top-end',
          icon: 'success',
          title: 'Publicidad Eliminada',
          showConfirmButton: false,
          timer: 1500
        })
        setTimeout(function(){
          that.loading = false;
          window.location.reload();
        },1600);
      }
    )
      }
    })
    

  }

  fillUbication(event){ 
    this.publicityList2 = [];
   this.ubicationSearch = event.value;
  this.publicityList.forEach(element => {
      if(element.publicityPlace == this.ubicationSearch){
        this.publicityList2.push(element);
      }
  });
     }

     reload(){
      this.ubicationSearch =  undefined;
     }
  } 

