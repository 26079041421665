<mat-card>
  <mat-card-content>
    <div class="col-sm-12" style="text-align: right !important; margin-top: 1rem;">
      <img src="../../../assets/images/userblacklogo.png" style="width: 17.8rem !important;">
    </div>
    <div class="col-sm-12">
      <table class="example-full-width" cellspacing="0"><tr>
        <td>
          <mat-form-field class="example-full-width" appearance="fill">
            <mat-label>País</mat-label>
            <mat-select [(ngModel)]="user.userCountry">
              <mat-option [value]="countryList.name" *ngFor="let countryList of countryList">{{countryList.name}}</mat-option>
            </mat-select>
          </mat-form-field>
        </td>
        <td >
          <mat-form-field class="example-full-width" appearance="fill">
            <mat-label>Rol de usuario</mat-label>
            <mat-select [(ngModel)]="user.userRole">
              <mat-option [value]="roleList.code" *ngFor="let roleList of rolesList">{{roleList.name}}</mat-option>
            </mat-select>
          </mat-form-field>
        </td>
        </tr>
      </table>
      <table class="example-full-width" cellspacing="0"><tr>
        <td><mat-form-field class="example-full-width" appearance="fill">
          <mat-label>Correo Electrónico</mat-label>
          <input matInput [(ngModel)]="user.userEmail">
          </mat-form-field></td>
        <td >
          
          <!-- <mat-form-field class="example-full-width" appearance="fill">
          <mat-label>Compañía</mat-label>
          <input matInput [(ngModel)]="company.companyName" disabled>
          </mat-form-field> -->
        
          <mat-form-field class="example-full-width" appearance="fill">
            <mat-label>Compañía</mat-label>
            <mat-select [(ngModel)]="company">
              <mat-option [value]="companies" *ngFor="let companies of companyList">{{companies.companyName}}</mat-option>
            </mat-select>
          </mat-form-field>

        </td>
        </tr>
      </table>
      <table class="example-full-width" cellspacing="0"><tr>
        <td><mat-form-field class="example-full-width" appearance="fill">
          <mat-label>Primer Nombre</mat-label>
          <input matInput [(ngModel)]="user.userFirstName">
          </mat-form-field></td>
          <td><mat-form-field class="example-full-width" appearance="fill">
            <mat-label>Primer Apellido</mat-label>
            <input matInput [(ngModel)]="user.userLastName">
          </mat-form-field></td>
        </tr>
      </table>
    </div>
  </mat-card-content>
  <div class="col-sm-12" style="margin: 2rem 0 0 0; text-align: center;"
  *ngIf="user.userCountry &&  user.userRole && user.userEmail && userDataToShow.userCompanyName && user.userFirstName && user.userLastName">
    <button mat-raised-button style="background-color: green; color: white;" (click)="saveUser()"><mat-icon>save</mat-icon>Guardar</button>
  </div>
</mat-card>
