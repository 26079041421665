import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {

  transform(value:any , arg:any):any {
    var result = [];
  
if(value != undefined){
  for(const company of value){
    if(arg == undefined){
      result.push(company);
    }else if(company.companyCategoryUUID == arg){
      result.push(company);
    }else if(company.companyName.normalize('NFD').replace(/[\u0300-\u036f]/g,"").toLowerCase()
    .indexOf(arg.normalize('NFD').replace(/[\u0300-\u036f]/g,"").toLowerCase()) > -1){
      result.push(company);
    }else if(company.companyCategory.normalize('NFD').replace(/[\u0300-\u036f]/g,"").toLowerCase()
      .indexOf(arg.normalize('NFD').replace(/[\u0300-\u036f]/g,"").toLowerCase())>-1){
        result.push(company);
      }
    };
      return result;
}
 
  }
}

