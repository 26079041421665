import { Component, OnInit } from '@angular/core';
import { CompanyService } from 'src/app/services/company.services';
import { CompanyModel } from 'src/app/models/companyModel';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { MessageCenterService } from 'src/app/services/messageCenter.service';
import { v4 as uuidv4 } from 'uuid';
import { UserService } from 'src/app/services/user.service';
import { ChatTableModel } from 'src/app/models/chatTableModel';
import { PublicityService } from 'src/app/services/publicity.service';
import {ProductsService}from 'src/app/services/products.service';
import { ViewChild, ElementRef ,AfterViewChecked } from '@angular/core';
import { ProductsModel } from 'src/app/models/productsModel';
import cigData from '../../../../assets/data/cigData.json';
import { Location } from '@angular/common';
import { element } from 'protractor';

@Component({
  selector: 'app-business-directory-one-view',
  templateUrl: './business-directory-one-view.component.html',
  styleUrls: ['./business-directory-one-view.component.scss']
})
export class BusinessDirectoryOneViewComponent implements OnInit, AfterViewChecked  {
  loading = false;
  companyData= {} as CompanyModel;
  date="";
  data= {} as any;
  activeIndex = 0;
  userUUID;
  promQ1=0;
  promQ2=0;
  promQ3=0;
  promQ4=0;
  promQ5=0;
  edit = false;
  publicityMyProfile;
  companyProducts = {} as any;
  productsData = {} as ProductsModel;
  productList;
  productList1;
  productsListFinishedEnable = false;
  productsListStart=0;
  productsListFinished = 3;
  productsListStartEnable= false;
  userRole;
  gremList;
  badgeList=[];
  badgeListMaster=[];
  public userLog: Boolean = true;
  public itsMyBusinessProfile: Boolean = false;
  public showExtraCategory: Boolean = false;
  public extraCategory = "";
  public catShow: boolean = false;
  // betaBadge=UsuarioBeta2
  badges = [{id:"1",name:"Usuario Beta",url:'../../../../assets/images/core/UsuarioBeta.png',style:"width: 5.3rem;height: 5.8rem;",add:0},
  {id:"2",name:"Usuario Beta 2",url:'../../../../assets/images/core/UsuarioBeta2.png',style:"width: 5.3rem;height: 5.8rem;",add:0}]
  p3;
  @ViewChild('labelImportP3')
  labelImportP3: ElementRef;
  imageProfile = true;
   cigDataList = cigData;
  public gremCig : any;

  @ViewChild('bigDiv') bigDiv: ElementRef;
  @ViewChild('smallDiv') smallDiv: ElementRef;

  constructor(
    private route: ActivatedRoute,
    private companyService: CompanyService,
    private chatService: MessageCenterService,
    private userservice: UserService,
    private publicityService: PublicityService,
    private productService: ProductsService,
    private router: Router,
    private location: Location
  ) { }
  

  async ngOnInit(){
    try {
      JSON.parse(localStorage.getItem('user')).userId;
      this.userRole = JSON.parse(localStorage.getItem('user')).userRole;
     } catch (error) {
       this.userLog = false;
     }
    this.loading = true;
    // let actualRoute = document.location.href;
     
    if (localStorage.getItem('user')) {
      this.userUUID = JSON.parse(localStorage.getItem('user'));
      this.userRole = this.userUUID.userRole;
      
    }




    await this.publicityService.getAllPublicity().then(
      (result: any) => {
        const DB = result.records[0][0];
        this.publicityMyProfile = DB.filter(x => x.publicityPlace == "DE01" && x.publicityStatus == "Activo");
        
      }
    )


    await this.route.paramMap.subscribe((params: any) => {
      const companyUUID = params.params.companyBussinesCode;
     this.companyService.companyGetOne(companyUUID).then(
        (response: any)=> {
          this.companyData = JSON.parse(response).records[0];
          this.showNewCategory();
          console.log(this.companyData);
          var suma = parseFloat(this.companyData.Q1) +parseFloat(this.companyData.Q2) +parseFloat(this.companyData.Q3)+parseFloat(this.companyData.Q4)+parseFloat(this.companyData.Q5)
          if (suma>0) {
            this.promQ1 = ((parseFloat(this.companyData.Q1)/suma)*100);
            this.promQ2 = ((parseFloat(this.companyData.Q2)/suma)*100);
            this.promQ3 = ((parseFloat(this.companyData.Q3)/suma)*100);
            this.promQ4 = ((parseFloat(this.companyData.Q4)/suma)*100);
            this.promQ5 = ((parseFloat(this.companyData.Q5)/suma)*100);
          }
          this.getBadges(this.companyData.companyBadges);
          var fecha = this.companyData.companyDate.split("-")
   
          this.date = "Miembro de CIGMatch desde: "+this.months(parseInt(fecha[1]))+" "+fecha[0]
          this.gremList = this.companyData.companyMap.split(".,")
          this.loading = false;

          this.userservice.oneUser(this.userUUID.userId).then(
            (response: any) =>{
                const userCompanyUUID = JSON.parse(response).records[0].userCompanyUUID;
                if (this.companyData.companyBussinesCode == userCompanyUUID){
                  this.itsMyBusinessProfile = true;
      
                }
        
            }
          )
       
        }
        
      )
    
    });


    setInterval(() => {
      this.activeIndex = (this.activeIndex + 1) % this.publicityMyProfile.length;
    }, 3000);    

  
   this.route.params.subscribe((params: any) => {
    
      this.getSingleProduct(params.companyBussinesCode);
      document.location.href = 'VistaEmpresa/'+params.companyBussinesCode+'#oneview';
    });

    // window.addEventListener('popstate', () => {
    //    const state = this.location.getState()
    //    console.log(state);
    // })
    this.checkOdd();
     this.getProducts();

    
  }


  ngAfterViewChecked() {
    const bigDivHeight = this.bigDiv.nativeElement.offsetHeight;
    const smallDivHeight = this.smallDiv.nativeElement.offsetHeight;
 console.log(bigDivHeight,smallDivHeight)
    if (bigDivHeight > smallDivHeight) {
      this.smallDiv.nativeElement.style.height = bigDivHeight + 'px';
    }else if(bigDivHeight < smallDivHeight){
      this.bigDiv.nativeElement.style.height = smallDivHeight + 'px';
    }
  }
  
  async getBadges(badges){
    console.log(this.userRole);
    if (this.userRole != "Master") {
      let badge = "";
    for (let i = 0; i < badges.length; i++) {
      if (badges[i] == ",") {
        let findBadge = this.badges.find(x => x.id == badge);
        if (findBadge != null) {
          this.badgeList.push(findBadge);
          
        }
        badge = "";
      }else{
        badge = badge+badges[i];
      }
      
    }
    }else{

      let badge = "";
      let bList =[];
    for (let i = 0; i < badges.length; i++) {
      if (badges[i] == ",") {
        let findBadge = this.badges.find(x => x.id == badge);
        if (findBadge != null) {
          bList.push(findBadge);
          
        }
        badge = "";
      }else{
        badge = badge+badges[i];
      }
      
    }
    this.badges.forEach(element=>{
      let findBadge = bList.find(x => x.id == element.id);
      if (findBadge == null) {
        element.style = "width: 5.0rem;height: 5.5rem;opacity: 40%;";
        element.add = 0;
      }else{
        element.style = "width: 5.0rem;height: 5.5rem;opacity: 100%;";
        element.add = 1;
      }
      this.badgeListMaster.push(element);
    })
     console.log(this.badgeListMaster);

    }
    
  }
  convert(list){
    let word = "";
    list.forEach(element => {
      if (element.add == 1) {
        word = word + element.id + ",";
      }
    });
    return word;
  }
  async addBadges(badge){
    this.loading=true;
   await this.badgeListMaster.forEach(element=>{
      if (element.id == badge.id) {
        element.add = 1;
        let stringBadge = this.convert(this.badgeListMaster);
        console.log(stringBadge)
        this.saveBadges(stringBadge,1)
      }
    });
    
   
  }
  async deleteBadges(badge){
    this.loading=true;
    await this.badgeListMaster.forEach(element=>{
      if (element.id == badge.id) {
        element.add = 0;
        let stringBadge = this.convert(this.badgeListMaster);
        console.log(stringBadge)
        this.saveBadges(stringBadge,0)
      }
    });
  }

 async showNewCategory(){
  console.log(this.companyData);
  const newCategoryId = this.companyData.newCategory;
   if(this.companyData.newCategory !== null){
      this.showExtraCategory = true;
   }

   var data = this.companyData.newCategory;
   console.log(newCategoryId);
   this.companyService.getOneProductCategory(data).then(
    (response: any) => {
      console.log(JSON.parse(response));
      this.extraCategory = JSON.parse(response).records[0].productCategory;
    }
  )
  }

  showNewCategory2(){
    if(this.catShow == false){
      this.catShow = true;
    }else if(this.catShow){
      this.catShow = false;
    }
  }
  

  async saveBadges(stringBadge,op){
    let opcion = "";
    if (op== 1) {
      opcion  ="Insignia agregada correctamente"
    }else{
      opcion  ="Insignia eliminada correctamente"
    }
    
    this.companyData.companyBadges = stringBadge; 
    let obj = {companyBussinesCode:this.companyData.companyBussinesCode,companyBadge:stringBadge}
    this.companyService.addCompanyBadges(obj).then(
      response => {
        if (response.result === true) {
          this.loading = false;
          

          setTimeout(function(){
            window.location.reload();
            },1500);
            Swal.fire({
              position: 'top-end',
              icon: 'success',
              title: ''+opcion,
              showConfirmButton: false,
              // timer: 1500
            })
        } else {
        }
      },
      error => {
        Swal.fire({
          position: 'top-end',
          icon: 'error',
          title: 'Ha sucedido un error: '+error,
          showConfirmButton: false,
          // timer: 1500
        })
       this.loading = false;
      }
    )
    
  }
  updateGrem(event){
    console.log(event.value);
    var str = "";
      str = event.value.toString();
      this.companyData.companyMap = str;
  }
  deleteGrem(event){
    var result =""
    result = event.replace(/[.]/g,'')
    console.log("firstatemmp");
    //var newArray = this.companyData.companyMap.filter((x) => x.name !== result);
    var newArray = this.companyData.companyMap.splice(result);
    console.log(newArray);
     
  }


  image3(files: FileList) {
    this.labelImportP3.nativeElement.innerText = Array.from(files)
      .map(f => f.name)
      .join(', ');
    this.p3 = files.item(0);
    this.imageProfile = false;
  }
update(){
    this.loading = true;
    var principalI;
    var p1;
    var p2;
    var p3;
    var contractUUID = uuidv4();
  Swal.fire({
    position: 'center',
    icon: 'question',
    text:'¿Está seguro que desea actualizar los datos?',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    cancelButtonText: 'Cancelar',
    confirmButtonText: 'Aceptar'
  }).then((result =>{
         if(result.isConfirmed){
             if (this.p3 != null) {
      this.companyService.uploadFileS3(this.p3, "profile", contractUUID).then(
        (responsePI: any) => {
          principalI = responsePI.Location;
          this.companyData.companyIcon = principalI;
          this.updateCompany();
        }
      )
    }else{
      this.updateCompany();
    }
         }else{
            Swal.fire({
               position:'center',
               icon:'error',
               text:'Cancelado'
            })
            this.loading = false;
            setTimeout(function(){
              window.location.reload();
              },1500);
         } 
      
  }));
  
   
  
}
updateCompany(){
  this.companyService.updateCompanyOnTable(this.companyData).then(
    response => {
      if (response.result === true) {
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: 'Datos Actualizados correctamente',
          showConfirmButton: true
        })
        setTimeout(function(){
          this.loading = false;
          window.location.reload();
        },1600);
      } else {
        this.loading = false;
      }
    },
    error => {
      (error);
    }
  )
}
  addBadge(num){
    this.companyData.companyBadge = num;
    var questionTitle = "";
    var question = "";
    var questionButtom = "";
    if (num === 0) {
      question = 'Quitar Insignia'
      questionTitle =  "¿Está seguro de quitar la Insignia a esta Empresa?"
      questionButtom = "Quitar";
    }else{
      question = 'Agregar Insignia'
      questionTitle =  "¿Está seguro de agregar la Insignia a esta Empresa?"
      questionButtom = "Agregar";
    }
    Swal.fire({
      title: question+'',
      text:  questionTitle+'',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: questionButtom
    }).then( (result) => {
      if (result.isConfirmed) {
        this.loading = true;
    this.companyService.addCompanyBadge(this.companyData).then(
      response => {
        if (response.result === true) {
          this.loading = false;
          Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: 'Insignia agregada correctamente',
            showConfirmButton: false,
            timer: 1500
          })

          setTimeout(function(){
            window.location.reload();
            },1500);
        } else {
        }
      },
      error => {
        Swal.fire({
          position: 'top-end',
          icon: 'error',
          title: 'Ha sucedido un error: '+error,
          showConfirmButton: false,
          timer: 1500
        })
       this.loading = false;
      }
    )
      }
    })
    
    

  }

  openPublicity(url){
    window.open(url+"", "_blank");
  }

  checkOdd(){
    if(this.publicityMyProfile.length%2 == 1 && this.publicityMyProfile.length>1){
      let len = this.publicityMyProfile.length -1;
      let random = Math.floor((Math.random()*(len - 1+ 1))+ 1);
      this.publicityMyProfile.push(this.publicityMyProfile[random]);
     }
     this.actv();
  }

  actv(){
    var act = true;
        this.publicityMyProfile.forEach(element => {
          if (act) {
            element.active = "active";
            act = false;
          }else{
            element.active = "";
          }
        });
  }
   async addUser(){
  
    var company = {} as any;
    company = this.companyData;

    var companyDB;
       await this.userservice.oneUser(this.userUUID.userId).then(
          (userData: any) => {
            const userDB = JSON.parse(userData).records[0];
            
            this.companyService.companyGetOne(userDB.userCompanyUUID).then(
              (companyData: any) => {
                
                companyDB = JSON.parse(companyData).records[0];
                Swal.fire({
                  title: 'Agregar Contacto',
                  text: "¿Está seguro de agregar a su listado de contactos?",
                  icon: 'success',
                  showCancelButton: true,
                  confirmButtonColor: '#3085d6',
                  cancelButtonColor: '#d33',
                  confirmButtonText: 'Agregar'
                }).then( (result) => {
                  if (result.isConfirmed) {
                    
                    this.loading = true;
                    this.data.chatUser1UUID = this.userUUID.userId;
                    this.data.chatUser2UUID = company.userCognitoCode;
                    this.data.chatUUID = uuidv4();
                    this.data.chatUserSender = this.userUUID.name+"  ( "+companyDB.companyName+" )";
                    this.data.chatUserReceiver = company.companyName;
                    this.data.productName = "(chat con directorio empresarial)";
                    this.data.chatStartDate = "05/05/2022";
                    this.data.chatStatus = "contact";
                    this.data.productUUID = this.companyData.companyBussinesCode;
            
                    
            
                  this.chatService.mainMessageTableInsert(this.data).then(
                    messageResponse => {

                      var chat= {} as ChatTableModel;
                      let localData = JSON.parse(localStorage.getItem('user'));
                      chat.chatUUID = this.data.chatUUID;
                      chat.chatMessage_status = "contact"
                      this.chatService.mainChatTableStatusUpdate(chat).then(
                      response => {
            
                      });


                      this.loading = false;
                      Swal.fire({
                        position: 'top-end',
                        icon: 'success',
                        title: 'Agregado a contactos correctamente.',
                        showConfirmButton: false,
                        timer: 1500
                      })
            
                    }
                  );
            
            
            
                   
                  }
                })
              }
            )
          }
        )

   
  }

  months(month):string{
    if (month == 1) {
      return "Enero"
    }else if (month == 2) {
      return "Febrero"
    }else if (month == 3) {
      return "Marzo"
    }else if (month == 4) {
      return "Abril"
    }else if (month == 5) {
      return "Mayo"
    }else if (month == 6) {
      return "Junio"
    }else if (month == 7) {
      return "Julio"
    }else if (month == 8) {
      return "Agosto"
    }else if (month == 9) {
      return "Septiembre"
    }else if (month == 10) {
      return "Octubre"
    }else if (month == 11) {
      return "Noviembre"
    }else if (month == 12) {
      return "Diciembre"
    }
  }

  
  getProducts(){
    this.loading = true;
        this.productService.productGetAll().then(
             result =>{
                  this.companyProducts = result.records[0][0];
                
                //  console.log(this.companyData);
                const companyUs = this.companyProducts.filter(x => x.productCompanyUUID == this.companyData.companyBussinesCode);
                companyUs.sort(function() { return Math.random() - 0.5 });
                this.productList1 = companyUs
                 const filter=[];
                 if(companyUs.length > 3){
                  this.productsListFinishedEnable = true;
                  var contador=0;
                    companyUs.forEach(element => {
                      if(contador >= this.productsListStart && contador < this.productsListFinished ){
                           filter.push(element);
                          
                      }
                      contador++;
                    });
                    this.productList = filter;
                 }else{
                   this.productList = companyUs;
                 }
                  
                   this.loading = false;
                   
             }
        );
  }

  nextProduct(){
    this.productsListFinished++;
    this.productsListStart++;
    if (this.productsListStart>0) {
      this.productsListStartEnable= true;
    }else{
      this.productsListStartEnable= true;
    }
    if (this.productList1.length >= this.productsListFinished) {
      if (this.productList1.length == this.productsListFinished) {
        this.productsListFinishedEnable = false;
      }
      var contador=0;
      const filterO=[];
  
      this.productList1.forEach(element => {
         if (contador >= this.productsListStart && contador < this.productsListFinished) {
           filterO.push(element);
          
          
         }
       contador++;
       });
       this.productList=filterO;
    }else{
      this.productsListFinishedEnable = false;
    }

  }

  prevProduct(){
    this.productsListFinished--;
    this.productsListStart--;
    if (this.productsListStart>0) {
      this.productsListStartEnable= true;
    }else{
      this.productsListStartEnable= false;
    }
    if (this.productList1.length > this.productsListFinished) {
      var contador=0;
      const filterO=[];
      this.productList1.forEach(element => {
         if (contador >= this.productsListStart && contador < this.productsListFinished) {
           filterO.push(element);
         }
       contador++;
       });
       this.productList=filterO;
       this.productsListFinishedEnable=true;
    }

  }

  getOneProduct(){
    this.productService.productGetOne(this.companyProducts.productUUID).then(
      (resultUsers: any) => {
        const userDB = JSON.parse(resultUsers).records[0];
        this.productsData = userDB;
        (this.productsData); 
        
      });
  }

  getSingleProduct(productUUID){
    this.loading = true;
   
    this.productService.productGetOne(productUUID).then(
      (resultUsers: any) => {
        const userDB = JSON.parse(resultUsers).records[0];
        this.productsData = userDB;
       
        (this.productsData);
      });
  }

  viewProduct(productUUID){
    this.loading = true;
    // this.router.navigate(['/VistaProducto/' + productUUID]);
    // document.location.href = "VistaProducto/"+ productUUID+"#productoo";
    this.router.navigate(['/VistaProducto/' + productUUID])
  .then(() => {
    window.location.reload();
  });
  }

  navigateToUrl(data: string){
       if(data.includes('http://') || data.includes('https://')){
        window.open(data, '_blank');
       }else{
        window.open('http://'+data, '_blank');
       }
       
  }

  navigateToLogin(){
    this.router.navigate(['/RegistroPropio/'])
    .then(() => {
      window.location.reload();
    });
  }
   
}
