<div class="container">
  <div class="row">
    <div id="loader" style="display:flex; justify-content: center !important;">
      <!-- <div class="dot"></div>
      <div class="dot"></div>
      <div class="dot"></div>
      <div class="dot"></div>
      <div class="dot"></div>
      <div class="dot"></div>
      <div class="dot"></div>
      <div class="dot"></div>
      <div class="loading"></div> -->
      <div class="logo-container">
        <img class="cig-logo" src="../../../assets/images/core/Loader-CIG-MATCH-v2-blue.gif">
      </div>
      
    </div>
    <p class="spinner-message" *ngIf="message && message !== ''">
      {{message}}
    </p>
  </div>
</div>
