<div class="col-sm-12">
    <div class="col-sm-12">
        <div class="title-container">
            <div class="title">Video tutoriales</div>
        </div>
        
    </div>
    <div class="container">
        <div class="col-sm-12">
            <div class="row">
                <div class="col-sm-4">
                    <div class="card-container">
                        <div class="image-container">
                            <img src="../../../../assets/images/core/directory_call.png" class="image">
                       </div>
                       <div class="title-video--container">
                            <span class="title-video">Directorio empresarial</span>
                       </div>
                       <div class="link-container">
                        <a href="/VistaTutoriales/Directorio">
                            <span class="link">Ver video</span>
                        </a>      
                       </div>
                    </div>
                </div>
                <div class="col-sm-4">
                    <div class="card-container">
                        <div class="image-container">
                            <img src="../../../../assets/images/core/category_tag.png" class="image">
                       </div>
                       <div class="title-video--container">
                            <span class="title-video">Categorías</span>
                       </div>
                       <div class="link-container">
                        <a href="/VistaTutoriales/categorias">
                            <span class="link">Ver video</span>
                        </a>      
                       </div>
                    </div>
                </div>
                <div class="col-sm-4">
                    <div class="card-container">
                        <div class="image-container">
                            <img src="../../../../assets/images/core/publish_ic.png" class="image">
                       </div>
                       <div class="title-video--container">
                            <span class="title-video">Portal de solicitudes</span>
                       </div>
                       <div class="link-container">
                        <a href="/VistaTutoriales/portal">
                            <span class="link">Ver video</span>
                        </a>      
                       </div>
                    </div>
                </div>
             
                <div class="col-sm-4">
                    <div class="card-container">
                        <div class="image-container">
                            <img src="../../../../assets/images/core/cube_design.png" class="image">
                       </div>
                       <div class="title-video--container">
                            <span class="title-video">Publicar un producto o servicio</span>
                       </div>
                       <div class="link-container">
                        <a href="/VistaTutoriales/publicar">
                            <span class="link">Ver video</span>
                        </a>      
                       </div>
                    </div>
                </div>
                <div class="col-sm-4">
                    <div class="card-container">
                        <div class="image-container">
                            <img src="../../../../assets/images/core/message_unread.png" class="image">
                       </div>
                       <div class="title-video--container">
                            <span class="title-video">Centro de mensajería</span>
                       </div>
                       <div class="link-container">
                        <a href="/VistaTutoriales/mensajeria">
                            <span class="link">Ver video</span>
                        </a>      
                       </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>