import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders,HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { catchError, map, tap } from 'rxjs/operators';
import { Observable } from 'rxjs/Observable';
import { throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PrivateDashboardServiceService {

  constructor(private http: HttpClient) { }
  private apiurl = "cigmatchbackend-";

  productsLastDays(): Promise<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.get(environment.API_URL + this.apiurl+'productsLastDays', { headers}).toPromise();
  }

  getToken(): Promise<any>{
     const headers = new HttpHeaders().set('Content-Type', 'application/sjon');
     return this.http.get(environment.API_URL + this.apiurl+'obtainToken', { headers}).toPromise();
  }

  getGAContent(params:any, headers: any){
    let queryParams = new HttpParams();
    for(let key in params) {
          queryParams = queryParams.set(key, params[key]);
    }
    return this.http.get(`https://content.googleapis.com/analytics/v3/data/ga`,{
        params: queryParams,
        headers: {
          'Authorization': `Bearer ${headers.gaToken}`
        }
      }
    ).pipe(
        map( (res: any) =>
          res
        )
    );
  }


  getIpAddress():Promise<any>{
    var resp;
    this.http.get<any>('https://geolocation-db.com/json/')
    .pipe(
      catchError(err => {
        return throwError(err);
      }),
      tap(response => {
        console.log(response.IPv4);
       resp = response;
      })
    )
   return resp;
  }

  insertRouteVisited(value: any):Promise<any>{
    let params = JSON.stringify(value);
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.post(environment.API_URL + this.apiurl + 'insertRouteHistory', params, { headers}).toPromise();
  }

  getTotalInteractions():Promise<any>{
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.get(environment.API_URL + this.apiurl+'getTotalInteractions', { headers}).toPromise();
  }

  getTotalVisitsCount():Promise<any>{
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.get(environment.API_URL + this.apiurl+'getTotalVisitsCount', { headers}).toPromise();
  }

  getRangeDates(values: any){
    const param1 = values.param1;
    const param2 = values.param2;
    let params2 = new HttpParams();
    const headers = new HttpHeaders().set('Content-type','application/json');
    return this.http.get(environment.API_URL + this.apiurl+ 'getRangeDate/'+param1+'/end/'+param2,{headers}).toPromise();
  }

  getProductRangeDates(values: any){
    const param1 = values.param1;
    const param2 = values.param2;
    let params2 = new HttpParams();
    const headers = new HttpHeaders().set('Content-type','application/json');
    return this.http.get(environment.API_URL + this.apiurl+ 'getProductRangeDate/'+param1+'/end/'+param2,{headers}).toPromise();
  }

  getRequestRangeDate(values: any){
    const param1 = values.param1;
    const param2 = values.param2;
    let params2 = new HttpParams();
    const headers = new HttpHeaders().set('Content-type','application/json');
    return this.http.get(environment.API_URL + this.apiurl+ 'getRequestRangeDate/'+param1+'/end/'+param2,{headers}).toPromise();
  }

  getFinishedMessages(values: any){
    const param = values;
    console.log(param);
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.get(environment.API_URL + this.apiurl+'finishedMessages/value/'+param, { headers}).toPromise();
  }

  getCompanyRangeDates(values: any){
    const param1 = values.param1;
    const param2 = values.param2;
    let params2 = new HttpParams();
    const headers = new HttpHeaders().set('Content-type','application/json');
    return this.http.get(environment.API_URL + this.apiurl+ 'getCompanyRangeDate/'+param1+'/end/'+param2,{headers}).toPromise();
  }

  getUserRangeDates(values: any){
    const param1 = values.param1;
    const param2 = values.param2;
    let params2 = new HttpParams();
    const headers = new HttpHeaders().set('Content-type','application/json');
    return this.http.get(environment.API_URL + this.apiurl+ 'getUserRangeDate/'+param1+'/end/'+param2,{headers}).toPromise();
  }
  
  getAllChatStatus(){
    const headers = new HttpHeaders().set('Content-type','application/json');
    return this.http.get(environment.API_URL + this.apiurl+ 'getAllChatStatus/',{headers}).toPromise();
  }
  
}
