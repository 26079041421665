import { CreateApplicationComponent } from './views/application-portal/create-application/create-application.component';
import { ApplicationPortalComponent } from './views/application-portal/application-portal.component';
import { SelfRegisterRequestComponent } from './core/self-register-request/self-register-request.component';
import { BusinessDirectoryOneViewComponent } from './views/business-directory/business-directory-one-view/business-directory-one-view.component';
import { UserProfileComponent } from './core/user-profile/user-profile.component';
import { CompanyUsersInfoComponent } from './views/companyViews/company-users-info/company-users-info.component';
import { CompanySelfUserCreatComponent } from './views/companyViews/company-self-user-creat/company-self-user-creat.component';
import { AutoConfirmComponent } from './core/auto-confirm/auto-confirm.component';
import { PublicityDashboardComponent } from './views/publicity-dashboard/publicity-dashboard.component';
import { RegisterComponent } from './views/register/register.component';
import { NewCategoryComponent } from './views/new-category/new-category.component';
import { CreatProductComponent } from './views/products/creat-product/creat-product.component';
import { BusinessDirectoryComponent } from './views/business-directory/business-directory.component';
import { CategoriesComponent } from './views/categories/categories.component';
import { AuthGuard } from './services/auth-guard.service';
import { LoginComponent } from './core/login/login.component';
import { ProductsComponent } from './views/products/products.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ProductOneViewComponent } from './views/products/product-one-view/product-one-view.component';
import { ApplicationRequestComponent } from './views/application-portal/aplication-request/application-request.component';
import { MyProfile } from './core/user-profile/my-profile/my-profile.component';
import { ProductsCardsComponentOption } from './views/products/products-cards-option/products-cards-option.component';
import { PendingApplicationPortalComponent } from './views/application-portal/pending-application-portal/pending-application-portal.component';
import { PendingApplicationViewComponent } from './views/application-portal/pending-application-portal/pending-application-view/pending-application-view.component';
import { HelpSectionComponent } from './views/help-section/help-section.component';
import { VideoTutorialsComponent } from './views/help-section/video-tutorials/video-tutorials.component';
import { ShowVideoTutorialsComponent } from './views/help-section/video-tutorials/show-video-tutorials/show-video-tutorials.component';
import { ProductsFoundComponent } from './views/products/products-found/products-found.component';
import { EmailSendingComponent } from './core/user-profile/email-sending/email-sending.component';
import { PrivateDashboardComponent } from './views/private-dashboard/private-dashboard.component';
import { UserRegisterDashboardComponent } from './views/register/user-register-dashboard/user-register-dashboard.component';
import { PasswordRecoverComponent } from './core/login/password-recover/password-recover.component';
const routes: Routes = [
  {path: '', component: ProductsComponent},
  {path: 'Login', component: LoginComponent},
  {path: 'RegistroPropio', component: SelfRegisterRequestComponent},
  {path: 'Categorías', component: CategoriesComponent},
  {path: 'Categorías/:search', canActivate: [AuthGuard], component: CategoriesComponent},
  {path: 'DirectorioEmpresarial',  component: BusinessDirectoryComponent},
  {path: 'RegistroDeProducto', canActivate: [AuthGuard], component: CreatProductComponent},
  {path: 'CrearCategoria', canActivate: [AuthGuard], component: NewCategoryComponent},
  {path: 'Registro', canActivate: [AuthGuard], component: RegisterComponent},
  {path: 'VistaProducto/:productUUID', component: ProductOneViewComponent},
  {path: 'AdministracionDePublicidad', canActivate: [AuthGuard], component: PublicityDashboardComponent},
  {path: 'userAutoConfirm/:userEmail/:verificationCode', component: AutoConfirmComponent},
  {path: 'nuevoUsuario', canActivate: [AuthGuard], component: CompanySelfUserCreatComponent},
  {path: 'misUsuarios', canActivate: [AuthGuard], component: CompanyUsersInfoComponent},
  {path: 'Mensajes', canActivate: [AuthGuard], component: UserProfileComponent},
  {path: 'PortalDeSolicitudes', component: ApplicationPortalComponent},
  {path: 'NuevaSolicitud', canActivate: [AuthGuard], component: CreateApplicationComponent},
  {path: 'VistaSolicitud/:requestCode', canActivate: [AuthGuard], component: ApplicationRequestComponent},
  {path: 'VistaEmpresa/:companyBussinesCode', component: BusinessDirectoryOneViewComponent},
  {path: 'MiPerfil', canActivate: [AuthGuard], component: MyProfile},
  {path: 'Productos/:option', canActivate: [AuthGuard], component: ProductsCardsComponentOption},
  {path: 'productosPendientes', canActivate: [AuthGuard], component: PendingApplicationPortalComponent},
  {path: 'vistaProductosPendientes/:id', canActivate: [AuthGuard], component: PendingApplicationViewComponent},
  {path: 'Ayuda', canActivate: [AuthGuard], component: HelpSectionComponent},
  {path: 'VideoTutoriales', canActivate: [AuthGuard], component:VideoTutorialsComponent },
  {path: 'VistaTutoriales/:videoToShow', canActivate: [AuthGuard], component: ShowVideoTutorialsComponent},
  {path: 'ProductosEncontrados/:product', canActivate: [AuthGuard], component: ProductsFoundComponent},
  {path: 'EnviarCorreos', canActivate: [AuthGuard], component: EmailSendingComponent},
  {path: 'Dashboard', canActivate: [AuthGuard], component: PrivateDashboardComponent},
  {path: 'validacion',canActivate: [AuthGuard], component: UserRegisterDashboardComponent},
  {path: 'Recuperacion', component: PasswordRecoverComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
