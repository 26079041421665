<header><app-header></app-header></header>
<section (click)="closeChat()">
  <router-outlet class="router"></router-outlet>
</section>

<footer><app-footer></app-footer></footer>
<!-- href="https://wa.me/41508502" -->
<a class="whatsapp" target="_blank" (click)="openChat()" > <i class="whatsapp-icon"></i></a>
<div id="chatPopup" #chatWindow style="display: none; position: fixed; bottom: 20px; right: 20px; width: 375px; height: 450px; background-color: #fff; box-shadow: 0 3px 10px rgba(0,0,0,0.2); z-index: 999;">
  <div style="" class="chat-header"><div style="width: 95%; padding-top: 12px!important; text-align: end;" ><span (click)="closeChat()"><i class="fas fa-times" style="cursor:pointer"></i></span></div></div>
  <div style="padding: 10px;">
     <div class="col-sm-12">
       <div class="container">
        <div class="row">
           <div class="col-sm-12">
            <div class="image-cig--container" style="display: flex; width: 100%;">
              <img src="../assets/images/core/CIG_M-lanczos3.png" style="height: auto; width: 60%; margin: 12px auto !important;">
            </div>
               
           </div>
           <div class="col-sm-12">
              <div class="button-chat--container" style="width: 100%; text-align: center; margin-top: 30px !important;">
                <a href="https://wa.me/41508502" target="_blank">
                  <button style="color: white; background-color: #f58626 ; width: 60%; border-color: transparent; border-radius: 50px; font-size: 1.2rem; line-height: 20px; padding: 12px !important;"> <span><i class="fas fa-comments"></i></span> Iniciar chat con CIGMatch</button>
                </a>
               
              </div>
           </div>
        </div>
       </div>
     </div>
  </div>
  <div style="position: absolute; bottom: 20px; left: 0; right: 0; padding: 10px; background-color: #ffffff; border-bottom-left-radius: 20px; border-bottom-right-radius: 20px;">
    <input type="text" placeholder="¿Cómo podemos ayduarte?" style="width: 100%; padding: 10px; border: none; border-radius: 5px; text-align: center; padding-bottom: 40px; font-size: 1.1rem; border-bottom-left-radius: 20px !important; border-bottom-right-radius: 20px !important;" disabled="true">
    <!-- <button style="background-color: #4CAF50; border: none; color: white; padding: 10px 16px; font-size: 16px; cursor: pointer; border-radius: 5px; margin-left: 10px;">Send</button> -->
  </div>
</div>
