import { Pipe, PipeTransform } from '@angular/core';
import { Console } from 'console';

@Pipe({
  name: 'categoriesFilter'
})
export class CategoriesFilterPipe implements PipeTransform {

  transform(value: any, args:any):any {
    var response;
    var subCategoryList: any = [];
    var subCategoryTemp:any = [];
    for(const cats of args){
     // console.log(cats);
      if(value.normalize('NFD').replace(/[\u0300-\u036f]/g,"").toLowerCase()
      .indexOf(cats.masterCatName.normalize('NFD').replace(/[\u0300-\u036f]/g,"").toLowerCase()) > -1){  
              subCategoryList.push(cats);
      }else{
         for(const category of cats.category){
            console.log(category);
            if(value.normalize('NFD').replace(/[\u0300-\u036f]/g,"").toLowerCase()
            .indexOf(category.catName.normalize('NFD').replace(/[\u0300-\u036f]/g,"").toLowerCase()) > -1){
              // subCategoryTemp = {
              //      category: [{category}],
              //      masterCatIcon: cats.masterCatIcon,
              //      masterCatName: cats.masterCatName,
              //      masterCatUUID: cats.masterCatUUID
              // } 
              subCategoryList.push(cats);
            }
               else{
              for(const subCategory of category.subCategory){
                 if(value.normalize('NFD').replace(/[\u0300-\u036f]/g,"").toLowerCase()
                 .indexOf(subCategory.subCatName.normalize('NFD').replace(/[\u0300-\u036f]/g,"").toLowerCase())> -1){
                   subCategoryList.push(cats);
                 }
              }
            }
         }
      }
    }
      console.log(subCategoryList);
        console.log(value);
        console.log(args);

    return subCategoryList;
  }

}
