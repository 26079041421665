import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'charCount'
})
export class CharCountPipe implements PipeTransform {

  transform(value:any , args:any): any {
        var characters = args.split('');
      return(characters.length);
  }

}
