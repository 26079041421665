<app-loading-wrapper-overlay *ngIf="loading"></app-loading-wrapper-overlay>
<app-public-carousel></app-public-carousel>
<div class="row">
    <div class="col-sm-12 title" id="product-title">
      
        <h1 class="featuredProducts">Productos relacionados con: {{option}}</h1>
      
    </div>
  </div>
  <div class="row" *ngIf="!dontHaveResults">
      <div class="col-sm-12 alert-text--container">
      <div class="alert-text"> <i class="fas fa-exclamation-triangle"></i> No se encontraron coincidencias</div>
      </div>
  </div>
<div class="row">
    <div class="col-md-3" *ngFor="let products of productsList" style="padding: 1.5rem!important;height: auto">
      <div class="card h-100"  style="border: none;padding: 0.5rem;cursor: pointer;box-shadow: 0 6px 20px rgba(71, 71, 71, 0.08), 0 0 6px rgba(71, 71, 71, 0.08);padding-bottom: 0%!important;"  (click)="viewProduct(products.productUUID)" >
        <div style="width: 100%;padding: 1rem;height: 10rem;">
          <img [src]='products.productMainPicture' alt="..." style="width: 100%;height: 10rem;">
        
        </div>
          <div class="card-body" style="margin-bottom: 0%!important;margin-top: 3px;">
            <div class="row">
              
              <div class="col-sm-7">
                <span style="color: #004F9F;font-size: 0.8rem;font-weight: 500;line-height: 0!important;">{{products.productName}}</span>
              </div>
              <div class="col-sm-5">
                <span class="float-end" style="font-weight: 700;" *ngIf=" 1 * products.productPrice > 0">{{products.unitPriceType}}. {{products.productPrice}}</span> 
              </div>
              
              
            </div>
          </div>
          <div class="col-sm-12" style="text-align: right;color: #9c9c9c;font-size: 0.7rem;bottom: 0;margin-bottom: 1rem;padding-right: 1rem;font-family: 'Arial';padding-left: 1rem;">
             <small *ngIf="products.newCategory == null">{{products.productCategory}}</small>
                  <span class="hover top" (mouseenter)="mouseEvent(products.newCategory)" (mouseleave)="mouseEvent2()" *ngIf="products.newCategory != null"><small>{{products.productCategory}}<span *ngIf="products.newCategory!= null"> +1</span></small>
                    <span class="text">{{categoryToShow}}</span>
                  </span>
          </div>
      </div>
  </div>
