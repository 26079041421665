import { Component, OnInit,ViewChild, ElementRef} from '@angular/core';
import { $ } from 'protractor';
import { PublicityService } from 'src/app/services/publicity.service';

@Component({
  selector: 'app-public-carousel',
  templateUrl: './public-carousel.component.html',
  styleUrls: ['./public-carousel.component.scss']
})


export class PublicCarouselComponent implements OnInit {
  publicityListProduct1;
  @ViewChild('myButton') myButton: ElementRef;
  constructor(private publicityService:PublicityService) { 
      setTimeout(() => {
        this.triggerClick();
      }, 5000);
  }

  async ngOnInit(){
    //publicidad
await this.publicityService.getAllPublicity().then(
  
  (result: any) => {
    
    const DB = result.records[0][0];
    this.publicityListProduct1 = DB.filter(x => x.publicityPlace == "IBANNER" && x.publicityStatus == "Activo");
  
    
  }
)
// this.checkOdd();
//fin de publicidad
  }

  checkOdd(){
    if(this.publicityListProduct1.length%2 == 1 && this.publicityListProduct1.length>1){
      let len = this.publicityListProduct1.length -1;
      let random = Math.floor((Math.random()*(len - 1+ 1))+ 1);
      this.publicityListProduct1.push(this.publicityListProduct1[random]);
     }
     this.actv();
  }
  actv(){
    
    var act = true;
    var slide=0;
    this.publicityListProduct1.forEach(element => {
      if (act) {
        element.active = "active";
        element.aria = true;
        act = false;
      }else{
        element.active = "";
        element.aria = false;
      }
      element.slide = slide;
      slide++;
    });

    console.log(this.publicityListProduct1);
  }

  triggerClick(){
    let button: HTMLElement = this.myButton.nativeElement as HTMLElement;
    button.click();
  }

}
