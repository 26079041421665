import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter3'
})
export class Filter3Pipe implements PipeTransform {
  
  transform(value: any, args:any):any {
    const result = []
    var publicityName = args.publicityPlace;
    for(const i of value){
         if(i.code == publicityName){
           result.push(i.name);
         }
    }
    return result;
  }

}
