<app-loading-wrapper-overlay *ngIf="loading"></app-loading-wrapper-overlay>
      <div class="col-sm-12 col-md-12 col-lg-12" >
        <!-- <mat-drawer-container class="example-container" autosize> -->
          <div class="row" style="height: 42rem;margin: 2rem!important;">
            <div class="col-sm-1" style="background-color: #004F9F;border-radius: 1rem;margin-bottom: 1.5rem;">
                  <div class="row" (click)="inbox()" style="margin-top: 1rem;cursor: pointer;">
                       <div class="col-sm-12" style="text-align: center;">
                             <img src="../../../assets/images/core/menu/Mesa de trabajo 1.png" style="width: 6rem;">
                       </div>
                       <div class="col-sm-12">
                        <h3 style="text-align: center;color: white;">Inicio</h3>
                      </div>
                  </div>

                  <div class="row" (click)="finish()" style="margin-top: 0rem;cursor: pointer;">
                    <div class="col-sm-12" style="text-align: center;">
                          <img src="../../../assets/images/core/menu/tick_message_icon_213639 (1).png" style="width: 5rem;">
                    </div>
                    <div class="col-sm-12">
                     <h3 style="text-align: center;color: white;">Finalizado</h3>
                   </div>
                  </div>

                  <div class="row" (click)="deletedMessagesToggle()" style="margin-top: 0rem;cursor: pointer;">
                    <div class="col-sm-12" style="text-align: center;">
                          <img src="../../../assets/images/core/Mesa de trabajo 4.png" style="width: 6rem;">
                    </div>
                    <div class="col-sm-12">
                     <h3 style="text-align: center;color: white;">Borradores</h3>
                   </div>
                  </div>


                  <div class="row" (click)="contacts()" style="margin-top: 0rem;cursor: pointer;">
                    <div class="col-sm-12" style="text-align: center;">
                          <img src="../../../assets/images/core/Mesa de trabajo 3.png" style="width: 6rem;">
                    </div>
                    <div class="col-sm-12">
                     <h3 style="text-align: center;color: white;">Contactos</h3>
                   </div>
                  </div>

                  <div class="row" (click)="sentMessagesToggle()" style="margin-top: 0rem;cursor: pointer;">
                    <div class="col-sm-12" style="text-align: center;">
                          <img src="../../../assets/images/core/Mesa de trabajo 2.png" style="width: 6rem;">
                    </div>
                    <div class="col-sm-12">
                     <h3 style="text-align: center;color: white;">Eliminado</h3>
                   </div>
                  </div>

                  <!-- <div class="row" style="margin-top: 0rem; cursor: pointer;">
                  <div class="col-sm-12" style="text-align: center;">
                     <img src="../../../assets/images/core/publish_ic.png" style="width: 6rem;">
                  </div>
                  <div class="col-sm-12">
                    <h3 style="text-align: center; color: white;">Enviar Correo</h3>
                  </div>
                  </div> -->

            </div>
            <div class="col-sm-11" style="padding-left: 2rem;height: 45rem!important;overflow-y: auto;margin-top: 1rem;">
                <div class="row box1">
                     <div class="col-sm-12">
                      <div class="example-sidenav" >
                        <app-answer-pending *ngIf="answerPending" style="padding: 0!important;"></app-answer-pending>
                        <app-finished-messages *ngIf="finishedMessages" style="padding: 0!important;"></app-finished-messages>
                        <app-sent-messages *ngIf="sentMessages" style="padding: 0!important;"></app-sent-messages>
                        <app-store-messages *ngIf="storeMessages" style="height: 100%;overflow-y: auto;"></app-store-messages>
                        <app-deleted-messages *ngIf="deletedMessages"style="padding: 0!important;"></app-deleted-messages>
                        <app-contacts  *ngIf="contactsChat" style="padding: 0!important;"></app-contacts>
                      </div>
                     </div>
                </div>
            </div>
            

          </div>
          <!-- <mat-drawer #drawer class="example-sidenav" mode="side">
            <p class="box" style="cursor: grab;" (click)="inbox()">Bandeja de entrada</p>
            <p class="box" style="cursor: grab;" (click)="sentMessagesToggle()">Enviados</p>
            <p class="box" style="cursor: grab;" (click)="storeMessagesToggle()">Archivados</p>
            <p class="box" style="cursor: grab;" (click)="deletedMessagesToggle()">Eliminados</p>

          </mat-drawer> -->

          <!-- <div class="example-sidenav-content">
            <app-answer-pending *ngIf="answerPending"></app-answer-pending>
            <app-sent-messages *ngIf="sentMessages"></app-sent-messages>
            <app-store-messages *ngIf="storeMessages"></app-store-messages>
            <app-deleted-messages *ngIf="deletedMessages"></app-deleted-messages>
          </div> -->

        <!-- </mat-drawer-container> -->
      </div>
      <div class="row" style="margin-top: 4rem!important;">

      </div>
    <!-- </mat-tab> -->
  <!-- </mat-tab-group> -->
<!-- </mat-card> -->
