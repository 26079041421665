import { Component, OnInit } from '@angular/core';
import country from '../../../../assets/data/contry.json';
import companySelfUserRoles from '../../../../assets/data/companySelfUserRoles.json';
import masterUserRoles from '../../../../assets/data/masterUserRoles.json';
import { UserModel } from '../../../models/userModel';
import { CompanyModel } from '../../../models/companyModel';
import { v4 as uuidv4 } from 'uuid';
import { UserService } from '../../../services/user.service';
import { AuthService } from '../../../services/auth.service'
import { Router } from '@angular/router';
import { CompanyService } from 'src/app/services/company.services';
import { CategoryService } from 'src/app/services/category.service';
import { MasterCatModel } from 'src/app/models/msterCatModel';
import { SubCategoryModel } from 'src/app/models/subcategoryModel';
import { CategoryModel } from 'src/app/models/categoryModel';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { element } from 'protractor';

@Component({
  selector: 'app-company-self-user-creat',
  templateUrl: './company-self-user-creat.component.html',
  styleUrls: ['./company-self-user-creat.component.scss']
})
export class CompanySelfUserCreatComponent implements OnInit {

  countryList = country;
  rolesList;
  localUser;
  loading = false;
  masterCatList;
  subcategories = {} as SubCategoryModel;
  masterCategory = {} as MasterCatModel;
  categories = {} as CategoryModel;
  categoriesList: [];
  subcategoryList;
  subCatStatus = true;
  familyCatStatus = true;
  masterCatForSave;
  subCatForSave;
  familyCatForSave;
  userDataToShow;
  userdata;
  companyList = [];
  user = {} as UserModel;
  company = {} as CompanyModel;
  inputDisplay = 'display:none;';

  constructor(
    private router: Router,
    private authService: AuthService,
    private userService: UserService,
    private companyService: CompanyService,
    private categoryService: CategoryService,
    private userservice: UserService
  ) { }

  async ngOnInit(){ 
    let user = {} as UserModel;
  if (localStorage.getItem('user')) {
    this.localUser = JSON.parse(localStorage.getItem('user'));
    
    if (this.localUser.userRole === "Master") {
      this.rolesList = masterUserRoles;
      this.inputDisplay = '';
    }else{
      this.rolesList = companySelfUserRoles;
      this.inputDisplay = 'display:none;';
    }
  }

  await this.userservice.oneUser(this.localUser.userId).then(
    (response: any) => {
      const userDB = JSON.parse(response).records[0];
      this.userDataToShow = userDB;
      this.loading = false;
    }
  );
  
  await this.companyService.companyGetAll().then(
    (response: any)=>{
      this.companyList = response.records[0][0];
      
      if (this.userDataToShow.userRole != 'Master') {
        const list = this.companyList;
        this.companyList = [];
        list.forEach(element =>{
           if (element.companyBussinesCode == this.userDataToShow.userCompanyUUID) {
            this.companyList.push(element);
           }
        })
      }
     
      
    } 
  );
 }


  generatePassword() {
    // No cambiar los var loquea el generador.
    const length = 5;
    const lowercase = 'abcdefghijklmnopqrstuvwxyz';
    const uppercase = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const numbers = '0123456789';
    const punctuation = '!@#$%&';
    let retVal = '';
    for (var i = 0, n = lowercase.length; i < length; ++i) {
      retVal += lowercase.charAt(Math.floor(Math.random() * n));
      // tslint:disable-next-line:no-shadowed-variable
      for (var i = 0, n = uppercase.length; i < length; ++i) {
        retVal += uppercase.charAt(Math.floor(Math.random() * n));
        // tslint:disable-next-line:no-shadowed-variable
        for (var i = 0, n = numbers.length; i < length; ++i) {
          retVal += numbers.charAt(Math.floor(Math.random() * n));
          // tslint:disable-next-line:no-shadowed-variable
          for (var i = 0, n = punctuation.length; i < length; ++i) {
            retVal += punctuation.charAt(Math.floor(Math.random() * n));
          }
        }
      }
    }
    return retVal;
  }

  async saveUser() {
    this.user.userPassword = this.generatePassword();
    if (this.user.userPassword.length >= 8){
      const UserSub: any = await this.authService.signUp(this.user);
      this.user.userCognitoCode = UserSub.UserSub;
      this.user.userStatus = "1";
      this.user.userCreationUser = this.localUser.email;
      this.user.userUpdateUser = this.localUser.email;
      if (this.userDataToShow.userRole == 'Master') {
        this.user.userCompanyName = this.company.companyName;
      this.user.userCompanyUUID = this.company.companyBussinesCode;
      }else{
      this.user.userCompanyName = this.userDataToShow.userCompanyName;
      this.user.userCompanyUUID = this.userDataToShow.userCompanyUUID;
      }
      this.userService.saveUserOnTable(this.user).then(
        response => {
          if (response.result === true) {
            Swal.fire({
              icon: 'success',
              title: 'Registro Éxitoso',
              text: 'Usuario registrado Correctamente',
            })
            this.loading = false;
            window.location.reload();
          } else {
            // const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
            // const status: NbComponentStatus = 'warning';
            // this.toastrService.show(status || 'warning', response.message, { position, status });
          }
        },
        error => {
          // const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
          // const status: NbComponentStatus = 'danger';
          // this.toastrService.show(status || 'Danger', <any>error, { position, status });
        }
      )
    } else {
    }
  }

  checked = false;

  toggle(checked: boolean) {
    this.checked = checked;
  }

}
