<div class="col-sm-12">
    <div class="col-sm-12">
        <div class="text-container">
            <div class="col" style="height: 60px; padding-top: 55px; padding-left: 40px;">
                <span class="text" style="color:#004f9f; font-weight: bolder; font-size:1.7rem;">Recuperación de contraseña</span>
                <div style="height: 3px; width:20%; background-color: orange;"></div>
            </div>
        </div>
    </div>
    <div class="container">
        <div class=row>
            <div class="col-sm-12">
                <div class="email-container">
                    <div class="card">
                        <div class="text-container">
                            <div class  class="col" style="height: 60px; padding-top: 55px; padding-left: 40px;">
                                <span class="text" style="color:#004f9f; font-weight: bolder; font-size:1.4rem;">Ingrese su correo electrónico en el siguiente espacio: </span>
                            </div>
                        </div>
                        <div class="email-input--container">
                            <i class="fas fa-at"></i> <input type="text" class="email-input" placeholder="Ingrese su correo electrónico" [(ngModel)]="userEmail">
                        </div>
                        <div class="button-container">
                            <button class="button" (click)="enableMethods()">Siguiente</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- *ngIf="enable" -->
        <div class="col-sm-12" *ngIf="enable">
            <div class="container">
                <div class="row">

                        <div class="col-6">
                            <div class="card">
                                <div class="title-container">
                                    Recuperar contraseña: 
                                </div>
                                <div class="text-container">
                                   * Con este método de recuperación recibirá sus credenciales en su correo electrónico
                                </div>
                               <div class="button-container">
                                <button class="button" (click)="recoveryPswd()">Enviar al correo</button>
                            </div>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="card">
                                <div class="title-container">
                                    Cambiar la contraseña:
                                </div>
                                <div class="text-container">
                                    * Con este método podrá cambiar su contraseña definitivamente, recibirá un código en su correo electrónico que deberá ingresar en este formulario para verificar que es el propietario de la cuenta
                                 </div>
                                <div class="button-container">
                                    <button class="button" (click)="sendVerificationCode()">Enviar código de verificación y cambiar la contraseña</button>
                                </div> 
                                <!--  -->
                                <div class="change-password--container" *ngIf="enablePasswordChangeInputs">
                                    <div class="code-input--container">
                                        <input type="text" class="code-input" [(ngModel)]="verificationCode" placeholder="Ingrese el código de verificación">
                                       </div>
                                       <div class="password-input--container">
                                        <input type="password" class="code-input" [(ngModel)]="key" (input)="passwordComparation(key)" placeholder="Ingrese la nueva contraseña">
                                       </div>
                                       <div class="repeat-input--container">
                                        <input type="password" class="code-input" [(ngModel)]="newKey" placeholder="Repita la contraseña">
                                       </div>
                                       <div class="button-container">
                                        <button class="button" (click)="userForgotPassword()">Cambiar la contraseña</button>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
    
                </div>
            </div>
        </div>

   
    </div>
</div>