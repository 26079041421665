import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ProductsService } from 'src/app/services/products.service';

@Component({
  selector: 'app-products-found',
  templateUrl: './products-found.component.html',
  styleUrls: ['./products-found.component.scss']
})
export class ProductsFoundComponent implements OnInit {
public option = String;
public productSearch = String;
public productsList: any;
public loading: Boolean = false;
public dontHaveResults: Boolean = true;
  constructor(private route: ActivatedRoute, private productsService: ProductsService, private router: Router) { }

  ngOnInit(): void {

    this.route.paramMap.subscribe((params: any) => {
      this.loading = true;
      this.option = params.params.product;
        this.productSearch = this.option;
        console.log(this.productSearch);
})

   this.productsService.getProductWanted(this.productSearch).then(
       (response: any) => {
        console.log(response);
              this.productsList = JSON.parse(response).records;
              console.log(this.productsList);
              document.location.href = "ProductosEncontrados/"+this.option+"#product-title";
              if(this.productsList.length == 0 ){
                 this.dontHaveResults = false;
              }
              this.loading = false;
       }
   )

  
  }

  viewProduct(productUUID: String){
    this.router.navigate(['/VistaProducto/'+productUUID]);
  }
}
