import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
const POOL_DATA = {
  UserPoolId: environment.UserPoolId,
  ClientId: environment.ClientId
};
@Injectable({
  providedIn: 'root'
})
export class AuthServicesService {

  constructor( private http: HttpClient) { }

  apiurl = environment.EnvironmentName;
  getAccess(): Promise<any>{
    const headers = new HttpHeaders().set('Content-Type', 'application/json');

    return this.http.get(environment.API_URL + this.apiurl+'-getSession', { headers}).toPromise();
  }
}
