import { Component, Input, OnInit, Output } from '@angular/core';
import { FormGroup,FormControl, FormGroupDirective, NgForm, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { UserService } from 'src/app/services/user.service';
import {UserModel} from '../../models/userModel';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { CompanyService } from 'src/app/services/company.services';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  hide: boolean = true;
  loading = false;
  userPassword: string;
  userEmail: string;
  userLocalData: any;
  @Output() userMail;

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private userService: UserService,
    public router: Router,
    private companyService:CompanyService
    ) { }

  ngOnInit(): void {
    this.userLocalData = JSON.parse(localStorage.getItem('user'));
    if(this.userLocalData != null){
      this.router.navigate(['//'])
      .then(() => {
        window.location.reload();
      });
    }
  }

  emailFormControl = new FormControl('', [
    Validators.required,
    Validators.email,
  ]);

  passwordFormControl = new FormControl('', [
    Validators.required
  ]);

  signin: FormGroup = new FormGroup({
    email: new FormControl('', [Validators.email, Validators.required ]),
    password: new FormControl('', [Validators.required, Validators.min(3) ])
  });s


  get passwordInput() { return this.signin.get('password'); }

  onSubmit(){
    const email = this.userEmail;
    const password = this.userPassword;
    this.userMail = this.userEmail;
    this.loading = true;
    this.authService.signIn(email, password);
  }

  async signInWithGoogle() {
    console.log("entra");
   window.open("https://cigmatchbackend-prod.auth.us-west-2.amazoncognito.com/login?client_id=6ulvu7avvtt3jc00713ub7emgh&response_type=code&scope=aws.cognito.signin.user.admin+email+openid+phone+profile&redirect_uri=https://cigmatch.com/");
    // this.loading = true;
    // await this.authService.socialSignIn(AuthService.GOOGLE).then(
    //   (auth) => {
        
    //     console.log(auth);
    //     this.loading = false;
    //   }
    // ).catch(
    //   (err) => { 
    //     console.log(err) 
    //     this.loading = false; 
    //   } 
    // ).finally( 
    //   () => { 
    //     this.loading = false; 
    //   }
    // );
  }

  sendPasswordAllUser(){
    
      // this.companyService.companySelfGetOne("1").then(
      //   (result: any) => {
      //     const userDB = JSON.parse(result).records[0][1];
      //     userDB.forEach(async(element) => {
      //         let params = {
      //                 userName: element.userEmail,
      //                 userEmail: element.userEmail,
      //                 userPassword : element.userPassword
      //                 }
      //         await this.authService.sendEmail(params);
      //     });
      //   }
      // );
    

    
  }

  recoverPass(){
    this.router.navigate(['/Recuperacion'])
    // Swal.fire({
    //     imageUrl: '../../../assets/images/core/CIG_M-lanczos3.png',
    //     imageWidth:200,
    //     title:'Ingrese su Correo',
    //     input:'text',
    //     showCancelButton: true,
    //     confirmButtonText: 'Enviar',
    //     cancelButtonText: 'Cancelar',
    //     confirmButtonColor: '#0078d7',
    //     position: 'center',
    //     preConfirm: (email) =>{
    //         console.log(email);
    //         this.userService.oneUserBackend(email).then(
    //           (resultUsers: any) =>{
    //             if(typeof(resultUsers) ==='string'){
    //               resultUsers = JSON.parse(resultUsers);
    //             }else{
    //                 throw resultUsers.errorMessage;
    //             }
    //              const userDB: UserModel = resultUsers.records[1][0];
    //              const psd = userDB.userPassword;
                 
    //            let params = {
    //              userName: email,
    //              userEmail: email,
    //              userPassword : psd
    //            }
    //            this.authService.sendEmail(params);
    //               Swal.fire({
    //                  position: 'center',
    //                  icon: 'success',
    //                  title:'Revisa tu correo eléctronico',
    //                  showConfirmButton: false,
    //                  timer: 1500
    //               })
    //               this.router.navigate(['/Login']);
    //           }
    //         ).catch((err) =>{
    //             Swal.fire({
    //                icon: 'error',
    //                title: 'Ooops..',
    //                text:'Algo salio mal'
    //             })
    //             this.router.navigate(['/Login']);
    //         })
    //     }
    // });
  }

}
