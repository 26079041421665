import { Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatOption } from '@angular/material/core';
import { UserService } from 'src/app/services/user.service';
import { MessageCenterService } from 'src/app/services/messageCenter.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { v4 as uuidv4 } from 'uuid';
import { ChatTableModel } from 'src/app/models/chatTableModel';
@Component({
  selector: 'app-email-sending',
  templateUrl: './email-sending.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./email-sending.component.scss']
})

export class EmailSendingComponent implements OnInit {

   

public contacts: any;
public contactSelected = [];
public files: File = null;
public fileToBase64: any;
public fileName = "";
public fileToS3: any;
public chatData = {} as ChatTableModel;

@ViewChild('allSelected') private allSelected: MatOption;
@ViewChild('userCig') private userCig: MatOption;
@ViewChild('userMix') private userMix: MatOption;
@ViewChild('usersPurchases') private usersPurchases: MatOption;
@ViewChild('usersSales') private usersSales: MatOption;
@ViewChild('usersNotPartners') private usersNotPartners: MatOption;

@ViewChild('uploadDocument') uploadDOcument: ElementRef;



  constructor(private userService: UserService,
     private formGroup: FormBuilder,
      private messageCenter: MessageCenterService) { }

  contactEmails =this.formGroup.group({
    email : new FormControl('')
  })

  emailSend = this.formGroup.group({
     affair: new FormControl(''),
     message: new FormControl('') 
  })

  ngOnInit(): void {
    this.userService.getAllUsers().then(
      (response:any) => {
        console.log(response);
        this.contacts = response.records[0][0];
        console.log(this.contacts);
      }
    )
  }

  selectThisContact(){
       console.log(this.contactEmails);
       this.contactSelected = [];
       this.contactSelected.push(this.contactEmails.value.email);
       if(this.allSelected.selected){
        this.allSelected.deselect();
       }

  }

  selectAll(){
   if(this.allSelected.selected){
     this.contactEmails.controls.email.patchValue([...this.contacts, 0]);
     this.contactSelected = [];
     this.contactSelected.push(this.contactEmails.value.email);
   }else{
  this.deselect();
   }
  }

  selectUsersCig(){
     if(this.userCig.selected){
        let userCigFiltered = this.contacts.filter(x => x.userRole == 'Usuario CIG' || x.userRole == 'UsuarioCIG');
        this.contactEmails.controls.email.patchValue([...userCigFiltered, 1]);
        this.contactSelected = [];
        this.contactSelected.push(this.contactEmails.value.email);
     }else{
      this.deselect();
     }
  }

  selectUsersMix(){
       if(this.userMix.selected){
          let userMixFiltered = this.contacts.filter(x => x.userRole === 'Usuarios CIG Mix (Compra y venta)' || x.userRole == 'USUARIOMIX');
          this.contactEmails.controls.email.patchValue([...userMixFiltered, 2]);
          this.contactSelected = [];
          this.contactSelected.push(this.contactEmails.value.email);
       }else{
         this.deselect();
       }
  }

  selectUsersPurchases(){
    if(this.usersPurchases.selected){
      let usersPurchasesFiltered = this.contacts.filter(x => x.userRole == 'Usuario CIG Compras' || x.userRole == 'USUARIOCOMPRAS');
      this.contactEmails.controls.email.patchValue([...usersPurchasesFiltered, 3]);
      this.contactSelected = [];
      this.contactSelected.push(this.contactEmails.value.email);
   }else{
     this.deselect();
   }
  }

  selectUsersSales(){
    if(this.usersSales.selected){
      let usersSalesFiltered = this.contacts.filter(x => x.userRole == 'Usuario CIG Ventas' || x.userRole == 'USUARIOVENTAS');
      this.contactEmails.controls.email.patchValue([...usersSalesFiltered, 4]);
      this.contactSelected = [];
      this.contactSelected.push(this.contactEmails.value.email);
   }else{
     this.deselect();
   }
  }

  selectUsersNotPartners(){
    if(this.usersNotPartners.selected){
      let usersNotPartnersFiltered = this.contacts.filter(x => x.userRole == 'Usuario No Socio' || x.userRole == 'UsuarioNoSocio');
      this.contactEmails.controls.email.patchValue([...usersNotPartnersFiltered, 5]);
      this.contactSelected = [];
      this.contactSelected.push(this.contactEmails.value.email);
   }else{
     this.deselect();
   }
  }

  deselect(){
      this.contactEmails.controls.email.patchValue([]);
      this.contactSelected = [];
  }

async  sendEmail(){
    console.log(this.contactSelected);
  if(this.contactSelected.length == 0 || this.emailSend.value.message.length == 0 ){
    Swal.fire({
         title:'Error',
         text:'una o más casillas vacías',
         icon: 'error'
    })
  }else{
    Swal.fire({
      title: 'Enviando correo(s)...',
      icon:'info'
  })  
  await this.sendMailsToSes();

  }
  }

  sendMailsToSes(){
    for(let emails of this.contactSelected[0]){
      console.log(emails.userEmail);
      let email = {
        emailsToSend: emails.userEmail,
        affair: this.emailSend.value.affair,
        message: this.emailSend.value.message,
        file: this.fileToBase64,
        fileName: this.fileName
    }
  this.messageCenter.sendEmailPersonalized(email).then(
      (response:any) => {
        console.log(response);
      }
     )
     }
     Swal.fire({
      title: 'Correo(s) enviados',
      icon:'success',
      timer: 3000
      
  })
     setTimeout(() => {
      location.reload();
  }, 4000);
  }

  async addDocument(files: FileList){
      console.log(files);
      this.fileName = files[0].name;
      const thisFile = files.item(0);
      this.fileToS3 = thisFile;
      const toBase64 = await this.convertToBase64(thisFile);
      this.fileToBase64 = toBase64;
      console.log(this.fileName);
      console.log(this.fileToBase64);
  }

 async convertToBase64(file:File): Promise<String>{
          return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () =>{
               resolve(reader.result as string);
            };
            reader.onerror = reject;
            reader.readAsDataURL(file);
          })
  }

  quitDocument(){
     this.fileToBase64 = undefined;
  }

async uploadFiles3(){
   var codeUUID = uuidv4();
   await this.messageCenter.uploadFileS3(this.fileToS3, codeUUID).then(
    (result: any) => {
       console.log(result);
       const link = result.Location;
       console.log(link);
       this.chatData.chatDocument = result.Location;
    }
   )
}

async sendInMessage(){
   if(this.fileToS3 != null){
     await this.uploadFiles3;
   }
}

}
