<footer id="footer">
  <div class="footer-top">
    <!-- <div class="col-sm-12 col-lg-12 col-md-12" style="text-align-last: center;"><img src="../../../assets/images/core/CIG_M-lanczos3Blanco.ico" style="max-width: 8rem !important; min-width: 8rem !important;"></div> -->
    <div class="row" style="min-width: 100% !important; max-width: 100% !important; padding: 3rem 1rem 2rem 1rem !important;">
      <div class="col-lg-2 col-md-2 col-sm-2">
        <img src="../../../assets/images/core/CIG_M-lanczos3Blanco.ico" style="max-width: 13rem !important; min-width: 13rem !important;margin-top: -2.5rem!important;">
      </div>    
      <div class="col-lg-3 col-md-3 col-sm-3">
            <div class="row">
              <!-- <div class="col-sm-6 col-lg-6 col-md-6">
                <div class="col-sm-12 col-lg-12 col-md-12"><span style="color: white; font-size: smaller; font-size: small; font-weight: bold;width: 70%;"> CIGMatch es un producto de: </span></div>
                <a href="https://cig.industriaguate.com/"><div class="col-sm-12 col-lg-12 col-md-12" ><img src="../../../assets/images/core/CIGLogo.png" class="CIGLogo" ></div></a>
              </div>
              <div class="col-sm-1 col-lg-1 col-md-1" style="place-self: center !important;">
                <div class="vl"></div>
              </div> -->
                <div class="col-sm-12 col-lg-12 col-md-12" style="margin-left: 10px !important;width: 100%;">
                  <span style="color: white; font-size: medium;"> <br> </span>
                </div>
                <div class="col-sm-12 col-lg-12 col-md-12" style="margin-left: 10px !important;width: 150%;">
                  <span style="color: white; font-size: medium;">  Afiliate a la red profesional de industrias en</span>
                </div>
                <div class="col-sm-12 col-lg-12 col-md-12" style="margin-left: 10px !important;width: 150%;">
                  <span style="color: white; font-size: medium;"> Guatemala</span>
                </div>
                <div class="col-sm-12 col-lg-12 col-md-12" style="margin-left: 10px !important;width: 150%;margin-top: 15px!important;">
                  <span style="color: white; font-size: medium;"> <i class="fas fa-phone-alt"></i>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <a href="https://wa.me/41508502" style="text-decoration: none; color:inherit">+502 4150-8502</a></span>
                </div>
                <div class="col-sm-12 col-lg-12 col-md-12" style="margin-left: 10px !important;width: 150%;">
                  <span style="color: white; font-size: medium;"> <i class="far fa-envelope-open"></i>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <a href="mailto:cigmatch@industriaguate.com" style="text-decoration: none; color:inherit">cigmatch@industriaguate.com</a></span>
                </div>
                <div class="col-sm-12 col-lg-12 col-md-12" style="margin-left: 10px !important;width: 100%;margin-top: 10px!important;">
                  <span style="color: white; font-size: medium;"> </span>
                </div>
                <!-- <div class="col-sm-12 col-lg-12 col-md-12" style="margin-left: 40px !important;width: 150%;">
                  <a style="color: white;" href="https://www.facebook.com/industriaguate/"><i class="fab fa-facebook" style="padding: 4px !important; font-size: xx-large;margin-left: 8px!important;"></i></a><i class="fab fa-instagram" style="padding: 4px !important; font-size: xx-large;margin-left: 8px!important;"></i>
                  <i class="fab fa-twitter" style="padding: 4px !important; font-size: xx-large;margin-left: 8px!important;"></i><i class="fab fa-linkedin" style="padding: 4px !important; font-size: xx-large;margin-left: 8px!important;"></i>
                </div> -->
            </div>
          </div>
          <div class="col-lg-2 col-md-2 col-sm-2">
            <div class="row" style="text-align: justify; justify-content: flex-end;">
              <!-- <div class="col-lg-3 col-md-3 col-sm-3 footer-links">
                    <h4>Comprar</h4>
                    <ul>
                        <a class="col-sm-12 col-lg-12 col-md-12" style="text-decoration: none;"><span>¿Cómo comprar?</span></a>
                        <a class="col-sm-12 col-lg-12 col-md-12" style="text-decoration: none;"><span>Ver proveedores</span></a>
                        <a class="col-sm-12 col-lg-12 col-md-12" style="text-decoration: none;"><span>Publica una solicitud</span></a>
                        <a class="col-sm-12 col-lg-12 col-md-12" style="text-decoration: none;"><span>Ver categorías</span></a>
                    </ul>
                </div> -->
                <!-- <div class="col-lg-3 col-md-3 col-sm-3 footer-links">
                    <h4>Vender</h4>
                    <ul>
                      <a class="col-sm-12 col-lg-12 col-md-12" style="text-decoration: none;"><span>¿Cómo vender?</span></a>
                      <a class="col-sm-12 col-lg-12 col-md-12" style="text-decoration: none;"><span>Publica un producto</span></a>
                      <a class="col-sm-12 col-lg-12 col-md-12" style="text-decoration: none;"><span>Date de alta como vendedor</span></a>
                    </ul>
                </div> -->
                <!-- <div class="col-lg-3 col-md-3 col-sm-3 footer-links">
                  <h4>CIGMatch</h4>
                  <ul>
                    <a><span style="text-decoration: none;">Política de privacidad</span></a>
                    <a><span style="text-decoration: none;">Términos y condiciones</span></a>
                    <a><span style="text-decoration: none;">Ayuda: cigmatch@industriaguate.com</span></a>
                  </ul>
              </div> -->

              <div class="col-lg-12 col-md-12 col-sm-12 footer-links" style="margin-top: 1rem!important;">
            
                <ul>
                  <a href="Categorías" class="col-sm-12 col-lg-12 col-md-12" style="text-decoration: none;color: white; font-size: medium; cursor: pointer;"><span>Categorías</span></a>
                  <a href="DirectorioEmpresarial" class="col-sm-12 col-lg-12 col-md-12" style="text-decoration: none;color: white; font-size: medium; cursor: pointer;"><span>Directorio Empresarial</span></a>
                  <a href="PortalDeSolicitudes"class="col-sm-12 col-lg-12 col-md-12" style="text-decoration: none;color: white; font-size: medium; cursor: pointer;"><span>Portal de solicitudes</span></a>
                  <a href="RegistroDeProducto" class="col-sm-12 col-lg-12 col-md-12" style="text-decoration: none;color: white; font-size: medium; cursor: pointer;"><span>Publicar producto</span></a>
                  <a href="https://cigmatchbackend-prod-files.s3.us-west-2.amazonaws.com/policies/T%C3%A9rminos+y+condiciones.pdf" target="_blank" class="col-sm-12 col-lg-12 col-md-12" style="text-decoration: none;color: white; font-size: medium;"><span>Términos y condiciones</span></a>
                  <a href="https://cigmatchbackend-prod-files.s3.us-west-2.amazonaws.com/policies/Pol%C3%ADtica+de+privacidad.pdf" target="_blank" class="col-sm-12 col-lg-12 col-md-12" style="text-decoration: none;color: white; font-size: medium;"><span>Política de privacidad</span></a>
                </ul>
            </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-3 col-sm-3">
            <div class="row">

            
          <div class="col-sm-12 col-lg-12 col-md-12"><span style="text-decoration: none;color: white; font-size:medium; font-weight: bold;width: 100%;text-align: center;"> CIGMatch es un producto de: </span></div>
                <a href="https://cig.industriaguate.com/"><div class="col-sm-12 col-lg-12 col-md-12" ><img src="../../../assets/images/core/CIGLogo.png" class="CIGLogo" ></div></a>
              </div>
            </div>

          <div class="col-lg-2 col-md-2 col-sm-2" style="text-align-last: center;" id="logo-container">
            <div class="row">
                <div class="col-sm-12 col-lg-12 col-md-12"><span style="color: white; font-size: smaller; font-size: small; font-weight: bold;"> Estrategia y desarrollo elaborado por: </span></div>
               <a href="https://myappsoftware.com/"><div class="col-sm-12 col-lg-12 col-md-12"><img src="../../../assets/images/core/Logo Blanco MA.png" width="130" style="border-right: 2px solid #F58626;" id="myapp">
                <img src="../../../assets/images/core/Logo Blanco C.png" width="100" id="cripto"></div></a>
            </div>
          </div>
      </div>
      </div>

      <div class="col-sm-12 col-lg-12 col-md-12" style="text-align: -webkit-center;margin: 0.5rem 0 0.5rem 0 !important;background-color: #FFFFFF;color: #0078D7;margin-top: -0.5rem!important; padding-top: 0.4rem!important;padding-bottom: 0rem!important;"><p> <span style="color: #0078D7;font-size: 20px;margin-top: 15px!important;">©</span> Derechos Reservados | Cámara de Industria Guatemala</p></div>
  
</footer>
