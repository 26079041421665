<!-- Carousel wrapper -->
<div id="carouselExampleIndicators"class="carousel slide carousel-fade" data-bs-ride="true" >
  <!-- Indicators -->
  <div class="carousel-indicators" *ngIf="publicityListProduct1 != undefined">
    <!-- <div class="carousel-item {{publicity3.active}}" *ngFor="let publicity3 of publicityListProduct1"> <img [src]="publicity3.publicityBanner" class="carouselImage d-block w-100"> </div> -->
    <button #myButton id="myButton"  value="refresh" type="button" data-bs-target="#carouselExampleIndicators" *ngFor="let publicity3 of publicityListProduct1;let index = index; first as isFirst" [attr.data-bs-slide-to]="index" [ngClass]="isFirst ? 'design active':'design'" [attr.aria-current]="isFirst ? 'true' : 'false'" ></button>
    <!-- <button type="button"  data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" class="design active" aria-current="true" aria-label="Slide 0"></button>
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" class="design" aria-current="false" aria-label="Slide 1"></button>
    <button type="button"  data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" class="design" aria-current="false" aria-label="Slide 2"></button> -->
  </div>
  <!-- Inner -->
  <div class="carousel-inner">
     
    <!-- Single item -->
    <!-- <div class="carousel-item active">
      <img src="../../../assets/images/core/banner/Mesa de trabajo 26 copia 4.jpg" class="carouselImage d-block w-100" alt="..."/>
    </div>
    <div class="carousel-item">
      <img src="../../../assets/images/core/banner/Mesa de trabajo 26.jpg" class="carouselImage d-block w-100" alt="..."/>
    </div>
    <div class="carousel-item">
      <img src="../../../assets/images/core/banner/Mesa de trabajo 26 copia 2.jpg" class="carouselImage d-block w-100" alt="..."/>
    </div>
    <div class="carousel-item">
      <img src="../../../assets/images/core/banner/Mesa de trabajo 26 copia 3.jpg" class="carouselImage d-block w-100" alt="..."/>
    </div> -->
    <div [ngClass]="isFirst ? 'carousel-item active' : 'carousel-item'"  *ngFor="let publicity3 of publicityListProduct1;first as isFirst"><a href="{{publicity3.publicityLink}}"><img [src]="publicity3.publicityBanner" class="carouselImage d-block w-100"></a></div>
   
    
  </div>
</div>
<!-- Carousel wrapper -->

<!-- <div id="carouselExampleCaptions" class="carousel slide" data-ride="carousel">
  <ol class="carousel-indicators">
    <li data-target="#carouselExampleCaptions"  *ngFor="let publicity3 of publicityListProduct1;let index = index" [attr.data-slide-to]="index"></li>
  </ol>
  <div class="carousel-inner">
    <div class="carousel-item" *ngFor="let publicity3 of publicityListProduct1;let index = index;first as isFirst"  [ngClass]="isFirst ? 'carousel-item active' : 'carousel-item' ">
      <img [src]="publicity3.publicityBanner" class="d-block w-100" [alt]="">
      <div class="carousel-caption d-none d-md-block">
      </div>
    </div>
  </div>
  <a class="carousel-control-prev" href="#carouselExampleCaptions" role="button" data-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="sr-only">Previous</span>
  </a>
  <a class="carousel-control-next" href="#carouselExampleCaptions" role="button" data-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="sr-only">Next</span>
  </a>
</div> -->