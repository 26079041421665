import { Component, OnInit } from '@angular/core';
import { RequestModel} from 'src/app/models/requestModel';
import { ProductsModel } from 'src/app/models/productsModel';
import { Router } from '@angular/router';
import { RequestService } from 'src/app/services/request.service';
import { ProductsService } from 'src/app/services/products.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { updateLanguageServiceSourceFile } from 'typescript';

@Component({
  selector: 'app-pending-application-portal',
  templateUrl: './pending-application-portal.component.html',
  styleUrls: ['./pending-application-portal.component.scss']
})
export class PendingApplicationPortalComponent implements OnInit {

  constructor(private requestService: RequestService, private router: Router, private productsService: ProductsService) { }
public requestList;
public pagination: Number = 16;
request = {} as RequestModel;
public productsList;
  ngOnInit(): void {
       this.productsService.productGetAllPending().then(
        response => {
          console.log(response);
          this.productsList = response.records[0][0];
        }
       )

  }

  changePagination(value){
    this.pagination = value;
  
}

navigateRequest(code){
  var ruta = "vistaProductosPendientes/"+code
  this.router.navigateByUrl(ruta);
}

acceptOrDeleteRequest(value, code){
  console.log(value, code);
  if(value == 1){
    Swal.fire({
       title:'¿Está seguro de ACEPTAR la solicitud?',
       icon: 'warning',
       showCancelButton: true,
       confirmButtonText: 'Aceptar',
       cancelButtonText: 'Cancelar'
    }).then((result) =>{
      var params = {
        pendingStatus: value,
         productCode: code
      }
        if(result.isConfirmed){
           this.productsService.updatePendingProduct(params).then(
              (response: any) =>{
                console.log(response);
                if(response.statusCode == 200){
                  Swal.fire(
                    'Aceptado',
                    'Solicitud aceptada correctamente',
                    'success'
                  )
                  setTimeout(function (){
                    window.location.reload();
                  }, 1600);                             
                }
              }
           )
        }
    })
  }else if(value == 2){
     Swal.fire({
      title: "¿Está seguro de DENEGAR la solicitud?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Aceptar',
      cancelButtonText: 'Cancelar'
     }).then((result)=>{
       var params = {
        pendingStatus: value,
        productCode: code
       }
       if(result.isConfirmed){
         this.productsService.updatePendingProduct(params).then(
          (response: any) =>{
            console.log(response);
            if(response.statusCode == 200){
              Swal.fire(
                'Denegado',
                'Solicitud denegada de manera correcta',
                'success'
              )
              setTimeout(function (){
                  window.location.reload()
             }, 1600); 
            } 
          }
         )
       }
     })
  }
}

}
