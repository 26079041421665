<app-loading-wrapper-overlay *ngIf="loading"></app-loading-wrapper-overlay>
<div class="row">
    <div class="col-sm-5" style="overflow-y: auto;height: 40rem;overflow-x: hidden; padding-left: 0!important;" >

<div class="col-md-12" style="padding-left: 15%;padding-right: 15%;margin-bottom: 1rem;">
  <div class="form"> <input type="text" class="form-control form-input" placeholder="¿Qué estás buscando?" [(ngModel)]="this.busqueda" on-keyup="$event.keyCode == 13 && this.search()"></div>

</div>
<div class="col-md-12 " *ngFor="let messages of mainMessageInfo" style="margin: 0!important;width: 100%;padding-left: -5px!important;z-index: 1;">
    <div class="shadow p-3 bg-white" (click)="loadMessages(messages)" style="margin-left: -5%!important;width: 107%;">
        <div class="d-flex justify-content-between" style="align-items: center;margin: 0!important;width: 100%;">
          <div class="row">
            <div class="col-sm-12">
                
              <h3 class="mb-0" style="color: #004F9F;font-weight: 500;font-size: 0.8rem;text-align: right;">{{ messages.chatMessage_DATE | date :'d/MM/YYYY, h:mm a'}}</h3>
              
           </div>
           
            <div class="col-sm-3 d-flex flex-row align-items-center" style="margin-top: -2rem!important;">
              <div class="position-relative"> <img src="../../../../../assets/images/core/menu/1485477097-avatar_78580.png" width="130" class="rounded-circle">  </div>
          </div>

          <div class="col-sm-8" style="margin-top: 0rem;">
            <div class="row">
              <div class="col-sm-12" style="color: #004F9F;font-size: 1.5rem;font-weight: 700;"> <span>{{messages.chatUserSender}}</span></div>
              <div class="col-sm-12" style="color: #0078D7;font-size: 1.2rem;margin-top: 5px;"> <span>{{messages.productName}}</span></div>
              <div class="col-sm-12" style="color: #898989;font-size: 1.1rem;margin-top: 5px;"><span style="color: #F58626;font-size: 1.0rem;font-weight: 700;">(Borrador)</span> <span>&nbsp;&nbsp;&nbsp; {{messages.chatMessage}}</span></div>
              
            </div>
          </div>
          <div class="col-sm-1" style="justify-content: right!important;cursor: pointer;" (click)="delete(messages.chatUUID)">
            <img src="../../../../../assets/images/core/menu/delete_message.png" width="20px" style="opacity: 60%;">
           </div>

          </div>





        </div>
    </div>
</div>


</div>
<div class="col-sm-7">
  <div class="row"  *ngIf="this.codeChat != ''">
  <div class="col-sm-12">
    <div class="row" style="border-bottom: 2px solid #ebebeb;overflow-y: hidden; height: auto !important;"> 
      <div class="col-sm-12" id="product-info--container">
        <div class="row">
          <div class="col-sm-2">
            <img [src]="productsData.productMainPicture" style="width: 100%;">
          </div>
          <div class="col-sm-5">
  
            <div style="cursor: pointer;" (click) ="viewCompany(data)"><span style="color: #004F9F;font-size: 1.5rem;font-weight: 700;" >{{productsData.productName}}</span></div>
            <!-- <div style="margin-top: 1rem!important;cursor: pointer;" (click) ="viewCompanyD()"><span style="color: #0078D7;font-size: 1.2rem;font-weight: 100;text-decoration: underline;cursor: pointer;"  >{{data.chatUserReceiver}}</span></div> -->
            <div  style="color: #898989;font-size: 0.8rem;margin-top: 0.5rem;"*ngIf=" 1 * productsData.productPrice > 0"> <span>{{productsData.unitPriceType}}{{productsData.productPrice}}</span></div>
        </div>
        <div class="col-sm-4" style="text-align: right;" *ngIf = "userCompare">
          
          <div style="margin-top: 3rem;"><span><button mat-button style="width: 70%; background-color:#F5F5F5;" (click)="qualifyRequest()">Solicitar calificación</button></span></div>
      </div>
        </div>
         </div>
     </div>
  </div>
</div>
<div class="row">
  <div class="col-sm-12" *ngIf="this.codeChat == ''" style="align-items: center;padding: 2rem;padding-bottom: 0rem;padding-top: 10%;opacity: 50%;">
    <img src="../../../../../assets/images/core/menu/que-es-networking.jpg" style="width: 100%;">
    <h1 style="color: #898989;margin-top: -1rem;text-align: center;font-size: 2rem;font-weight: bolder;">Bienvenido al centro de mensajes</h1>
</div>
</div>

<div class="row"  *ngIf="this.codeChat != ''">
  <div class="col-sm-12" style="height: 38rem!important;overflow-y: auto; padding: 1rem;" id="ventanachat">


  <div *ngFor="let messages of messagesData">
    <!-- <div class="media-body">
      <p *ngIf="actualUser !== chatData.chatUser_Sender && messages.chatMessageR !== null">{{messages.chatMessageR + " - "}} <time datetime="2018" style="color: black;">{{messages.chatMessage_DATE | date:'short'}}</time></p>
      <p *ngIf="actualUser === chatData.chatUser_Sender && messages.chatMessage !== null">{{messages.chatMessage + " - "}} <time datetime="2018" style="color: black;">{{messages.chatMessage_DATE | date:'short'}}</time></p> -->
      <!-- <p *ngIf="actualUser !== chatData.chatUser_Receiver && messages.chatMessageR !== null">{{messages.chatMessageR + " - "}} <time datetime="2018" style="color: black;">{{messages.chatMessage_DATE | date:'short'}}</time></p> -->
    <!-- </div>
    <div class="media media-chat media-chat-reverse">
      <div class="media-body" style="float: right;">
        <time datetime="2018" style="color: black;text-align: right;">{{messages.chatMessage_DATE | date: 'd/MM/YYYY, h:mm:ss a'}}</time>
        <p *ngIf="actualUser !== chatData.chatUser_Receiver && messages.chatMessage !== null">{{messages.chatMessage + " - "}}</p> -->
        <!-- <p *ngIf="actualUser !== chatData.chatUser_Sender && messages.chatMessage !== null">{{messages.chatMessage + " - "}} <time datetime="2018" style="color: black;">{{messages.chatMessage_DATE | date:'short'}}</time></p> -->
      <!-- </div>
    </div> -->

    <div class="row" style="margin-top: 1.3%;margin-left: 0.5rem;border-color: black!important;justify-content: left!important;" *ngIf="actualUser !== messages.chatUser_Sender && messages.chatMessageR !== null">
      <div class="col-sm-1" style="cursor: pointer!important;">
        <img src="../../../../../assets/images/core/menu/1485477097-avatar_78580.png" width="50" class="rounded-circle" style="margin-left: -10px;"> 
      </div>
      <div class="col-sm-9" class="sms2"  style="max-width: 60%!important;">
        <div class="row"> 
             <div class="col-sm-12">
              <span style="width: auto;max-width: 100%;">{{messages.chatMessageR}}  </span>
             </div>
             <div class="col-sm-12" style="text-align: right;margin-bottom: -10px;">
              <time datetime="2018" style="color: #ffffff;font-weight: lighter;font-size: smaller;">{{messages.chatMessage_DATE | date:'d/MM/YYYY, h:mm a'}}</time>
             </div>
        </div>
       
      </div>
    </div>


    <div class="row" style="margin-top: 1.3%;margin-left: 0.5rem;border-color: black!important;justify-content: left!important;" *ngIf="actualUser == messages.chatUser_Receiver && messages.chatMessage !== null">
      <div class="col-sm-1">
        <img src="../../../../../assets/images/core/menu/1485477097-avatar_78580.png" width="50" class="rounded-circle" style="margin-left: -10px;"> 
      </div>
      <div class="col-sm-9" class="sms2"  style="max-width: 60%!important;">
        <div class="row"> 
             <div class="col-sm-12">
              <span style="width: auto;max-width: 100%;">{{messages.chatMessage}}  </span>
             </div>
             <div class="col-sm-12" style="text-align: right;margin-bottom: -10px;">
              <time datetime="2018" style="color: #ffffff;font-weight: lighter;font-size: smaller;">{{messages.chatMessage_DATE | date:'d/MM/YYYY, h:mm a'}}</time>
             </div>
        </div>
       
      </div>
    </div>
    

    <div class="row" style="margin-top: 1.3%;border-color: black!important;justify-content: right!important;">
      
      <div class="col-sm-9" class="sms" *ngIf="actualUser !== messages.chatUser_Receiver && messages.chatMessage !== null" style="max-width: 60%!important;">
        <div class="row"> 
             <div class="col-sm-12">
              <span style="width: auto;max-width: 100%;">{{messages.chatMessage}}  </span>
             </div>
             <div class="col-sm-12" style="text-align: right;justify-content: right!important;margin-bottom: -10px;">
              <time datetime="2018" style="color: #fff;font-weight: lighter;font-size: smaller;">{{messages.chatMessage_DATE | date:'d/MM/YYYY, h:mm a'}}</time>
             </div>
        </div>
       
      </div>
    </div>


    <div class="row" style="margin-top: 1.3%;border-color: black!important;justify-content: right!important;">
      
      <div class="col-sm-9" class="sms" *ngIf="actualUser === messages.chatUser_Sender && messages.chatMessageR !== null" style="max-width: 60%!important;">
        <div class="row"> 
             <div class="col-sm-12">
              <span style="width: auto;max-width: 100%;">{{messages.chatMessageR}}  </span>
             </div>
             <div class="col-sm-12" style="text-align: right;justify-content: right!important;margin-bottom: -10px;">
              <time datetime="2018" style="color: #fff;font-weight: lighter;font-size: smaller;">{{messages.chatMessage_DATE | date:'d/MM/YYYY, h:mm a'}}</time>
             </div>
        </div>
       
      </div>
    </div>


</div>
</div>
<div class="col-sm-12">
  <div class="publisher bt-1 border-light"> <img class="avatar avatar-xs" src="https://img.icons8.com/color/36/000000/administrator-male.png" alt="...">
    <input class="publisher-input" type="text" placeholder="Escribe tu mensaje ..." [(ngModel)]="localChatMessage" #chatTextInput on-keyup="$event.keyCode == 13 && sendMessage() || $event.keyCode != 13 && sendMessageTemp()">
    <span class="publisher-btn file-group"> <i class="fa fa-paperclip file-browser"></i>
    <input type="file"> </span>
    <a class="publisher-btn" data-abc="true"><i class="fa fa-smile"></i></a>

    <a style="padding: 0;margin-left: 0.2rem;" class="publisher-btn text-info" data-abc="true" (click)="sendMessage()"><img src="../../../../../../assets/images/core/sendMessage.png" alt="Enviar" style="width: 1.45rem;height: 1.45rem;margin: 0;border: 0;"></a>
  </div>
</div>
</div>
</div>
</div>
