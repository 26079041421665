<h2 mat-dialog-title>Nueva publicidad</h2>
<mat-dialog-content class="mat-typography" style="min-height: 43vh; max-height: 43vh">
  <div class="row" >
    <div class="col-sm-12">
      <div class="row" >
        <div class="col-sm-12" >
          <mat-form-field appearance="outline" style="width: 100%!important;">
            <mat-label>Nombre de publicidad</mat-label>
            <input matInput placeholder="Nombre de publicidad" autofilled= false [(ngModel)]="publicityModel.publicityName" >
          </mat-form-field>
        </div>
        <div class="col-sm-12" >
          <mat-form-field appearance="outline" style="width: 100%!important;">
            <mat-label>Hipervínculo de publicidad</mat-label>
            <input matInput placeholder="Hipervínculo de publicidad" autofilled= false [(ngModel)]="publicityModel.publicityLink" >
          </mat-form-field>
        </div>
        <div class="col-sm-9">
          <mat-form-field appearance="outline" style="width: 100%!important;">
            <mat-label>Seleccione lugar</mat-label>
            <mat-select [(ngModel)]="publicityModel.publicityPlace" required>
              <mat-option *ngFor="let publicityPlace of rolepublicityPlaceList" [value]="publicityPlace.code">
                {{publicityPlace.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      
    </div>
  </div>

<div class="col-sm-12">
  <div class="col-sm-3">
    <label class="custom-file-label" #labelImportPrincipal for="imagenPrincipal"><i class="fas fa-cloud-upload-alt"></i> Subir imagen</label>
    <button  mat-icon-button>
      <mat-icon  for="imagenPrincipal" (click)="imagenPrincipal.click()" style="font-size: 4rem;">cloud_upload</mat-icon>
      <input nbInput type="file" class="custom-file-input" #imagenPrincipal id="imagenPrincipal" accept="image/x-png,image/gif,image/jpeg" (change)="principalImage($event.target.files)" hidden>
    </button>
  </div>
  <div class="col-sm-12" *ngIf="publicityModel.publicityBanner != null && publicityModel.publicityBanner != undefined && publicityModel.publicityBanner != ''" style="margin-top: 1.5rem!important;">
    <img [src]="publicityModel.publicityBanner" style="max-height: 5rem!important;max-width: 90%!important;">
  </div>
</div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Cancel</button>
  <button mat-button (click)="uploadBanner()" cdkFocusInitial>Subir</button>
  <!-- <button mat-button [mat-dialog-close]="true" cdkFocusInitial>Install</button> -->
</mat-dialog-actions>
