import { Component, OnInit } from '@angular/core';
import { CompanyModel } from 'src/app/models/companyModel';
import { UserModel } from 'src/app/models/userModel';
import { FormGroup, FormControl } from '@angular/forms';
import { CompanyService } from 'src/app/services/company.services';
import { UserService } from 'src/app/services/user.service';
import { AuthService } from 'src/app/services/auth.service';
import Swal from 'sweetalert2';
import * as xlsx from 'xlsx-with-styles';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-company-users-info',
  templateUrl: './company-users-info.component.html',
  styleUrls: ['./company-users-info.component.scss']
})
export class CompanyUsersInfoComponent implements OnInit {
  loading = false;
  user;
  userDataToShow;
  userList;
  p: number = 1;
  show=false;
  pass="";
  searchUser = "";
  searchEmail = "";
  searchRole ="";
  searchBusiness ="";
  userRole;
  oldpassword="";
  newpassword="";
  private usersToCopy = [];
  public usersEmailsToShow = "";
  public emails: any;
  public button;
  public disabledAll:Boolean = false;
  public disabledAlert:Boolean = false;
  public countProductsElement = [];
  public userReport;
  public productReport;
  

  constructor(
    private userService: UserService,
    private companyService: CompanyService,
    private authService: AuthService
  ) { }

  async ngOnInit(){
    this.loading = true;
    if (localStorage.getItem('user')) {
      this.user = JSON.parse(localStorage.getItem('user'));
      this.userRole = this.user.userRole;
    }
    await this.userService.oneUser(this.user.userId).then(
      (response: any) => {
        const userDB = JSON.parse(response).records[0];
        this.userDataToShow = userDB;
        this.companySelfGetAll(this.userDataToShow.userCompanyUUID);
      }
    );
  }
  showPassword(password){
    
    Swal
    .fire({
        title: "Ingrese el pin único para ver la contraseña",
        input: "password",
        showCancelButton: true,
        confirmButtonText: "Mostrar",
        confirmButtonColor: '#004F9F',
        cancelButtonText: "Cancelar",
        cancelButtonColor: "#898989",
        
    })
    .then(resultado => {
        if (resultado.value) {
            let pass = resultado.value;
            if (pass == "1234") {
              // this.show = true;
              Swal.fire({
                position: 'center',
                icon: 'success',
                title: ''+password,
                showConfirmButton: true
              })
            }else{
              Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: 'Pin incorrecto',
                showConfirmButton: false,
                timer: 1500
              })
            }
            
        }
    });

    
  }

  editPassword(event){
    console.log(event);

    var password = event.userPassword;
    var newpass="";
    Swal
    .fire({
        title: "Ingrese la Contraseña Actual",
        input: "password",
        showCancelButton: true,
        confirmButtonText: "Aceptar",
        confirmButtonColor: '#004F9F',
        cancelButtonText: "Cancelar",
        cancelButtonColor: "#898989",
        
    })
    .then(resultado => {
        if (resultado.value) {
            let pass = resultado.value;
            if (pass == password) {
                  //Ingresar nueva contraseña
                  Swal.fire({
                      title: "Ingrese la Nueva Contraseña",
                      input: "password",
                      showCancelButton: true,
                      confirmButtonText: "Cambiar Contraseña",
                      confirmButtonColor: '#004F9F',
                      cancelButtonText: "Cancelar",
                      cancelButtonColor: "#898989",
        
                   }).then(async(response) => {
                        if (response.value) {
                      newpass = response.value;
                      if (newpass !== password) {
                        this.loading = true;
                          
                          //Cambiar Contraseña
                          console.log(event.userCognitoCode);
                          console.log(this.user.userCognitoCode);
                          await this.userService.changePasswordCognito(event.userCognitoCode, password, newpass)
                          .then(
                            async (result) => {
                              console.log(result)
                                const user = {
                                    userCognitoCode: event.userCognitoCode,
                                     userPassword: newpass,
                                } as UserModel;
                                console.log(user);
                                
                                await this.userService.updateUserBackend(user).then(res =>{
                                  console.log(event);
                                  
                                  // this.sesService.sendEmail(usrName, password, usrName, subdomain);
                                  let params = {
                                  userName: event.userEmail,
                                  userPassword: newpass,
                                  userEmail: event.userEmail,
                                  cigURLSite: environment.devsite,
                                }
                                this.authService.sendEmailUpdateCredentials(params).then(res =>{
                                  this.loading = false;
                                  Swal.fire({
                                    position: 'center',
                                    icon: 'success',
                                    title: 'Contraseña Actualizada exitosamente',
                                    showConfirmButton: true
                                  })
                                  setTimeout(function(){
                                    this.loading = false;
                                    window.location.reload();
                                  },1600);
                                 
                                });
                               
                                });
           
          }
        ).catch(
          (err) => {
           //error 
           console.log(err);
          }
        );

                        


                      }else{
                       Swal.fire({
                          position: 'top-end',
                          icon: 'error',
                          title: 'La nueva contraseña no debe ser igual a la contraseña antigua, Por favor intente de nuevo',
                          showConfirmButton: false,
                          timer: 1500
              })
            }
            
        }
    });

            }else{
              Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: 'Contraseña Incorrecta',
                showConfirmButton: false,
                timer: 1500
              })
            }
            
        }
    });

    
  }



  async companySelfGetAll(companyBussinesCode){
   await this.companyService.companySelfGetOne(companyBussinesCode).then(
      (result: any) => {
        const userDB = JSON.parse(result).records[0][1];
        this.userList = userDB;
        console.log(this.userList);
        this.loading = false;
        this.emails = this.userList;
      }
    );
  }

  searchUsers(){
    this.userList = this.emails;
this.userList = this.userList.filter(x => x.userRole !== 'Admin' && x.userRole !== 'Master' && x.userRole !== 'Administrador SAC');
   this.extractEmails();
  }

  searchEmailByRole(value){
    this.userList = this.emails;
    this.userList = this.userList.filter(x => x.userRole === value);
    this.extractEmails();
  }

async extractEmails(){
  this.disabledAll = true;
  this.disabledAlert = false;
  this.usersToCopy = [];
  await this.userList.forEach(x => {
        this.usersToCopy.push(x.userEmail);
    });
    this.usersEmailsToShow = String(this.usersToCopy);
}

 async showActiveUsers(){
  this.disabledAlert = true;
    var text = document.getElementById('textCopy');
    var select = document.createRange();
    select.selectNodeContents(text);
    window.getSelection().removeAllRanges();
    window.getSelection().addRange(select);
    var res = document.execCommand('copy');
    window.getSelection().removeRange(select);
    console.log(text);
  }

async download(){
  // await this.userList.forEach(element => {
  //   this.userService.getCountProducts(element.userCognitoCode).then(
  //     (response:any) => {
  //       var cant = JSON.parse(response);
  //       this.countProductsElement.push(Object.values(cant.records[0])[0]);
      
  //     }
  //   )
  //  })
  this.userService.getUserReport().then(
     (response:any) =>{

         this.userReport = JSON.parse(response).records[0][0];
         this.productReport = JSON.parse(response).records[0][1];
         console.log(this.userReport);
         console.log(this.productReport);
         this.downloadXlsx();
     }
  )
Swal.fire({
    title: 'Descargando archivo',
    icon: 'info'
})
   setTimeout(() => {

   }, 5000)

}

  downloadXlsx(){
     const ws = {
      "!ref": "A1:AU10000",
     }

     var wscols = [
      {wch:22.30},
      {wch:30},
      {wch:32},
      {wch:30},
      {wch:30}
     ]

     ws["!merges"]=[
      {s: {r:0, c:0}, e:{r:0, c:21}},
      {s: {r:1, c:0}, e:{r:1, c:21}},
      
    ]

    ws['A' + 2] = {t: 's', v: 'Usuarios Cig Match' , s: { border: { top: { style: "medium", color: { rgb: "ffffff" } }, bottom: { style: "medium", color: { rgb: "ffffff" } }, left: { style: "medium", color: { rgb: "ffffff" } }, right: { style: "medium", color: { rgb: "ffffff" } }, }, font:{name:"cambria", sz: "14", bold: true} } };

    ws['!cols'] = wscols;
      let letter = 'A';
         ws[letter + '3'] = {
          t: 's',
          v: 'Nombre',
          s:{
            fill: { bgColor: { rgb: "000000" }, fgColor: { rgb: "000000" } },
            font: { color: { rgb: "ffffff" } },
            border: {
              top: { style: "medium", color: { auto: 1 } },
              bottom: { style: "medium", color: { auto: 1 } },
              left: { style: "medium", color: { auto: 1 } },
              right: { style: "medium", color: { auto: 1 } },
          }
         }
        };
        let secondLetter = 'B'
        ws[ secondLetter + '3'] = {
          t: 's',
          v: 'Correo',
          s:{
            fill: { bgColor: { rgb: "000000" }, fgColor: { rgb: "000000" } },
            font: { color: { rgb: "ffffff" } },
            border: {
              top: { style: "medium", color: { auto: 1 } },
              bottom: { style: "medium", color: { auto: 1 } },
              left: { style: "medium", color: { auto: 1 } },
              right: { style: "medium", color: { auto: 1 } },
          }
         }
        };
        ws[ 'C' + '3'] = {
          t: 's',
          v: 'Rol',
          s:{
            fill: { bgColor: { rgb: "000000" }, fgColor: { rgb: "000000" } },
            font: { color: { rgb: "ffffff" } },
            border: {
              top: { style: "medium", color: { auto: 1 } },
              bottom: { style: "medium", color: { auto: 1 } },
              left: { style: "medium", color: { auto: 1 } },
              right: { style: "medium", color: { auto: 1 } },
          }
         }
        };
        ws[ 'D' + '3'] = {
          t: 's',
          v: 'Empresa',
          s:{
            fill: { bgColor: { rgb: "000000" }, fgColor: { rgb: "000000" } },
            font: { color: { rgb: "ffffff" } },
            border: {
              top: { style: "medium", color: { auto: 1 } },
              bottom: { style: "medium", color: { auto: 1 } },
              left: { style: "medium", color: { auto: 1 } },
              right: { style: "medium", color: { auto: 1 } },
          }
         }
        };
        ws[ 'E' + '3'] = {
          t: 's',
          v: 'Productos activos',
          s:{
            fill: { bgColor: { rgb: "000000" }, fgColor: { rgb: "000000" } },
            font: { color: { rgb: "ffffff" } },
            border: {
              top: { style: "medium", color: { auto: 1 } },
              bottom: { style: "medium", color: { auto: 1 } },
              left: { style: "medium", color: { auto: 1 } },
              right: { style: "medium", color: { auto: 1 } },
          }
         }
        };

    var row = 4;

        // for(let i = 0; i<this.userList.length; i ++){
        //   ws['A' +  row] = {t: 's', v:this.userList[i].userFirstName +" " + this.userList[i].userLastName},
        //   ws['B' +  row] = {t: 's', v:this.userList[i].userEmail},
        //   ws['C' +  row] = {t: 's', v:this.userList[i].userRole},
        //   ws['D' + row] = {t: 's', v:this.userList[i].userCompanyName},
        //   ws['E' + row] = {t: 's', v:this.countProductsElement[i]}

        //         row ++; 
        // }

      this.userReport.forEach(element => {
        ws['A' +  row] = {t: 's', v:element.userFirstName +" " + element.userLastName},
        ws['B' +  row] = {t: 's', v:element.userEmail},
        ws['C' +  row] = {t: 's', v:element.userRole},
        ws['D' + row] = {t: 's', v:element.userCompanyName},
        ws['E' + row] = {t: 's', v:element.total_products}
        row++;
      });

        // ws['A' +  row] = {t: 's', v:element.userFirstName +" " + element.userLastName},
        // ws['B' +  row] = {t: 's', v:element.userEmail},
        // ws['C' +  row] = {t: 's', v:element.userRole},
        // ws['D' + row] = {t: 's', v:element.userCompanyName},
        // ws['E' + row] = {t: 's', v:element.countProductsElement[counter]}

        row = row + 3;   
        ws[ 'A' + row] = {
          t: 's',
          v: 'Nombre',
          s:{
            fill: { bgColor: { rgb: "000000" }, fgColor: { rgb: "000000" } },
            font: { color: { rgb: "ffffff" } },
            border: {
              top: { style: "medium", color: { auto: 1 } },
              bottom: { style: "medium", color: { auto: 1 } },
              left: { style: "medium", color: { auto: 1 } },
              right: { style: "medium", color: { auto: 1 } },
          }
         }
        };

        ws[ 'B' + row] = {
          t: 's',
          v: 'Empresa',
          s:{
            fill: { bgColor: { rgb: "000000" }, fgColor: { rgb: "000000" } },
            font: { color: { rgb: "ffffff" } },
            border: {
              top: { style: "medium", color: { auto: 1 } },
              bottom: { style: "medium", color: { auto: 1 } },
              left: { style: "medium", color: { auto: 1 } },
              right: { style: "medium", color: { auto: 1 } },
          }
         }
        };

        ws[ 'C' + row] = {
          t: 's',
          v: 'Nombre del producto',
          s:{
            fill: { bgColor: { rgb: "000000" }, fgColor: { rgb: "000000" } },
            font: { color: { rgb: "ffffff" } },
            border: {
              top: { style: "medium", color: { auto: 1 } },
              bottom: { style: "medium", color: { auto: 1 } },
              left: { style: "medium", color: { auto: 1 } },
              right: { style: "medium", color: { auto: 1 } },
          }
         }
        };

        ws[ 'D' + row] = {
          t: 's',
          v: 'Fecha de creación',
          s:{
            fill: { bgColor: { rgb: "000000" }, fgColor: { rgb: "000000" } },
            font: { color: { rgb: "ffffff" } },
            border: {
              top: { style: "medium", color: { auto: 1 } },
              bottom: { style: "medium", color: { auto: 1 } },
              left: { style: "medium", color: { auto: 1 } },
              right: { style: "medium", color: { auto: 1 } },
          }
         }
        };

        ws[ 'E' + row] = {
          t: 's',
          v: 'Enlace',
          s:{
            fill: { bgColor: { rgb: "000000" }, fgColor: { rgb: "000000" } },
            font: { color: { rgb: "ffffff" } },
            border: {
              top: { style: "medium", color: { auto: 1 } },
              bottom: { style: "medium", color: { auto: 1 } },
              left: { style: "medium", color: { auto: 1 } },
              right: { style: "medium", color: { auto: 1 } },
          }
         }
        };
row = row +1;
        this.productReport.forEach (x=>{
          ws['A' +  row] = {t: 's', v:x.userFirstName},
          ws['B' +  row] = {t: 's', v:x.userCompanyName},
          ws['C' +  row] = {t: 's', v:x.productName},
          ws['D' + row] = {t: 's', v:x.productCreateDate},
          ws['E' + row] = {t: 's', v:'https://cigmatch.com/VistaProducto/'+x.productUUID}
          row++;
        })

      
    const wb: xlsx.WorkBook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wb, ws, 'Reporte');
    xlsx.writeFile(wb, 'reporte_usuarios.xlsx');

    Swal.fire({
      title: 'Listo',
      icon:  'success'
  })
  }

}


