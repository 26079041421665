import { Injectable, NgZone } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AuthenticationDetails, CognitoUser, CognitoUserAttribute, CognitoUserPool } from 'amazon-cognito-identity-js';
import { CategoryModel } from '../models/categoryModel';
import { SubCategoryModel } from '../models/subcategoryModel';
import { MasterCatModel } from '../models/msterCatModel';
import * as S3 from 'aws-sdk/clients/s3';

const POOL_DATA = {
  UserPoolId: environment.UserPoolId,
  ClientId: environment.ClientId
};
const userPool = new CognitoUserPool(POOL_DATA);
@Injectable({
  providedIn: 'root'
})

export class CategoryService {

  constructor(
    private http: HttpClient,
  ) {}
  apiurl = environment.EnvironmentName;
  saveCatOnTable(category: CategoryModel): Promise<any> {
    let params = JSON.stringify(category);
    const headers = new HttpHeaders().set('Content-Type', 'application/json');

    return this.http.post(environment.API_URL +this.apiurl+ '-category', params, { headers}).toPromise();
  }

  catGetAll(): Promise<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');

    return this.http.get(environment.API_URL + this.apiurl+'-category', { headers}).toPromise();
  }

  categoryGetOne(masterCatUUID){
    const filter = masterCatUUID;
    return this.http.get(environment.API_URL + this.apiurl+'-category' + '/one/' + filter).toPromise();
  }

  categoryGetOneForSave(catUUID){
    const filter = catUUID;
    return this.http.get(environment.API_URL + this.apiurl+'-categoryGetOneForSave' + '/one/' + filter).toPromise();
  }

  saveOnSubCatTable(subcategories: SubCategoryModel): Promise<any> {
    let params = JSON.stringify(subcategories);
    const headers = new HttpHeaders().set('Content-Type', 'application/json');

    return this.http.post(environment.API_URL + this.apiurl+'-subcategory', params, { headers}).toPromise();
  }

  subCatGetOne(catUUID){
    const filter = catUUID;
    return this.http.get(environment.API_URL + this.apiurl+'-subcategory' + '/one/' + filter).toPromise();
  }

  subCatGetOneForSave(subCatUUID){
    const filter = subCatUUID;
    return this.http.get(environment.API_URL + this.apiurl+'-subcategoryGetOneForSave' + '/one/' + filter).toPromise();
  }

  subCatGetAll(): Promise<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');

    return this.http.get(environment.API_URL + this.apiurl+'-subcategory', { headers}).toPromise();
  }

  saveOnMasterCatTable(masterCat: MasterCatModel): Promise<any> {
    let params = JSON.stringify(masterCat);
    const headers = new HttpHeaders().set('Content-Type', 'application/json');

    return this.http.post(environment.API_URL + this.apiurl+'-masterCategory', params, { headers}).toPromise();
  }

  masterGetAll(): Promise<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');

    return this.http.get(environment.API_URL + this.apiurl+'-masterCategory', { headers}).toPromise();
  }

  mastercatGetOne(masterCatUUID){
    const filter = masterCatUUID;
    return this.http.get(environment.API_URL + this.apiurl+'-masterCatGetOne' + '/one/' + filter).toPromise();
  }

  uploadFileS3(file): Promise<any> {
    // this.companyProgress.next(0);
    const contentType = file.type;
    const bucket = new S3(
      {
        accessKeyId: environment.accessKeyId,
        secretAccessKey: environment.secretAccessKey,
        region: environment.region
      }
    );
    const params = {
      Bucket: environment.bucketFiles,
      Key: 'Iconos' + '/' + file.name,
      Body: file,
      ContentType: contentType,
      ACL: 'public-read'
    };
    return new Promise((resolve, reject) => {
      bucket.upload(params).on('httpUploadProgress', (evt) => {
        const percent = (evt.loaded / evt.total) * 100;
        // this.companyProgress.next(percent);
      }).send((err, data) => {
        if (err) {
          return reject(err);
        }
        data.Location = environment.putIcon +'/Iconos/'+ file.name;
        return resolve(data);
      });
    });
  }


}
