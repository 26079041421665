import { PublicityService } from './../../../services/publicity.service';
import { PublicityModel } from './../../../models/publicityModel';
import { Component, ElementRef, OnInit, ViewChild, Inject  } from '@angular/core';
import publicityPlace from '../../../../assets/data/publicityPlace.json';
import { v4 as uuidv4 } from 'uuid';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { UserModel } from 'src/app/models/userModel';
import { ProductsService } from 'src/app/services/products.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-new-publicity',
  templateUrl: './new-publicity.component.html',
  styleUrls: ['./new-publicity.component.scss']
})
export class NewPublicityComponent implements OnInit {
  loading = false;
  rolepublicityPlaceList = publicityPlace;

  @ViewChild('labelImportPrincipal')
  labelImportPrincipal: ElementRef;
  principalimage: File = null;
  publicityModel= {} as PublicityModel;
  localUser;


  constructor(
    private productsService: ProductsService,
    private publicityService: PublicityService,
    private dialogRef: MatDialogRef<NewPublicityComponent>,
    @Inject(MAT_DIALOG_DATA) public data: PublicityModel
  ) { }

  ngOnInit(){
    console.log(this.data);
    if(this.data !== null){
        this.publicityModel = this.data;
    }
    let user = {} as UserModel;
    if (localStorage.getItem('user')) {
      this.localUser = JSON.parse(localStorage.getItem('user'));
    }
  }

  principalImage(files: FileList) {
    this.labelImportPrincipal.nativeElement.innerText = Array.from(files)
      .map(f => f.name)
      .join(', ');
    this.principalimage = files.item(0);
    console.log(files);
  }

  updatePublicity(){
    var event = this.publicityModel;
    this.loading = true;
    var that = this;
    event.publicityDelete = 0;
    const params = event
    this.publicityService.updatePublicity(params).then(
      (result: any) => {
        Swal.fire({
          position: 'top-end',
          icon: 'success',
          title: 'Publicidad Actualizada',
          showConfirmButton: false,
          timer: 1500
        })
        setTimeout(function(){
          that.loading = false;
          window.location.reload();
        },1600);
      }
    )

  }


  uploadBanner(){
    this.loading = true;
    var bannerURL;
    var that = this;
    var publicityUUID;
    if (this.publicityModel.publicityUUID != null) {
      publicityUUID = this.publicityModel.publicityUUID;
    }else{
      publicityUUID = uuidv4();
    }
    
    if (this.principalimage != null) {
      this.productsService.uploadFileS3publicity(this.principalimage, "publicity", publicityUUID).then(
        (responsePI: any) => {
          (responsePI);
          console.log(responsePI);
          this.publicityModel.publicityBanner = responsePI.Location;
          this.publicityModel.publicityUUID = publicityUUID;
          this.publicityModel.publicityStatus = "Activo";
          this.publicityModel.publicityUserCreation = this.localUser.email;
          Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: 'Imagen subida',
            showConfirmButton: false,
            timer: 1500
          })
          setTimeout(function(){
            if (this.data != null) {
              that.updatePublicity();
            }else{
              that.savePublicity();
            }
            
          },1600);
        }
      )
    }else{
      
      if (this.data != null) {
        that.updatePublicity();
      }else{
        that.savePublicity();
      }
        
    }

    


    // this.publicityService.uploadFileS3(this.principalimage, publicityUUID).then(
    //   (responsePI: any) => {
    //     (responsePI);
    //     this.publicityModel.publicityBanner = responsePI.Location;
    //     this.publicityModel.publicityUUID = publicityUUID;
    //     this.publicityModel.publicityStatus = "Activo";
    //     this.publicityModel.publicityUserCreation = this.localUser.email;
    //     Swal.fire({
    //       position: 'top-end',
    //       icon: 'success',
    //       title: 'Imagen subida',
    //       showConfirmButton: false,
    //       timer: 1500
    //     })
    //     setTimeout(function(){
    //       that.savePublicity();
    //     },1600);
    //   }
    // )

  }

  savePublicity(){
    this.publicityService.saveOnTable(this.publicityModel).then(
      response => {
        Swal.fire({
          position: 'top-end',
          icon: 'success',
          title: 'Publicidad creada',
          showConfirmButton: false,
          timer: 1500
        })
        setTimeout(function(){
          window.location.reload();
        },1600);
      })
  }

}
