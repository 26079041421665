import { Injectable, NgZone } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AuthenticationDetails, CognitoUser, CognitoUserAttribute, CognitoUserPool } from 'amazon-cognito-identity-js';
import { RequestModel } from '../models/requestModel';
import { CategoryModel } from '../models/categoryModel';
import { SubCategoryModel } from '../models/subcategoryModel';
import { newCategoryModel } from '../models/newCatModel';
import { MasterCatModel } from '../models/msterCatModel';
import { AuthServicesService } from './auth-services.service';
import * as S3 from 'aws-sdk/clients/s3';

const POOL_DATA = {
  UserPoolId: environment.UserPoolId,
  ClientId: environment.ClientId
};
const userPool = new CognitoUserPool(POOL_DATA);
@Injectable({
  providedIn: 'root'
})

export class RequestService {
  AWS = require('aws-sdk');
  constructor(
    private http: HttpClient,
    private getAccess: AuthServicesService
  ) {}

  saveRequestOnTable(request: RequestModel): Promise<any> {
    let params = JSON.stringify(request);
    const headers = new HttpHeaders().set('Content-Type', 'application/json');

    return this.http.post(environment.API_URL + 'cigmatchbackend-request', params, { headers}).toPromise();
  }

  requestGetAll(): Promise<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');

    return this.http.get(environment.API_URL + 'cigmatchbackend-request', { headers}).toPromise();
  }

  requestGetAllPending(): Promise<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');

    return this.http.get(environment.API_URL + 'cigmatchbackend-requestPendingGetAll', { headers}).toPromise();
  }

  saveNewCategoryProduct(category: newCategoryModel ): Promise<any>{
    let params = JSON.stringify(category);
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.post(environment.API_URL + 'cigmatchbackend-insertNewCategory', params, {headers}).toPromise();
 }
 getOneProductCategory(categoryUUID){
   const filter = categoryUUID;
   return this.http.get(environment.API_URL + 'cigmatchbackend-getOneProductCategory' + '/one/' + filter).toPromise();
 }


  updateRequestState(request){
    let params = JSON.stringify(request);
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.post(environment.API_URL + 'cigmatchbackend-requestPendingUpdate', params, { headers}).toPromise();
  }

  requestGetForCat(masterCatUUID):Promise<any> {
    const filter = masterCatUUID;
    return this.http.get(environment.API_URL + 'cigmatchbackend-requestgetforcat' + '/one/' + filter).toPromise();
  }

  requestGetForCatMaster(catUUID):Promise<any> {
    const filter = catUUID;
    return this.http.get(environment.API_URL + 'cigmatchbackend-requestgetforcatmaster' + '/one/' + filter).toPromise();
  }
  requestGetForSubCat(catUUID):Promise<any> {
    const filter = catUUID;
    return this.http.get(environment.API_URL + 'cigmatchbackend-requestgetforsubcat' + '/one/' + filter).toPromise();
  }

  saveOnSubCatTable(subcategories: SubCategoryModel): Promise<any> {
    let params = JSON.stringify(subcategories);
    const headers = new HttpHeaders().set('Content-Type', 'application/json');

    return this.http.post(environment.API_URL + 'cigmatchbackend-subcategory', params, { headers}).toPromise();
  }

  subCatGetOne(catUUID){
    const filter = catUUID;
    return this.http.get(environment.API_URL + 'cigmatchbackend-subcategory' + '/one/' + filter).toPromise();
  }
 async uploadFileS3(file, companyUUID, productUUID): Promise<any> {
    try {
      const response: any = await this.getAccess.getAccess();
      const access = response.accessKeyId;
      const secret = response.secretAccessKey;
      const session = response.sessionToken;
      const contentType = file.type;
  
      this.AWS.config.update({
          region: environment.region,
          accessKeyId: access,
          secretAccessKey: secret,
          sessionToken: session,
      });
  
      const s3 = new this.AWS.S3();
        
      const params = {
          Bucket: environment.bucketFiles,
          Key: 'products' + '/' + companyUUID + '/' + productUUID + '/' + file.name,
          Body: file,
          ContentType: contentType
      };
      
      return new Promise((resolve, reject) => {
          s3.putObject(params, (err, data) =>{
              if(err){
                  console.log(err.message);
                  return reject(err);
              } else {
                  data.Location = environment.cloudFrontUrl + params.Key.replace(' ', '+');
                  return resolve(data);
              }
          });
      });
  } catch (error) {
      console.log(error);
      throw error;
  }
  }

  subCatGetOneForSave(subCatUUID){
    const filter = subCatUUID;
    return this.http.get(environment.API_URL + 'cigmatchbackend-subcategoryGetOneForSave' + '/one/' + filter).toPromise();
  }

  subCatGetAll(): Promise<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');

    return this.http.get(environment.API_URL + 'cigmatchbackend-subcategory', { headers}).toPromise();
  }

  saveOnMasterCatTable(masterCat: MasterCatModel): Promise<any> {
    let params = JSON.stringify(masterCat);
    const headers = new HttpHeaders().set('Content-Type', 'application/json');

    return this.http.post(environment.API_URL + 'cigmatchbackend-masterCategory', params, { headers}).toPromise();
  }

  masterGetAll(): Promise<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');

    return this.http.get(environment.API_URL + 'cigmatchbackend-masterCategory', { headers}).toPromise();
  }

  mastercatGetOne(masterCatUUID){
    const filter = masterCatUUID;
    return this.http.get(environment.API_URL + 'cigmatchbackend-masterCatGetOne' + '/one/' + filter).toPromise();
  }

}
