<main>
  <div class="container-fluid bg-trasparent my-4 p-3" style="position: relative;padding-left: 10%!important;padding-right: 10%!important;" >
      <div class="row row-cols-1 row-cols-xs-2 row-cols-sm-2 row-cols-lg-4 g-3">
        <div class="col-md-3" *ngFor="let products of productListAll | paginate:{itemsPerPage: pagination, currentPage: companies, status:false}" style="padding: 1.5rem!important;height: auto">
          <div class="card h-100"  style="border: none;padding: 0.5rem;cursor: pointer;box-shadow: 0 6px 20px rgba(71, 71, 71, 0.08), 0 0 6px rgba(71, 71, 71, 0.08);padding-bottom: 0%!important;"  (click)="viewProduct(products.productUUID)" >
            <div style="width: 100%;padding: 1rem;height: 10rem;">
              <img [src]='products.productMainPicture' alt="..." style="width: 100%;height: 10rem;">
            
            </div>
            <div class="col-sm-12">
              <div class="card-body" style="margin-bottom: 0%!important;margin-top: 1.5rem!important;">
                <div class="row">
                  
                  <div class="col-sm-7">
                    <span style="color: #004F9F;font-size: 0.8rem;font-weight: 500;line-height: 0!important;">{{products.productName}}</span>
                  </div>
                  <div class="col-sm-5">
                    <span class="float-end" style="font-weight: 700;" *ngIf="1 * products.productPrice > 0 ">{{products.unitPriceType}} {{products.productPrice}}</span> 
                  </div>
                  
                  
                </div>
              </div>
            </div>
              <div class="col-sm-12" style="text-align: right;color: #9c9c9c;font-size: 0.7rem;bottom: 0;margin-bottom: 1rem;padding-right: 1rem;font-family: 'Arial';padding-left: 1rem;">
                <small *ngIf="products.newCategory == null">{{products.productCategory}}</small>
                      <span class="hover top" (mouseenter)="mouseEvent(products.newCategory)" (mouseleave)="mouseLeave()" *ngIf="products.newCategory != null"><small>{{products.productCategory}}<span *ngIf="products.newCategory!= null"> +1</span></small>
                        <span class="text">{{categoryToShow}}</span>
                      </span>
              </div>
          </div>
      </div>
      </div>
      <!-- <div class="row">
        <div class="col-sm-12" style="margin-top: 1rem;">
          <div class="row">
              <div class="col-sm-3">
                <span style="color: #636363;font-family: 'Lato';font-size: 1.1rem;">{{this.listText}}</span>
                
              </div>
              <div class="col-sm-6" >
                   <div class="row" *ngIf="productListDiv.length > 0 " style="justify-content: center!important;">
                    <div class="col-sm-2" style="text-align: center;border: none;margin: 0.5rem;padding: 0.6rem;cursor: pointer;color: #0078D7;font-family: 'Lato';font-size: 0.9rem;font-weight: 700;" (click)="prev()" *ngIf="this.actual.numero > 1">
                      <span>..Anterior</span>
                     </div> 
                       <div class="col-sm-2 card {{listado.color}}" *ngFor="let listado of productListDiv" style="text-align: center;border: none;margin: 0.5rem;width: 2.5rem;height: 2.5rem;padding: 0.6rem;cursor: pointer;box-shadow: 0 6px 20px rgba(71, 71, 71, 0.08), 0 0 6px rgba(71, 71, 71, 0.08);" (click)="changeListProduct(listado)">
                             <span>{{listado.numero}}</span>
                        </div>
                        <div class="col-sm-2" style="text-align: center;border: none;margin: 0.5rem;padding: 0.6rem;cursor: pointer;color: #0078D7;font-family: 'Lato';font-size: 0.9rem;font-weight: 700;" (click)="next()" *ngIf="nextavailable">
                          <span>Siguiente..</span>
                     </div>
                   </div>
              </div>
              <div class="col-sm-3" style="text-align: right;">
                 <span style="color: #636363;font-family: 'Lato';font-size: 1.1rem;">Mostrar<span style="cursor: pointer;" (click)="listProductSize(this.contador20)"> {{this.contador20}} </span>|<span style="cursor: pointer;" (click)="listProductSize(this.contador40)"> {{this.contador40}} </span>|<span style="cursor: pointer;" (click)="listProductSize(this.contador60)"> {{this.contador60}} </span>&nbsp;&nbsp;</span>
              </div>
          </div>
      </div>
      </div> -->
  </div>
  <div class="col-sm-12" style="margin-bottom: 20px;">
  <div class="container">
    <div class="row">
      <div class="col-lg-12" style="display: flex;">
        <div class="pagination--container" style="margin: 0 auto; font-weight: lighter;">
          <pagination-controls class="pagination"(pageChange)="companies = $event" previousLabel="Anterior" nextLabel="Siguiente" style="margin: 0 auto;">  </pagination-controls>
        </div>
      </div>
      <div class="col-lg-12" style="font-weight: lighter;">
        <div class="container">
          <div class="col-sm-12">
            <div class="row">
              <div class="pagination-number--container" style="text-align:start; width: 50%;">
                {{pagination}} de {{totalProducts}}
              </div>

              
        <div class="change-pagination--container" style="text-align: end; width: 50%;">
          <span>Mostrar </span>
          <span  (click)="changePagination(8)" style="cursor: pointer;"><span class="first-number"> 8 </span></span>
          <span  (click)="changePagination(12)" style="cursor: pointer;">|<span class="first-number"> 12 </span></span>
          <span  (click)="changePagination(24)" style="cursor: pointer;">|<span class="second-number"> 24 </span></span>
          <span  (click)="changePagination(36)" style="cursor: pointer;">|<span class="third-number"> 36 </span></span>
          </div>
            </div>
          </div>
        </div>
        
        
      
      </div>
    </div>
  </div>
</div>
</main>
