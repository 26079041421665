import { Component, OnInit } from '@angular/core';
import { CategoryModel } from 'src/app/models/categoryModel';
import { MasterCatModel } from 'src/app/models/msterCatModel';
import { SubCategoryModel } from 'src/app/models/subcategoryModel';
import { UserModel } from 'src/app/models/userModel';
import { AuthService } from 'src/app/services/auth.service';
import { CategoryService } from 'src/app/services/category.service';
import { ProductsModel } from '../../../models/productsModel';
import { ProductsService } from '../../../services/products.service';
import { Router } from '@angular/router';
import { element } from 'protractor';

@Component({
  selector: 'app-products-cards',
  templateUrl: './products-cards.component.html',
  styleUrls: ['./products-cards.component.scss']
})
export class ProductsCardsComponent implements OnInit {

  userRole: string;
  subcategories = {} as SubCategoryModel;
  masterCategory = {} as MasterCatModel;
  categories = {} as CategoryModel;
  categoriesList: [];
  masterCatList;
  subcategoryList;
  contador20 = 8;
  contador40= 20;
  contador60 = 40;
  show = 1;
  actual;
  listProducts;
  productsList;
  productListAll;
  listText="";
  productListDiv=[];
  loading = false;
  nextavailable= false;
  prevavailable= false;
  companies: number = 1;
  pagination: number = 8;
  public totalProducts: number = 0;
  public categoryToShow: String = "...";

  constructor(
    private productService: ProductsService,
    private categoryService: CategoryService,
    private router: Router,
    private authService: AuthService
  ) { }

  ngOnInit(){
    let user: UserModel;
    this.authService.userRole.subscribe(userRoleApp => {
      this.userRole = userRoleApp;
    })
    if (localStorage.getItem('user')) {
      user = JSON.parse(localStorage.getItem('user'));
      this.userRole = user.userRole;
    }
    this.getAllProducts();
    this.listMasterCategories();
  }

  getAllProducts(){
    this.loading = true;
    this.productService.productGetAll().then(
     response=> {
       const userDB = response.records[0][0];
       //this.productsList = userDB;
       this.productListAll = userDB;
       this.totalProducts = this.productListAll.length;
      //  var productListTemp = [];
       //this.productsList.sort(function() { return Math.random() - 0.5 });
      //  var contador= 0;
      //  userDB.forEach(element => {
      //    if (contador < this.contador20) {
      //         productListTemp.push(element);
           
      //    }
      //    contador++;
      //  });
      //  this.productsList = productListTemp;
      //  this.listModules(this.contador20);
      //  var ini = 1
      //  this.listText = ini + " al "+this.contador20+" de "+this.productListAll.length;
       //Mostrar 20 productos al iniciar
      //  this.loading = false;
     }
   )

   }
   listModules(size){
    this.productListDiv = [];
     if (size >= this.productListAll.length) {
      this.listText = 1 + " al "+this.productListAll.length+" de "+this.productListAll.length
     }else{
      var conteo = 0;
      var noModulos=this.productListAll.length/size;
      var noModulosint = noModulos.toFixed()+"";
      conteo= parseInt(noModulosint);
      var multiplication = parseInt(noModulosint)*size;
      if (multiplication < this.productListAll.length) {
       conteo = parseInt(noModulosint)+1;
 
      }
      var contadorTemp = 1;
      while (contadorTemp <= conteo) {
        if (contadorTemp == 1) {
          this.productListDiv.push({numero:contadorTemp,nodes:conteo,products:this.productListAll.length,order:size,color:'colorBottomGray'})
       
        }else{
          this.productListDiv.push({numero:contadorTemp,nodes:conteo,products:this.productListAll.length,order:size,color:'colorBottomWhite'})
       
        }
        contadorTemp++;
      }
      this.actual = {numero:1,nodes:conteo,products:this.productListAll.length,order:size,color:'colorBottomWhite'};
      this.listText = 1 + " al "+size+" de "+this.productListAll.length;
      var event1 = this.productListDiv.find(x => x.numero == (this.actual.numero+1));
     if (event1!=undefined) {
      this.nextavailable= true;
     }else{
      this.nextavailable= false;
     }
      
     }
    
   }
   changecolor(numero){
    this.productListDiv.forEach(element=>{
     
        element.color = 'colorBottomWhite';
      
    });

     this.productListDiv.forEach(element=>{
       if (element.numero == numero) {
         element.color = 'colorBottomGray';
       }
     });
   }
   listProductSize(size){
    var productListTemp = [];
      var contador= 0;
      this.productListAll.forEach(element => {
        if (contador < size) {
             productListTemp.push(element);
          
        }
        contador++;
      });
      this.productsList = productListTemp;
      this.listModules(size);
   }
   changeListProduct(event){
     var fin = event.numero * event.order;
     var start = fin - event.order;

     var productListTemp = [];
      var contador= 0;
      this.productListAll.forEach(element => {
        if (contador >= start && contador < fin) {
             productListTemp.push(element);
          
        }
        contador++;
      });
      this.productsList = productListTemp;
      var ini = start+1;
      if (fin > this.productListAll.length) {
        fin = this.productListAll.length;
      }
      this.listText = ini + " al "+fin+" de "+this.productListAll.length
      this.actual = event;
      this.changecolor(event.numero)

      var event1 = this.productListDiv.find(x => x.numero == (this.actual.numero+1));
     if (event1!=undefined) {
      this.nextavailable= true;
     }else{
      this.nextavailable= false;
     }
     document.location.href = "#productos";
   }
   next(){
     var event = this.productListDiv.find(x => x.numero == (this.actual.numero+1));
     console.log(event);
     this.changeListProduct(event);
     
     var event1 = this.productListDiv.find(x => x.numero == (this.actual.numero+1));
     if (event1!=undefined) {
      this.nextavailable= true;
     }else{
      this.nextavailable= false;
     }
   }

   changePagination(value){
    this.pagination = value;
 }


   prev(){
    var event = this.productListDiv.find(x => x.numero == (this.actual.numero-1));
    console.log(event);
    this.changeListProduct(event);
  }
   viewProduct(productUUID){
    this.loading = true;
    // this.router.navigate(['/VistaProducto/' + productUUID]);
    // window.location.reload();
    this.router.navigate(['/VistaProducto/' + productUUID])
  .then(() => {
    window.location.reload();
  });
  }

  listMasterCategories(){
    this.categoryService.masterGetAll().then(
      response=> {
        const userDB: MasterCatModel = response.records[0][0];
        this.masterCatList = userDB;
      }
    )
  }

  mouseEvent(event){
    this.productService.getOneProductCategory(event).then(
      (response: any) => {
        this.categoryToShow = JSON.parse(response).records[0].productCategory;
      }
    )
  }

  mouseLeave(){
    this.categoryToShow = "...";
  }
}
