<app-loading-wrapper-overlay *ngIf="loading"></app-loading-wrapper-overlay>
<div class="col-sm-12 col-md-12 col-lg-12" (click)="subMenu=true" id="row-container">
 

<div class="col-sm-12 col-md-12 col-lg-12" style="padding-top: 3rem !important;padding-left: 1rem !important;">
  <div class="row">
    <h1 style="font-weight: 900; color: #004F9F ; font-size: 1.8rem;position: relative;left: 2rem; border-bottom: 2px solid #0078D7; width: 90%;" id="title">SOLICITUD</h1>
    <br><br>
    <div class="col-sm-12 col-md-12 col-lg-12" style="padding: 2rem!important;">
      <div class="row">
      <div class="col-sm-9">
        <div class="container shadow p-3 bg-white rounded boxi" id="container-main--card" style="height: 30px auto!important;width: 97%!important;">
        <div class="row" style="height: 100% !important;" *ngIf="this.request != undefined">
          <div class="col-sm-12 col-md-12 col-lg-12" style="display: flex; padding: 1rem !important;">
            <div class="col-sm-12 col-md-12 col-lg-12">
              <div class="col-sm-12 col-md-12 col-lg-12" style="margin: 5px 0 5px 0 !important;">
                <div class="container">
                  <div class="row">
                    <div class="col-sm-6">
                      <h2 style="color: #0078d7;font-weight: 900; font-size: 1.7rem;">{{this.request.requestTitle}}</h2>
                    </div>
                    <div class="col-sm-6" style="text-align: right;">
                      <section style="width: 95%;" (click)="deleteRequestFunction()" *ngIf="deleteRequest || userRole === 'Master' || userRole === 'Admin'">
                        <i class="far fa-trash-alt" style="width: 25px; height:25px; margin-right: 50px; cursor: pointer;"></i>
                      </section>               
                    </div>
                  </div>
                  </div>
                </div>
               
              
              <div class="col-sm-12 col-md-12 col-lg-12" style="margin: 5px 0 45px 0 !important;">
                <span>Publicado el <span> {{this.request.requestDate}} </span></span>
              </div>

              <div class="row" style="font-size: 1rem;">
                <div class = "col-sm-8">
                  <div class="image-details--container" [style.display]="imageState">
                    <div class="image-details">
                    </div>
                  </div>
                  <div class = "row">
                    <div class="col-sm-12 col-md-12 col-lg-12" style="margin: 5px 0 5px 0 !important;">
                      <span style="color: #004F9F; font-weight: bold;">Presupuesto: </span><span>{{this.request.currency}}.{{this.request.requestBudget}}</span>
                    </div>
                    <div class="col-sm-12 col-md-12 col-lg-12" style="margin: 5px 0 5px 0 !important;">
                      <span style="color: #004F9F; font-weight: bold;">Cantidad Requerida: </span><span>{{this.request.requestQuantityNeeded}}</span>
                    </div>
                    <div class="col-sm-12 col-md-12 col-lg-12" style="margin: 5px 0 5px 0 !important;">
                      <span style="color:  #004F9F; font-weight: bold;">Unidad de medida: </span><span>{{this.request.requestUnit}}</span>
                    </div>
                    <div class="col-sm-12 col-md-12 col-lg-12" style="margin: 5px 0 5px 0 !important;">
                      <span style="color:  #004F9F; font-weight: bold;">Plazo de entrega en días hábiles: </span><span>{{this.request.requestDeliveryTerm}} días</span>
                    </div>
                    <div class="col-sm-12 col-md-12 col-lg-12" style="margin: 40px 0 5px 0 !important; height: 25px;" *ngIf = "this.request.requestOffers == 'NO'">
                      <span style="color: #F58626; font-weight: 600;" >*Este usuario no acepta ofertas</span>
                    </div>
                    <div class="col-sm-12 col-md-12 col-lg-12" style="margin: 40px 0 5px 0 !important; height: 25px;" *ngIf = "this.request.requestOffers == 'YES'">
                      <span style="color: #F58626; font-weight: 600;" >*Este usuario acepta ofertas</span>
                    </div>
                    
                    <div class="col-sm-12 col-md-12 col-lg-12" style="margin: 5px 0 5px 0 !important;">
                      <span style="color:  #004F9F; font-weight: bold;">Descripción: </span>
                    </div>
                    
                        <div class="col-sm-12 col-md-12 col-lg-12" style="margin: 5px 0 5px 0 !important;color: #898989;text-align: left;">
                          <div class="row" style="font-size: smaller!important;">
                            <div class="col-sm-2" style="width: auto;">
                              <button mat-raised-button style="color: #FFFFFF;font-size: smaller!important;background-color: #004F9F;margin-right: 1rem!important; margin-top: 1rem !important;border-radius: 0.7rem;width: auto;"><span style="margin: 0.8rem!important;width: 80%;max-width: 5rem!important;">{{this.request.categoryNameDes}}</span> </button>
                             </div>
                             <div class="col-sm-2" style="width: auto;">
                              <button mat-raised-button style="color: #FFFFFF;font-size: smaller!important;background-color: #0078D7;margin-right: 1rem!important;margin-top: 1rem !important; border-radius: 0.7rem;width: auto;"><span style="margin: 0.8rem!important;width: 80%;max-width: 80%!important;">{{this.request.subCategoryNameDes}}</span> </button>
                             
                            </div>
                            <div class="col-sm-2"style="width: auto;">
                              <button mat-raised-button style="color: #FFFFFF;font-size: smaller!important;background-color: #F58626;margin-top: 1rem!important;border-radius: 0.7rem;width: auto;height: auto;"><span style="margin: 0.8rem!important;max-width: 80%!important;">{{this.request.masterCategoryNameDes}}</span> </button>
                             
                            </div>
                        </div>
                        </div>
                  </div>
                </div>
                
                <div class="col-sm-4" style="margin-top: -30px !important;">
                        <div class="row">
                           <div class="col-sm-3">
                             <div class="row">
                              <div class="col-sm-12" style="width: 4rem; cursor: pointer;" *ngIf ="changeRequestImage" (click)="changeImage(this.request.requestIMG1)">
                                  <img [src]="this.request.requestIMG1" style="width: 100%;">
                                </div>
                                <div class="col-sm-12" style="width: 4rem; cursor: pointer;" *ngIf ="this.request.requestIMG2 != null" (click)="changeImage(this.request.requestIMG2)" >
                                  <img [src]="this.request.requestIMG2" style="width: 100%;" >
                              
                                </div>
                                <div class="col-sm-12" style="width: 4rem; margin-top: 1rem !important; cursor: pointer;" *ngIf="this.request.requestIMG3 != null" (click)="changeImage(this.request.requestIMG3)">
                                  <img [src]="this.request.requestIMG3" style="width: 100%;">
                                  
                                </div>
                                <div class="col-sm-12" style="width: 4rem;  margin-top: 1rem !important; cursor: pointer;" *ngIf = "this.request.requestIMG4 != null" (click)="changeImage(this.request.requestIMG4)">
                                  <img [src]="this.request.requestIMG4" style="width: 100%;">
                                 
                                </div>
                             </div>
                           </div>
                           <div class="col-sm-9" style="width: 13rem;" *ngIf ="this.request.requestIMG1 != null && changeRequestImage !== true"  class="id-container">
                                 <img [src]= "this.request.requestIMG1" style="width: 100%;" [attr.data-zoom]="this.request.requestIMG1" class="request-image" (mouseenter)="showAument('img.request-image')" (mouseleave)="shadowImage()">       
                           </div>
                           <div class="col-sm-9" style="width: 13rem;" *ngIf="changeRequestImage" (mouseenter)="showAument('img.request-image2')" (mouseleave)="shadowImage()"class="id-container">
                            <img [src]= "image1" style="width: 100%;" [attr.data-zoom]="image1" class="request-image2">  
                          </div>
                        </div>
                   
                </div>
        </div>


              <div class="col-sm-12 col-md-12 col-lg-12" style="margin: 30px 0 5px 0 !important;">
                <span>{{this.request.requestDetail}}</span>
              </div>
            </div>
          </div>
        </div>
  <!--BANNER PUBLICIDAD HORIZONTAL-->
  <div class="col-sm-12" style="display: flex !important;">
    <section style="margin: 0 auto !important;">
      <div class="carousel" id="carousel-h"  >
        <ng-container *ngFor="let image of publicityListProduct1; index as i">
          <div class="carousel-item" id="carousel-item--h" [ngClass]="{ 'active': i === activeIndexh }">
            <img [src]="image.publicityBanner" (click)="openPublicity(image.publicityLink)">
          </div>
        </ng-container>
      </div>
    </section>
   
  </div>
    <div class="col-sm-12 col-md-12 col-lg-12" style="text-align: center; display: flex; margin-top: 2rem !important; margin-bottom: 90px!important;">
      <!-- <img width="790" src="../../../../assets/images/core/promo.jpg"> -->
      <!-- <div class="carousel 1carousel-fade img-details" data-bs-ride="carousel" data-bs-interval="4000" style="height:auto; display: flex; text-align: center;">
            <div class="carousel-inner" id="carousel-h">
                <div class="carousel-item {{publicity1.active}}" style="text-align: center;" *ngFor="let publicity1 of publicityListProduct1"><img [src]="publicity1.publicityBanner" class="imgPublicity-h" (click)="openPublicity(publicity1.publicityLink)"> </div>
            </div>
        </div>  -->
       
    </div>
  <!--BANNER PUBLICIDAD HORIZONTAL-->
        <h3 id="request" style="font-weight: 900;color: #0078d7;font-size: 1.8rem;position: relative; left: 1rem; margin-bottom: 1.5rem!important;">Solicitudes Similares</h3>
        <div class="container shadow p-3 bg-white rounded boxi" id="request-container" *ngFor="let request of requestList" style="cursor: pointer; margin-bottom: 1.9rem!important;width: 95%!important; margin-left: 20px !important ;" (click)="navigateRequest(request.requestCode)">
          <div class="row" style="height: 100% !important;">
            <div class="col-sm-12 col-md-12 col-lg-12" style="display: flex; padding: 1rem !important;">
              <div class="col-sm-8 col-md-8 col-lg-8">
                <div class="col-sm-12 col-md-12 col-lg-12" style="margin: 5px 0 5px 0 !important;">
                  <h1 style="color: #004F9F;">{{request.requestTitle}}</h1>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-12" style="margin: 5px 0 5px 0 !important;">
                  <span style="color: #0078D7;text-decoration: underline;font-size: large;">{{request.requestContactName}} <img src="../../../../assets/images/core/Insignia.png" style="width: 1.3rem;height: 1.3rem;margin-left: 0.8rem;"></span>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-12" style="margin: 5px 0 5px 0 !important;">
                  <span style="color: #898989;">Presupuesto <span>{{request.currency}}. <span>{{request.requestBudget}}</span></span></span>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-12" style="margin: 5px 0 5px 0 !important;">
                  <span style="color: #898989;">{{request.requestDetail}}</span>
                </div>
              </div>
        <div class="col-sm-4 col-md-4 col-lg-4">
           <div class="col-sm-12 col-md-12 col-lg-12" style="margin: 5px 0 5px 0 !important;color: #898989;text-align: right;">
                <span> {{request.requestDate}} </span>
            </div>
           <div class="col-sm-12 col-md-12 col-lg-12" style="text-align: right !important; margin-top: 20px !important;">
                <button mat-raised-button style="color: #FFFFFF;font-size: smaller!important;background-color: #004F9F;margin-bottom: 1rem!important;border-radius: 0.7rem;width:auto;"><span style="margin: 0.8rem!important;width: 80%;max-width: 5rem!important;">{{request.categoryNameDes}}</span> </button>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-12" style="text-align: right !important;">
                <button mat-raised-button style="color: #FFFFFF;font-size: smaller!important;background-color: #0078D7;margin-bottom: 1rem!important;border-radius: 0.7rem;width:auto;"><span style="margin: 0.8rem!important;width: 80%;max-width: 80%!important;">{{request.subCategoryNameDes}}</span> </button>
            </div>
              <div class="col-sm-12 col-md-12 col-lg-12" style="text-align: right !important;">
                <button mat-raised-button style="color: #FFFFFF;font-size: smaller!important;background-color: #F58626;margin-bottom: 1rem!important;border-radius: 0.7rem;width:auto;height: auto; "><span style="margin: 0.8rem!important;max-width: 80%!important;">{{request.masterCategoryNameDes}}</span> </button>
            </div>
        </div>
     </div>
   </div>
</div>
       <div class="col-sm-12 col-md-12 col-lg-12" style="height: 50px;">
       </div>




      
  </div>
   <br>
 
   <!-- <div class="container shadow p-3 bg-white rounded boxi" *ngFor="let request of requestList" style="height: auto!important;width: 97%!important; margin-top: 1rem!important;cursor: pointer;" (click)="navigateRequest(request.requestCode)">
  <div class="row" style="height: 100% !important;">
    <div class="col-sm-12 col-md-12 col-lg-12" style="display: flex; padding: 1rem !important;">
      <div class="col-sm-9 col-md-9 col-lg-9">
        <div class="col-sm-12 col-md-12 col-lg-12" style="margin: 5px 0 5px 0 !important;">
          <h1 style="color: #055285;">{{request.requestTitle}}</h1>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-12" style="margin: 5px 0 5px 0 !important;">
          <span>{{request.requestDetail}}</span>
        </div>
      </div>
      <div class="col-sm-3 col-md-3 col-lg-3">
        <div class="col-sm-12 col-md-12 col-lg-12" style="margin: 5px 0 5px 0 !important;">
          <span>Presupuesto <span style="color: #055285; font-weight: bold;">Q. <span>{{request.requestBudget}}</span></span></span>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-12" style="margin: 5px 0 5px 0 !important;">
          <span>Cantidad solicitada: <span> {{request.requestQuantityNeeded}}</span></span>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-12" style="margin: 5px 0 5px 0 !important;">
          <span>Fecha de publicación: <span> {{request.requestDate}} </span></span>
        </div>
      </div>
    </div>

    

  </div>
  </div> -->
 </div>
  <div class="col-sm-3"> 
     
  <!-- <div class="col-sm-3 col-md-3 col-lg-3" style="margin-left: 2.5rem!important;margin-top: -1rem!important;"> -->
    <div class="container shadow p-3 bg-white rounded boxi" style="height: auto!important; width: 89% !important; margin-left: 15px!important; border-radius: 1.8% !important;" id="petitioner-container">
      <div class="row" style="height: 100% !important;" *ngIf="this.request != undefined">
        <div class="col-sm-12 col-md-12 col-lg-12" style="display: flex; padding: 2rem !important;">
          <div class="col-sm-12 col-md-12 col-lg-12">
            <div class="col-sm-12 col-md-12 col-lg-12" style="margin: 5px 0 5px 0 !important;">
              <h4 style="color:  rgb(110 122 136);text-align: left; font-weight: 600; font-size: 1.3rem;">Datos de solicitante</h4>
            </div>
  
            <div class="col-sm-12 col-md-12 col-lg-12" style="margin: 5px 0 5px 0 !important;">
              <span> </span>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-12" style="margin: 5px 0 5px 0 !important;">
              <span style="color: #055285; font-weight: bold;">{{this.request.requestContactName}} 
                <!-- <img src="../../../../assets/images/core/Insignia.png" style="width: 2rem;height: 2rem;"> -->
              </span>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-12" style="margin: 5px 0 5px 0 !important;">
              <span>Guatemala, Guatemala</span>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-12" style="margin: 25px  0 0 0px !important;">
              <span style="font-weight: bold !important; font-size: 0.7rem !important; color:  #0078D7 !important;"><u>{{this.request.masterCatName}} </u>
                <span class="hover top" (mouseenter)="mouseEvent(this.request.newCategory)" (mouseleave)="mouseLeave()"><span *ngIf="this.request.newCategory !== null" style="color: #0078d7; font-weight: bolder; font-size: 0.9rem;"> +1</span><span class="text">{{categoryToShow}}</span></span></span>

            </div>
            <div class="col-sm-12 col-md-12 col-lg-12" style="margin: 5px 0 5px 0 !important;">
              <button mat-button style="background-color: #055285; color: white; width:80%;  margin-top: 30px!important; padding-bottom: 3px !important; height: 35px; border-radius: 12px;" (click)="startChat()">Enviar propuesta</button>
            </div>
          </div>
        </div>
  
        
  
      </div>
    </div>    
    <!-- <div class="col-sm-3 col-md-3 col-lg-3" style="margin-left: 2.5rem!important;margin-top: -1rem!important;"> -->
      
      <!--BANNER PUBLICIDAD VERTICAL-->
      <div class="container" style="height: auto!important;margin-top: 1rem!important;">
        <div class="row" style="height: 100% !important;" *ngIf="this.request != undefined">
          <div class="col-sm-12 col-md-12 col-lg-12" style="display: flex; padding: 1rem !important;">
            <div class="col-sm-12 col-md-12 col-lg-12" style="border-radius: 1.6% !important;">
               <div class="carousel 1carousel-fade img-details" data-bs-ride="carousel" data-bs-interval="4000" style="height:auto">
              <!-- <img src="../../../../assets/images/core/imgRequestOne.PNG" style="width: 100%; height: 800px; border-radius: 1.6% !important;"> -->
                  <div class="carousel-inner">
                      <div class="carousel-item {{publicity2.active}}" style="text-align: center;" *ngFor="let publicity2 of publicityListProduct2"><img [src]="publicity2.publicityBanner" style="width: 100%; height: 800px; border-radius: 1.6% !important;" (click)="openPublicity(publicity2.publicityLink)"> </div>
                  </div>
              </div>  
            </div>
          </div>
        </div>
          <!--BANNER PUBLICIDAD VERTICAL-->
    </div>
 </div>



</div>

</div>
