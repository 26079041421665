import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-show-video-tutorials',
  templateUrl: './show-video-tutorials.component.html',
  styleUrls: ['./show-video-tutorials.component.scss']
})
export class ShowVideoTutorialsComponent implements OnInit {
public titleVideo = "" ;
public videoUrl = "";
public videoDescription = "";
  constructor( private route: ActivatedRoute) {

   }

  ngOnInit(): void {
    this.route.paramMap.subscribe(async (params: any) => {
      const videoToShow = params.params.videoToShow;
      if (videoToShow !== undefined && videoToShow == 'Directorio') {
        this.videoDescription = "Conoce el directorio empresarial de CIGMatch y como sacar el mayor provecho para tu industria";  
        this.titleVideo = "Directorio Empresarial";
           this.videoUrl = "Directorio";
      }else if( videoToShow !== undefined && videoToShow == 'categorias'){
        this.videoDescription = "Conoce las categorías que conforman CIGMatch en las cuales podrás encontrar los distintos productos/servicios disponibles para su industria";
        this.titleVideo = "Categorias"
        this.videoUrl = "categorias"
      }else if( videoToShow !== undefined && videoToShow == 'portal'){
        this.videoDescription = "Conoce el Portal de solicitudes en donde podrás postear tus requerimientos y solventar los de otros";
        this.titleVideo = "Portal de Solicitudes"
        this.videoUrl = "portal"
      }else if( videoToShow !== undefined && videoToShow == 'publicar'){
        this.videoDescription = "Publicar tus productos/servicios nunca fue tan sencillo como en CIGMatch, atrévete a incursionar";
        this.titleVideo = "Publicar un producto o servicio"
        this.videoUrl = "publicar"
      }else if( videoToShow !== undefined && videoToShow == 'mensajeria'){
        this.videoDescription = "Conectividad con compradores y nuevos distribuidores de manera inmediata con CIGMatch";
        this.titleVideo = "Centro de mensajería"
        this.videoUrl = "mensajeria"
      } 
    });
  }

}
