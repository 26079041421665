import { AplicationModel } from './../../../models/aplicationModel';
import { ViewChild, ElementRef } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { FormControl, Validators,FormGroup } from '@angular/forms';
import { CategoryModel } from 'src/app/models/categoryModel';
import { MasterCatModel } from 'src/app/models/msterCatModel';
import { SubCategoryModel } from 'src/app/models/subcategoryModel';
import { UserModel } from 'src/app/models/userModel';
import { CategoryService } from 'src/app/services/category.service';
import { RequestModel} from 'src/app/models/requestModel';
import { UserService } from 'src/app/services/user.service';
import { RequestService } from 'src/app/services/request.service';
import { newCategoryModel } from 'src/app/models/newCatModel';
import  Unit from '../../../../assets/data/unit.json';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import priceType from '../../../../assets/data/priceType.json';
import { CompanyService } from 'src/app/services/company.services';
import { MatDialog } from '@angular/material/dialog';
import { v4 as uuidv4 } from 'uuid';
import { CompanyModel } from 'src/app/models/companyModel';
import { isElementAccessExpression } from 'typescript';
import { SendEmailsDialogComponent } from './send-emails-dialog/send-emails-dialog.component';
@Component({
  selector: 'app-create-application',
  templateUrl: './create-application.component.html',
  styleUrls: ['./create-application.component.scss']
})
export class CreateApplicationComponent implements OnInit {
  fontStyleControl = new FormControl();
  fontStyle?: string;
  products = {} as AplicationModel;
  subcategories = {} as SubCategoryModel;
  masterCategory = {} as MasterCatModel;
  categories = {} as CategoryModel;
  request = {} as RequestModel;
  categoriesList: [];
  masterCatList;
  subcategoryList;
  masterSelected;
  subCatStatus = true;
  familyCatStatus = true;
  masterCatForSave;
  subCatForSave;
  companyData = {} as CompanyModel;
  familyCatForSave;
  userDataToShow = {} as UserModel;
  user;
  loading = false;
  priceList = priceType;
  priceCurrency: String = 'Q';
  addImage: boolean = false;
  public categoryCant: boolean = false;
  public newCategory = {} as newCategoryModel;
  public subCatStatusTwo:Boolean = true;
  public categoriesListTwo: []
  public familyCatStatusTwo:Boolean = true;
  public subcategoryListTwo: [];
  public familyCatForSaveTwo;
  aceptOffers: boolean;
  unitaryList = Unit;
  lastInsertID
  userEmails;
  private subCatSelected = "";
  @ViewChild('labelImportPrincipal')
  labelImportPrincipal: ElementRef;

  @ViewChild('labelImportP1')
  labelImportP1: ElementRef;

  @ViewChild('labelImportP2')
  labelImportP2: ElementRef;

  @ViewChild('labelImportP3')
  labelImportP3: ElementRef;

  formImport: FormGroup;
  principalimage: File = null;
  p1: File = null;
  p2: File = null;
  p3: File = null;

  
  categoria = new FormControl('', Validators.required);
  subcategoria = new FormControl('', Validators.required);
  items = new FormControl('', Validators.required);

  
  categoriaTwo = new FormControl('', Validators.required);
  subcategoriaTwo = new FormControl('', Validators.required);
  itemsTwo = new FormControl('', Validators.required);


  constructor(
    private categoryService: CategoryService,
    private userservice: UserService,
    private requestService: RequestService,
    private companyService: CompanyService,
    private dialog : MatDialog
  ) { }

  async ngOnInit(){
    document.location.href = "NuevaSolicitud#request"
    this.loading = true;
    const userLogged = JSON.parse(localStorage.getItem('user'));
    this.user = userLogged;
    await this.userservice.oneUser(userLogged.userId).then(
      (response: any) => {
        const userDB = JSON.parse(response).records[0];
        this.userDataToShow = userDB;
     
        this.loading = false;
      }
    );
    this.listMasterCategories();
  }

  checked = false;

  toggle(checked: boolean) {
    this.checked = checked;
  }

  listMasterCategories(){
    
    this.categoryService.masterGetAll().then(
      response=> {
        const userDB: MasterCatModel = response.records[0][0];
        this.masterCatList = userDB;
      }
    )
  }

  masterForSave(masterCatUUID){
    this.categoryService.mastercatGetOne(masterCatUUID).then(
      (response: any) => {
        const userDB = JSON.parse(response).records[0];
        this.masterCatForSave = userDB.masterCatName;
      }
    )
  }

  listCategories(masterCatUUID){
    this.masterForSave(masterCatUUID.value);
    this.categoryService.categoryGetOne(masterCatUUID.value).then(
      (response: any) => {
        const userDB = JSON.parse(response).records;
        this.categoriesList = userDB;
        this.subCatStatus = false;
      }
    )
  }

  subcatforsave(subCatUUID){
    this.categoryService.categoryGetOneForSave(subCatUUID).then(
      (response: any) => {
        const userDB = JSON.parse(response).records[0];
        this.subCatForSave = userDB.catName;
      }
    )
  }

  listSubCat(subcatUUID){
    this.subcatforsave(subcatUUID.value);
    this.categoryService.subCatGetOne(subcatUUID.value).then(
      (response: any) => {
        const userDB = JSON.parse(response).records;
        this.subcategoryList = userDB;
        this.familyCatStatus = false;
      }
    )
  }

  itemFamily(familyUUID){
    this.categoryService.subCatGetOneForSave(familyUUID.value).then(
      (response: any) => {
        const userDB = JSON.parse(response).records[0];
        this.familyCatForSave = userDB.subCatName;
      }
    )

  }
 async saveRequest(){
    if(this.aceptOffers){
      this.request.requestOffers = "YES"
    }else{
       this.request.requestOffers = "NO"
    }


    const userLogged = JSON.parse(localStorage.getItem('user'));
    this.user = userLogged;
    this.request.requestUserCreate = this.user.userId;
    await this.userservice.oneUser(userLogged.userId).then(
      (response: any) => {
        const userDB = JSON.parse(response).records[0];
        
      this.companyService.companyGetOne(userDB.userCompanyUUID).then(
          (response1: any)=> {
            const companyy = JSON.parse(response1).records[0];
            

            if (userDB.userCompanyName === null || userDB.userCompanyName == "") {
              this.request.requestContactName = userDB.userFirstName + " " + userDB.userLastName;
              this.request.requestContactPhone = null;
            }else{
              this.request.requestContactName = userDB.userCompanyName;
              this.request.requestContactPhone = companyy.companyPhone1;
            }
            this.request.requestContactEmail=userDB.userEmail;
            
            this.sendRequestToDb();
          },
         
        )
      }
    );
            
  }

  familyRegex(value){
  this.subCatSelected = value;

  }

 async sendRequestToDb(){
    console.log(this.request);
    if(this.categoryCant && this.newCategory.itemFamilyUUID!== undefined && this.newCategory.productCategoryUUID !== undefined && this.newCategory.productSubCatUUID !== undefined){
      this.request.newCategory = uuidv4();
      this.newCategory.newCategoryUUID = this.request.newCategory;
      await this.requestService.saveNewCategoryProduct(this.newCategory).then(
        response =>{
          console.log(response);
          this.saveRequestApproved()
        }
      )
    }else if(this.categoryCant == false){
      this.saveRequestApproved()
  }else {
    Swal.fire({
       title: 'No puede enviar la categoría vacia',
       icon: 'error'
    })

  }  
  }

  async saveRequestApproved(){
    this.request.pendingRequest = 1;
    console.log(this.request);
  await this.requestService.saveRequestOnTable(this.request).then(
      (response: any) => {
      console.log(response);
      this.lastInsertID = Object.values(response.records[0][21][0])[0];
    this.getEmails();
       console.log("last insert id" + this.lastInsertID);
        if (response.statusCode == 200) {
          // Swal.fire({
          //   icon:'success',
          //   title:'Solicitud Enviada !'
          // })
          // this.loading = false;
          // setTimeout(function(){
          //  window.location.reload();
          // },1000);
          
          this.loading = false;
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Algo salió mal',
            text: response.message,
          })
          this.loading = false;
          setTimeout(function(){
           // window.location.reload();
          },1000);
        }
      },
      error => {
      }
    )
  }

 async getEmails(){
    this.userservice.getUsersWithCategory(this.request.requestSubCatUUID).then(
      (response : any) =>{
      console.log(response);
       this.userEmails = JSON.parse(response).records;
        const title = String(this.request.requestTitle);
        const id = String(this.lastInsertID);
      
       for(var i in this.userEmails){
        let params = {
          title: title,
          requestId: id,
          userEmail: this.userEmails[i].userEmail
        }

        console.log(this.userEmails)
        
        this.userservice.userSendEmailRequest(params).then(
          response => { 
            console.log(response);
          }
        )
       }
       this.loading = false;
      }
   )
   await this.showUserSendNotification();
  }

 async showUserSendNotification(){
  var companyEmails;
      //  Swal.fire({
      //      title: '¿Deseas enviar una notificación de tu solicitud a otras empresas?',
      //     showDenyButton: true,
      //     confirmButtonText: 'Enviar',
      //     denyButtonText: 'Cancelar',
      //     inputOptions: {
      //        companyEmails
      //     }
      //  }).then((result) =>{
      //      if(result.isConfirmed){
      //       setTimeout(function(){
      //         window.location.reload();
      //         },1500);
      //      }
      //  })

      this.dialog.open(SendEmailsDialogComponent, {
         data: {
            title: String(this.request.requestTitle),
            code: String(this.lastInsertID),
            subCatUUID: String(this.request.requestSubCatUUID)
         }
      })
  }


  async uploadImage() {
    this.loading = true;
    var principalI;
    var p1;
    var p2;
    var p3;

    var contractUUID = uuidv4();
    if (this.addImage) {
      this.userDataToShow.userCompanyUUID
      for (var i = 0; i < 4; i++) {
        if (i == 0) {
          if (this.principalimage != null) {
            await this.requestService.uploadFileS3(this.principalimage, "request", contractUUID).then(
              (responsePI: any) => {
                principalI = responsePI.Location;
              }
              )
          }else{
            principalI = null;
          }
        }else if (i == 1) {
          if (this.p1 != null) {
            await this.requestService.uploadFileS3(this.p1, "request", contractUUID).then(
              (responsePI: any) => {
                p1 = responsePI.Location;
              }
              )
          }else{
            p1 = null;
          }
        }else if (i == 2) {
          if (this.p2 != null) {
            await this.requestService.uploadFileS3(this.p2, "request", contractUUID).then(
              (responsePI: any) => {
                p2 = responsePI.Location;
              }
              )
          }else{
            p2 = null;
          }
        }else if (i == 3) {
          if (this.p3 != null) {
            await this.requestService.uploadFileS3(this.p3, "request", contractUUID).then(
              (responsePI: any) => {
                p3 = responsePI.Location;
                this.request.requestIMG1 = principalI;
                this.request.requestIMG2 = p1;
                this.request.requestIMG3 = p2;
                this.request.requestIMG4 = p3;
                this.saveRequest();
                
              }
              )
          }else{
            p3 = null;
            this.request.requestIMG1 = principalI;
                this.request.requestIMG2 = p1;
                this.request.requestIMG3 = p2;
                this.request.requestIMG4 = p3;
                this.saveRequest();
          }
        }
     }
      
    }else{
                    this.request.requestIMG1 = null;
                    this.request.requestIMG2 = null;
                    this.request.requestIMG3 = null;
                    this.request.requestIMG4 = null;
                    this.saveRequest();
    }
    // this.requestService.uploadFileS3(this.principalimage, "request", contractUUID).then(
    //   (responsePI: any) => {
    //     principalI = responsePI.Location;
    //     this.requestService.uploadFileS3(this.p1, "request", contractUUID).then(
    //       (responseP1: any) => {
    //         p1 = responseP1.Location;
    //         this.requestService.uploadFileS3(this.p2, "request", contractUUID).then(
    //           (responseP2: any) => {
    //             p2 = responseP2.Location;
    //             this.requestService.uploadFileS3(this.p3, "request", contractUUID).then(
    //               (responseP3: any) => {
    //                 p3 = responseP3.Location;
    //                 this.request.requestIMG1 = principalI;
    //                 this.request.requestIMG2 = p1;
    //                 this.request.requestIMG3 = p2;
    //                 this.request.requestIMG3 = p3;
    //                 this.saveRequest();
    //               }
    //             )
    //           }
    //         )
    //       }
    //     )
    //   }
    // )
  }

  principalImage(files: FileList) {
    this.labelImportPrincipal.nativeElement.innerText = Array.from(files)
      .map(f => f.name)
      .join(', ');
      if(files[0].name != null){
        this.principalimage = files.item(0);
      }else{
           this.labelImportPrincipal.nativeElement.innerText = 'Subir imagen'
      }
   
  }

  image1(files: FileList) {
    this.labelImportP1.nativeElement.innerText = Array.from(files)
      .map(f => f.name)
      .join(', ');
      if(files[0].name != null){
        this.p1 = files.item(0);
      }else{
        this.labelImportP1.nativeElement.innerText = 'Subir imagen'
      }
    
  }

  image2(files: FileList) {
    this.labelImportP2.nativeElement.innerText = Array.from(files)
      .map(f => f.name)
      .join(', ');
      if(files[0].name != null){
        this.p2 = files.item(0);
      }else{
         this.labelImportP2.nativeElement.innerText = 'Subir imagen'
      }
    
  }

  image3(files: FileList) {
    this.labelImportP3.nativeElement.innerText = Array.from(files)
      .map(f => f.name)
      .join(', ');
      if(files[0].name != null){
        this.p3 = files.item(0);
      }else{
        this.labelImportP3.nativeElement.innerText = 'Subir imagen'
      }
   
  }
  currency(currency){
    this.priceCurrency = currency.value;
 }

 addCategory(){
  this.categoryCant = true
 }

 addExtraCategory(catName){
  this.newCategory.productCategory = catName;
}

listOtherCategories(masterCatUUID){
this.categoryService.categoryGetOne(masterCatUUID.value).then(
  (response: any) => {
    const userDB = JSON.parse(response).records;
    this.categoriesListTwo = userDB;
    this.subCatStatusTwo = false;
  }
)
}

listOtherSubCat(subcatUUID){
this.categoryService.subCatGetOne(subcatUUID.value).then(
  (response: any) => {
    const userDB = JSON.parse(response).records;
    this.subcategoryListTwo = userDB;
    this.familyCatStatusTwo = false;
  }
)
}

addExtraSubCat(value){
this.newCategory.productSubCat = value;
}

itemOtherFamily(familyUUID){
this.categoryService.subCatGetOneForSave(familyUUID.value).then(
  (response: any) => {
    const userDB = JSON.parse(response).records[0];
    this.familyCatForSaveTwo = userDB.subCatName;
  }
)
}

addExtraFamily(value){
console.log(value);
this.newCategory.itemFamily = value;
}


}
