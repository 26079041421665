import { Component, OnInit } from '@angular/core';
import { PrivateDashboardServiceService } from 'src/app/services/private-dashboard-service.service';
import {FormGroup, FormControl} from '@angular/forms';

import credentials from '../../../assets/data/serviceAccountKey.json'
import Swal from 'sweetalert2';
interface requests{
   requestContactName: String,
   requestDetail: String,
   requestTitle: String,
   clicks: Number
}
@Component({
  selector: 'app-private-dashboard',
  templateUrl: './private-dashboard.component.html',
  styleUrls: ['./private-dashboard.component.scss']
})


export class PrivateDashboardComponent implements OnInit {
data: any;
token: any;
public userVisitData: any;
public totalHomeVisited: any;
public uniqueVisits: any;
public favoriteProducts;
public productsLast7Day;
public requestsLast7Days;
public picker;
public rangeDateSearch: Boolean = false;
public dateFormatToShow = "";
public loading: Boolean = false;
public finishedMessages;
public productsLastDaysInfo;
public requestLastDaysInfo;
public persistentUserVisitData;
public requestEvents =[];
public chatStatusList; //save status chat list
public getStatus: Boolean = false;
public statusOption; //save last select status

//company and users in a week
public usersLast7Days;
public companyLast7Days;
public userList;
public companyList;
users: number =1;


range = new FormGroup({
  start: new FormControl(<Date | null>(null)),
  end: new FormControl(<Date | null>(null)),
}); 
  constructor(private dashboardService: PrivateDashboardServiceService) { }
 


 async ngOnInit() {
 this.loading = true;
  await this.dashboardService.productsLastDays().then(
      (response:any) =>{
        this.productsLast7Day = Object.values(response.records[0][0][0])[0];
        this.favoriteProducts = Object.values(response.records[0][2][0])[0];
        this.requestsLast7Days = Object.values(response.records[0][1][0])[0];
        this.productsLastDaysInfo = response.records[0][3];
        this.requestLastDaysInfo = response.records[0][4];
        this.usersLast7Days = Object.values(response.records[0][5][0])[0];
        this.companyLast7Days = Object.values(response.records[0][6][0])[0];
        console.log(this.companyLast7Days);
        console.log(this.usersLast7Days);
      }
   )

   await this.dashboardService.getTotalInteractions().then(
    (response: any) =>{
      this.countRoutes(response);
    }
   )

   await this.dashboardService.getTotalVisitsCount().then(
    (response: any)=>{
       this.totalHomeVisited = Object.values(response.records[0][0][0])[0];
       this.loading = false;
    }
   )

   await this.dashboardService.getAllChatStatus().then(
      (response: any) =>{
           console.log(response);
           this.chatStatusList = response.records[0][0];
      }
   )
  
 }

 countRoutes(value: any){
    this.userVisitData = value.records[0][0];
    this.persistentUserVisitData = value.records[0][0];
    console.log(this.persistentUserVisitData);
    let hash = {};
    var duplicated = this.userVisitData.filter(x=> x.route_name == '/');
    var onlyVisited = duplicated.filter(x=> hash[x.user_ip] ? false: hash[x.user_ip] = true);
    this.uniqueVisits = onlyVisited.length;
 }

 inputDate(rangeForm: any){
 this.rangeDateSearch = true;
 this.loading = true;
 const date = new Date(rangeForm.start);
  let startDateFormat = date.toISOString().split('T')[0];
  const date2 = new Date(rangeForm.end);
  let endDateFormat = date2.toISOString().split('T')[0];

   if(rangeForm.end == null || rangeForm.start == null){
      Swal.fire({
          'title': 'Debe de seleccionar un rango de fechas válido',
          'icon': 'error'
      })
   }else{
      console.log(rangeForm.start, rangeForm.end);
      console.log("de: "+startDateFormat+" a "+endDateFormat);
      this.dateFormatToShow = "Registros de: "+startDateFormat+" a "+endDateFormat;
      const params = {
         startDateFormat : startDateFormat,
         endDateFormat : endDateFormat
      }
      console.log(params);
      this.dashboardService.getRangeDates({param1: params.startDateFormat, param2: params.endDateFormat}).then(
         (response: any) => {
           this.countRoutesFiltered(response);
         }
      )

      this.dashboardService.getProductRangeDates({param1: params.startDateFormat, param2: params.endDateFormat}).then(
         (response: any) => {
          console.log(response);
          console.log(response.rows.length);
          this.productsLast7Day = response.rows.length;
          this.productsLastDaysInfo = response.rows;
     
         }
      )

      this.dashboardService.getRequestRangeDate({param1: params.startDateFormat, param2: params.endDateFormat}).then(
        (response: any) => {
          //requestsLast7Days
          console.log(response);
          console.log(this.persistentUserVisitData)
          this.requestsLast7Days = response.rows.length;
          this.requestLastDaysInfo = response.rows;
          console.log(this.requestsLast7Days);
          response.rows.forEach(element => {
             var counter = 0;
           var data = this.persistentUserVisitData.filter(x=> x.route_name == '/VistaSolicitud/'+element.requestCode+'#row-container');
           var code = element.requestCode;
           this.requestEvents.push(data);
          });
          console.log(this.requestEvents);
          this.loading = false;
        }
     )

     this.dashboardService.getCompanyRangeDates({param1: params.startDateFormat, param2: params.endDateFormat}).then(
         (response:any)=>{
               this.companyLast7Days = response.rows.length;
               this.companyList = response.rows.filter(x=> x.companyAddress != "Usuario externo");
               console.log(this.companyList);
         }
     )

     this.dashboardService.getUserRangeDates({param1: params.startDateFormat, param2: params.endDateFormat}).then(
       (response: any)=>{
               this.usersLast7Days = response.rows.length;
               this.userList = response.rows;
               console.log(this.userList);
       }
     )

   }
 }

 countRoutesFiltered(values:any){
  this.userVisitData = values.rows;
  let hash = {};
  var duplicated = this.userVisitData.filter(x=> x.route_name == '/');
  var onlyVisited = duplicated.filter(x=> hash[x.user_ip] ? false: hash[x.user_ip] = true);
  this.uniqueVisits = onlyVisited.length;
  var total = 0;
  duplicated.forEach(element => {
     total = total + element.num; 
  });
  this.totalHomeVisited = total;
 }

 async getOneChatStatus(value: any){
   this.getStatus = true;
   this.statusOption = value;
    await this.dashboardService.getFinishedMessages(value).then(
      (response: any) =>{
        this.finishedMessages = response.rows;
        console.log(response);
      }
     )
 }

     // const viewId = '348454663';
    // const apiKey = this.token;
    // const endDate = new Date().toISOString().slice(0,10);
    // const startDate = new Date(new Date().setDate(new Date().getDate()-30)).toISOString().slice(0,10);

    // const url = `https://analyticsreporting.googleapis.com/v4/reports:batchGet?access_token=${apiKey}`;


    // var reportRequests =
    //     {
    //       viewId,
    //       dateRanges: [{startDate, endDate}],
    //       metrics: [{expression: "ga:users"}, {expression: "ga:pageviews"}]
    //     }
    //     console.log(this.token);
    //     this.dashboardService.getGAContent(reportRequests, {gaToken: this.token}).subscribe(
    //       (data:any) =>{
    //          console.log(data);
    //       }, err =>{
    //          console.log(err);
    //       }
    //     )
    
    // }

  
 

    // this.dashboardService.getToken().then(
    //    (response:any) => {
    //     console.log(response);
    //    }
    // )

}
