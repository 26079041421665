import { Component, OnInit } from '@angular/core';
import { CategoryModel } from 'src/app/models/categoryModel';
import { MasterCatModel } from 'src/app/models/msterCatModel';
import { SubCategoryModel } from 'src/app/models/subcategoryModel';
import { UserModel } from 'src/app/models/userModel';
import { AuthService } from 'src/app/services/auth.service';
import { CategoryService } from 'src/app/services/category.service';
import { ProductsModel } from '../../../models/productsModel';
import { ProductsService } from '../../../services/products.service';
import { ActivatedRoute, Router } from '@angular/router';
import { element } from 'protractor';
import { MatDialog } from '@angular/material/dialog';
import { PublicityService } from 'src/app/services/publicity.service';

@Component({
  selector: 'app-products-cards-option',
  templateUrl: './products-cards-option.component.html',
  styleUrls: ['./products-cards-option.component.scss']
})
export class ProductsCardsComponentOption implements OnInit {

  userRole: string;
  subcategories = {} as SubCategoryModel;
  masterCategory = {} as MasterCatModel;
  categories = {} as CategoryModel;
  categoriesList: [];
  masterCatList;
  subcategoryList;
  contador20 = 20;
  contador40= 40;
  contador60 = 60;
  show = 1;
  actual;
  listProducts;
  productsList;
  productsList2;
  productsList3;
  productListAll;
  listText="";
  productListDiv=[];
  loading = false;
  nextavailable= false;
  prevavailable= false;
  publicityListfirst;
  publicityListSecond;
  option="";
  subCatUUID="";
  principalTitle= "";
  familyCatForSave;
  categoryToShow="...";
  constructor(
    private productService: ProductsService,
    private categoryService: CategoryService,
    private router: Router,
    private authService: AuthService,
    private publicityService: PublicityService,
    private route: ActivatedRoute,
  ) { }

  async ngOnInit(){
    
    let user;
    this.authService.userRole.subscribe(userRoleApp => {
      this.userRole = userRoleApp;
    })
    if (localStorage.getItem('user')) {
      user = JSON.parse(localStorage.getItem('user'));
      this.userRole = user.userRole;
    }
    
    this.listMasterCategories();

    this.route.paramMap.subscribe((params: any) => {
      this.option = params.params.option;
      if (params.params.option == "favoritos") {
        this.principalTitle = "Mis productos Favoritos";
        
        this.getAllProducts(user.userId);
      }else if(params.params.option=="destacados"){
        this.principalTitle = "Productos Destacados";
        this.getAllProductsSalient();
      }else if(params.params.option=="buscados"){
        this.principalTitle = "Lo Mas Buscado";
        this.getAllProductSearch();
      }else if(params.params.option=="agregados"){
        this.principalTitle = "Agregado Recientemente";
        this.getAllProductsADD();
      }else{
        this.subCatUUID = params.params.option;
        this.itemFamily(this.subCatUUID);
        this.getAllProductsSimilar(this.subCatUUID);
        // productos filtrados por categoria

      }
    });


    //publicidad
    await this.publicityService.getAllPublicity().then(
      (result: any) => {
        const DB = result.records[0][0];
        this.publicityListfirst = DB.filter(x => x.publicityPlace == "PPFS" && x.publicityStatus == "Activo");
        this.publicityListSecond = DB.filter(x => x.publicityPlace == "PPFI" && x.publicityStatus == "Activo");

    
      }
    )
   
      this.checkOdd();
       //fin de publicidad
        
  }

  itemFamily(familyUUID){
    this.categoryService.subCatGetOneForSave(familyUUID).then(
      (response: any) => {
        
        const userDB = JSON.parse(response).records[0];
        
        this.familyCatForSave = userDB.subCatName;
        this.principalTitle= "Productos de: "+ this.familyCatForSave;
      }
    )
  }

  getAllProductSearch(){
    this.loading = true;
    this.productService.productGetSearch().then(
     response=> {
       
       var userDB= response.records[0][0];
       const filterO=[];
       var contador = 0;
       if (userDB.length>2) {
        userDB.forEach(element => {
          if (contador<30) {
            filterO.push(element)
          }
          contador++;
         });
         
         
         userDB = filterO;
        }

       this.productListAll = userDB;
       var productListTemp = [];
       var productListTemp2 = [];
       var productListTemp3 = [];
       //this.productsList.sort(function() { return Math.random() - 0.5 });
       var contador= 0;
       var contador2 = 0;
       userDB.forEach(element => {
         if (contador < this.contador20) {
             element.contador = contador;
             if (contador < 4) {
              productListTemp.push(element);
             }else if (contador >= 4 && contador < 12) {
              productListTemp2.push(element);
             }else{
              productListTemp3.push(element);
             }
              

         }
         contador++;
       });
       this.productsList = productListTemp;
       this.productsList2 = productListTemp2;
       this.productsList3 = productListTemp3;
       this.listModules(this.contador20);
       var ini = 1
       if (this.contador20 >= this.productListAll.length) {
        this.listText = ini + " al "+this.productListAll.length+" de "+this.productListAll.length;
       }else{
        this.listText = ini + " al "+this.contador20+" de "+this.productListAll.length;
       }
      
       //Mostrar 20 productos al iniciar
       this.loading = false;
     }
   )

   }

  checkOdd(){
    if(this.publicityListfirst.length%2 == 1 && this.publicityListfirst.length>1){
      let len = this.publicityListfirst.length -1;
      let random = Math.floor((Math.random()*(len - 1+ 1))+ 1);
      this.publicityListfirst.push(this.publicityListfirst[random]);
      this.actv();
     } if(this.publicityListSecond.length%2 == 1 && this.publicityListSecond.length>1){
      let len = this.publicityListSecond.length-1;
      let random = Math.floor((Math.random()*(len -1 +1))+1);
      this.publicityListSecond.push(this.publicityListSecond[random])
      this.actv();
  }
this.actv();
  }

  actv(){
    var act = true;
    this.publicityListfirst.forEach(element => {
      if (act) {
        element.active = "active";
        act = false;
      }else{
        element.active = "";
      }
    });
    
    var act1 = true;
    this.publicityListSecond.forEach(element => {
      if (act1) {
        element.active = "active";
        act1 = false;
      }else{
        element.active = "";
      }
    }); 
  }

  getAllProducts(userId){
    this.loading = true;
    this.productService.productGetFavorite(userId).then(
      (resultUsers: any) => {
        var list=[];
        const userDB = JSON.parse(resultUsers).records;
        this.productListAll = userDB;
       var productListTemp = [];
       var productListTemp2 = [];
       var productListTemp3 = [];
       //this.productsList.sort(function() { return Math.random() - 0.5 });
       var contador= 0;
       var contador2 = 0;
       userDB.forEach(element => {
         if (contador < this.contador20) {
             element.contador = contador;
             if (contador < 4) {
              productListTemp.push(element);
             }else if (contador >= 4 && contador < 12) {
              productListTemp2.push(element);
             }else{
              productListTemp3.push(element);
             }
              

         }
         contador++;
       });
       this.productsList = productListTemp;
       this.productsList2 = productListTemp2;
       this.productsList3 = productListTemp3;
       this.listModules(this.contador20);
       var ini = 1
       if (this.contador20 >= this.productListAll.length) {
        this.listText = ini + " al "+this.productListAll.length+" de "+this.productListAll.length;
       }else{
        this.listText = ini + " al "+this.contador20+" de "+this.productListAll.length;
       }
      
       //Mostrar 20 productos al iniciar
       this.loading = false;
       
      });


   }

   getAllProductsADD(){
    this.loading = true;
    this.productService.productGetAll().then(
     response=> {
       
       var userDB= response.records[0][0];
       const filterO=[];
       var contador = 0;
       if (userDB.length>2) {
        userDB.forEach(element => {
          if (contador<30) {
            filterO.push(element)
          }
          contador++;
         });
         
         
         userDB = filterO;
        }

       this.productListAll = userDB;
       var productListTemp = [];
       var productListTemp2 = [];
       var productListTemp3 = [];
       //this.productsList.sort(function() { return Math.random() - 0.5 });
       var contador= 0;
       var contador2 = 0;
       userDB.forEach(element => {
         if (contador < this.contador20) {
             element.contador = contador;
             if (contador < 4) {
              productListTemp.push(element);
             }else if (contador >= 4 && contador < 12) {
              productListTemp2.push(element);
             }else{
              productListTemp3.push(element);
             }
              

         }
         contador++;
       });
       this.productsList = productListTemp;
       this.productsList2 = productListTemp2;
       this.productsList3 = productListTemp3;
       this.listModules(this.contador20);
       var ini = 1
       if (this.contador20 >= this.productListAll.length) {
        this.listText = ini + " al "+this.productListAll.length+" de "+this.productListAll.length;
       }else{
        this.listText = ini + " al "+this.contador20+" de "+this.productListAll.length;
       }
      
       //Mostrar 20 productos al iniciar
       this.loading = false;
     }
   )

   }


   getAllProductsSalient(){
    this.loading = true;
    this.productService.productGetAll().then(
     response=> {
       
       var userDB= response.records[0][0];
       const filterO=[];
       var contador = 0;
       if (userDB.length>2) {
        userDB.forEach(element => {
          if (contador<30) {
            filterO.push(element)
          }
          contador++;
         });
         
         filterO.sort(function() { return Math.random() - 0.5 });
         userDB = filterO;
        }

       this.productListAll = userDB;
       var productListTemp = [];
       var productListTemp2 = [];
       var productListTemp3 = [];
       //this.productsList.sort(function() { return Math.random() - 0.5 });
       var contador= 0;
       var contador2 = 0;
       userDB.forEach(element => {
         if (contador < this.contador20) {
             element.contador = contador;
             if (contador < 4) {
              productListTemp.push(element);
             }else if (contador >= 4 && contador < 12) {
              productListTemp2.push(element);
             }else{
              productListTemp3.push(element);
             }
              

         }
         contador++;
       });
       this.productsList = productListTemp;
       this.productsList2 = productListTemp2;
       this.productsList3 = productListTemp3;
       this.listModules(this.contador20);
       var ini = 1
       if (this.contador20 >= this.productListAll.length) {
        this.listText = ini + " al "+this.productListAll.length+" de "+this.productListAll.length;
       }else{
        this.listText = ini + " al "+this.contador20+" de "+this.productListAll.length;
       }
      
       //Mostrar 20 productos al iniciar
       this.loading = false;
     }
   )

   }

   getAllProductsSimilar(subCat){
    //getSecondsCategorysTest
    let familyItem = subCat;
    console.log(familyItem);
    var productsByNewCategory;
    this.productService.getProductsByFamilyItem(familyItem).then(
      (result: any) =>{
         productsByNewCategory = JSON.parse(result).records;
         console.log(productsByNewCategory);
      }
    )

    this.loading = true;
    this.productService.productGetAll().then(
     response=> {
      var userDB = response.records[0][0];
       const userDB1 = userDB.filter(x => x.itemFamilyUUID == subCat);
       userDB = userDB1;
       this.productListAll = userDB;
       console.log(this.productListAll);
       if(productsByNewCategory !== undefined){
        for(let i in productsByNewCategory){
          this.productListAll.push(productsByNewCategory[i]);
        }
       }
       var productListTemp = [];
       var productListTemp2 = [];
       var productListTemp3 = [];
       //this.productsList.sort(function() { return Math.random() - 0.5 });
       var contador= 0;
       userDB.forEach(element => {
         if (contador < this.contador20) {
             element.contador = contador;
             if (contador < 4) {
              productListTemp.push(element);
             }else if (contador >= 4 && contador < 12) {
              productListTemp2.push(element);
             }else{
              productListTemp3.push(element);
             }
              

         }
         contador++;
       });
       this.productsList = productListTemp;
       this.productsList2 = productListTemp2;
       this.productsList3 = productListTemp3;
       this.listModules(this.contador20);
       var ini = 1
       if (this.contador20 >= this.productListAll.length) {
        this.listText = ini + " al "+this.productListAll.length+" de "+this.productListAll.length;
       }else{
        this.listText = ini + " al "+this.contador20+" de "+this.productListAll.length;
       }
      
       this.loading = false;
     }
   )

   }



   listModules(size){
    this.productListDiv = [];
     if (size >= this.productListAll.length) {
      this.listText = 1 + " al "+this.productListAll.length+" de "+this.productListAll.length
     }else{
      var conteo = 0;
      var noModulos=this.productListAll.length/size;
      var noModulosint = noModulos.toFixed()+"";
      conteo= parseInt(noModulosint);
      var multiplication = parseInt(noModulosint)*size;
      if (multiplication < this.productListAll.length) {
       conteo = parseInt(noModulosint)+1;
 
      }
      var contadorTemp = 1;
      while (contadorTemp <= conteo) {
        if (contadorTemp == 1) {
          this.productListDiv.push({numero:contadorTemp,nodes:conteo,products:this.productListAll.length,order:size,color:'colorBottomGray'})
       
        }else{
          this.productListDiv.push({numero:contadorTemp,nodes:conteo,products:this.productListAll.length,order:size,color:'colorBottomWhite'})
       
        }
        contadorTemp++;
      }
      this.actual = {numero:1,nodes:conteo,products:this.productListAll.length,order:size,color:'colorBottomWhite'};
      this.listText = 1 + " al "+size+" de "+this.productListAll.length;
      var event1 = this.productListDiv.find(x => x.numero == (this.actual.numero+1));
     if (event1!=undefined) {
      this.nextavailable= true;
     }else{
      this.nextavailable= false;
     }
      
     }
    
   }
   changecolor(numero){
    this.productListDiv.forEach(element=>{
     
        element.color = 'colorBottomWhite';
      
    });

     this.productListDiv.forEach(element=>{
       if (element.numero == numero) {
         element.color = 'colorBottomGray';
       }
     });
   }
   listProductSize(size){
    var productListTemp = [];
    var productListTemp2 = [];
    var productListTemp3 = [];
      var contador= 0;
      this.productListAll.forEach(element => {
        if (contador < size) {
          if (contador < 4) {
            productListTemp.push(element);
           }else if (contador >= 4 && contador < 12) {
            productListTemp2.push(element);
           }else{
            productListTemp3.push(element);
           }
          
        }
        contador++;
      });
      this.productsList = productListTemp;
      this.productsList2 = productListTemp2;
       this.productsList3 = productListTemp3;
      this.listModules(size);
   }
   changeListProduct(event){
     var fin = event.numero * event.order;
     var start = fin - event.order;

     var productListTemp = [];
     var productListTemp2 = [];
       var productListTemp3 = [];
      var contador= 0;
      var contador2= 0;
      this.productListAll.forEach(element => {
        if (contador >= start && contador < fin) {
          element.contador = contador2;
          if (contador2 < 4) {
           productListTemp.push(element);
          }else if (contador2 >= 4 && contador2 < 12) {
           productListTemp2.push(element);
          }else{
           productListTemp3.push(element);
          }
          contador2++;
        }
        contador++;
      });
      this.productsList = productListTemp;
       this.productsList2 = productListTemp2;
       this.productsList3 = productListTemp3;
      var ini = start+1;
      if (fin > this.productListAll.length) {
        fin = this.productListAll.length;
      }
      this.listText = ini + " al "+fin+" de "+this.productListAll.length
      this.actual = event;
      this.changecolor(event.numero)

      var event1 = this.productListDiv.find(x => x.numero == (this.actual.numero+1));
     if (event1!=undefined) {
      this.nextavailable= true;
     }else{
      this.nextavailable= false;
     }
     document.location.href = "Productos/"+this.option+"#productos";
   }
   next(){
     var event = this.productListDiv.find(x => x.numero == (this.actual.numero+1));
     
     this.changeListProduct(event);
     
     var event1 = this.productListDiv.find(x => x.numero == (this.actual.numero+1));
     if (event1!=undefined) {
      this.nextavailable= true;
     }else{
      this.nextavailable= false;
     }
   }

   prev(){
    var event = this.productListDiv.find(x => x.numero == (this.actual.numero-1));
   
    this.changeListProduct(event);
  }
   viewProduct(productUUID){
    this.loading = true;
    // this.router.navigate(['/VistaProducto/' + productUUID]);
    // window.location.reload();
    this.router.navigate(['/VistaProducto/' + productUUID])
  .then(() => {
    window.location.reload();
  });
  }

  listMasterCategories(){
    this.categoryService.masterGetAll().then(
      response=> {
        const userDB: MasterCatModel = response.records[0][0];
        this.masterCatList = userDB;
      }
    )
  }
  mouseEvent(event){
    this.productService.getOneProductCategory(event).then(
      (response:any) => {
          this.categoryToShow = JSON.parse(response).records[0].productCategory
          console.log(this.categoryToShow);
      }
    )
  }
  
  mouseEvent2(){
       this.categoryToShow = "...";
  }
}
