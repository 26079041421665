<!-- Navbar -->
<nav class="navbar navbar-expand-lg navbar-light bg-black" >
  <!-- Container wrapper -->
  <div class="container-fluid">
    <!-- Toggle button -->
    <button
      class="navbar-toggler"
      type="button"
      data-mdb-toggle="collapse"
      data-mdb-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <i class="fas fa-bars"></i>
    </button>

    <!-- Collapsible wrapper -->
    <div class="collapse navbar-collapse row" id="navbarSupportedContent">
      <!-- Navbar brand -->
      <div class="col-sm-12">
      <a class="navbar-brand" (click)="home()"><img src="../../../assets/images/core/CIG-Match-1 2.png" width="350" height="auto" alt=""/></a>
    
    </div>
    </div>
    <!-- Collapsible wrapper -->
      <div  class="location" *ngIf="directory">
        <p  class="location-name">Directorio Empresarial</p>
      </div>
      <div  class="location" *ngIf="productView">
        <p class="location-name">Producto / Servicio</p>
      </div>
      <div  class="location" *ngIf="portalRequest">
        <p class="location-name">Portal de Solicitudes</p>
      </div>
      <div  class="location" *ngIf="requestView">
        <p class="location-name">Portal de Solicitudes</p>
      </div>
      <div  class="location" *ngIf="profile">
        <p class="location-name">Mi Perfil</p>
      </div>
      <div  class="location" *ngIf="newRequest">
        <p class="location-name">Publicar una solicitud</p>
      </div>
      <div  class="location" *ngIf="newProduct" style="margin-top:-10px">
        <p class="location-name">Publicar un producto / servicio</p>
      </div>
      <div  class="location" *ngIf="messageProfile">
        <p class="location-name">Mensajes</p>
      </div>
      <div  class="location" *ngIf="category">
        <p class="location-name">Categorías</p>
      </div>
    <!-- Right elements -->
    <div class="d-flex align-items-center">
      <!-- Notifications -->

      <!-- Avatar -->
      <a
        class="dropdown-toggle d-flex align-items-center hidden-arrow"
        href="#"
        id="navbarDropdownMenuLink"
        role="button"
        data-mdb-toggle="dropdown"
        aria-expanded="false"
        style="text-decoration: none!important;"
      > <span style="color: white;font-size: 1.5rem;text-decoration: none!important;font-weight: 700; padding-top: 28px !important;" class="in-text" *ngIf="!isLogged">Ingreso</span>
      <div class="avatar-message">
        <div class="notification" *ngIf ="messageReceived>0">
        </div>  
      <img *ngIf="userLog" src="../../../assets/images/Usuario1.png" class="rounded-circle" height="100px"alt=""loading="lazy"/>
      <img *ngIf="!userLog" src="../../../assets/images/core/CIG_M-lanczos3Blanco.ico" class="rounded-circle" height="100px"alt=""loading="lazy"/>
    </div>
      </a>
      
      <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdownMenuLink" style="border-radius: 1rem!important;margin-right: 3.6rem!important;color: #898989;font-size: medium; min-width: 12% !important;">
        <li>
          <a class="dropdown-item" href="MiPerfil" *ngIf="isLogged" style="font-size: medium; font-weight: lighter; color: #898989"><img src="../../../assets/images/core/menu/Mesa de trabajo 1 copia 5.png" style="width: 1.5rem;height: 1.5rem;"> Perfil de Usuario </a>
        </li>
        <li>
          <a class="dropdown-item" href="Mensajes" *ngIf="isLogged" style="font-size: medium; font-weight: lighter;color: #898989"><img src="../../../assets/images/core/menu/Mesa de trabajo 1 copia 11.png" style="width: 1.5rem;height: 1.5rem;"> Mensajes <span *ngIf="messageReceived>0" class="message-noti">{{messageReceived}}</span></a>
        </li>
        <li>
          <a class="dropdown-item" (click)="productsD()" *ngIf="isLogged" style="font-size: medium; font-weight: lighter;color: #898989"><img src="../../../assets/images/core/menu/Mesa de trabajo 1 copia 4.png" style="width: 1.5rem;height: 1.5rem;"> Favoritos</a>
        </li>
        <!-- <li>
          <a class="dropdown-item" href="Perfil" *ngIf="isLogged" style="font-size: medium;color: #898989"><img src="../../../assets/images/core/menu/Mesa de trabajo 1 copia 9.png" style="width: 1.5rem;height: 1.5rem;"> Notificaciones</a>
        </li> -->
        <li>
           <div style="height: 30px;"></div>
        </li>
        <li>
          <a class="dropdown-item" (click)="logout()" *ngIf="isLogged" style="font-size: medium; font-weight: lighter; color: #898989;cursor: pointer;"><img src="../../../assets/images/core/menu/Mesa de trabajo 1 copia 7.png" style="width: 1.5rem;height: 1.5rem;"> Cerrar Sesión &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</a>
        </li>
        <li>
          <a class="dropdown-item" href="Login" *ngIf="!isLogged" style="font-weight: lighter; color: #898989;cursor: pointer;" (click)="navigateToLogin()"><img src="../../../assets/images/core/menu/Mesa de trabajo 1 copia 5.png" style="width: 1.5rem;height: 1.5rem;"> Ingresar</a>
        </li>
        <li style="width: 250px;">
          <a class="dropdown-item" href="RegistroPropio" *ngIf="!isLogged" style="font-weight: lighter;color: #898989;cursor: pointer;"><img src="../../../assets/images/core/menu/Mesa de trabajo 1 copia 7.png" style="width: 1.5rem;height: 1.5rem;"> Registrarte </a>
        </li>
      </ul>
    
    </div>
    <!-- Right elements -->
  </div>
  <!-- Container wrapper -->
</nav>
<!-- Navbar -->


<!-- Navbar -->

<nav class="navbar navbar-expand-lg navbar-light bg-black" style="background-color: #0078D7!important">

  <!-- Container wrapper -->
  <div class="container-fluid">
    <!-- Toggle button -->
    

    <!-- Collapsible wrapper -->
    <div class="collapse navbar-collapse row" id="navbarSupportedContent">
      <!-- Navbar brand -->
      
      <!-- Left links -->
      <div class="col-sm-12" style="position:static; background-color: #0078D7;">
      <ul class="navbar-nav me-auto mb-2 mb-lg-0">
        <li class="nav-item" *ngIf="!isLogged">
          <a class="nav-link" (click)="logout()" href="Login" style="display: none" id="in-text--dropdown">Ingreso</a>
        </li>
        <li class="nav-item" *ngIf="isLogged">
          <a class="nav-link"  style="display: none" id="out-text--dropdown">Cerrar sesión</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="DirectorioEmpresarial">Directorio empresarial</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="Categorías" >Categorías</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="RegistroDeProducto" *ngIf="isLogged && userRole != 'USUARIOCOMPRAS' && userRole != 'UsuarioNoSocio' && userRole !='Usuario CIG Compras'">Publica un producto / servicio</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="PortalDeSolicitudes" >Portal de solicitudes</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="Ayuda" *ngIf="isLogged">Ayuda</a>
        </li>
        <li class="nav-item dropdown" *ngIf="isLogged && (userRole === 'Admin' || userRole === 'Master')">
          <a class="nav-link  dropdown-toggle" href="#" data-bs-toggle="dropdown">  Administración  </a>
           <ul class="dropdown-menu" style="border-radius: 1rem !important;">
           <li><a class="dropdown-item" href="nuevoUsuario" style="color: #898989; font-weight: lighter"><i class="fas fa-plus-circle"></i>  Registrar un Usuario</a></li>
           <li><a class="dropdown-item" href="misUsuarios" style="color: #898989; font-weight: lighter"><i class="fas fa-tasks"></i>  Usuario</a></li>
           </ul>
        </li>
        <li class="nav-item dropdown" *ngIf="isLogged && (userRole === 'Admin' || userRole === 'Master')">
          <a class="nav-link  dropdown-toggle" href="#" data-bs-toggle="dropdown">  Administración  </a>
           <ul class="dropdown-menu" style="border-radius: 1rem !important;">
           <li><a class="dropdown-item" href="Registro" style="color: #898989; font-weight: lighter"><i class="fas fa-plus-circle"></i> Registro</a></li>
           <li  *ngIf="userRole === 'Master'"><a class="dropdown-item" href="CrearCategoria" style="color: #898989; font-weight: lighter"><i class="fas fa-tasks"></i> Crear Categoría </a></li>
           <li *ngIf="userRole === 'Master'"><a class="dropdown-item" href="AdministracionDePublicidad" style="color: #898989; font-weight: lighter"><i class="fas fa-columns"></i> Publicidad </a></li>
           <li *ngIf="userRole === 'Master'"><a class="dropdown-item" href="EnviarCorreos" style="color: #898989; font-weight: lighter"><i class="fas fa-paper-plane"></i> Envío de correos</a></li>
           <li *ngIf="userRole === 'Master'"><a class="dropdown-item" href="Dashboard" style="color: #898989; font-weight: lighter"><i class="fas fa-chart-line"></i> Dashboard</a></li>
           <li *ngIf="userRole === 'Master'"><a class="dropdown-item" href="validacion" style="color: #898989; font-weight: lighter"><i class="fas fa-check-circle"></i> Validación de usuarios</a></li>
           <li *ngIf="userRole === 'Master'"><a class="dropdown-item" href="productosPendientes" style="color: #898989; font-weight: lighter"><i class="fas fa-check-circle"></i> Productos pendientes de aprobación</a></li>
           </ul>
        </li>
      </ul>
    </div>
      <!-- Left links -->
    </div>
    <!-- Collapsible wrapper -->
  </div>
  <!-- Container wrapper -->
</nav>
<!-- Navbar -->
