<body class="row">
<div class="background-image">
  <div class="col-sm-12" style="margin-bottom: 6rem;">
    <div class="row  d-flex flex-row-reverse">
       
      <div class="col-md-5 mt-3 col-12 col-sm-12" style="text-align: -webkit-center;margin-top: 116px!important;">
        <!-- <div class="card card1 p-3 col-12" style="box-shadow: none!important;"> -->
          <div class="row">
            <div class="col-md-12 col-12">

           
          <a><img src="../../../assets/images/core/CIG-Match-1 2.png" style="width: 15rem;"></a>
          <a><img src="../../../assets/images/core/LogoComenzar.png" style="width: 20rem;"></a>
          <br>
          <br>
          <form [formGroup]="signin" style="border: 1px solid rgb(204, 201, 201);background-color: #ffffff;border-radius: 0.5rem;width: 100%;padding-top: 0.8rem;padding-left: 1rem;padding-right: 1rem;padding-bottom: 0.8rem;" id="email-input">
           
            <input type="email" matInput [formControl]="emailFormControl" placeholder="Correo electrónico *" [(ngModel)]="userEmail" onkeypress="return (event.charCode != 32)" min="1" style="border: 0!important;margin:0!important;font-size:1.3rem;text-align: left!important;color: rgb(94, 94, 94);">

          </form>
          <form [formGroup]="signin" style="border: 1px solid rgb(204, 201, 201);background-color: #ffffff;border-radius: 0.5rem;width: 100%;padding-top: 0.8rem;padding-left: 1rem;padding-right: 1rem;padding-bottom: 0.8rem;margin-top: 2rem;">
           
            <input matInput placeholder="Contraseña" [(ngModel)]="userPassword" [type]="hide ? 'password' : 'text'" formControlName="password" required  onkeypress="return (event.charCode != 32)" min="1" style="border: 0!important;margin:0!important;font-size:1.3rem;text-align: left!important;color: rgb(94, 94, 94);">
            <mat-icon matSuffix (click)="hide = !hide" style="cursor:pointer;">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>

          </form>
          <div class="row">
            <div class="col-12 col-sm-12">
              <a (click)="recoverPass()" style="text-decoration: none!important;font-weight: 600;margin-top: 0.8rem!important;font-size: medium; color: #0d6efd;">
                <p class="text-left pt-2 ml-1" >¿Olvidaste tu contraseña?</p>
              </a>
            </div>
          </div>
          <div>
            <button mat-stroked-button color="primary" style="min-width: 50% !important; max-width: 50% !important;background-color: #0078D7;color: #ffffff;font-size: medium;border-radius: 0.5rem!important;" (click)="onSubmit()">Ingresar</button>
          </div>
          <div class="row" style="margin-top: 1rem;color: rgb(121, 120, 120);">
            <div class="col-sm-1">
             
            </div>
            <div class="col-sm-4">
              <hr style="height: 1.5px;background-color: rgb(121, 120, 120);opacity: 70%;margin-top: 0.8rem;">
            </div>
            <div class="col-sm-2" style="opacity: 70%;text-align: center;">
              <span style="font-size:2.5rem;">○</span>
            </div>
            <div class="col-sm-4">
              <hr style="height: 1.5px;background-color: rgb(121, 120, 120);opacity: 70%;margin-top: 0.8rem;">
            </div>
            <div class="col-sm-1">
             
            </div>

          </div>
       
          <div class="col-12" style="padding-top: 0.5rem !important;">
            <div class="row">
              <div class="col-sm-2">

              </div>
              <div class="col-12 col-sm-12">
                <a href="/RegistroPropio" style="text-decoration: none!important;font-weight: 600;margin-top: 0.8rem!important;font-size: medium; color: #0d6efd;">
                  <p class="text-left pt-2 ml-1" style="color: #F58626;;" >Solicitar acceso</p>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
    </div>
  </div>
</div>
</body>
