import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { UserService } from 'src/app/services/user.service';
import { Router } from '@angular/router';
import { UserModel } from 'src/app/models/userModel';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-password-recover',
  templateUrl: './password-recover.component.html',
  styleUrls: ['./password-recover.component.scss']
})
export class PasswordRecoverComponent implements OnInit {
public userEmail;
public enable: Boolean = false;
public enablePasswordChangeInputs: Boolean = false;
public key: String;
public newKey: String;
public verificationCode: String;
private userData;
  constructor(private auth: AuthService, private userService: UserService, public router: Router) { }

  ngOnInit(){
  }

  enableMethods(){
    const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    let str = this.userEmail.replace(/\s+/g, "");
    if(this.userEmail != null && regex.test(str)){
      this.enable = true;
      this.userEmail = str;
    }else{
      Swal.fire({
         title: 'Ingrese un correo electrónico válido',
         text: 'vuelva a intentarlo',
         icon: 'error'
      })
    }
    this.userService.oneUserBackend(this.userEmail).then(
      (response: any)=>{
        this.userData = JSON.parse(response).records[1][0];
        if(this.userData !== undefined){
           this.auth.updateUser(this.userData).then(
            (response: any) => {
            }
           )
        }
      }
    )
    
  }
  recoveryPswd(){
    this.userService.oneUserBackend(this.userEmail).then(
      (resultUsers: any) =>{
        if(typeof(resultUsers) ==='string'){
          resultUsers = JSON.parse(resultUsers);
        }else{
            throw resultUsers.errorMessage;
        }
         const userDB: UserModel = resultUsers.records[1][0];
         const psd = userDB.userPassword;
         
       let params = {
         userName: this.userEmail,
         userEmail: this.userEmail,
         userPassword : psd
       }
       this.auth.sendEmail(params);
          Swal.fire({
             position: 'center',
             icon: 'success',
             title:'Revisa tu correo eléctronico',
             showConfirmButton: false,
             timer: 1500
          })
          this.router.navigate(['/Login']);
      }
    ).catch((err) =>{
        Swal.fire({
           icon: 'error',
           title: 'Ooops..',
           text:'Algo salio mal o el correo es incorrecto'
        })
        this.router.navigate(['/Login']);
    })
  }
sendVerificationCode(){
  this.enablePasswordChangeInputs = true;
  try{
    this.auth.userForgotPasswordSendCode(this.userEmail).then(
      (response: any) =>{
        Swal.fire({
          title: 'Código de verificación enviado',
          text: 'Revisa tu correo electrónico',
          icon: 'success'
        })
      } 
   )
  }catch(err){
    Swal.fire({
      title: 'Error',
      text: 'Usuario no encontrado',
      icon: 'error'
    })
  }
 
}

passwordComparation(value: any){

}
userForgotPassword(){
  const regex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_+-=[\]{};':"\\|,.<>/?]).{8,}$/;
   if(!this.key.match(regex) || this.key.match(/\s+/g)){
    Swal.fire({
      icon: 'error',
      title: 'error',
      text:'La contraseña debe de tener 8 caracteres o más, sin espacios, almenos un número, un caracter especial ejemplo: $%{}[]&*()@! y letras mayúsculas y minúsculas'
    })
   }else if(this.key !== this.newKey){
    Swal.fire({
      icon: 'error',
      title: 'error',
      text:'Las contraseñas no coinciden '
    })
   }else{
    this.verificationCode = this.verificationCode.replace(/\s+/g, "");
    this.auth.userConfirmUpdatePassword(this.verificationCode, this.key, this.userEmail).then(
      (response: any)=>{
        if(response){
          this.userData.userPassword = this.key;
          this.userService.updateUserBackend(this.userData).then(
            (response: any)=>{
               if(response.statusCode == 200){
                Swal.fire({
                  title:'Contraseña cambiada correctamente',
                  icon:'success'
                })
                setTimeout(() => {
                  this.router.navigate(['/Login']);
                }, 3200);
               }
            }
          )
        }
      }
    )
   }


  }

}
