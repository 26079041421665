import { Component, OnInit } from '@angular/core';
import { CompanyModel } from 'src/app/models/companyModel';
import{ProductsModel} from 'src/app/models/productsModel';
import { UserModel } from 'src/app/models/userModel';
import { CompanyService } from 'src/app/services/company.services';
import { UserService } from 'src/app/services/user.service';
import { DatePipe } from '@angular/common';
import { PublicityService } from 'src/app/services/publicity.service';
import {ProductsService} from 'src/app/services/products.service';
import { ActivatedRoute, Router } from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import { EditProductDialogComponent } from './edit-product-dialog/edit-product-dialog.component';
import Swal from 'sweetalert2/dist/sweetalert2.js';
interface userRegisterModel {
  name,
  userEmail,
  company,
  nit,
  country,
  phone,
}

@Component({
  selector: 'app-my-profile',
  templateUrl: './my-profile.component.html',
  styleUrls: ['./my-profile.component.scss'],
  providers: [DatePipe]
})
export class MyProfile implements OnInit {
  answerPending = false;
  sentMessages = false;
  storeMessages = false;
  deletedMessages = false;
  date="";
  loading = false;
  userData = {} as UserModel;
  beforeEdit = true;
  user = {} as UserModel;
  companyData = {} as CompanyModel;
  publicityMyProfile;
  allProducts;

  productsList;
  productsListTemp;
  productsListStart=0;
  productsListFinished=3;
  productsListStartEnable=false;
  productsListFinishedEnable=false;

  constructor(
    private userservice: UserService,
    private companyService: CompanyService,
    private miDatePipe: DatePipe,
    private publicityService: PublicityService,
    private productsService: ProductsService,
    private router: Router,
    private dialog: MatDialog
  ) { }

  async ngOnInit() {
    this.loading = true;
    let user;
    if (localStorage.getItem('user')) {



      await this.publicityService.getAllPublicity().then(
        (result: any) => {
          const DB = result.records[0][0];
          this.publicityMyProfile = DB.filter(x => x.publicityPlace == "MP" && x.publicityStatus == "Activo");
  
          
        }
      )
      this.checkOdd();
      
      user = JSON.parse(localStorage.getItem('user'));
      await this.userservice.oneUser(user.userId).then(
        (userData: any) => {
          const userDB = JSON.parse(userData).records[0];
          this.user = userDB;
          var fecha = userDB.userCreationDate.split("-")

          this.date = "Miembro de CIGMatch desde: "+this.months(parseInt(fecha[1]))+" "+fecha[0]
          this.companyService.companyGetOne(userDB.userCompanyUUID).then(
            (companyData: any) => {
              const companyDB = JSON.parse(companyData).records[0];
              this.companyData = companyDB;
              console.log(this.companyData);
            }
          )
        }
      )
    }
    this.getMyProducts();
    this.getProducts();
  
  }
  
  months(month):string{
    switch(month){
      case 1: return "Enero";
      break;
      case 2: return "Febrero";
      break;
      case 3: return "Marzo";
      break;
      case 4: return "Abril";
      break;
      case 5: return "Mayo";
      break;
      case 6: return "Junio"; 
      break;
      case 7: return "Julio";
      break;
      case 8: return "Agosto";
      break;
      case 9: return "Septiembre";
      break;
      case 10: return "Octubre";
      break;
      case 11: return "Noviembre";
      break;
      case 12: return "Diciembre";
      break;
    }
  }



 getMyProducts(){
      console.log(this.user.userCompanyUUID);
 }

  checkOdd(){
    if(this.publicityMyProfile.length%2 == 1 && this.publicityMyProfile.length>1){
      let len = this.publicityMyProfile.length -1;
      let random = Math.floor((Math.random()*(len - 1+ 1))+ 1);
      this.publicityMyProfile.push(this.publicityMyProfile[random]);
      this.actv();
     }
this.actv();
  }
  
  actv(){
    var act = true;
    this.publicityMyProfile.forEach(element => {
      if (act) {
        element.active = "active";
        act = false;
      }else{
        element.active = "";
      }
    });
  }



 getProducts(){
   this.loading = true;
     this.productsService.productGetAll().then(
         result =>{
           this.allProducts = result.records[0][0];
           const companyUs = this.allProducts.filter(x => x.userCreateUUID === this.user.userCognitoCode);
           companyUs.sort(function(){return Math.random() -0.5});
           this.productsListTemp = companyUs;
           const filterO=[];
           if (companyUs.length > 3) {
            this.productsListFinishedEnable = true;
             var count=0;
             companyUs.forEach(element => {
                if (count >= this.productsListStart && count < this.productsListFinished) {
                  filterO.push(element);
                }
              count++;
              });
              this.productsList=filterO;
           }else{
            this.productsList=companyUs;
           }
           console.log(this.productsList);
           this.loading = false;
         }
       
       )
         }


         viewProduct(productUUID){
          this.loading = true;
          // this.router.navigate(['/VistaProducto/' + productUUID]);
          // window.location.reload();
          this.router.navigate(['/VistaProducto/' + productUUID])
        .then(() => {
          window.location.reload();
        });
        }
  nextProduct(){
    this.productsListFinished++;
    this.productsListStart++;
    if (this.productsListStart>0) {
      this.productsListStartEnable= true;
    }else{
      this.productsListStartEnable= true;
    }
    if (this.productsListTemp.length >= this.productsListFinished) {
      if (this.productsListTemp.length == this.productsListFinished) {
        this.productsListFinishedEnable = false;
      }
      var contador=0;
      const filter=[];
      this.productsListTemp.forEach(element => {
         if (contador >= this.productsListStart && contador < this.productsListFinished) {
           filter.push(element);
         }
       contador++;
       });
       this.productsList=filter;
    }else{
      this.productsListFinishedEnable = false;
    }
  }

  prevProduct(){
    this.productsListFinished--;
    this.productsListStart--;
    if (this.productsListStart>0) {
      this.productsListStartEnable= true;
    }else{
      this.productsListStartEnable= false;
    }
    if (this.productsListTemp.length > this.productsListFinished) {
      var contador=0;
      const filter=[];
      this.productsListTemp.forEach(element => {
         if (contador >= this.productsListStart && contador < this.productsListFinished) {
           filter.push(element);
         }
       contador++;
       });
       this.productsList=filter;
       this.productsListFinishedEnable=true;
    }

  }

  openEditDialogComponent(value: any){
       this.dialog.open(EditProductDialogComponent, {
         data: {
            value
         }
       });
  }

  deleteProduct(value: any){
console.log(value);
    Swal.fire({
       title: '¿Seguro que quiere eliminar el producto?',
       icon: 'question',
       showCancelButton: true,
       confirmButtonText: 'Aceptar',
       cancelButtonText: 'Cancelar'
    }).then ((result) => {
         if(result.isConfirmed){
          this.productsService.productDelete({productUUID:value.productUUID,userUUID:value.userId}).then(
            (response: any) => {
               console.log(response);
               if(response.statusCode == 200){
                  Swal.fire({
                      title: 'Producto eliminado',
                      icon: 'success'
                  }).then ((result) =>{
                    setTimeout(function (){
                      window.location.reload();
                    }, 1200);
                  })

               }else{
                Swal.fire({
                   title:'error',
                   icon:'error'
                })
               }
            }
          )
         }
    })  
  }
 }
 