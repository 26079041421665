import { PublicityModel } from './../models/publicityModel';
import { Injectable, NgZone } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthenticationDetails, CognitoUser, CognitoUserAttribute, CognitoUserPool } from 'amazon-cognito-identity-js';
import { environment } from 'src/environments/environment';
import * as S3 from 'aws-sdk/clients/s3';

const POOL_DATA = {
  UserPoolId: environment.UserPoolId,
  ClientId: environment.ClientId
};
const userPool = new CognitoUserPool(POOL_DATA);

@Injectable({
  providedIn: 'root'
})

export class PublicityService {

  constructor(
    private http: HttpClient,
  ) {}
  apiurl = environment.EnvironmentName;
  getAllPublicity(): Promise<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');

    return this.http.get(environment.API_URL + this.apiurl+'-publicity', { headers}).toPromise();
  }

  saveOnTable(publicity: PublicityModel): Promise<any> {
    let params = JSON.stringify(publicity);
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.post(environment.API_URL +this.apiurl+ '-publicity', params, { headers}).toPromise();
  }

  updatePublicity(event){
    (event);
    let params = JSON.stringify(event);
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.post(environment.API_URL +this.apiurl+ '-publicityUpdate', params, { headers}).toPromise();
  }
  uploadFileS3(file, productUUID): Promise<any> {
    console.log(file.name)
    // this.companyProgress.next(0);
    const contentType = file.type;
    const bucket = new S3(
      {
        accessKeyId: environment.accessKeyId,
        secretAccessKey: environment.secretAccessKey,
        region: environment.region
      }
    );
    const params = {
      Bucket: environment.bucketFiles,
      Key: 'publicity' + '/' + productUUID + '/' + file.name,
      Body: file,
      ContentType: contentType
    };
    return new Promise((resolve, reject) => {
      bucket.upload(params).on('httpUploadProgress', (evt) => {
        const percent = (evt.loaded / evt.total) * 100;
        // this.companyProgress.next(percent);
      }).send((err, data) => {
        if (err) {
          return reject(err);
        }
        console.log(data);
        data.Location = environment.cloudFrontUrl + params.Key.replace(' ', '+');
        return resolve(data);
      });
    });
  }
  // uploadFileS3(file, publicityUUID): Promise<any> {
  //   // this.companyProgress.next(0);
  //   const contentType = file.type;
  //   const bucket = new S3(
  //     {
  //       accessKeyId: environment.accessKeyId,
  //       secretAccessKey: environment.secretAccessKey,
  //       region: environment.region
  //     }
  //   );
  //   const params = {
  //     Bucket: environment.bucketFiles,
  //     Key: 'publicity' + '/' + publicityUUID + '/' + file.name,
  //     Body: file,
  //     ContentType: contentType
  //   };
  //   return new Promise((resolve, reject) => {
  //     bucket.upload(params).on('httpUploadProgress', (evt) => {
  //       const percent = (evt.loaded / evt.total) * 100;
  //       // this.companyProgress.next(percent);
  //     }).send((err, data) => {
  //       if (err) {
  //         return reject(err);
  //       }
  //       data.Location = environment.cloudFrontUrl + params.Key.replace(' ', '+');
  //       return resolve(data);
  //     });
  //   });
  // }
}
