import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { CategoryService } from 'src/app/services/category.service';
import { MasterCatModel } from 'src/app/models/msterCatModel';
import { SubCategoryModel } from 'src/app/models/subcategoryModel';
import { RequestModel} from 'src/app/models/requestModel';
import { RequestService } from 'src/app/services/request.service';
import { ActivatedRoute } from '@angular/router';
import { v4 as uuidv4 } from 'uuid';
import Drift from 'drift-zoom';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { MasterChatTableModel } from 'src/app/models/masterChatTableModel';
import { UserService } from 'src/app/services/user.service';
import { MatDialog } from '@angular/material/dialog';
import { ChatDialogComponent } from 'src/app/core/user-profile/messageCenterViews/answer-pending/chat-dialog/chat-dialog.component';
import { PublicityService } from 'src/app/services/publicity.service';
import { MessageCenterService } from 'src/app/services/messageCenter.service';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
@Component({
  selector: 'app-application-request',
  templateUrl: './application-request.component.html',
  styleUrls: ['./application-request.component.scss']
})
export class ApplicationRequestComponent implements OnInit {
  masterCategory = {} as MasterCatModel;
  subcategories = {} as SubCategoryModel;
  request = {} as RequestModel;
  masterCatList;
  subcategoryList:[];
  requestList;
  masterCatForSave;
  categoriesList: [];
  subCatStatus = true;
  subMenu=true;
  catExist=false;
  loading = false;
  subCatForSave;
  familyCatStatus;
  clases="col-sm-9 col-md-9 col-lg-9";
  publicityListProduct1;
  publicityListProduct2;
  createChat:Boolean = false;
  private userCode = "";
  public deleteRequest: Boolean = false;
  public userRole = "";
  private requestCode;
  public categoryToShow: String ="...";
  public image1;
  public changeRequestImage:Boolean = false;
  public showImageZoom: Boolean = false;
  public imageState = "none";
  public image: any;
  public carouselImage: any;
  public activeIndexh = 0;
  public activeIndexv = 0;
  chatId;
  messagesModel = {} as MasterChatTableModel;
  constructor(
    public dialog: MatDialog,
    private categoryService: CategoryService,
    private requestService: RequestService,
    private router: Router,
    private route: ActivatedRoute,
    private publicityService: PublicityService,
    private chatService: MessageCenterService,
    private userService: UserService
  ) { }

  async ngOnInit() {
    const userParse = JSON.parse(localStorage.getItem('user'))
    console.log(userParse);
    this.userCode = userParse.userId;
    this.userRole = userParse.userRole;
    console.log(this.userRole);
    this.loading = true;
    let actualRoute = window.location.href;
    document.location.href = actualRoute+"#row-container";
    this.listMasterCategories();
    
    await this.route.paramMap.subscribe((params: any) => {
      const requestUUID = params.params.requestCode; 
      this.requestCode = requestUUID;
      this.listAllRequest(requestUUID);
    });

    await this.publicityService.getAllPublicity().then(
      (result: any) => {
        const DB = result.records[0][0];
        this.publicityListProduct1 = DB.filter(x => x.publicityPlace == "PVSH" && x.publicityStatus == "Activo");
        this.publicityListProduct2 = DB.filter(x => x.publicityPlace == "PVSV" && x.publicityStatus == "Activo");
    
      
        
      }
    )

    setInterval(() => {
      this.activeIndexh = (this.activeIndexh + 1) % this.publicityListProduct1.length;
      this.activeIndexv = (this.activeIndexv + 1) % this.publicityListProduct2.length;
    }, 3000);  

    this.checkOdd();

  }

  navigate(){
    this.router.navigateByUrl('/NuevaSolicitud');
  }

  checkOdd(){
    if(this.publicityListProduct1.length%2 == 1 && this.publicityListProduct1.length>1){
      let len = this.publicityListProduct1.length -1;
      let random = Math.floor((Math.random()*(len - 1+ 1))+ 1);
      this.publicityListProduct1.push(this.publicityListProduct1[random]);
      this.actv();
     } if(this.publicityListProduct2.length%2 == 1 && this.publicityListProduct2.length>1){
      let len = this.publicityListProduct2.length-1;
      let random = Math.floor((Math.random()*(len -1 +1))+1);
      this.publicityListProduct2.push(this.publicityListProduct2[random])
      this.actv();
  }
this.actv();
  } 

  actv(){
    var act = true;
    this.publicityListProduct1.forEach(element => {
      if (act) {
        element.active = "active";
        act = false;
      }else{
        element.active = "";
      }
    });
    
    var act1 = true;
    this.publicityListProduct2.forEach(element => {
      if (act1) {
        element.active = "active";
        act1 = false;
      }else{
        element.active = "";
      }
      this.loading = false;
    }); 
  }
  navigateRequest(code){
    var ruta = "VistaSolicitud/"+code
    this.router.navigateByUrl(ruta);
    document.location.href = ruta+"#title"
  }

  listMasterCategories(){
    this.categoryService.masterGetAll().then(
      response=> {
        const userDB: MasterCatModel = response.records[0][0];
        this.masterCatList = userDB;
      }
    )
  }
  async listGetRequestSubCat(UUID){
    this.subMenu=true;
    this.requestList=[];
    var requests = [];
    await this.requestService.requestGetForSubCat(UUID).then(
      response=> {
        const userDB: RequestModel = JSON.parse(response).records;
        
        this.requestList = userDB;
        var contador = 0;
        this.requestList.forEach(element => {
          if (contador < 3) {
            requests.push(element)
            console.log(element);
          }
          contador++;
        });
        this.requestList = requests;
        console.log(this.requestList);
        this.requestList.forEach(element => {
          element.requestDate = element.requestDateFormat;
          element.requestSubCatUUID = element.categoryName;
        });
        if(this.requestList.length==0){
          this.catExist=true;
        }else{
          this.catExist=false;
        }
      }
    )
    
  }
 async listAllRequest(code){
    
    this.subMenu=true;
    var requests = [];
 await this.requestService.requestGetAll().then(
      (response: any) => {
        const userDB: RequestModel = response.records[0][0];
        this.requestList = userDB;
        console.log(this.requestList);
        this.requestList.forEach(element => {
          element.requestDate = element.requestDateFormat
          element.requestSubCatUUID = element.categoryName;
        });
        this.request=this.requestList.find(x => x.requestCode == code);
        var contador = 0;
        this.requestList.filter(x => x.requestMasterCatUUID === this.request.requestMasterCatUUID);
        this.requestList.sort(function() { return Math.random() - 0.5 });
        this.requestList.forEach(element => {
          if (contador < 3) {
            requests.push(element);
          }
          contador++;
        });
        this.requestList = requests;
        console.log(this.requestList);
        
        
        
        this.subCatStatus = false;
        this.image1 = '../../../../assets/images/caja.png'
        // this.image1 = this.request.requestIMG1
        this.listCategories(this.request.requestCatUUID)
        
      }
     
    )

   await this.userService.oneUser(this.userCode).then(
       (response: any) => {
        const companyName = JSON.parse(response).records[0].userCompanyName;
         if(this.request.requestContactName === companyName){
          this.deleteRequest = true;
   }
       }
    )
  }

  masterForSave(masterCatUUID){
    this.categoryService.mastercatGetOne(masterCatUUID).then(
      (response: any) => {
        const userDB = JSON.parse(response).records[0];
       
        if (userDB != undefined) {
          this.masterCatForSave = userDB.masterCatName;
        }
        
      }
    )
  }

  listCategories(masterCatUUID){
    this.clases="col-sm-9 col-md-9 col-lg-9";
    this.subcategoryList = [];
    this.subMenu=false;
    this.categoriesList= [];
    this.masterForSave(masterCatUUID);
    this.categoryService.categoryGetOne(masterCatUUID).then(
      (response: any) => {
        const userDB = JSON.parse(response).records;
        this.categoriesList = userDB;
        this.subCatStatus = false;
        
      }
    )
  }

  subcatforsave(subCatUUID){
    this.categoryService.categoryGetOneForSave(subCatUUID).then(
      (response: any) => {
        const userDB = JSON.parse(response).records[0];
        this.subCatForSave = userDB.catName;
      }
    )
  }

  listSubCat(subcatUUID){
    this.clases="col-sm-4 col-md-4 col-lg-4";
    this.subcategoryList = [];
    this.subcatforsave(subcatUUID);
    this.categoryService.subCatGetOne(subcatUUID).then(
      (response: any) => {
        
        const userDB = JSON.parse(response).records;
        this.subcategoryList = userDB;
        this.familyCatStatus = false;
      }
    )
  }


  chat(){
    try {
      this.messagesModel.chatUUID = uuidv4();
      this.messagesModel.chatUser1UUID = JSON.parse(localStorage.getItem('user')).userId; 
       this.messagesModel.chatUserSender = this.requestList[0].masterCatUUID;
       this.messagesModel.productName = this.requestList[0].requestTitle;
       this.messagesModel.chatUser2UUID = this.requestList[0].requestMasterCatUUID;
     //   this.messagesModel.chatUserReceiver = this.productsData.productCompany;
      
      this.openChat(this.messagesModel);
    } catch (error) {
      Swal.fire({
        position: 'top-end',
        icon: 'warning',
        title: 'Inicie sesión para contactar al vendedor',
        showConfirmButton: false,
        timer: 1500
      })
    }
  }

  async startChat(){ 

    try {
 
     console.log(this.request.requestCode);
      
   await  this.chatService.mainGetChatOld(JSON.parse(localStorage.getItem('user')).userId,this.request.requestUserCreate, this.request.requestCode).then(
         (companyData: any) => {
           const arr = JSON.parse(companyData)
        console.log(arr);
           if(arr.records[0].length >= 1){
             this.chatId = JSON.parse(companyData).records[0][0].chatUUID;
             this.messagesModel.chatUUID = this.chatId;
             this.createChat = false;
           }else{
             this.messagesModel.chatUUID=uuidv4();
             this.createChat = true;
           }
         }
       )
       console.log(JSON.parse(localStorage.getItem('user')));
       this.messagesModel.chatUser1UUID = JSON.parse(localStorage.getItem('user')).userId; // Usuario que envia
     this.messagesModel.chatUserSender = this.request.requestContactName; // Usuario que envia
     this.messagesModel.productName = this.request.requestTitle;
     this.messagesModel.chatUser2UUID = this.request.requestUserCreate; // Usuario que recibe
     this.messagesModel.chatUserReceiver = this.request.requestContactName;;
     this.messagesModel.productUUID = this.request.requestCode;
        this.openChat(this.messagesModel);
 
     // if (this.messagesModel.chatUUID && this.messagesModel.chatUser1UUID && this.messagesModel.chatUser2UUID) {
     //   Swal.fire({
     //     title: 'Enviar Chat',
     //     text: "Usted iniciara un chat",
     //     icon: 'warning',
     //     showCancelButton: true,
     //     confirmButtonColor: '#3085d6',
     //     cancelButtonColor: '#d33',
     //     confirmButtonText: 'Iniciar chat'
     //   }).then((result) => {
     //     if (result.isConfirmed) {
     //       this.chatService.mainMessageTableInsert(this.messagesModel).then(
     //         messageResponse => {
     //           Swal.fire({
     //             position: 'top-end',
     //             icon: 'success',
     //             title: 'Mensaje creado ve a mensajes para verlo.',
     //             showConfirmButton: false,
     //             timer: 1500
     //           })
     //         }
     //       )
     //     }
     //   })
     // }
    } catch (error) {
      Swal.fire({
        position: 'top-end',
        icon: 'warning',
       title: 'Inicie sesión para contactar al vendedor',
        showConfirmButton: false,
     timer: 1500
      })
    }
   }


   openChat(event) {
    event.empty = true;
    event.fristMessage = "";
    if(this.createChat){
      event.createChat = true
    }else{
      event.createChat= false;
    }
    this.request.productUUID = this.request.requestCode
    event.productData=this.request;
    const dialogRef = this.dialog.open(ChatDialogComponent,
      {
        data: event
      });

    dialogRef.afterClosed().subscribe(result => {
      // (`Dialog result: ${result}`);
    });
  }

  openPublicity(url){
    window.open(url+"", "_blank");
  }

deleteRequestFunction(){
    Swal.fire({
      title: "¿Está seguro de ELIMINAR su solicitud?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Aceptar',
      cancelButtonText: 'Cancelar',
      confirmButtonColor: '#3085d6'
     }).then((result)=>{
      let request = {
        pendingStatus: 0,
        requestCode: this.requestCode
    }
       if(result.isConfirmed){
         this.requestService.updateRequestState(request).then(
          (response: any) =>{
            console.log(response);
            if(response.statusCode == 200){
              Swal.fire(
                'Eliminada',
                'Solicitud eliminada satisfactoriamente',
                'success'
              )
              setTimeout(function (){
                this.router.navigateByUrl('/PortalDeSolicitudes');
             }, 1600); 
             this.router.navigateByUrl('/PortalDeSolicitudes');
            } 
          }
         )
       }
     })

}
changeImage(value){
  this.changeRequestImage = true;
    this.image1 = value;
    console.log(this.request.requestIMG1);
}

mouseEvent(event){
   this.requestService.getOneProductCategory(event).then(
    (response:any) => {
      this.categoryToShow = JSON.parse(response).records[0].productCategory;
    }
   )
}

mouseLeave(){
   this.categoryToShow= "...";
}

async showAument(event){
  console.log(event);
this.showImageZoom = true;
this.imageState = "block";
this.image =  document.querySelector(".image-details");
this.carouselImage = await document.querySelector(event);
await this.thisAument();
}

async thisAument(){
console.log(this.carouselImage);
this.image.removeAttribute("display")
 await new Drift(this.carouselImage, {
   paneContainer: this.image,
   zoomFactor: 6,
   inlinePane: false

})

}

shadowImage(){
    this.imageState = "none"
    this.carouselImage = "";
}
}
