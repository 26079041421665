import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'emailFilter'
})
export class EmailFilterPipe implements PipeTransform {
  transform(value:any,  args:any): any {
    const result = [];
     for(let i of value){
     if(i.userEmail.toLowerCase().indexOf(args.toLowerCase()) >-1){
         result.push(i);
       }
     }
      return result;
   }

}
