<div class="col-sm-12">
    <div class="container">
        <div class="container shadow p-3 bg-white boxi" *ngFor="let products of productsList | paginate: { itemsPerPage: pagination, currentPage: request }" style="cursor: pointer; margin-bottom: 1.1rem!important; margin-top: 2rem !important;" >
            <div class="row" style="height: 100% !important;" >
              <div class="col-sm-12 col-md-12 col-lg-12" style="display: flex; padding: 1rem !important;">
                <div class="col-sm-7 col-md-7 col-lg-7" (click)="navigateRequest(products.productUUID)">
                  <div class="col-sm-12 col-md-12 col-lg-12" style="margin: 5px 0 5px 0 !important;">
                    <h1 style="color: #004F9F;">{{products.productName}}</h1>
                  </div>
                  <div class="col-sm-12 col-md-12 col-lg-12" style="margin: 5px 0 5px 0 !important;">
                    <span style="color: #0078D7;text-decoration: underline;font-size: x-large;">{{products.porductCompany}} </span>
                  </div>
                  <div class="col-sm-12 col-md-12 col-lg-12" style="margin: 5px 0 5px 0 !important;">
                    <h1 style="color: #000000; font-size: small; font-weight: lighter !important;"> {{products.productCategory}}, {{products.productSubCat}}, {{products.itemFamily}}</h1>
                  </div>
                  <div class="col-sm-12 col-md-12 col-lg-12" style="margin: 10px 0 20px 0 !important;">
                    <span style="color: #898989;font-size: large;">Precio unitario: <span>{{products.productPrice}}.<span>{{request.requestBudget}}</span></span></span>
                  </div>
          
                  <div class="col-sm-12 col-md-12 col-lg-12" style="margin: 5px 0 5px 0 !important;">
                    <span style="color: #898989;font-size: large;">{{products.productDescription}}</span>
                  </div>
                </div>
                <div class="col-sm-5 col-md-5 col-lg-5" style="justify-content:right;">
                  <div class="" style="width: 100%;">
                  <div class="col-sm-12 col-md-12 col-lg-12" style="margin: 5px 0 5px 0 !important;color: #898989;text-align: right;">
                    <span> {{products.productCreateDate}} </span>
                  </div>
                  <div class="col-sm-12 col-md-12 col-lg-12" style="margin: 5px 0 5px 0 !important;color: #898989;text-align: right;">
                    <button mat-raised-button style="color: #FFFFFF;font-size: smaller!important;background-color: #004F9F;margin-top: 0.5rem!important;margin-bottom: 0.5rem!important;border-radius: 0.7rem;width: auto; max-width: 100%;white-space: nowrap; overflow: hidden; text-overflow: ellipsis;" (click)="acceptOrDeleteRequest('1', products.productId)"><i class="fas fa-check" style="width: 20px; height: 20px;"></i><span style="margin: 0.8rem!important;width: 80%;max-width: 5rem!important;">Aceptar</span> </button>
                   </div>
                   <div class="col-sm-12 col-md-12 col-lg-12" style="margin: 5px 0 5px 0 !important;color: #898989;text-align: right;">
                    <button mat-raised-button style="color: #FFFFFF;font-size: smaller!important;background-color: #0078D7;margin-top: 0.5rem!important;margin-bottom: 0.5rem!important;border-radius: 0.7rem;width: auto; max-width: 100%;white-space: nowrap; overflow: hidden; text-overflow: ellipsis;" (click)="acceptOrDeleteRequest('2', products.productId)"><i class="fas fa-times" style="width: 20px; height: 20px;"></i><span style="margin: 0.8rem!important;width: 80%;max-width: 80%!important;">Denegar</span> </button>
                   
                  </div>

                </div>
                  
                  
                </div>
                
              </div>
        
              <div class="col-sm-12 col-md-12 col-lg-12">
                <div class="row" style="font-size: smaller!important;">
                     
                </div>
              </div>
        
            </div>
        </div>
        
        <div class="container">
            <div class="row align-items-start">
              <div class="col">
                
              </div>
              <div class="col" style="font-weight: lighter;">
                <pagination-controls class="pagination" (pageChange)="request = $event" previousLabel="Anterior" nextLabel="Siguiente">  </pagination-controls>
              </div>
              <div class="col" style="text-align: end; font-weight:lighter">
               
                <span>Mostrar </span>
              <span  (click)="changePagination(16)" style="cursor: pointer;"> 16 </span>
              <span  (click)="changePagination(30)" style="cursor: pointer;">| 30 </span>
              <span  (click)="changePagination(42)" style="cursor: pointer;">| 42 </span>
        
              </div>
            </div>
          </div>
    </div>
</div>
