<app-loading-wrapper-overlay *ngIf="loading"></app-loading-wrapper-overlay>
<div class="dashboard-container">
    <div class="col-sm-12">
        <div class="container">
            <div class="row">
              <div class="col-sm-12">
                <div class="card" style="width: 79%; margin-bottom: 40px;">
                <div class="row">
                  <div class="col-sm-12" id="title-date--container">{{dateFormatToShow}}</div>
                  <div class="col-sm-6" id="date-picker--container">
                    <mat-form-field appearance="fill">
                      <mat-label>Ingrese un rango de fechas</mat-label>
                      <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
                        <input matStartDate formControlName="start" placeholder="Start date">
                        <input matEndDate formControlName="end" placeholder="End date">
                      </mat-date-range-input>
                      <mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint>
                      <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                      <mat-date-range-picker #picker></mat-date-range-picker>
                    
                      <mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid')">Invalid start date</mat-error>
                      <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')">Invalid end date</mat-error>
                    </mat-form-field>
                  </div>
                  <div class="col-sm-6" id="button-container">
                    <input type="button" class="button" value="Seleccionar rango de fechas" (click)="inputDate(range.value)" >
                  </div>
                </div>
              </div>
                 
                
              </div>

                <div class="col-sm-6">
                  <div class="product-title">Visitas totales (cigmatch.com):</div>
                  <div class="space-decoration"></div>
                 <div class="card">
                        <div class="product-count" style="height: 80px; text-align: center;">
                          <i class="fas fa-users"></i>{{totalHomeVisited}}
                        </div>
                 </div>
                </div>
                <div class="col-sm-6">
                          <div class="title">Visitas únicas (cigmatch.com): </div>
                          <div class="space-decoration"></div>
                    <div class="card">
                        <div class="count" style="height: 80px; text-align: center;">
                          <i class="fas fa-user"></i>{{uniqueVisits}}
                        </div>
                    </div>
                </div>
                <div class="col-sm-6">
                  <div class="title" style="margin-top: 40px;"># de productos marcados como favoritos: </div>
                  <div class="space-decoration" style="height:2px"></div>
            <div class="card">
                <div class="count" style="height: 80px; text-align: center;">
                  <i class="fas fa-bookmark" style="margin-right: 12px"></i>{{favoriteProducts}}
                </div>
            </div>
        </div>
        <div class="col-sm-6">
          <div class="title" style="margin-top: 40px;" *ngIf="!rangeDateSearch">Productos publicados en la última semana: </div>
          <div class="title" style="margin-top: 40px;" *ngIf="rangeDateSearch">Productos publicados: </div>
          <div class="space-decoration" style="height:2px"></div>
    <div class="card">
        <div class="count" style="height: 80px; text-align: center;">
          <i class="fas fa-shopping-bag" style="margin-right: 12px"></i>{{productsLast7Day}}
        </div>
    </div>
</div>

  <div class="col-sm-6">
                  <div class="title" style="margin-top: 40px;" *ngIf="!rangeDateSearch">Solicitudes publicadas en la última semana: </div>
                  <div class="title" style="margin-top: 40px;" *ngIf="rangeDateSearch">Solicitudes publicadas: </div>
                  <div class="space-decoration" style="height:2px"></div>
            <div class="card">
                <div class="count" style="height: 80px; text-align: center;">
                  <i class="fas fa-exchange-alt" style="margin-right: 12px"></i>{{requestsLast7Days}}
                </div>
            </div>
        </div>
        <div class="col-sm-6">
          <div class="title" style="margin-top: 40px;" *ngIf="!rangeDateSearch">Usuarios nuevos en la última semana: </div>
          <div class="title" style="margin-top: 40px;" *ngIf="rangeDateSearch">Usuarios nuevos: </div>
          <div class="space-decoration" style="height:2px"></div>
    <div class="card">
        <div class="count" style="height: 80px; text-align: center;">
          <i class="fas fa-exchange-alt" style="margin-right: 12px"></i>{{usersLast7Days}}
        </div>
    </div>
</div>
<!-- <div class="col-sm-6">
  <div class="title" style="margin-top: 40px;" *ngIf="!rangeDateSearch">Empresas nuevas en la última semana: </div>
  <div class="title" style="margin-top: 40px;" *ngIf="rangeDateSearch">Empresas nuevas: </div>
  <div class="space-decoration" style="height:2px"></div>
<div class="card">
<div class="count" style="height: 80px; text-align: center;">
  <i class="fas fa-exchange-alt" style="margin-right: 12px"></i>{{companyLast7Days}}
</div>
</div>
</div> -->
        <div class="col-sm-12">
          <div class="title" style="margin-top: 40px;">Comportamiento de chats : </div>
          <div class="space-decoration" style="height:2px"></div>
          <div class="card">
            <div class="col">
               <table class="table" style="text-align: center;">
                <thead>
                  <tr class="title-container">
                    <th scope="col">Estatus</th>
                    <th scope="col">Cantidad</th>
                  </tr>
              </thead>
              <tbody>
                  <tr  *ngFor="let messages of chatStatusList let index = index" class="th-container">
                    <th scope="row" class="row-table" *ngIf="messages.chatStatus == ''" (click)="getOneChatStatus('unread')">No leídos</th>
                    <th scope="row" class="row-table" *ngIf="messages.chatStatus == 'contact'" (click)="getOneChatStatus('contact')">Leídos</th>
                    <th scope="row" class="row-table" *ngIf="messages.chatStatus == 'qualifying'"(click)="getOneChatStatus('qualifying')">En proceso de calificación</th>
                    <th scope="row" class="row-table" *ngIf="messages.chatStatus == 'finished'"(click)="getOneChatStatus('finished')">Finalizados</th>
                    <th scope="row" class="row-table" *ngIf="messages.chatStatus == 'deleted'"(click)="getOneChatStatus('deleted')">Eliminados</th>
                    <th scope="row">{{messages["COUNT(*)"]}}</th>
                  </tr>
              </tbody>
               </table>
            </div>
            </div>
          <div class="card" style="margin-top: 45px;">
        <div class="col" style="text-align: center;" *ngIf="getStatus">
           <table class="table">
            <thead>
              <tr class="title-container">
                <th scope="col">Emisor</th>
                <th scope="col">Receptor</th>
                <th scope="col">Producto</th>
                <th scope="col" *ngIf="statusOption == 'finished'">Calificación</th>
              </tr>
          </thead>
          <tbody>
              <tr  *ngFor="let messages of finishedMessages let index = index">
                <th scope="row">{{messages.chatUserSender}}</th>
                <th scope="row">{{messages.chatUserReceiver}}</th>
                <th scope="row"> {{messages.productName}}</th>
                <th scope="row" *ngIf="statusOption == 'finished'"> {{messages.chatQualification}}</th>
              </tr>
          </tbody>
           </table>
        </div>
        </div>
      </div>

    <div class="col-sm-12">
      <div class="title" style="margin-top: 40px;">Comportamiento de productos : </div>
      <div class="space-decoration" style="height:2px"></div>
  <div class="card">
    <div class="col">
      <table class="table">9
        <thead>
          <tr class="title-container">
            <th scope="col">Empresa</th>
            <th scope="col">Producto</th>
            <th scope="col">Interacciones</th>
            <th scope="col">Estatus</th>
          </tr>
      </thead>
      <tbody>
          <tr  *ngFor="let product of productsLastDaysInfo let index = index">
            <th scope="row">{{product.productCompany}}</th>
            <th scope="row">{{product.productName}}</th>
            <th scope="row" style="text-align: center"> {{product.productSearch}}</th>
            <th scope="row" *ngIf="product.productDelete == 0">Activo</th>
            <th scope="row" *ngIf="product.productDelete == 1">Eliminado</th>
          </tr>
      </tbody>
       </table>
    </div>
    </div>
  </div>
  <div class="col-sm-12" *ngIf="rangeDateSearch">
    <div class="title" style="margin-top: 40px;">Detalles de empresas nuevas : </div>
    <div class="space-decoration" style="height:2px"></div>
<div class="card">
  <div class="col">
    <table class="table">
      <thead>
        <tr class="title-container">
          <th scope="col">Nombre</th>
          <th scope="col">Correo</th>
          <th scope="col">NIT</th>
        </tr>
    </thead>
    <tbody>
        <tr  *ngFor="let company of companyList let index = index">
          <th scope="row">{{company.companyName}}</th>
          <th scope="row">{{company.companyEmail}}</th>
          <th scope="row"> {{company.companyNIT}}</th>

        </tr>
    </tbody>
     </table>
  </div>
  </div>
</div>
<div class="col-sm-12" *ngIf="rangeDateSearch">
  <div class="title" style="margin-top: 40px;">Detalles de usuarios nuevos : </div>
  <div class="space-decoration" style="height:2px"></div>
<div class="card">
<div class="col">
  <table class="table">
    <thead>
      <tr class="title-container">
        <th scope="col">Nombre</th>
        <th scope="col">Apellido</th>
        <th scope="col">Correo</th>
        <th scope="col">Rol</th>
      </tr>
  </thead>
  <tbody>
      <tr  *ngFor="let user of userList let index = index">
        <th scope="row">{{user.userFirstName}}</th>
        <th scope="row">{{user.userLastName}}</th>
        <th scope="row">{{user.userEmail}}</th>
        <th scope="row"> {{user.userRole}}</th>

      </tr>
  </tbody>
   </table>
</div>
</div>
</div>
    <!-- <div class="col-sm-12">
      <div class="title" style="margin-top: 40px;">Comportamiento de solicitudes : </div>
      <div class="space-decoration" style="height:2px"></div>
  <div class="card">
    <div class="col">
      <table class="table">
        <thead>
          <tr class="title-container">
            <th scope="col">Empresa</th>
            <th scope="col">Solicitud</th>
             <th scope="col">Interacciones</th>
          </tr>
      </thead>
      <tbody>
          <tr  *ngFor="let request of requestLastDaysInfo let index = index">
            <th scope="row">{{request.requestContactName}}</th>
            <th scope="row">{{request.requestTitle}}</th>
  
          </tr>
      </tbody>
       </table>
    </div>
    </div>
    <div class="card">
      <div class="col">
        <table class="table">
          <thead>
            <tr class="title-container">
              <th scope="col">Empresa</th>
              <th scope="col">Interacciones</th>
            </tr>
        </thead>
        <tbody>
            <tr  *ngFor="let interactions of requestEvents let index = index">
              <th scope="row">{{interactions}}}</th>
            </tr>
        </tbody>
         </table>
      </div>
      </div>
  </div> -->
                </div>
                <div class="row">
          
                    <div class="col" style="margin-top: 40px;">
                      <table class="table">
                          <thead>
                            <tr class="title-container">
                              <th scope="col"></th>
                              <th scope="col">Usuario</th>
                              <th scope="col">IP usuario</th>
                              <th scope="col">Evento</th>
                              <th scope="col">Cantidad</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr *ngFor="let users of userVisitData | paginate: { itemsPerPage: 15, currentPage: users}">
                              <th scope="row">{{users.id_interaction}}</th>
                              <th scope="row">{{users.user_name}}</th>
                              <th scope="row">{{users.user_ip}}</th>
                              <th scope="row">{{users.route_name}}</th>
                              <th scope="row">{{users.num}}</th>
                            </tr>
  
                          </tbody>
                        </table>
                        <div class="col-sm-12" style="text-align:right">
                          <div class="controlPage">
                            <pagination-controls (pageChange)="users = $event" style="color: #004f9f;"></pagination-controls>
                          </div>
                        </div>
                    
                      <div class="col">
  
                      </div>
                      <div class="col">
  
                      </div>
                  </div>
              
   
              
            </div>
           
        </div>
    </div>
</div>