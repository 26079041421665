import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { Location } from '@angular/common';
import { UserModel } from 'src/app/models/userModel';
import { MessageCenterService } from 'src/app/services/messageCenter.service';
import { timeStamp } from 'console';
import * as internal from 'stream';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  userRole: string;
  isLogged: boolean;
  isCollapsed = true;
  userUUID: String;
  messageReceived: any;
  userLog=true;
  actualRoute;
  directory: String;
  productView: String;
  portalRequest:String;
  profile: Boolean;
  requestView: String;
  newRequest: String;
  newProduct: String;
  messageProfile: Boolean;
  category:String;
  constructor(
    private authService: AuthService,
    private router: Router,
    private location: Location,
    private messages: MessageCenterService
  ) {
  

    }

  async ngOnInit() {
    try {
      JSON.parse(localStorage.getItem('user')).userId;
     } catch (error) {
       this.userLog = false;
     }
    let user = {} as UserModel;
    await this.authService.authStatusChanged.subscribe(authenticated => {
      this.isLogged = authenticated;
    })
    this.authService.userRole.subscribe(userRoleApp => {
      this.userRole = userRoleApp;
    })
    if (localStorage.getItem('user')) {
      user = JSON.parse(localStorage.getItem('user'));
      this.userRole = user.userRole;
      this.userUUID = JSON.parse(localStorage.getItem('user')).userId;
      
    }
    this.authService.initAuth();
    this.mainMessages();
    this.pagelocation();
  }

  logout(){
    this.authService.logout();
  }
  home(){
    this.router.navigate([''])
  .then(() => {
    window.location.reload();
  });
  }
  productsD(){
    this.router.navigate(['/Productos/favoritos'])
  .then(() => {
    window.location.reload();
  });
  }

  mainMessages(){
      this.messages.mainMessageTableGet(this.userUUID).then(
      (response:any) =>{
          var resp = JSON.parse(response).records
          var contador = [];
          resp.forEach(x => {    
            if(x.messageStatus != null && x.messageUserReceiver == this.userUUID  && x.messageStatus == "recibido"){
                contador.push(x.messageStatus)
            }
             
          });
          this.messageReceived = contador.length;
        }
      )
  }

  pagelocation(){
    this.router.events.subscribe(e => {
      if(e instanceof NavigationEnd){
        this.actualRoute =  e.url;
        this.directory = this.actualRoute.includes('DirectorioEmpresarial');
        this.productView = this.actualRoute.includes('VistaProducto');
        this.portalRequest = this.actualRoute.includes('PortalDeSolicitudes');
        this.profile = this.actualRoute.includes('/MiPerfil');
        this.requestView = this.actualRoute.includes('VistaSolicitud');
        this.newRequest = this.actualRoute.includes('NuevaSolicitud');
        this.newProduct = this.actualRoute.includes('RegistroDeProducto');
        this.messageProfile = this.actualRoute.includes('Mensajes');
        this.category = this.actualRoute.includes('Categor%C3%ADas');
      }
    })
  }

  navigateToLogin(){
    if(localStorage.getItem('user') != null){
       localStorage.clear();
       this.router.navigate(['/Login'])

    }
  }

}
