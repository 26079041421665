<app-loading-wrapper-overlay *ngIf="loading"></app-loading-wrapper-overlay>
<div>
  <mat-card class="targetProduct" style="width: 86%;">
    <mat-card-content>
      <div class="row">
            <div class="col-sm-2">
              <img src="../../../../assets/images/core/avatarMessage.png" style="width: 100%;">
            </div>
            <div class="col-sm-1">

            </div>
            <div class="col-sm-7">
               <div class="row">
                  <div class="col-sm-12">
                    <h1 style="color: #004F9F;font-weight: bolder;font-size: xx-large;">{{user.userFirstName + " " + user.userLastName}}<img src="../../../../assets/images/core/Insignia.png" style="width: 2.3rem;height: 2.5rem;margin-left: 0.8rem;margin-top: -10px!important;"></h1>
                  </div>
                  <div class="col-sm-12">
                      <h2 style="color: #0078D7;font-weight:300;font-size:x-large;text-decoration: underline;">{{companyData.companyCategory}}</h2>
                  </div>
                  <div class="col-sm-12">
                    <h3 style="color: #F58629;font-weight:400;font-size:1.3rem;margin-top: -10px!important;">{{date}}</h3>
                </div>
               </div>
            </div>
      </div>
    </mat-card-content>
  </mat-card>

  <div class="row" style="margin-left: 7%!important;margin-right: 7%!important;margin-top: 4rem!important;">
      <div class="col-sm-6" style="padding-right: 1.9rem!important;">
        <mat-card class="target">
          <mat-card-content>
            <div class="row">
                <div class="col-sm-12">
                  <h3 style="color: #004F9F;font-weight:800;font-size:1.7rem;">Mi información: </h3>
                </div>
                <div class="col-sm-12" >
                  <span style="color: #898989;font-weight:500;font-size:1.2rem;">Correo Electrónico: <span style="color: #0078D7;font-weight:500;font-size:1.2rem;"> {{companyData.userEmail}}</span></span>
                </div>
                <div class="col-sm-12" style="margin-top: 1rem;">
                  <span style="color: #898989;font-weight:500;font-size:1.2rem;">Teléfono: <span style="color: #0078D7;font-weight:500;font-size:1.2rem;"> {{companyData.companyPhone1}}</span></span>
                </div>
                <div class="col-sm-12" style="margin-top: 1rem;">
                  <span style="color: #898989;font-weight:500;font-size:1.2rem;">Rol: <span style="color: #0078D7;font-weight:500;font-size:1.2rem;"> {{companyData.userRole}}</span></span>
                </div>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
      <div class="col-sm-6" style="padding-left:1.9rem!important; margin-bottom: 3rem!important;">
          <mat-card class="target">
            <mat-card-content>
              <div class="row">
                <div class="col-sm-12">
                  <h3 style="color: #004F9F;font-weight:800;font-size:1.7rem;">Información de Compañía en CIG:</h3>
                </div>
                <!-- <div class="col-sm-12">
                  <span style="color: #898989;font-weight:500;font-size:1.2rem;">Gremial(es): <span style="color: #0078D7;font-weight:500;font-size:1.2rem;">{{companyData.companyEmail}}</span></span>
                </div>
                <div class="col-sm-12" style="margin-top: 1rem;">
                  <span style="color: #898989;font-weight:500;font-size:1.2rem;">Categorias: <span style="color: #0078D7;font-weight:500;font-size:1.2rem;">{{companyData.companyCategory}}</span></span>
                </div> -->
                <div class="col-sm-12" style="margin-top: 0rem;">
                  <span style="color: #898989;font-weight:500;font-size:1.2rem;">Directorio empresarial: <span style="color: #0078D7;font-weight:500;font-size:1.2rem;"> <a href="/VistaEmpresa/{{companyData.companyBussinesCode}}">{{companyData.companyName}}</a></span></span>
                </div>
                <div class="col-sm-12" style="margin-top: 1.2rem;">
                  <span style="color: #898989;font-weight:500;font-size:1.2rem;">Sitio Web: <span style="color: #0078D7;font-weight:500;font-size:1.2rem;"><a href="https://www.{{companyData.companySite}}">{{companyData.companySite}}</a></span></span>
                </div>
                <div class="col-sm-12" style="margin-top: 1.2rem;">
                  <span style="color: #898989;font-weight:500;font-size:1.2rem;">Dirección: <span style="color: #0078D7;font-weight:500;font-size:1.2rem;"> {{companyData.companyAddress}}</span></span>
                </div>
            
            </div>
            </mat-card-content>
          </mat-card>
      </div>
  </div>

<div class="row">
  <div class="col-sm-12 publicity" >
    <!-- publicidad -->
    <div style="margin-left: 30%!important;margin-right: 30%!important;">
      <div id="my" class="carousel 1carousel-fade img-details" data-bs-ride="carousel" data-bs-interval="4000" style="height:auto">
        <div class="carousel-inner">
            <div class="carousel-item {{publicity1.active}}" style="text-align: center;" *ngFor="let publicity1 of publicityMyProfile"> <img [src]="publicity1.publicityBanner" class="imgPublicity"> </div>
          </div>   
      </div>
    </div>
    <!-- fin de publicidad -->
  </div>
</div>
<div style="min-width: 84% !important; max-width: 84% !important; text-align-last: left; margin-bottom: 1rem; margin-top: 1rem;margin-left: 7%!important;margin-right: 7%!important;" *ngIf="!loading">
  <h1 style="color: #004F9F; text-align: left; margin-bottom: 0% !important; font-size: 2rem;font-weight: 700;">Mis Productos</h1>
  <hr style="margin-top: 1rem; height: 2px;background-color: #F58626;opacity: 100%;margin-right: 3rem;">

  <div class="myProducts-container">
    <div class="row">
      <div style="background-color: #FFFFFF;height: 5rem;position: absolute;width: 5%;left: 93%;margin-top: 10%;text-align: center;" id="nextPE"   (click)="nextProduct()" *ngIf="this.productsListFinishedEnable">
        <i class="fa fa-chevron-right" aria-hidden="true" id="nextPE"></i>
      </div>
      <div style="background-color: #FFFFFF;height: 5rem;position: absolute;width: 5%;left: 1%;margin-top: 10%;text-align: center;" id="prevPE" (click)="prevProduct()" *ngIf="this.productsListStartEnable">
        <i class="fa fa-chevron-left" aria-hidden="true" id="prevPE"></i>
      </div>
      <div class="col-md-4" *ngFor="let products of productsList" style="padding: 1.5rem!important;">
        <div class="card h-100" id="cards"  style="border: none;padding: 0.5rem;cursor: pointer;box-shadow: 0 6px 20px rgba(71, 71, 71, 0.08), 0 0 6px rgba(71, 71, 71, 0.08);padding-bottom: 0%!important;" >
         <div class="edit-container" >
             <section (click) ="deleteProduct(products)" style="margin-right: 20px">
          <i class="fas fa-trash-alt"></i>
         </section>
          <section (click)="openEditDialogComponent(products)">
            <i class="fas fa-edit" ></i>
          </section>
         </div>
         <section class="cart-info--container" (click)="viewProduct(products.productUUID)">

          <div style="width: 100%;padding: 1rem;height: 12rem;">
            <img [src]='products.productMainPicture' alt="..." style="width: 100%;height: 12rem;">
          
          </div>
            <div class="card-body" style="margin-bottom: 0%!important;padding-top: 1.6rem!important;padding-left: 1.5rem; padding-right: 1.5rem;">
              <div class="row">
                
                <div class="col-sm-7">
                  <span style="color: #004F9F;font-size: 0.8rem;font-weight: 500;line-height: 0!important;">{{products.productName}}</span>
                </div>
                <div class="col-sm-5">
                  <span class="float-end" style="font-weight: 700;" *ngIf="1 * products.productPrice > 0 ">{{products.unitPriceType}} {{products.productPrice}}</span> 
                </div>
                
                
              </div>
            </div>
            <div class="col-sm-12" style="text-align: right;color: #9c9c9c;font-size: 0.7rem;bottom: 0;margin-bottom: 1rem;margin-right: 1.5rem;font-family: 'Arial'; padding-left: 1.5rem;">
              <small>{{products.productCategory}}</small>
            </div>
         </section>
        </div>
      </div>
      
  </div>
 
  </div>
  
</div>
