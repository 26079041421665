<app-loading-wrapper-overlay *ngIf="loading"></app-loading-wrapper-overlay>
<mat-card>
  <mat-card-content>
    <div class="col-sm-12">
      <div class="container" style="min-width: 100% !important;">
        <div class="col-sm-12" style="height: auto!important; margin-bottom: 20px;">
          <div class="container">
            <div class="row">
            <div class="col-sm-12">
              <input type="radio" class="radio" name="emails"  value="1" (click)="searchUsers()">Todos los usuarios
              <input type="radio" class="radio" name="emails" value="2" (click)="searchEmailByRole('Usuario CIG Compras')">Usuarios CIG Compras
              <input type="radio" class="radio" name="emails" value="3" (click)="searchEmailByRole('Usuarios CIG Mix (Compra y venta)')"> Usuarios CIG Mix (Compra y venta)
              <input type="radio" class="radio" name="emails" value="4" (click)="searchEmailByRole('USUARIOMIX')">USUARIOMIX
            </div>
            <div class="col-sm-12" style="height: auto !important;" *ngIf="this.disabledAll">
              <div class="text-select" id="textCopy">
                <textarea class="text-area">{{usersEmailsToShow}}</textarea>
                <p></p>
               </div>
  
            </div>
            <div class="col-sm-12" *ngIf="this.disabledAll">
              <div class="col-sm-12" class="alert" style="margin-bottom: 10px;" *ngIf="disabledAlert">
                <div class="alert alert-success d-flex align-items-center" role="alert">
                  <i class="fas fa-check-circle" style="margin-right: 12px;"></i>
                  <div>
                    Correos electrónicos copiados al portapapeles
                  </div>
                </div>
              </div>
              <button type="submit" class="copy-emails" (click)="showActiveUsers()">Copiar correos</button>
            </div>
            <div class="col-sm-12" style="text-align: center;">
              <button type="submit" class="copy-emails" (click)="download()" style="margin-top: 12px; width: 30%;">Descargar datos de usuarios en formato .xlsx</button>
            </div>
          </div>
          </div>
        </div>
        <div class="table-responsive">
          <!-- <div class="row">
            <div class="col-sm-6">

            </div>
            <div class="col-sm-6">
              <span>Ingrese la Clave única:</span>
              <input type="text" [(ngModel)]= "pass">
              <button (click)="showPassword()">Mostrar Contraseñas</button>
            </div>
            
          </div> -->
          <table class="table table-responsive table-borderless" style="background-color: white; margin-bottom: 0.5px;">
            <thead>
              <tr class="bg-light" style="text-align: -webkit-center;">
                  <th scope="col" width="5%">#</th>
                  <th scope="col" width="20%">Nombre</th>
                  <th scope="col" width="20%">Correo</th>
                  <th scope="col" width="20%">Rol</th>
                  <th scope="col" width="20%">Empresa</th>
                  <th scope="col" width="20%" *ngIf="userRole === 'Master'">Ver Contraseña</th>
                  <th scope="col" width="20%" *ngIf="userRole === 'Master'"></th>
              </tr>
               </thead>    
            <thead>
                    <tr class="bg-light" style="text-align: -webkit-center;">   
                        <th scope="col" width="20%"></th>
                        <th scope="col" width="20%"><input type="text" class="form-control" style="width: 60%;" [(ngModel)] = "searchUser" autocomplete="off"></th>
                        <th scope="col" width="20%"><input type="text" class="form-control" style="width:60%" [(ngModel)]="searchEmail" autocomplete="off"></th>
                        <th scope="col" width="20%"><input type="text" class="form-control" style="width:60%" [(ngModel)]="searchRole" autocomplete="off"></th>
                        <th scope="col" width="20%"><input type="text" class="form-control" style="width:60%" [(ngModel)]="searchBusiness" autocomplete="off"></th>
                        <th scope="col" width="20%"></th>
                        <th scope="col" width="20%"></th>
                        
                    </tr>
                </thead>
                <tbody *ngIf = "searchUser == '' && searchEmail== '' && searchBusiness == '' && searchRole == '' ">
                    <tr style="text-align: -webkit-center;" *ngFor="let p of userList | paginate: { itemsPerPage: 15, currentPage: p }">
                        <td>{{p.userCode}}</td>
                        <td>{{p.userFirstName + ' ' + p.userLastName}}</td>
                        <td>{{p.userEmail}}</td>
                        <td >{{p.userRole}}</td>
                        <td >{{p.userCompanyName}}</td>
                        
                        <td style="cursor: pointer;" (click)="showPassword(p.userPassword)" *ngIf="userRole === 'Master'">
                          <!-- <input [type]="show ? 'text' : 'password'"  [(ngModel)]= "p.userPassword"/> -->
                          <i class="fa fa-eye" ></i>
                          
                        </td>
                        <td style="cursor: pointer;" (click)="editPassword(p)" *ngIf="userRole === 'Master'">
                          <!-- <input [type]="show ? 'text' : 'password'"  [(ngModel)]= "p.userPassword"/> -->
                          <!-- <i class="fa fa-eye" ></i> -->
                          <a style="text-decoration:underline;">Cambiar Contraseña</a>
                          
                        </td>
                    </tr>
                </tbody>
                <tbody *ngIf = "searchUser != '' && searchEmail == '' && searchRole == '' && searchBusiness == '' " >
                   <tr style="text-align: -webkit-center;" *ngFor="let p of userList | usersFilter: searchUser ">
                      <td>{{p.userCode}}</td>
                      <td>{{p.userFirstName+ ' ' + p.userLastName}}</td>
                      <td>{{p.userEmail}}</td>
                      <td>{{p.userRole}}</td>
                      <td >{{p.userCompanyName}}</td>
                      <td style="cursor: pointer;" (click)="showPassword(p.userPassword)">
                        <i class="fa fa-eye" ></i>
                        
                      </td>
                  </tr>
              </tbody>
              <tbody *ngIf = "searchEmail != ''">
                <tr style="text-align: -webkit-center;" *ngFor="let p of userList | emailFilter: searchEmail ">
                   <td>{{p.userCode}}</td>
                   <td>{{p.userFirstName+ ' ' + p.userLastName}}</td>
                   <td>{{p.userEmail}}</td>
                   <td>{{p.userRole}}</td>
                   <td >{{p.userCompanyName}}</td>
                   <td style="cursor: pointer;" (click)="showPassword(p.userPassword)">
                     <i class="fa fa-eye" ></i>
                     
                   </td>
                   <td style="cursor: pointer;" (click)="editPassword(p)" *ngIf="userRole === 'Master'">
                    <!-- <input [type]="show ? 'text' : 'password'"  [(ngModel)]= "p.userPassword"/> -->
                    <!-- <i class="fa fa-eye" ></i> -->
                    <a style="text-decoration:underline;">Cambiar Contraseña</a>
                    
                  </td>
               </tr>
           </tbody>
           <tbody *ngIf = "searchBusiness != ''">
            <tr style="text-align: -webkit-center;" *ngFor="let p of userList | businessFilter: searchBusiness ">
               <td>{{p.userCode}}</td>
               <td>{{p.userFirstName+ ' ' + p.userLastName}}</td>
               <td>{{p.userEmail}}</td>
               <td>{{p.userRole}}</td>
               <td >{{p.userCompanyName}}</td>
               <td style="cursor: pointer;" (click)="showPassword(p.userPassword)">
                 <i class="fa fa-eye" ></i>
                 
               </td>
               <td style="cursor: pointer;" (click)="editPassword(p)" *ngIf="userRole === 'Master'">
                <!-- <input [type]="show ? 'text' : 'password'"  [(ngModel)]= "p.userPassword"/> -->
                <!-- <i class="fa fa-eye" ></i> -->
                <a style="text-decoration:underline;">Cambiar Contraseña</a>
                
              </td>
           </tr>
       </tbody>
            </table>
            <div class="controlPage">
              <pagination-controls (pageChange)="p = $event" style="color: white;"></pagination-controls>
            </div>
        </div>
    </div>
    </div>
  </mat-card-content>
</mat-card>
