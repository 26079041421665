
// //QA
// export const environment = {
//   production: false,
//   UserPoolId: 'us-west-2_NmOzkcISM',
//   ClientId: '5kicunvkfde5l2272j69ntmhur',
//   API_URL: 'https://j7qyhf54ya.execute-api.us-west-2.amazonaws.com/qa/',
//   accessKeyId: 'AKIARYAAIDG6VOSEDTJR',
//   secretAccessKey: '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',
//   region: 'us-west-2',
//   EnvironmentName: 'cigmatchbackend',
//   bucketFiles: 'cigmatchbackend-qa-files',
//   bucketNameApp: 'cigmatchbackend-qa-myapp',
//   regionSES: 'us-west-2',
//   emailSES: 'https://d1n78jf1fuh5vd.cloudfront.net/',
//   emailImage: 'https://d1n78jf1fuh5vd.cloudfront.net/assets/images/emailLogo.jpg',
//   cloudFrontUrl: 'https://d1n78jf1fuh5vd.cloudfront.net/',
//   identityPoolId: 'us-west-2:8cae88ab-e0f7-4856-8b01-d07141660a63',
//   cognitoDomain: 'cigmatchbackend-qa.auth.us-west-2.amazoncognito.com',
//   cognitoRedirectSignIn: 'http://localhost:8083/',
//   cognitoRedirectSignOut: 'http://localhost:8083/',
//   cognitoResponseType: 'code',
//   downloadObjectUrl: 'https://cigmatchbackend-prod-files.s3.us-west-2.amazonaws.com/',
//   putIcon:'https://cigmatchbackend-qa-files.s3.us-west-2.amazonaws.com',
//   devsite: 'https://myappb2b.net/',
// };
//   accessKeyId: 'AKIARYAAIDG66QXTVP5I',
//   secretAccessKey: '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',

//Producción
export const environment = {
  production: true,
  UserPoolId: 'us-west-2_9tUKELNWY',
  ClientId: '6ulvu7avvtt3jc00713ub7emgh',
  API_URL: 'https://aweysvh7m5.execute-api.us-west-2.amazonaws.com/prod/',
  accessKeyId: 'AKIARYAAIDG6VOSEDTJR',
  secretAccessKey: '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',
  region: 'us-west-2',
    EnvironmentName: 'cigmatchbackend',
  bucketFiles: 'cigmatchbackend-prod-files',
  bucketNameApp: 'cigmatchbackend-prod-myapp',
  regionSES: 'us-west-2',
  emailSES: 'https://d1n78jf1fuh5vd.cloudfront.net/',
  emailImage: 'https://d1n78jf1fuh5vd.cloudfront.net/assets/images/emailLogo.jpg',
  cloudFrontUrl: 'https://d1n78jf1fuh5vd.cloudfront.net/',
  downloadObjectUrl: 'https://cigmatchbackend-prod-files.s3.us-west-2.amazonaws.com/',
   putIcon:'https://cigmatchbackend-prod-files.s3.us-west-2.amazonaws.com',
  identityPoolId: 'us-west-2:fea4afca-75f4-4d71-a51a-5aed4c9475f1',
  cognitoDomain: 'cigmatchbackend-prod.auth.us-west-2.amazoncognito.com',
  cognitoRedirectSignIn: 'http://localhost:8083/',
  cognitoRedirectSignOut: 'http://localhost:8083/',
  cognitoResponseType: 'code',
  idGoogleTag: 'G-JPMTSMZ997',
  devsite: 'https://cigmatch.com/',
};


//dev
// export const environment = {
//   production: false,
//   UserPoolId: 'us-east-1_1X4CZj0aC',
//   ClientId: '37rv721l8saf2h2uuabv1a9dcl',
//   API_URL: 'https://modyp9tjsi.execute-api.us-east-1.amazonaws.com/dev/',
//   accessKeyId: 'AKIAV42YBFE67NGAGB6M',
//   secretAccessKey: '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',
//   region: 'us-east-1',
//   bucketName: 'mb2b-dev-files',
//   bucketNameApp: 'cigmatchbackend-dev-myapp',
//   bucketFiles: 'cigmatchbackend-dev-files',
//   regionSES: 'us-east-1',
//   EnvironmentName: 'cigmatchbackend',
//   emailSES: 'https://d2lmdj51omcndh.cloudfront.net/',
//   emailImage: 'https://d2lmdj51omcndh.cloudfront.net/assets/images/emailLogo.jpg',
//   cloudFrontUrl: 'https://d2lmdj51omcndh.cloudfront.net/',
//   downloadObjectUrl: 'https://cigmatchbackend-prod-files.s3.us-west-2.amazonaws.com/',
//   identityPoolId: 'us-east-1:df6d613f-2adb-493e-90d7-8365941f1a5b',
//   cognitoDomain: 'cigmatchbackend-dev.auth.us-east-1.amazoncognito.com',
//   cognitoRedirectSignIn: 'http://localhost:8083/',
//   cognitoRedirectSignOut: 'http://localhost:8083/',
//   cognitoResponseType: 'code',
//   idGoogleTag: 'G-T03HPQSH2W',
//   devsite: 'https://myappb2b.net/',
// };



// accessKeyId: 'AKIARYAAIDG6WGWK7Z4J',
//   secretAccessKey: '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',

// //Grupo Ermita
// export const environment = {
//   production: true,
//   UserPoolId: 'us-east-1_6XptrpI7m',
//   ClientId: '6ulvu7avvtt3jc00713ub7emgh',
//   API_URL: 'https://tnbek2lw1j.execute-api.us-east-1.amazonaws.com/dev/',
//   accessKeyId: 'AKIAV42YBFE6X2BDWQXG',
//   secretAccessKey: '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',
//   region: 'us-east-1',
//   bucketFiles: 'grupoermita-dev-files',
//   bucketNameApp: 'grupoermita-dev-myapp',
//   regionSES: 'us-east-1',
//   emailSES: 'https://d2ycz7okp1prq1.cloudfront.net/',
//   emailImage: 'https://d2ycz7okp1prq1.cloudfront.net/assets/images/emailLogo.jpg',
//   cloudFrontUrl: 'https://d2ycz7okp1prq1.cloudfront.net/',
//   identityPoolId: 'us-east-1:f591f11e-0bf4-4ce7-81c9-d33cca15dab2',
//   cognitoDomain: 'grupoermita-dev.auth.us-east-1.amazoncognito.com',
//   cognitoRedirectSignIn: 'http://localhost:8083/',
//   cognitoRedirectSignOut: 'http://localhost:8083/',
//   cognitoResponseType: 'code',
//   devsite: 'https://cigmatch.com/',
// };
  
