import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'roleFilter'
})
export class RoleFilterPipe implements PipeTransform {

  transform(value: any, args: any):any {
      const result = [];
       for(let i of value){
       if(i.userRole.toLowerCase().indexOf(args.toLowerCase()) >-1){
           result.push(i);
         }
       }
        return result;
  }

}
