import { PipeCollector } from '@angular/compiler/src/template_parser/binding_parser';
import { Component, OnInit } from '@angular/core';
import { getMatIconFailedToSanitizeLiteralError } from '@angular/material/icon';
import { Router } from '@angular/router';
import { CategoryModel } from 'src/app/models/categoryModel';
import { CompanyModel } from 'src/app/models/companyModel';
import { MasterCatModel } from 'src/app/models/msterCatModel';
import { SubCategoryModel } from 'src/app/models/subcategoryModel';
import { CategoryService } from 'src/app/services/category.service';
import { CompanyService } from 'src/app/services/company.services';
import { PublicityService } from 'src/app/services/publicity.service';
import {FilterPipe}from '../../pipes/filter.pipe';

@Component({
  selector: 'app-business-directory-views',
  templateUrl: './business-directory.component.html',
  styleUrls: ['./business-directory.component.scss']
})

export class BusinessDirectoryComponent implements OnInit {
  loading = false;
  categoriesList: CategoryModel;
  masterCatList
  subCatList: SubCategoryModel;
  companiesList;
  companyFilter;
  companieList: CompanyModel;
  masterCat;
  companies: number = 1;
  pagination: number = 12;
  getCompany: Boolean;
  public userLog: Boolean = true;
  cat;
  search;
  icon;
  publicityListProduct;
  publicityListProduct1;
  public showTable:boolean = true;
  public companiesCount = 0;
  public userRole = "";
  public categoryToShow = "";
  public categoryToFilter : any;
  public companySearch;
  public activeIndex = 0;
  public activeIndexP1= 0;
  public filterFeatured;
  constructor(
    private categoriesService: CategoryService,
    private companyService: CompanyService,
    private router: Router,
    private publicityService: PublicityService,
    private pipe: FilterPipe
  ) { }

 async ngOnInit(){
  try {
    JSON.parse(localStorage.getItem('user')).userId;
    this.userRole = JSON.parse(localStorage.getItem('user')).userRole;
   } catch (error) {
     this.userLog = false;
   }
    this.getAllMasterCat();
    this.getAllCompanies();
  
  await this.publicityService.getAllPublicity().then(
    (result: any) => {
      const DB = result.records[0][0];
      this.publicityListProduct = DB.filter(x => x.publicityPlace == "PDEV" && x.publicityStatus == "Activo");
      this.publicityListProduct1 = DB.filter(x=> x.publicityPlace == "PDEH" && x.publicityStatus == "Activo");
    
    });

    setInterval(() => {
      this.activeIndex = (this.activeIndex + 1) % this.publicityListProduct.length;
      this.activeIndexP1 = (this.activeIndexP1 + 1) % this.publicityListProduct1.length;
    }, 3000);    
    
  this.checkOdd();
  }

  moveToCat(event) {
    this.categoriesList = event.category;
    this.cat = event;
  }

  getCompanies(event){
    this.checkCompany();
       this.masterCat = event.masterCatUUID;
       this.icon = event.masterCatIcon,
       this.getCompany = true;
      document.location.href = "DirectorioEmpresarial#companyContainer"
        this.getNewCategory(event.masterCatUUID);
  }

 async getNewCategory(event){
   this.categoryToFilter = this.pipe.transform(this.companiesList, this.masterCat );
 for(let i in this.companiesList){
     if(this.companiesList[i].newCategory !== null){
      await this.companyService.getOneCompanyCategory(this.companiesList[i].newCategory).then(
           (response:any) =>{
             let masterCatArr = JSON.parse(response).records[0].productCategoryUUID;
             if(masterCatArr === this.masterCat){
                    this.categoryToFilter.push(this.companiesList[i]);
             }
           }
      )
     }
 }

 console.log(this.masterCat);
          

  }

  searchCompany(event){
      //  this.masterCat = event;
      this.checkCompany();
      console.log(this.companiesList)
       this.companySearch = this.pipe.transform(this.companiesList, event);
       console.log(this.companySearch);
       document.location.href = "DirectorioEmpresarial#companyContainer"
      this.showTable = false;
  }

  checkOdd(){
    if(this.publicityListProduct.length%2 == 1 && this.publicityListProduct.length>1){
      let len = this.publicityListProduct.length-1;
      let random = Math.floor((Math.random()*(len -1 + 1))+1);
      this.publicityListProduct.push(this.publicityListProduct[random]);
      this.actv();
    }if(this.publicityListProduct1.length%2 == 1 && this.publicityListProduct1.length>1){
      let len = this.publicityListProduct1.length -1;
      let random = Math.floor((Math.random()*(len - 1+ 1))+ 1);
      this.publicityListProduct1.push(this.publicityListProduct1[random]);
      this.actv();
     }
    this.actv();
  }

  actv(){
    var act = true;
    this.publicityListProduct.forEach(element => {
      if (act) {
        element.active = "active";
        act = false;
      }else{
        element.active = "";
      }
    });
    
   
    var act = true;
    this.publicityListProduct1.forEach(element => {
      if (act) {
        element.active = "active";
        act = false;
      }else{
        element.active = "";
      }
    });
    
  } 


  getAllMasterCat(){
    this.loading = true;
    this.categoriesService.masterGetAll().then(
      response=> {
        const userDB = response.records[0][0];
        userDB.forEach(element => {
          element.category = JSON.parse(element.category);
        });
        this.masterCatList = userDB;
       this.loading = false;
      }
    )
  }

 async getAllCompanies(){
    this.loading = true;
  await  this.companyService.companyGetAll().then(
      response=> {
        const userDB: CompanyModel = response.records[0][0];
        this.companyFilter = userDB;
        this.companiesList =  this.companyFilter.filter(x => x.directoryActive > 0);
        this.companiesCount = this.companiesList.length;
        this.filterFeatured = this.companiesList.filter(comp => comp.companyBussinesCode == '66c3e9af-4e61-4484-bfb3-f7e5ac21ce3e' || comp.companyBussinesCode == '349ec334-f237-4293-997e-7979c679a1a9' || comp.companyBussinesCode == '87f1639f-469e-45e1-ab89-caaaf3979b50' || comp.companyBussinesCode == '9f50f4b0-00e6-484b-bfa6-52e61f45eb3e');
      }
    )
  }

  navigateToCompanyInfo(companyBussinesCode){
    this.loading = true;
    this.router.navigate(['/VistaEmpresa/' + companyBussinesCode])
    // this.router.navigate(['/VistaEmpresa/' + companyBussinesCode]);   //reload to login if the user is not logged.
    // var user = JSON.parse(localStorage.getItem('user'));
   
    // if (user == undefined || user == null) {
    //   this.router.navigate(['/Login'])
    //   .then(() => {
    //     window.location.reload();
    //   });
    // }else{
 
    //   .then(() => {
    //     window.location.reload();
    //   });
    // }
    
  }

  changePagination(value){
     this.pagination = value;
  }

  checkCompany(){
    this.getCompany = false;
    this.showTable = true;
    this.companies = 1;
  }

  openPublicity(url){
    window.open(url+"", "_blank");
  }

  mouseEvent(event){
    this.companyService.getOneProductCategory(event).then(
      (response:any) => {
          this.categoryToShow = JSON.parse(response).records[0].productCategory
          console.log(this.categoryToShow);
      }
    )
  }
  
  mouseEvent2(){
       this.categoryToShow = "...";
  }

}
