import { Component, OnInit } from '@angular/core';
import country from '../../../assets/data/contry.json';
import roles from '../../../assets/data/userRoles.json';
import rolesSAC from '../../../assets/data/companySelfUserRoles.json';
import rolesMaster from '../../../assets/data/masterUserRoles.json';
import { UserModel } from '../../models/userModel';
import { newCategoryModel } from 'src/app/models/newCatModel';
import { CompanyModel } from '../../models/companyModel';
import { v4 as uuidv4 } from 'uuid';
import { UserService } from '../../services/user.service';
import { AuthService } from '../../services/auth.service'
import { Router } from '@angular/router';
import { CompanyService } from 'src/app/services/company.services';
import { CategoryService } from 'src/app/services/category.service';
import { MasterCatModel } from 'src/app/models/msterCatModel';
import { SubCategoryModel } from 'src/app/models/subcategoryModel';
import Swal from 'sweetalert2';
import { CategoryModel } from 'src/app/models/categoryModel';
import { MatSlideToggle } from '@angular/material/slide-toggle';
import { FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {

  countryList = country;
  rolesList;
  localUser;
  loading = false;
  masterCatList;
  subcategories = {} as SubCategoryModel;
  masterCategory = {} as MasterCatModel;
  categories = {} as CategoryModel;
  categoriesList: [];
  subcategoryList;
  subCatStatus = true;
  familyCatStatus = true;
  masterCatForSave;
  subCatForSave;
  familyCatForSave;
  userDataToShow: any;
  public attrDisabled: String = "disabled";
  userdata;
  public buyerProfile: Boolean = false;
  public subCatStatusTwo:Boolean = true;
  public categoriesListTwo: []
  public newCategory = {} as newCategoryModel;
  public familyCatStatusTwo:Boolean = true;
  public subcategoryListTwo: [];
  public familyCatForSaveTwo;
  public categoryCant:Boolean = false
  user = {} as UserModel;
  company = {} as CompanyModel;

  constructor(
    private router: Router,
    private authService: AuthService,
    private userService: UserService,
    private companyService: CompanyService,
    private categoryService: CategoryService,
    private userservice: UserService
  ) { }
  categoria = new FormControl('', Validators.required);
  subcategoria = new FormControl('', Validators.required);
  items = new FormControl('', Validators.required);
  
  categoriaTwo = new FormControl('', Validators.required);
  subcategoriaTwo = new FormControl('', Validators.required);
  itemsTwo = new FormControl('', Validators.required);
  ngOnInit(){
    let user = {} as UserModel;
    if (localStorage.getItem('user')) {
      this.localUser = JSON.parse(localStorage.getItem('user'));
      if (this.localUser.userRole === "Master") {
        this.rolesList = rolesMaster;
      }else{
        this.rolesList = rolesSAC;
      }
    }
    this.company.directoryActive = 1;
    this.listMasterCategories();
  }

  listMasterCategories(){
    this.categoryService.masterGetAll().then(
      response=> {
        const userDB: MasterCatModel = response.records[0][0];
        this.masterCatList = userDB;
      }
    )
  }

  masterForSave(masterCatUUID){
    this.categoryService.mastercatGetOne(masterCatUUID).then(
      (response: any) => {
      const userDB = JSON.parse(response).records[0];
      this.masterCatForSave = userDB.masterCatName;
      }
    )
  }

  listCategories(masterCatUUID){
    this.masterForSave(masterCatUUID.value);
    this.categoryService.categoryGetOne(masterCatUUID.value).then(
      (response: any) => {
        const userDB = JSON.parse(response).records;
        this.categoriesList = userDB;
        this.subCatStatus = false;
      }
    )
  }

  subcatforsave(subCatUUID){
    this.categoryService.categoryGetOneForSave(subCatUUID).then(
      (response: any) => {
        const userDB = JSON.parse(response).records[0];
        this.subCatForSave = userDB.catName;
      }
    )
  }

  listSubCat(subcatUUID){
    this.subcatforsave(subcatUUID.value);
    this.categoryService.subCatGetOne(subcatUUID.value).then(
      (response: any) => {
        const userDB = JSON.parse(response).records;
        this.subcategoryList = userDB;
        this.familyCatStatus = false;
      }
    )
  }

  itemFamily(familyUUID){
    this.categoryService.subCatGetOneForSave(familyUUID.value).then(
      (response: any) => {
        const userDB = JSON.parse(response).records[0];
        this.familyCatForSave = userDB.subCatName;
      }
    )
  }

  generatePassword() {
    // No cambiar los var loquea el generador.
    const length = 5;
    const lowercase = 'abcdefghijklmnopqrstuvwxyz';
    const uppercase = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const numbers = '0123456789';
    const punctuation = '!@#$%&';
    let retVal = '';
    for (var i = 0, n = lowercase.length; i < length; ++i) {
      retVal += lowercase.charAt(Math.floor(Math.random() * n));
      // tslint:disable-next-line:no-shadowed-variable
      for (var i = 0, n = uppercase.length; i < length; ++i) {
        retVal += uppercase.charAt(Math.floor(Math.random() * n));
        // tslint:disable-next-line:no-shadowed-variable
        for (var i = 0, n = numbers.length; i < length; ++i) {
          retVal += numbers.charAt(Math.floor(Math.random() * n));
          // tslint:disable-next-line:no-shadowed-variable
          for (var i = 0, n = punctuation.length; i < length; ++i) {
            retVal += punctuation.charAt(Math.floor(Math.random() * n));
          }
        }
      }
    }
    return retVal;
  }

  async saveUser() {
    this.user.userPassword = this.generatePassword();
    if (this.user.userPassword.length >= 8){
      const UserSub: any = await this.authService.signUp(this.user);
      this.user.userCognitoCode = UserSub.UserSub;
      this.user.userStatus = "1";
      this.user.userCreationUser = this.localUser.email;
      this.user.userUpdateUser = this.localUser.email;
      this.user.userCompanyName = this.company.companyName;
      this.user.userCompanyUUID = this.company.companyBussinesCode;
      this.userService.saveUserOnTable(this.user).then(
        response => {
          if (response.result === true) {
            this.loading = false;
                                  Swal.fire({
                                    position: 'center',
                                    icon: 'success',
                                    title: 'Empresa creada correctamente',
                                    showConfirmButton: true
                                  })
                                  setTimeout(function(){
                                    this.loading = false;
                                    window.location.reload();
                                  },1600);
          } else {
          }
        },
        error => {
        }
      )
    } else {
    }
  }

  async saveCompany() {
    if(this.user.userRole === "Usuario No Socio"){
      this.company.directoryActive = 0;
    }
    this.loading = true; 
    this.company.companyBussinesCode = uuidv4();
    if(this.categoryCant && this.newCategory.itemFamilyUUID!== undefined && this.newCategory.productCategoryUUID !== undefined && this.newCategory.productSubCatUUID !== undefined){
    console.log(this.newCategory);
        this.company.newCategory = uuidv4();
      this.newCategory.newCategoryUUID = this.company.newCategory;
     await this.companyService.saveNewCategoryCompany(this.newCategory).then(
        response =>{
            console.log(response);
            this.saveNewCompany();
        }
      )
    }else if(this.categoryCant == false){
      this.saveNewCompany();
    }else{
      Swal.fire({
        title: 'No puede enviar la nueva categoría vacía',
        icon: 'error'
      })
    }
   
  }

  saveNewCompany(){
    this.company.companyCategory = this.masterCatForSave;
    this.company.subCat = this.subCatForSave;
    this.company.familyItem = this.familyCatForSave;
     if (this.company.companyBussinesCode.length >= 8){
       this.companyService.saveCompanyOnTable(this.company).then(
         response => {
           if (response.result === true) {
            this.saveUser();
           } else {
             this.loading = false;
           }
       },
         error => {
           (error);
         }
       )
     } else {
     }
  }

  saveAll(){
    this.loading = true;
    this.saveCompany().then(
      response => {
      },
      error => {
      }
    )
  }

  checked = false;

  toggle(checked: boolean) {
    this.checked = checked;
  }

  buyerProfileActivate(event: MatSlideToggle){
    if(event.checked){
      this.company.companyName = 'Usuario externo';
      this.company.companyAddress  = 'Usuario externo';
      this.company.companyDescription = 'Usuario externo';
      this.user.userRole = 'USUARIOCOMPRAS';
      this.company.directoryActive = 0;
    }else{
      this.company.companyName = '';
      this.company.companyAddress  = '';
      this.company.companyDescription = '';
      this.user.userRole = '';
      this.company.directoryActive = 1;
    }
  
  }

  addCategory(){
    this.categoryCant = true

}

addExtraCategory(catName){
 this.newCategory.productCategory = catName;
}

listOtherCategories(masterCatUUID){
this.categoryService.categoryGetOne(masterCatUUID.value).then(
 (response: any) => {
   const userDB = JSON.parse(response).records;
   this.categoriesListTwo = userDB;
   this.subCatStatusTwo = false;
 }
)
}

listOtherSubCat(subcatUUID){
this.categoryService.subCatGetOne(subcatUUID.value).then(
 (response: any) => {
   const userDB = JSON.parse(response).records;
   this.subcategoryListTwo = userDB;
   this.familyCatStatusTwo = false;
 }
)
}

addExtraSubCat(value){
this.newCategory.productSubCat = value;
}

itemOtherFamily(familyUUID){
this.categoryService.subCatGetOneForSave(familyUUID.value).then(
 (response: any) => {
   const userDB = JSON.parse(response).records[0];
   this.familyCatForSaveTwo = userDB.subCatName;
 }
)
}

addExtraFamily(value){
console.log(value);
this.newCategory.itemFamily = value;
}

}

