<app-loading-wrapper-overlay *ngIf="loading"></app-loading-wrapper-overlay>
<mat-card style="background-color: #ffffffa1; margin: 15px !important;" class="mat-elevation-z0" id="request">
  <h1 class="detalle">Detalles de solicitud</h1>
  <form>
    <div class="mb-3" class="form-container">
      <input type="text" class="form-control" id="Tittle" [(ngModel)]="request.requestTitle" placeholder="Titulo del requerimiento" [ngModelOptions]="{standalone: true}">
    </div>
    <div class="mb-3">
      <table class="example-full-width" cellspacing="0" style="width: 93.2% !important;"><tr>
        <td>
          <mat-form-field class="example-full-width" appearance="none">
            <mat-label>Categoría</mat-label>
            <mat-select [formControl]="categoria" (selectionChange)="listCategories($event)" [(ngModel)]="request.requestMasterCatUUID" name="requestMasterCatUUID" required>
              <mat-option [value]="masterCat.masterCatUUID" *ngFor="let masterCat of masterCatList">
                {{masterCat.masterCatName}}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="categoria.hasError('required')">Seleccione una categoría para continuar</mat-error>
            <mat-hint>{{categoria.value?.sound}}</mat-hint>
          </mat-form-field>
        </td>
        <td>
          <mat-form-field class="example-full-width" appearance="none">
            <mat-label>Sub-categoría</mat-label>
            <mat-select [formControl]="subcategoria" [disabled]="subCatStatus" (selectionChange)="listSubCat($event)" [(ngModel)]="request.requestCatUUID"  name="requestCatUUID" required>
              <mat-option [value]="subcat.catUUID" *ngFor="let subcat of categoriesList">
                {{subcat.catName}}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="subcategoria.hasError('required')">Seleccione una subcategoría para continuar</mat-error>
            <mat-hint>{{subcategoria.value?.sound}}</mat-hint>
          </mat-form-field>
        </td>
        <td>
          <mat-form-field class="example-full-width" appearance="none">
            <mat-label>Familia de sub-categoria</mat-label>
            <mat-select [formControl]="items" [disabled]="familyCatStatus" [(ngModel)]="request.requestSubCatUUID" (selectionChange)="itemFamily($event)" name="requestSubCatUUID" required>
              <mat-option [value]="families.subCatUUID" *ngFor="let families of subcategoryList" (click)="familyRegex(families.subCatName)">
                {{families.subCatName}}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="items.hasError('required')">Relacione un grupo a la subcategoría</mat-error>
            <mat-hint>{{items.value?.sound}}</mat-hint>
          </mat-form-field>
        </td>
        <td style= "padding-bottom: 24px; width: 1px !important;" (click)="addCategory()">
          <span class="hover top">
            <span class="addButton" style="cursor: pointer; width: 40px; height: 90px;">
              <i class="fas fa-plus" style="width: 20px; height: 20px;"></i>
            </span>
            <span class="text">
              Agregar otra categoría.
            </span>
          </span>
        </td>
        </tr>
      </table>
      <section class="new-table" *ngIf="categoryCant">
        <table class="example-full-width" cellspacing ="0">
          <tr>
            <td >
              <mat-form-field class="example-full-width" appearance="none">
                <mat-label>Categoría</mat-label>
                <mat-select [formControl]="categoriaTwo" (selectionChange)="listOtherCategories($event)" [(ngModel)]="newCategory.productCategoryUUID" required>
                  <mat-option [value]="masterCat.masterCatUUID" *ngFor="let masterCat of masterCatList" (click)="addExtraCategory(masterCat.masterCatName)">
                    {{masterCat.masterCatName}}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="categoriaTwo.hasError('required')">Seleccione una categoría para continuar</mat-error>
                <mat-hint>{{categoria.value?.sound}}</mat-hint>
              </mat-form-field>
            </td>
            <td>
              <mat-form-field class="example-full-width" appearance="none">
                <mat-label>Sub-categoría</mat-label>
                <mat-select [formControl]="subcategoriaTwo" [disabled]="subCatStatusTwo" (selectionChange)="listOtherSubCat($event)" [(ngModel)]="newCategory.productSubCatUUID" required>
                  <mat-option [value]="subcat.catUUID" *ngFor="let subcat of categoriesListTwo" (click)="addExtraSubCat(subcat.catDescription)">
                    {{subcat.catName}}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="subcategoriaTwo.hasError('required')">Seleccione una subcategoría para continuar</mat-error>
                <mat-hint>{{subcategoria.value?.sound}}</mat-hint>
              </mat-form-field>
            </td>
            <td>
              <mat-form-field class="example-full-width" appearance="none">
                <mat-label>Familia de sub-categoria</mat-label>
                <mat-select [formControl]="itemsTwo" [disabled]="familyCatStatusTwo" [(ngModel)]="newCategory.itemFamilyUUID" (selectionChange)="itemOtherFamily($event)" required>
                  <mat-option [value]="families.subCatUUID" *ngFor="let families of subcategoryListTwo" (click)="addExtraFamily(families.subCatName)">
                    {{families.subCatName}}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="itemsTwo.hasError('required')">Relacione un grupo a la subcategoría</mat-error>
                <mat-hint>{{items.value?.sound}}</mat-hint>
              </mat-form-field>
            </td>
        </table>
      </section>
      <table class="example-medium-width" cellspacing="0"><tr>
        <td>
          <mat-form-field class="example-full-width" appearance="none" id="text-area">
            <mat-label><div style="padding: 0px;">Descripción del producto</div> </mat-label>
            <textarea matInput placeholder="Descripción del producto" [(ngModel)]="request.requestDetail" style="min-height: 110px;max-height: 110px;padding-top: 6px;" maxlength="1000" [ngModelOptions]="{standalone: true}"></textarea>
            <span style="display: block;" class="char-counter"*ngIf="request.requestDetail != null" >{{charCounter | charCount: request.requestDetail}}/1000</span>
          </mat-form-field>
        </td>
        </tr>
      </table>
      <h1 class="condiciones">Condiciones</h1>
      <table class="example-container" cellspacing="0"><tr>
        <td><mat-form-field class="example-full-width" appearance="none"  floatLabel="always">
          <mat-label><div style="margin-top: -8px !important;">Presupuesto</div></mat-label>
          <input matInput type="number" [(ngModel)]="request.requestBudget" [ngModelOptions]="{standalone: true}">
          <span matPrefix>{{this.priceCurrency}}&nbsp;</span>
          </mat-form-field></td>
          <td><mat-form-field class="example-full-width" appearance="none">
            <mat-label>Cantidad que necesita</mat-label>
            <input style="color: black; font-weight: bold;" matInput [(ngModel)]="request.requestQuantityNeeded" [ngModelOptions]="{standalone: true}">
          </mat-form-field></td>
          <td><mat-form-field class="example-full-width" appearance="none">
            <mat-label>Unidad</mat-label>
            <mat-select [(ngModel)]="request.requestUnit" style="margin-left: 3px;">
                 <mat-option [value]="unit.name" *ngFor="let unit of unitaryList">{{unit.name}}</mat-option>
            </mat-select>
          </mat-form-field></td>
          <td class="currency">
            <mat-form-field appearance="none">
              <mat-label><div style="margin-left:8px!important;">Moneda</div></mat-label> 
              <mat-select (selectionChange) ="currency($event)" [(ngModel)]="request.currency" >
                <mat-option [value]="prices.name" *ngFor="let prices of priceList" >{{prices.name}}</mat-option>
              </mat-select>
              </mat-form-field>
          </td>
        </tr>
      </table>
      <table class="example-full-width" cellspacing="0" id="toggle-table"><tr>
          <td style="text-align: center !important;" class="button-toggle">
            <mat-label>¿Acepto ofertas?   </mat-label>
            <mat-slide-toggle [(ngModel)]="aceptOffers" aria-label="Font Style" [ngModelOptions]="{standalone: true}"></mat-slide-toggle>
          </td>
          <td class="toggle-container">
            <div class="col-sm-2 col-md-2 col-lg-2" id="toggle">
              <mat-label>Añadir imágenes</mat-label>
              <mat-slide-toggle [(ngModel)] = "addImage" [ngModelOptions]="{standalone: true}"></mat-slide-toggle>
            </div>
          </td>
          <td><mat-form-field class="days" appearance="none">
            <mat-label>Plazo de entrega en días hábiles</mat-label>
            <input style="color: black; font-weight: bold;" matInput [(ngModel)]="request.requestDeliveryTerm" [ngModelOptions]="{standalone: true}">
          </mat-form-field></td>
        </tr>
      </table>
      <!-- <h1 class="empresa">Datos de empresa</h1>
      <table class="example-full-width" cellspacing="0"><tr>
        <td><mat-form-field class="example-full-width" appearance="none">
          <mat-label>Nombre</mat-label>
          <input matInput [(ngModel)]="request.requestContactName">
          </mat-form-field></td>
          <td><mat-form-field class="example-full-width" appearance="none">
            <mat-label>Contacto</mat-label>
            <input style="color: black; font-weight: bold;" matInput [(ngModel)]="request.requestContactEmail" >
          </mat-form-field></td>
          <td><mat-form-field class="example-full-width" appearance="none">
            <mat-label>Numero de teléfono</mat-label>
            <input style="color: black; font-weight: bold;" matInput [(ngModel)]="request.requestContactPhone">
          </mat-form-field></td>
        </tr>
      </table> -->
    </div>
    
<div class="example-full-width" *ngIf ="addImage">
<h1> Imagenes </h1>
<div class="row">
  <div class="col-sm-3">
    <label class="custom-file-label" #labelImportPrincipal for="imagenPrincipal">Subir imagen</label>
    <button  mat-icon-button>
      <mat-icon  for="imagenPrincipal" (click)="imagenPrincipal.click()" style="font-size: 4rem;"><div class="upload_arrow"></div></mat-icon>
      <input nbInput type="file" class="custom-file-input" #imagenPrincipal id="imagenPrincipal" accept="image/x-png,image/gif,image/jpeg" (change)="principalImage($event.target.files)" hidden>
    </button>
    <mat-icon *ngIf ="principalimage != null" class="cancel-button" (click)="principalimage = null; principalImage(' ')">close</mat-icon>
  </div>
  <div class="col-sm-3">
    <label class="custom-file-label" #labelImportP1 for="importFile1"> Subir imagen</label>
    <button  mat-icon-button>
      <mat-icon  for="importFile1" (click)="importFile1.click()" style="font-size: 4rem;"><div class="upload_arrow"></div></mat-icon>
      <input nbInput type="file" class="custom-file-input" #importFile1 id="importFile1" accept="image/x-png,image/gif,image/jpeg" (change)="image1($event.target.files)" hidden>
    </button>
    <mat-icon *ngIf ="p1 != null" class="cancel-button" (click)="p1 = null; image1(' ')">close</mat-icon>
  </div>
  <div class="col-sm-3">
    <label class="custom-file-label" #labelImportP2 for="importFile2"> Subir imagen</label>
    <button  mat-icon-button>
      <mat-icon  for="importFile2" (click)="importFile2.click()" style="font-size: 4rem;"><div class="upload_arrow"></div></mat-icon>
      <input nbInput type="file" class="custom-file-input" #importFile2 id="importFile2" accept="image/x-png,image/gif,image/jpeg" (change)="image2($event.target.files)" hidden>
    </button>
    <mat-icon *ngIf ="p2 != null" class="cancel-button" (click)="p2 = null; image2(' ')">close</mat-icon>
  </div>
  <div class="col-sm-3">
    <label class="custom-file-label" #labelImportP3 for="importFile3"> Subir imagen</label>
    <button  mat-icon-button>
      <mat-icon  for="importFile3" (click)="importFile3.click()" style="font-size: 4rem;"><div class="upload_arrow"></div></mat-icon>
      <input nbInput type="file" class="custom-file-input" #importFile3 id="importFile3" accept="image/x-png,image/gif,image/jpeg" (change)="image3($event.target.files)" hidden>
    </button>
    <mat-icon *ngIf ="p3 != null" class="cancel-button" (click)="p3 = null; image3(' ')">close</mat-icon>
  </div>
   <div class="col-sm-7">
     <div class="instructions">
       <p class="ins-re"><br>Recomendaciones:<br>
       - Subir las fotografías de manera horizontal para una mejor presentación.<br>
       - Colocar de preferencia las 4 imágenes de distintos ángulos o en donde se vean de mejor manera el producto/servicio.<br>
       - Formatos no admisibles BMP, ICO, TIFF, RAW.<br>
       - Dimensiones de imagen ideal de 800x640 pixel.<br>
       - Evitar utilizar imágenes o fotografías con mucho texto, no saturadas y que muestren tu producto/servicio de manera clara.</p>
      </div>
   </div>
</div>
</div>
<div class="row">
  <div class="col-sm-12" style="justify-content: center!important;text-align: center;">
    <button type="submit" (click)="uploadImage()" class="btn btn-primary" style="width: 15rem;height:3rem;border-radius:1rem!important;">Publicar</button>
  </div>
</div>
  <div class="row">
    <div class="col-sm-2">

    </div>
    <div class="col-sm-8" style="max-width: 100%; margin-top:2.5rem!important;margin-bottom:0.5rem!important">
      <img src="../../../../assets/images/core/PromoServicio_Diseño_Banner.jpg" style="width: 100%;">
    </div>
    <div class="col-sm-2">

    </div>
  </div>
  </form>
</mat-card>
