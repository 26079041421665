import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'businessFilter'
})
export class BusinessFilterPipe implements PipeTransform {

  transform(value: any, args: any):any {
    const result = [];
    console.log(value);
    for(let i of value){
    if(i.userCompanyName.toLowerCase().indexOf(args.toLowerCase()) >-1){
        result.push(i);
      }
    }
     return result;
}
  }
