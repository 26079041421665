import { Component, OnInit } from '@angular/core';
import { CompanyModel } from 'src/app/models/companyModel';
import { UserModel } from 'src/app/models/userModel';
import { CompanyService } from 'src/app/services/company.services';
import { UserService } from 'src/app/services/user.service';
import { ProductsModel } from 'src/app/models/productsModel';
import { ChatTableModel } from 'src/app/models/chatTableModel';
import { MessageCenterService } from 'src/app/services/messageCenter.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit {
  answerPending = true;
  sentMessages = false;
  storeMessages = false;
  deletedMessages = false;
  finishedMessages = false;
  contactsChat= false;
  codeChat= "";
  loading = false;
  userData = {} as UserModel;
  beforeEdit = true;
  user = {} as UserModel;
  companyData = {} as CompanyModel;

  userlocaldata;
  messagesData = [];
  messagesDataL;
  chatData = {} as ChatTableModel;
  localChatMessage;
  actualUser;
  productsData = {} as ProductsModel;

  constructor(
    private userservice: UserService,
    private companyService: CompanyService,
    private messageCenterService: MessageCenterService,
  ) { }

  async ngOnInit(){
    this.loading = true;
    let user;
    if (localStorage.getItem('user')) {
      user = JSON.parse(localStorage.getItem('user'));
      console.log(user);
      await this.userservice.oneUser(user.userId).then(
        (userData: any) => {
          const userDB = JSON.parse(userData).records[0];
          this.user = userDB;
          this.companyService.companyGetOne(userDB.userCompanyUUID).then(
            (companyData: any) => {
              const companyDB = JSON.parse(companyData).records[0];
              this.companyData = companyDB;
              this.loading = false;
            }
          )
        }
      )
    }
  }
  finish(){
    this.finishedMessages = true;
    this.answerPending = false;
    this.sentMessages = false;
    this.storeMessages = false;
    this.deletedMessages = false;
    this.contactsChat= false;
  }
  inbox(){
    this.answerPending = true;
    this.sentMessages = false;
    this.storeMessages = false;
    this.deletedMessages = false;
    this.contactsChat= false;
    this.finishedMessages = false;
  }


  sentMessagesToggle(){
    this.answerPending = false;
    this.sentMessages = true;
    this.storeMessages = false;
    this.deletedMessages = false;
    this.contactsChat= false;
    this.finishedMessages = false;
  }
  contacts(){
    this.answerPending = false;
    this.sentMessages = false;
    this.storeMessages = false;
    this.deletedMessages = false;
    this.contactsChat= true;
    this.finishedMessages = false;
  }

  storeMessagesToggle(){
    this.answerPending = false;
    this.sentMessages = false;
    this.storeMessages = true;
    this.deletedMessages = false;
    this.contactsChat= false;
    this.finishedMessages = false;
  }

  deletedMessagesToggle(){
    this.answerPending = false;
    this.sentMessages = false;
    this.storeMessages = false;
    this.deletedMessages = true;
    this.contactsChat= false;
    this.finishedMessages = false;
  }


  loadMessages(data){
    this.loading = true;
    this.userlocaldata = JSON.parse(localStorage.getItem('user'));
    if (data.productData != undefined) {
      this.productsData = data.productData;
      
    }
    this.messageCenterService.chatTableGet(data.chatUUID).then(
      (response: any) => {
        const userDB = JSON.parse(response).records;
        
        userDB.forEach(element => {
          (element);
          this.messagesData.push(element);
          this.actualUser = this.userlocaldata.userId;
          this.messagesDataL = element;

          this.loading = false;
        });
        if (data.empty == undefined) {
          
          if (userDB.length === 0){
            Swal.fire({
              icon: 'error',
              title: 'Sin mensajes',
              text: 'No tienes mensajes crea un mensaje para iniciar.',
            })
            
          }
        }
        this.loading = false;
        
      }
    )
  }


}
