<app-loading-wrapper-overlay *ngIf="loading"></app-loading-wrapper-overlay>
<div class="col-sm-12" style="display: flex!important;">
  <div class="container">
    <div class="row" style="width:100%; display: flex!important;">
      <div class="card" style="margin:50px auto!important; align-items: center!important; margin-left:60px!important;">
        <mat-card class="masterCard">
          <mat-tab-group mat-stretch-tabs>
            <mat-tab label="CREAR CATEGORÍA">
              <div class="row" style="margin-top: 2rem;">
                <div class="col-sm-12" style="display:flex">
                  <mat-form-field appearance="outline" style="width: 70% !important; margin: 0 auto;">
                    <mat-label>Nombre de la categoría</mat-label>
                    <input matInput placeholder="Placeholder" [(ngModel)]="masterCatName" disableRipple>
                  </mat-form-field>
                </div>
              </div>
              <div class="row" style="margin-top: 2rem;">
              <div class="col-sm-12" style="max-height: 120px; overflow-y: scroll;">
                  <div class="item-container" *ngFor="let item of subcatItems; let i = index " style="width: 100%; text-align: center; display: flex;">
                    <div class="item" style="background-color: #0078d7; color:white; border-radius: 10px; margin-bottom: 2px; width: 60%; margin: 10px auto;">{{item.subCatName}}
                  </div>
              </div>
              </div>
              <div class="row" style="margin-top: 2rem;">
                <div class="col-sm-12" style="display:flex">
                  <mat-form-field appearance="outline" style="width: 70% !important; margin: 0 auto;">
                    <mat-label>Nombre de la Sub-categoría</mat-label>
                    <input matInput placeholder="Placeholder" [(ngModel)]="subCategoryName" (keyup.enter)="addSubCatToList()" disableRipple>
                  </mat-form-field>
                </div>
              </div>
              <div class="fam-container" *ngFor="let subcat of subcatItems">
                <div class="row" style="margin-top: 2rem; width: 100%;">
                  <div class="col-sm-12" style="max-height: 120px; overflow-y: scroll;">
                      <div class="item-container" *ngFor="let item of items; let i = index " style="">
                        <div class="item-show--condition" *ngIf="subcat == item.subcatName" sytle="text-align: center; display: flex;">
                          <div class="item" style="background-color: #0078d7; color:white; border-radius: 10px; margin: 10px auto; margin-bottom:10px; text-align: center; width: 60%;">{{item.famcatName}}</div>
                        </div>
                      </div>
                  </div>
                  </div>
                  <div class="subcat-name" style="color:#0078d7; width: bolder; font-size: 1.2rem;">
                    <span>Familias de subcategoría de {{subcat.subCatName}} :</span>
                  </div>
                  <div class="row" style="margin-top: 2rem;" >
                    <div class="col-sm-12" style="display:flex">
                      <mat-form-field appearance="outline" style="width: 70% !important; margin: 0 auto;">
                        <mat-label>Nombre de la familia de Sub-categoría</mat-label>
                        <input matInput placeholder="Placeholder" clss="famInput" #famInput (input)="newItem = $event.target.value" (keyup.enter)="addFamCat(subcat)" disableRipple>
                      </mat-form-field>
                    </div>
                  </div>
              </div>
              <div class="col-sm-12" style="text-align: center;">
                <label class="custom-file-label" #labelImportPrincipal for="imagenPrincipal">Subir icono</label>
                <button  mat-icon-button>
                  <mat-icon  for="imagenPrincipal" (click)="imagenPrincipal.click()" style="font-size: 4rem;"><div class="upload_arrow"></div></mat-icon>
                  <input nbInput type="file" class="custom-file-input" #imagenPrincipal id="imagenPrincipal" accept="image/x-png,image/gif,image/jpeg" (change)="principalImage($event.target.files)" hidden>
                </button>
                <mat-icon *ngIf ="principalimage != null" class="cancel-button" (click)="principalimage = null; principalImage(' ')">close</mat-icon>
              </div>
              <div class="col-sm-12">
                <div class="button-container">
                  <button class="button" (click)="saveCategory()">Guardar Categoria</button>
                </div>
              </div>
              </div>
            </mat-tab>
          </mat-tab-group>
        </mat-card>
        </div>
    </div>
  </div>
</div>
