import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ProductsModel } from '../../../models/productsModel';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { v4 as uuidv4 } from 'uuid';
import { ProductsService } from 'src/app/services/products.service';
import { CategoryService } from '../../../services/category.service';
import { SubCategoryModel } from '../../../models/subcategoryModel';
import { MasterCatModel } from 'src/app/models/msterCatModel';
import { CategoryModel } from '../../../models/categoryModel';
import { UserModel } from '../../../models/userModel';
import { newCategoryModel } from 'src/app/models/newCatModel';
import { UserService } from 'src/app/services/user.service';
import unit from '../../../../assets/data/unit.json';
import { Router } from '@angular/router';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import priceType from '../../../../assets/data/priceType.json';
@Component({
  selector: 'app-creat-product',
  templateUrl: './creat-product.component.html',
  styleUrls: ['./creat-product.component.scss']
})
export class CreatProductComponent implements OnInit {

  loading = false;
  products = {} as ProductsModel;
  subcategories = {} as SubCategoryModel;
  masterCategory = {} as MasterCatModel;
  categories = {} as CategoryModel;
  categoriesList: [];
  masterCatList;
  subcategoryList;
  user;
  userDataToShow = {} as UserModel;
  masterSelected;
  subCatStatus = true;
  familyCatStatus = true;
  masterCatForSave;
  subCatForSave;
  familyCatForSave;
  familyCatForSaveTwo;
  unitaryPrice: boolean = true;
  mayorPrice: boolean = false;
  unitList = unit;
  priceList = priceType;
  priceCurrency: String = 'Q';
  charCounter:number= 400; 
  unitaryPriceValidator: boolean = false;
  disabled: boolean = false;
  public categoryCant:Boolean = false
  private allMasterUsers;
  private regEx = /ambiente|ambiental|instrumento|regularización|regularizacion|cumplimiento|legal|estudio técnico de aguas residuales|ETAR|EIA|MARN|Ministerio de Ambiente  y Recursos Naturales|Planos|Ministerio de salud|MSPAS|Licencia ambiental|Licencia sanitaria|Estudio Hidrogeológico|Plantas de tratamiento|Aguas residuales|Auditorías Ambientales|Auditoría|Asesoría|Asesoría Ambiental|Ingeniero ambiental|Ingeniería Ambiental|Sanitarista|Desechos|Desechos sólidos|Residuos|Residuos sólidos|Plan de manejo|Plan de gestión|Gestión ambiental|RECSA|Prórroga|16 de diciembre 2022/gi;
  private regExCat = /Actividades de apoyo a la agroindustria|Servicios de apoyo a la explotación|Distribución y tratamiento de agua|Evacuación, tratamiento y descontaminación de aguas residuales|Actividades de apoyo al transporte|Actividades jurídicas|Consultorías de gestión|Convenciones y exposiciones|Otros servicios|Otros tipos de enseñanza|Actividades de apoyo a la enseñanza/gi;
  public subCatSelected = "";
  public subCatStatusTwo:Boolean = true;
  public categoriesListTwo: []
  public newCategory = {} as newCategoryModel;
  public familyCatStatusTwo:Boolean = true;
  public subcategoryListTwo: [];
  public subCatSelected2;
  public userRole;
  @ViewChild('labelImportPrincipal')
  labelImportPrincipal: ElementRef;

  @ViewChild('labelImportP1')
  labelImportP1: ElementRef;

  @ViewChild('labelImportP2')
  labelImportP2: ElementRef;

  @ViewChild('labelImportP3')
  labelImportP3: ElementRef;

  formImport: FormGroup;
  principalimage: File = null;
  p1: File = null;
  p2: File = null;
  p3: File = null;


  constructor(
    private productsService: ProductsService,
    private categoryService: CategoryService,
    private userservice: UserService,
    private router: Router
  ) { }

  async ngOnInit(){
    this.loading = true;
    const userLogged = JSON.parse(localStorage.getItem('user'));
    this.user = userLogged;
    this.userRole = this.user.userRole;
    console.log(this.userRole);
    if(this.userRole == 'UsuarioNoSocio'){
      this.router.navigate(['/'])
    }
    await this.userservice.oneUser(userLogged.userId).then(
      (response: any) => {
        const userDB = JSON.parse(response).records[0];
        this.userDataToShow = userDB;
        this.loading = false;
      }
    );

    await this.userservice.getAllMasterUsers().then(
      (response: any) => {
        if(JSON.stringify(response)==='string'){
          this.allMasterUsers = JSON.parse(response).records[0][0];
         
        }else{
          this.allMasterUsers = response.records[0][0];
        }
      }
    )
    this.products.unitPriceType = 'Q';
    this.listMasterCategories();
    this.checkValue(this.unitaryPrice);
  }
  categoria = new FormControl('', Validators.required);
  subcategoria = new FormControl('', Validators.required);
  items = new FormControl('', Validators.required);

  categoriaTwo = new FormControl('', Validators.required);
  subcategoriaTwo = new FormControl('', Validators.required);
  itemsTwo = new FormControl('', Validators.required);

  checked = false;

  toggle(checked: boolean) {
    this.checked = checked;
  }

  listMasterCategories(){
    this.categoryService.masterGetAll().then(
      response=> {
        const userDB: MasterCatModel = response.records[0][0];
        this.masterCatList = userDB;
      }
    )
  }

  masterForSave(masterCatUUID){
    this.categoryService.mastercatGetOne(masterCatUUID).then(
      (response: any) => {
        const userDB = JSON.parse(response).records[0];
        this.masterCatForSave = userDB.masterCatName;
      }
    )
  }

  listCategories(masterCatUUID){
    this.masterForSave(masterCatUUID.value);
    this.categoryService.categoryGetOne(masterCatUUID.value).then(
      (response: any) => {
        const userDB = JSON.parse(response).records;
        this.categoriesList = userDB;
        this.subCatStatus = false;
      }
    )
  }

  subcatforsave(subCatUUID){
    this.categoryService.categoryGetOneForSave(subCatUUID).then(
      (response: any) => {
        const userDB = JSON.parse(response).records[0];
        this.subCatForSave = userDB.catName;
      }
    )
  }

  listSubCat(subcatUUID){
    this.subcatforsave(subcatUUID.value);
    this.categoryService.subCatGetOne(subcatUUID.value).then(
      (response: any) => {
        const userDB = JSON.parse(response).records;
        this.subcategoryList = userDB;
        this.familyCatStatus = false;
      }
    )
  }

  itemFamily(familyUUID){
    this.categoryService.subCatGetOneForSave(familyUUID.value).then(
      (response: any) => {
        const userDB = JSON.parse(response).records[0];
        this.familyCatForSave = userDB.subCatName;
      }
    )
  }

  async uploadImage() {
    this.loading = true;
    var principalI;
    var p1;
    var p2;
    var p3;
        if(!!this.products.productPrice === false && !!this.products.unitSystemValue === false && !!this.products.unitPriceType ===false
            && this.unitaryPrice === true){
           Swal.fire({
               title:'Campos de precio vacíos',
               icon: 'error'
           })
           this.loading = false;
        }else{
    var contractUUID = uuidv4();
    // this.productsService.uploadFileS3(this.principalimage, this.userDataToShow.userCompanyUUID, contractUUID).then(
    //   (responsePI: any) => {
    //     principalI = responsePI.Location;
    //     this.productsService.uploadFileS3(this.p1, this.userDataToShow.userCompanyUUID, contractUUID).then(
    //       (responseP1: any) => {
    //         p1 = responseP1.Location;
    //         this.productsService.uploadFileS3(this.p2, this.userDataToShow.userCompanyUUID, contractUUID).then(
    //           (responseP2: any) => {
    //             p2 = responseP2.Location;
    //             this.productsService.uploadFileS3(this.p3, this.userDataToShow.userCompanyUUID, contractUUID).then(
    //               (responseP3: any) => {
    //                 p3 = responseP3.Location;
    //                 this.products.productMainPicture = principalI;
    //                 this.products.productP1 = p1;
    //                 this.products.productP2 = p2;
    //                 this.products.productP3 = p3;
    //                 this.saveProduct();
    //               }
    //             )
    //           }
    //         )
    //       }
    //     )
    //   }
    // )
if (this.principalimage === null) {
  Swal.fire({
    icon: 'error',
    title: 'Algo salio mal',
    text: 'Ingrese al menos una imagen',
  })
  this.loading = false;
}else{
  for (var i = 0; i < 4; i++) {
    if (i == 0) {
      if (this.principalimage != null) {
        await this.productsService.uploadFileS3(this.principalimage, this.userDataToShow.userCompanyUUID, contractUUID).then(
          (responsePI: any) => {
            principalI = responsePI.Location;
            this.products.productMainPicture = principalI;
          }
          )
      }else{
        principalI = null;
        this.products.productMainPicture = principalI;
      }
    }else if (i == 1) {
      if (this.p1 != null) {
        await this.productsService.uploadFileS3(this.p1, this.userDataToShow.userCompanyUUID, contractUUID).then(
          (responsePI: any) => {
            p1 = responsePI.Location;
            this.products.productP1 = p1;
          }
          )
      }else{
        p1 = null;
        this.products.productP1 = p1;
      }
    }else if (i == 2) {
      if (this.p2 != null) {
        await this.productsService.uploadFileS3(this.p2, this.userDataToShow.userCompanyUUID, contractUUID).then(
          (responsePI: any) => {
            p2 = responsePI.Location;
            this.products.productP2 = p2;
          }
          )
      }else{
        p2 = null;
        this.products.productP2 = p2;
      }
    }else if (i == 3) {
      if (this.p3 != null) {
        await this.productsService.uploadFileS3(this.p3, this.userDataToShow.userCompanyUUID, contractUUID).then(
          (responsePI: any) => {
            p3 = responsePI.Location;
            this.products.productMainPicture = principalI;
                  this.products.productP1 = p1;
                  this.products.productP2 = p2;
                  this.products.productP3 = p3;
                  this.saveProductCategory();
                  
            
          }
          )
      }else{
        p3 = null;
        this.products.productP3 = p3;
        this.saveProductCategory();
      }
    }
 }
}
        }
    
  }

  changeFamilyItem(value){
      this.subCatSelected = value;
      console.log(this.subCatSelected);
  }

  changeFamilyItem2(value){
     this.subCatSelected2 = value;
     console.log(this.subCatSelected2)
  }

  async saveProductCategory() {
    this.products.productUUID = uuidv4();
    if(this.categoryCant && this.newCategory.itemFamilyUUID!== undefined && this.newCategory.productCategoryUUID !== undefined && this.newCategory.productSubCatUUID !== undefined){ 
      this.products.newCategory = uuidv4();
      this.newCategory.newCategoryUUID = this.products.newCategory;
        await this.productsService.saveNewCategoryProduct(this.newCategory).then(
            response =>{
              console.log(response);
              this.saveNewProduct();
            }
          )
    }else if(this.categoryCant == false){
      this.saveNewProduct();
  }else {
    Swal.fire({
       title: 'No puede enviar la categoría vacia',
       icon: 'error'
    })
  }
}

  saveNewProduct(){
    this.products.productInteractions = 0;
    this.products.productViews = 0;
    this.products.productCompany = this.userDataToShow.userCompanyName;
    this.products.productCompanyUUID = this.userDataToShow.userCompanyUUID;
    this.products.productContact = this.userDataToShow.userEmail;
    this.products.productCategory = this.masterCatForSave;
    this.products.productSubCat = this.subCatForSave;
    this.products.itemFamily = this.familyCatForSave;
    if (this.unitaryPrice == true) {
      this.products.unitaryPrice = 1;
    }else if(this.unitaryPrice == false){
      this.products.unitaryPrice = 0;
    }else{
      this.products.unitaryPrice = 1;
    }
    this.products.mayorPriceType = this.mayorPrice;
    this.products.userCreateUUID = JSON.parse(localStorage.getItem('user')).userId;
    if(this.products.productPrice == undefined){
       this.products.productPrice = 0;
    }
    console.log(this.products.productPrice);
   console.log(this.products);
    if(this.products.productName.search(this.regEx) != -1 || this.products.productDescription.search(this.regEx) != -1 || this.subCatSelected.search(this.regExCat) != -1 && this.products.productUUID.length >= 8){
       this.sendEmailDescription();
    }else if(this.categoryCant && this.newCategory.itemFamily.search(this.regExCat) != -1){
         this.sendEmailDescription();
    }else{
    if (this.products.productUUID.length >= 8){
      this.products.pendingProduct = 1;
      this.productsService.saveProductOnTable(this.products).then(
        response => {
          console.log(response);
          if (response.result === true) {
            Swal.fire({
               icon: 'success',
               text: 'Producto guardado',
               position: 'center'

            })
            this.loading = false;
            setTimeout(function(){
              window.location.reload();
              },1600);
          } else {
            Swal.fire({
              icon: 'error',
              title: 'Algo salio mal',
              text: response.message,
            })
            this.loading = false;
          }
        },
        error => {
          console.log(error);
        }
      )
    } else {
      // ("Error al crear producto");
    }
  }
  }

  sendEmailDescription(){
    console.log("enviando correos");
    this.sendEmails();  
    this.products.pendingProduct = 0;
        this.productsService.saveProductOnTable(this.products).then(
          (response) => {
            console.log(response);
            if(response.statusCode == 200){
              Swal.fire({
                title:'Su producto se encuentra pendiente de aprobación',
                text: 'Solicitud en espera de revisión',
                icon: 'warning',
                showCancelButton: false
              }).then((result) =>{
                window.location.reload();
              })
            }else{
              Swal.fire({
                title: 'Error',
                icon: 'error'
              })
              setTimeout(function (){
                window.location.reload();
              }, 1200);
            }

          }
          
        )
  }

  sendEmails(){
   
      for(let i in this.allMasterUsers){
        let params = {
          userEmail: this.allMasterUsers[i].userEmail
        }
        try{
          console.log(params);
          this.productsService.sendEmail(params).then(
            response =>{
              console.log(response);
            }
          )
        }catch{
           (console.error(Error));
        }
      }
  }

  principalImage(files: FileList) {
    this.labelImportPrincipal.nativeElement.innerText = Array.from(files)
      .map(f => f.name)
      .join(', ');
      if(files[0].name != undefined){
        this.principalimage = files.item(0);
      }else{
        this.labelImportPrincipal.nativeElement.innerText = 'Subir imagen'
      }
   
  }

  image1(files: FileList) {
    this.labelImportP1.nativeElement.innerText = Array.from(files)
      .map(f => f.name)
      .join(', ');
      if(files[0].name != undefined){
        this.p1 = files.item(0);
      }else{
        this.labelImportP1.nativeElement.innerText = 'Subir imagen'
      }
    
  }

  image2(files: FileList) {
    this.labelImportP2.nativeElement.innerText = Array.from(files)
      .map(f => f.name)
      .join(', ');
      if(files[0].name != undefined){
        this.p2 = files.item(0);
      }else{
        this.labelImportP2.nativeElement.innerText = 'Subir imagen'
      }
    
  }

  image3(files: FileList) {
    this.labelImportP3.nativeElement.innerText = Array.from(files)
      .map(f => f.name)
      .join(', ');
      if(files[0].name != undefined){
        this.p3 = files.item(0);
      }else{
        this.labelImportP3.nativeElement.innerText = 'Subir imagen'
      }
    
  }

  currency(currency){
     console.log(currency.value);
     this.priceCurrency = currency.value;
  }

  counter(event){
   console.log(event.length);
  }

  // import(): void {
  //   ('import ' + this.fileToUpload.name);
  // }

    checkValue(event: any){
       if(this.mayorPrice){
          this.unitaryPrice = true;
          this.disabled = true;
       }else if(this.mayorPrice == false){
        this.disabled = false;
       }
    }

    addCategory(){
           this.categoryCant = true
     
    }

    addExtraCategory(catName){
        this.newCategory.productCategory = catName;
    }

    listOtherCategories(masterCatUUID){
      this.categoryService.categoryGetOne(masterCatUUID.value).then(
        (response: any) => {
          const userDB = JSON.parse(response).records;
          this.categoriesListTwo = userDB;
          this.subCatStatusTwo = false;
        }
      )
    }

    listOtherSubCat(subcatUUID){
      this.categoryService.subCatGetOne(subcatUUID.value).then(
        (response: any) => {
          const userDB = JSON.parse(response).records;
          this.subcategoryListTwo = userDB;
          this.familyCatStatusTwo = false;
        }
      )
    }

    addExtraSubCat(value){
      this.newCategory.productSubCat = value;
    }

    itemOtherFamily(familyUUID){
      this.categoryService.subCatGetOneForSave(familyUUID.value).then(
        (response: any) => {
          const userDB = JSON.parse(response).records[0];
          this.familyCatForSaveTwo = userDB.subCatName;
        }
      )
    }

    addExtraFamily(value){
      console.log(value);
      this.newCategory.itemFamily = value;
    }

}
