<app-loading-wrapper-overlay *ngIf="loading"></app-loading-wrapper-overlay>
<mat-dialog-content class="mat-typography" style="overflow: auto!important; max-height: initial; min-height: 400px; min-width: 550px;" >
  <div class="col-md-12">
      <div class="card card-bordered">
          <div class="card-header">
              <h4 class="card-title"><strong>Chat</strong></h4>
              <div  (click)="closeDialog()">
                <i style="text-align: right;cursor: pointer;color: #898989;" class="fa fa-times" aria-hidden="true"></i>
          
              </div>
              </div>
          <div class="ps-container ps-theme-default ps-active-y" id="chat-content" style="overflow-y: scroll !important; height:400px !important;">
             
            <div class="row" style="margin-left: 2%;margin-top: 4%;width: 90%; border-width: 10px!important;border-color: black!important;; line-height: 96%;" *ngIf="productsData.productName != undefined && requestShowData == false">
                  <div class="col-sm-4"><img [src]="productsData.productMainPicture" width="100px" height="auto"> </div>
                  <div class="col-sm-7"><p style="color: blue;font-size: 20px; line-height: 25px;">{{productsData.productName}}</p>
                  <p><a href="" >{{productsData.productCompany}} </a><br>
                    <span style="line-height: 150%;"> {{productsData.unitPriceType}}{{productsData.productPrice}}</span>
                  </p>
                <p></p>
              </div>
              <div class="col-sm-12">
                    <hr>
              </div>        
             </div>
             <div class="row" style="margin-left: 2%;margin-top: 4%;width: 100%; border-width: 10px!important;border-color: black!important;; line-height: 96%;" *ngIf="requestsData.requestTitle != undefined && requestShowData == true">
              <div class="col-sm-4"><img [src]="requestsData.requestIMG1" width="100px" height="auto"> </div>
              <div class="col-sm-7"><p style="color: blue;font-size: 20px; line-height: 25px;">{{requestsData.requestTitle}}</p>
              <p style="min-width: 260px;"><a href="" >{{requestsData.requestContactName}} </a><br>
                <span style="line-height: 150%;font-weight: lighter;">Presupuesto: {{requestsData.currency}}{{requestsData.requestBudget}} </span>
                <br><span style="font-weight: lighter;">Cantidad requerida: {{requestsData.requestQuantityNeeded}}</span>
              </p>
            <p></p>
          </div>
          <div class="col-sm-12">
                <hr>
          </div>        
         </div> 
             <div *ngIf="this.data.createChat">
             <div class="row" style="margin-left: 2%;margin-top: 2%;width: 90%; border-width: 10px!important;border-color: black!important;" *ngIf="productsData.productName != undefined">
              <div class="col-sm-9" class="sms1">
                
                <span> Hola Mucho gusto, ponemos nuestros productos a su disposición ¿Cómo Podemos Ayudarle? </span>
              </div>
            </div>
             <div class="row" style="margin-left: 2%;margin-top: 5%;width: 90%; border-width: 10px!important;border-color: black!important;" *ngIf="productsData.productName != undefined">
              <div class="col-sm-9" class="sms" (click)="sendMessage1(1)">
                
                <span> Me interesa este producto para poder llegar a un acuerdo  </span>
              </div>
            </div>

            <div class="row" style="margin-left: 2%;margin-top: 5%;width: 90%; border-width: 10px!important;border-color: black!important;" *ngIf="productsData.productName != undefined">
              <div class="col-sm-9" class="sms" (click)="sendMessage1(2)">
                
                <span> Hola ¿Sigue disponible?  </span>
              </div>
            </div>
          </div>
          
            <div *ngIf="this.data.createChat == false">
              <div class="media media-chat"  *ngFor="let messages of messagesData" >
                <div class="row"  style="justify-content: left!important;">
                  <div class="col-sm-9" class="sms4" *ngIf="actualUser == messages.chatUser_Receiver && messages.chatMessage !== null">
                    <div class="row">
                       <div class="col-sm-12">
                 <!--   <p *ngIf="actualUser !== chatData.chatUser_Sender && messages.chatMessageR !== null">{{messages.chatMessageR + " - "}} <time datetime="2018" style="color: black;">{{messages.chatMessage_DATE | date:'short'}}</time></p>-->
                  <!--  <p *ngIf="actualUser === chatData.chatUser_Sender && messages.chatMessage !== null">{{messages.chatMessage + " - "}} <time datetime="2018" style="color: black;">{{messages.chatMessage_DATE | date:'short'}}</time></p>-->
                    <!-- <p *ngIf="actualUser !== chatData.chatUser_Receiver && messages.chatMessageR !== null">{{messages.chatMessageR + " - "}} <time datetime="2018" style="color: black;">{{messages.chatMessage_DATE | date:'short'}}</time></p> -->
                        <span style="width: auto;max-width: 100%; margin-right: 110px;">{{messages.chatMessage}} </span>
                         <div class="col-sm-12"  style="text-align: right;justify-content: right!important;margin-bottom: -10px;"> 
                             <time datetime="2018" style="color: rgb(255, 255, 255);">{{messages.chatMessage_DATE | date:'short'}}</time>
                         </div>
                     </div>  
                   </div>
                  </div>
                </div>
                <div class="row"  style="justify-content: right!important;">
                  <div class="col-sm-9" class="sms5" *ngIf="actualUser !== messages.chatUser_Receiver && messages.chatMessage !== null" >
                    <div class="row">
                    <div class="col-sm-12">
                      <span style="width: auto;max-width: 100%; margin-right: 110px;">{{messages.chatMessage}}</span>
                        <div class="col-sm-12" style="text-align: right;justify-content: right!important;margin-bottom: -10px;">
                           <time datetime="2018" style="color: rgb(255, 255, 255);">{{messages.chatMessage_DATE | date:'short'}}</time>
                        </div>
                    </div>
                  </div>
                </div>
                </div>
                <div class="row" style="justify-content: right!important;">
                  <div class="col-sm-9" class="sms5" *ngIf="actualUser !== messages.chatUser_Receiver && messages.chatMessageR !== null">
                <span style="width: auto;max-width: 100%; margin-right: 110px;"> {{messages.chatMessageR}}</span>
                   <div class="col-sm-12" style="text-align: right;justify-content: right!important;margin-bottom: -10px;">
                    <time datetime="2018" style="color: rgb(255, 255, 255);">{{messages.chatMessage_DATE | date:'short'}}</time>
                   </div>
                <!-- <p *ngIf="actualUser !== chatData.chatUser_Sender && messages.chatMessage !== null">{{messages.chatMessage + " - "}} <time datetime="2018" style="color: black;">{{messages.chatMessage_DATE | date:'short'}}</time></p> -->
             </div>
              </div>
              </div>
              </div>
              <div class="ps-scrollbar-x-rail" style="left: 0px; bottom: 0px;">
                  <div class="ps-scrollbar-x" tabindex="0" style="left: 0px; width: 0px;"></div>
              </div>
              <div class="ps-scrollbar-y-rail" style="top: 0px; height: 0px; right: 2px;">
                  <div class="ps-scrollbar-y" tabindex="0" style="top: 0px; height: 2px;"></div>
              </div>
          </div>
          <div class="publisher bt-1 border-light"> <img class="avatar avatar-xs" src="https://img.icons8.com/color/36/000000/administrator-male.png" alt="...">
            <input class="publisher-input" type="text" placeholder="Escribe tu mensaje ..." [(ngModel)]="localChatMessage" #chatTextInput on-keyup="$event.keyCode == 13 && sendMessage()">
            <span class="publisher-btn file-group"> <i class="fa fa-paperclip file-browser"></i>
            <input type="file"> </span>
            <a class="publisher-btn" data-abc="true"><i class="fa fa-smile"></i></a>
            
            <a style="padding: 0;margin-left: 0.2rem;" class="publisher-btn text-info" data-abc="true" (click)="sendMessage()"><img src="../../../../../../assets/images/core/sendMessage.png" alt="Enviar" style="width: 1.45rem;height: 1.45rem;margin: 0;border: 0;"></a> </div>
      </div>
  </div>
</mat-dialog-content>

