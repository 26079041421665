import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Route } from '@angular/router';
import { ProductsModel } from 'src/app/models/productsModel';
import { ProductsService } from 'src/app/services/products.service';

@Component({
  selector: 'app-pending-application-view',
  templateUrl: './pending-application-view.component.html',
  styleUrls: ['./pending-application-view.component.scss']
})
export class PendingApplicationViewComponent implements OnInit {

  constructor(private route: ActivatedRoute, private productService: ProductsService) { }
  productsData = {} as ProductsModel;
  ngOnInit(): void {
    this.route.paramMap.subscribe((params: any) => {
      this.getSingleProduct(params.params.id);
    });
  }

  getSingleProduct(productUUID){
    console.log(productUUID);
       this.productService.productGetOne(productUUID).then(
          (response : any) => {
           this.productsData = JSON.parse(response).records[0];
           console.log(this.productsData);
          }
       )
  }

  openimage(event){
    (event);
  }

}
