import { Component, OnInit, Inject } from '@angular/core';
import { MessageCenterService } from 'src/app/services/messageCenter.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ProductsModel } from 'src/app/models/productsModel';
import { RequestModel } from 'src/app/models/requestModel';
import { ChatTableModel } from 'src/app/models/chatTableModel';
import { v4 as uuidv4 } from 'uuid';
import { ProductsService } from 'src/app/services/products.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { UserService } from 'src/app/services/user.service';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-chat-dialog',
  templateUrl: './chat-dialog.component.html',
  styleUrls: ['./chat-dialog.component.scss']
})
export class ChatDialogComponent implements OnInit {
  loading = false
  userlocaldata;
  messagesData = [];
  messagesDataL;
  chatData = {} as ChatTableModel;
  localChatMessage;
  actualUser;
  productsData = {} as ProductsModel;
  requestsData = {} as RequestModel;
  requestShowData: Boolean = false;
  messageP1= "Me interesa este producto para poder llegar a un acuerdo";
  messageP2="Hola ¿Sigue disponible?";
  messageR1 = "Me interesa este producto para poder llegar a un acuerdo";
  messageR2 = "Hola ¿Sigue disponible?";
  constructor(
    private messageCenterService: MessageCenterService,
    private chatService: MessageCenterService,
    private dialogRef: MatDialogRef<ChatDialogComponent>,
    private productsService: ProductsService,
    private userService: UserService,
    private authService: AuthService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  async ngOnInit(){
      await this.loadMessages()
   
  }


  loadMessages(){
    this.loading = true;
    this.userlocaldata = JSON.parse(localStorage.getItem('user'));

    if (this.data.productData != undefined && this.data.productData.productName != undefined){
      this.productsData = this.data.productData;
    }else{
         this.requestsData = this.data.productData;
         
    }
    this.requestShowData = Object.keys(this.requestsData).length > 0 ? true : false;
     console.log(this.data);
    this.messageCenterService.chatTableGet(this.data.chatUUID).then(
      (response: any) => {
        const userDB = JSON.parse(response).records;
        userDB.forEach(element => {
          (element);
          this.messagesData.push(element);
          this.actualUser = this.userlocaldata.userId;
          this.messagesDataL = element;

          this.loading = false;
        });
        if (this.data.empty == undefined) {

          if (userDB.length === 0){
            Swal.fire({
              icon: 'error',
              title: 'Sin mensajes',
              text: 'No tienes mensajes crea un mensaje para iniciar.',
            })

          }
        }
        this.loading = false;

      }
    )
    this.loading = false;
  }

  sendMessage1(m){
    if (m==1) {
      this.localChatMessage=this.messageP1;
      this.sendMessage();
    } else if (m==2) {
      this.localChatMessage=this.messageP2;
      this.sendMessage();
    }
  }
  closeDialog(){

    const that = this;
    that.dialogRef.close();
  }
  sendMessage(){
    this.loading = true;
    if (this.data.createChat) {
      this.data.productUUID = this.productsData.productUUID;
      this.chatService.mainMessageTableInsert(this.data).then(
        messageResponse => {
        this.data.createChat = false;
         this.localChatMessage = " ";
        });
    }
    const that = this;
    let localData = JSON.parse(localStorage.getItem('user'));
    this.chatData.chatUUID = this.data.chatUUID;
    this.chatData.messageUUID = uuidv4();
    this.chatData.chatUser_Sender = localData.userId;
    this.chatData.chatUser_Receiver = this.data.chatUser2UUID;
    this.chatData.chatFile = localData.userId;
    this.chatData.chatMessage_status = "recibido";

    if (this.messagesData.length === 0){
      this.chatData.chatMessage = this.localChatMessage;
      this.messageCenterService.chatTableInsert(this.chatData).then(
        response => {
          this.chatData.chatMessage = '';
          this.loading = false;
          this.localChatMessage = " ";
          this.ngOnInit();
      })
    } else if (this.messagesData.length !== 0){
      if (this.messagesDataL.chatUser_Sender === localData.userId) {
        this.chatData.chatMessage = this.localChatMessage;
        this.messageCenterService.chatTableInsert(this.chatData).then(
          response => {
            this.chatData.chatMessage = '';
            this.loading = false;
            this.localChatMessage = " ";
            this.ngOnInit();
        })
      } else if (this.messagesDataL.chatUser_Sender !== localData.userId) {
        this.chatData.chatMessageR = this.localChatMessage;
        this.messageCenterService.chatTableInsert(this.chatData).then(
          response => {
            this.chatData.chatMessage = '';
            this.loading = false;
            this.localChatMessage = " ";
            this.ngOnInit();
        })
      }
    }

    this.userService.oneUser(this.chatData.chatUser_Receiver).then(
      (response: any)=>{
                     console.log(JSON.parse(response).records[0]);
                let params = {
                  userName: "",
                  userPassword: "",
                  userEmail: JSON.parse(response).records[0].userEmail,
                  cigURLSite: "",
                }
                console.log(response);
                this.authService.sendEmailNewMessage(params).then(res =>{
                  this.loading = false;
                  console.log(res);
                  setTimeout(function(){
                    this.loading = false;
                    
                  },1600);
                 
                });
                  }
                );
      for(var i =0; i<=this.messagesData.length; i++){
       this.messagesData.pop();
       console.log(this.messagesData[i]);
 }

  }
}