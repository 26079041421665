<div class="col-sm-12">
    <div class="container">
        <div class="row">
            <div class="col-sm-6">
                <p class="section-title">Seleccionar usuarios: </p>
                <div class="line-space" style="width: 12%; height: 2px; background-color: orange;"></div>
                <div class="user-select--container">
                    <form [formGroup]="contactEmails" type="submit" style="width: 90% ;">
                        <mat-form-field appearance = "outline">
                            <mat-label>Usuarios</mat-label>
                            <mat-select formControlName="email" multiple >
                                <mat-option #allSelected (click)="selectAll()"  class="select" [value]="0">Todos</mat-option>
                                <mat-option #userCig (click)="selectUsersCig()"  class="select" [value]="1">Usuario CIG</mat-option>
                                <mat-option #userMix (click)="selectUsersMix()"  class="select" [value]="2">Usuarios CIG Mix (Compra y venta)</mat-option>
                                <mat-option #usersPurchases (click)="selectUsersPurchases()"  class="select" [value]="3">Usuarios CIG compras</mat-option>
                                <mat-option #usersSales (click)="selectUsersSales()"  class="select" [value]="4">Usuarios CIG Ventas</mat-option>
                                <mat-option #usersNotPartners (click)="selectUsersNotPartners()"  class="select" [value]="5">Usuarios no socios</mat-option>
                                <mat-option *ngFor="let contact of contacts"  class="select" [value]="contact"  (click)="selectThisContact()">{{contact.userEmail}}, {{contact.userFirstName}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </form>
                </div>
            </div>
            <div class="col-sm-6">
                <p class="section-title">Enviar a: </p>
                <div class="line-space" style="width: 7%; height: 2px; background-color: orange;"></div>
                <div class="contactEmails-container">
                    <div class="contact-select">
                        <div class="email-bubble" *ngFor="let contact of contactSelected[0]"><div class="text-content">{{contact.userEmail}}</div></div>
                   </div>
                </div>
            </div>
            <form [formGroup]="emailSend">
            <div class="col-sm-12" class="affair-col">
                <div class="affair-container">
                    <p class="section-title">Asunto: </p>
                    <div class="line-space" style="width: 2%; height: 2px; background-color: orange;"></div>
                    <textarea placeholder="Asunto" class="affair" formControlName="affair"></textarea>
                </div>
            </div>
            <div class="col-sm-12" class="message-col">
                <div class="message-container">
                    <p class="section-title">Mensaje: </p>
                    <div class="line-space" style="width: 2%; height: 2px; background-color: orange;"></div>
                    <textarea placeholder="Mensaje" class="message" formControlName="message"></textarea>
                </div>
            </div>
            <div class="col-sm-12">
                   <div class="upload-document--container">
                    <p class="section-title">Adjuntar documentos:</p>
                    <div class="line-space" style="width: 7%; height: 2px; background-color: orange;"></div>
                        <div class="input-container">
                            <input (change)="addDocument($event.target.files)" #uploadDocument nbInput type="file" class="custom-file-input" id="uploadDocument" accept="image/*, .pdf, .xlsx">
                            <!-- <span class ="cancel-container" *ngIf="fileToBase64 !== undefined" (click)="quitDocument()">
                                <i class="fas fa-times"></i>
                            </span> -->
    
                        </div>
                   </div>
            </div>
            <div class="col-sm-12">
                <div class="button-container">
                    <button type="submit" (click)="sendEmail()" class="button"><i class="fas fa-paper-plane" style="height: 20px; width: 20px; margin-right: 12px;"></i> Enviar correo electrónico</button> 
                </div>
            </div>
        </form>
        </div>
    </div>
</div>


