<app-loading-wrapper-overlay *ngIf="loading"></app-loading-wrapper-overlay>
<div *ngIf="productsData.productDelete !== 0">
<h1 style="margin-top: 5rem;margin-bottom: 5rem; font-size:3rem">
  {{mesage}}
</h1>
</div>
<div style="background-color: white !important; height: 85% !important; margin: 1rem;" id="principio" *ngIf="productsData.productDelete === 0">
  <div class="row">
    <div class="col-sm-12 col-md-12 col-lg-12 classMargen" style="padding-left: 4rem;padding-bottom: 0rem;padding-right: 5rem;" id="productoo">
      <div class="row">
        <div class="col-sm-11">
          <h1 class="title">Producto</h1>
        </div>
        <div class="col-sm-12">
          <hr style="height: 3px;color: #0078D7;background-color: #0078D7;opacity: 60%;">
        </div>
      </div>


      </div>
    <div class="col-sm-9 col-md-9 col-lg-9 classMargen" >
      <mat-card class="targetProduct" style="width: 95%;">
        <mat-card-content >
          <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-12" style="place-self: center;cursor: pointer; display: flex; justify-content: flex-end;">
              <span style="margin-right: 9px;">
                <img src="../../../../assets/images/editPublicity.png" style="cursor: pointer;opacity: 81%; text-align: left!important; margin-bottom: 25px !important; width: 1.6rem;"  (click)="openEditWindow(productsData)" *ngIf="userLog.userRole === 'Master' || userLog.userId === productsData.userCreateUUID">
               </span>
              <span style="margin-right: 9px;">
                <img src="../../../assets/images/deletePublicity.png" style="cursor: pointer;opacity: 80%; text-align: left!important; margin-bottom: 25px !important; width: 1.8rem;"  (click)="deleteProduct()" *ngIf="userLog.userRole === 'Master' || userLog.userId === productsData.userCreateUUID">
               </span>
              

                <a (click)="favSelect()" *ngIf="!fav"><i class="far fa-bookmark" style="font-size: 1.5rem;"></i></a>
                <a (click)="unFavSelect()" *ngIf="fav"><i class="fas fa-bookmark" style="font-size: 1.5rem; color:#F58626;"></i></a>

   
              </div>
          </div>
          <div class="row" style="height: 70% !important;">
            <div class="col-sm-12 col-md-12 col-lg-12">
              <div class="row">
                <div class="col-sm-5 col-md-5 col-lg-5">
                  <div id="my" class="carousel 1carousel-fade img-details" data-bs-ride="carousel" data-bs-interval="3000" style="height:auto">
                    <!-- <div class="carousel-indicators">
                      <button type="button" data-bs-target="#my" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
                      <button type="button" data-bs-target="#my" data-bs-slide-to="1" aria-label="Slide 2"></button>
                      <button type="button" data-bs-target="#my" data-bs-slide-to="2" aria-label="Slide 3"></button>
                      <button type="button" data-bs-target="#my" data-bs-slide-to="3" aria-label="Slide 4"></button>
                    </div> -->
                    <div class="carousel-inner">
                        <div *ngIf="productsData.productMainPicture != null" data-bs-target="#my"  class="carousel-item active" style="text-align: center;" (mouseenter)="showAument('img.carouseImage')" (mouseleave)="shadowImage()" data-bs-slide-to="0"> <img [src]="productsData.productMainPicture" [attr.data-zoom]="productsData.productMainPicture" class="carouseImage" id="myimage" style="cursor: pointer;">   </div>
                        <div *ngIf="productsData.productP1 != null" data-bs-target="#my" class="carousel-item" style="text-align: center;" (mouseenter)="showAument('img.p1')" (mouseleave)="shadowImage()" data-bs-slide-to="1"> <img [src]="productsData.productP1" class="carouseImage p1" [attr.data-zoom]="productsData.productP1" (click)="openimage(productsData.productP1)"> </div>
                        <div *ngIf="productsData.productP2 != null" data-bs-target="#my"  class="carousel-item" style="text-align: center;" (mouseenter)="showAument('img.p2')" (mouseleave)="shadowImage()" data-bs-slide-to="2"> <img [src]="productsData.productP2" class="carouseImage p2" [attr.data-zoom]="productsData.productP2"> </div>
                        <div *ngIf="productsData.productP3 != null" data-bs-target="#my" class="carousel-item" style="text-align: center;" (mouseenter)="showAument('img.p3')" (mouseleave)="shadowImage()" data-bs-slide-to="3"> <img [src]="productsData.productP3" class="carouseImage p3" [attr.data-zoom]="productsData.productP3"> </div>
                    </div> <button class="carousel-control-prev" type="button" data-bs-target="#my" data-bs-slide="next">
                        <div class="icon"> <span class="fas fa-arrow-left"></span> </div> <span class="visually-hidden">Previous</span>
                    </button> <button class="carousel-control-next" type="button" data-bs-target="#my" data-bs-slide="prev">
                        <div class="icon"> <span class="fas fa-arrow-right"></span> </div> <span class="visually-hidden">Next</span>
                    </button>
                  </div>
                  <div class="row" style="margin-top: 1rem!important;">

                      <div class="col-sm-3 productList" data-bs-target="#my" data-bs-slide-to="0" style="padding: 10px;" *ngIf="productsData.productMainPicture != null">
                        <img [src]="productsData.productMainPicture" class="imgProducts">

                      </div>
                      <div class="col-sm-3 productList" data-bs-target="#my" data-bs-slide-to="1" style="padding: 10px;" *ngIf="productsData.productP1 != null">
                        <img [src]="productsData.productP1" class="imgProducts">
                      </div>
                      <div class="col-sm-3 productList" data-bs-target="#my" data-bs-slide-to="2" style="padding: 10px;" *ngIf="productsData.productP2 != null">
                        <img [src]="productsData.productP2" class="imgProducts">
                      </div>
                      <div class="col-sm-3 productList" data-bs-target="#my" data-bs-slide-to="3" style="padding: 10px;" *ngIf="productsData.productP3 != null">
                        <img [src]="productsData.productP3" class="imgProducts">
                      </div>
                      <div class="col-sm-3 productListm" style="padding: 10px;">
                        <img [src]="productsData.productMainPicture" class="imgProductsm" data-bs-target="#my" data-bs-slide-to="0" *ngIf="productsData.productMainPicture != null">
                        <img [src]="productsData.productP1" class="imgProductsm" data-bs-target="#my" data-bs-slide-to="1" *ngIf="productsData.productP1 != null">
                        <img [src]="productsData.productP2" class="imgProductsm" data-bs-target="#my" data-bs-slide-to="2" *ngIf="productsData.productP2 != null">
                        <img [src]="productsData.productP3" class="imgProductsm" data-bs-target="#my" data-bs-slide-to="3" *ngIf="productsData.productP3 != null">
                      </div>

                  </div>
                </div>
               
                <div class="image-details--container" [style.display]="imageState">
                  <div class="image-details" ></div>
                </div> 
         
                <div class="col-sm-7 col-md-7 col-lg-7" style="padding: 1rem;">
                  <div class="row" style="padding-top: 0px!important;">
                    <div class="col-sm-12 col-md-12 col-lg-12">
                  
                      <div class="row">
                        <div class="col-sm-9 col-md-9 col-lg-9">
                          <h1 style="color: #055285; text-align: left; margin-bottom: 0% !important; font-size: 2rem;font-weight: 700;">{{productsData.productName}}</h1>
                        </div>
                     
                      </div>
                    </div>
                    <div class="col-sm-12 col-md-12 col-lg-12" style="margin-top: 0%;" *ngIf="1 * productsData.productPrice > 0">
                      <h1 style="color: #000d16; margin-top: 0%; text-align: left; font-size: 1.8rem;"><span>{{productsData.unitPriceType}} </span> {{productsData.productPrice}}</h1>
                    </div>
                    <div class="col-sm-12 col-md-12 col-lg-12" *ngIf="productsData.priceRangeValue1 !== null || productsData.priceRangeValue2 !== null || productsData.priceRangeValue3 !== null">
                      <div class="text-center">
                        <div class="container">
                            <div class="row pt-4">
                                <div class="col-md-4" *ngIf="productsData.priceRangeValue1 !== null">
                                    <div class="cards mb-4" style="box-shadow: none!important;border: none;border-radius: 0px; border-right: 1px solid #918f8f;">
                                        <div class="card-body" style="padding-top: 1rem;">
                                            <span class="product-range" style="font-size: 1.1rem; font-weight: 500;">{{productsData.priceRange11}} - {{productsData.priceRange12}}<span style="font-size: 1rem; font-weight: lighter;"> {{productsData.unitSystemValue}}</span></span>   
                                            <ul class="list-unstyled mt-3 mb-4">
                                                <li>{{productsData.unitPriceType}} {{productsData.priceRangeValue1}}</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4" *ngIf="productsData.priceRangeValue2 !== null">
                                    <div class="cards mb-4" style="box-shadow: none!important;border: none;border-right: 1px solid #918f8f;border-radius: 0px;">
                                        <div class="card-body" style="padding-top: 1rem;">
                                            <span class="product-range" style="font-size: 1.1rem; font-weight: 500;">{{productsData.priceRange21}} - {{productsData.priceRange22}}<span style="font-size: 1rem; font-weight: lighter;"> {{productsData.unitSystemValue}}</span></span>
                                            <ul class="list-unstyled mt-3 mb-4">
                                                <li>{{productsData.unitPriceType}} {{productsData.priceRangeValue2}}</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4" *ngIf="productsData.priceRangeValue3 !== null">
                                  <div class="cards mb-4" style="box-shadow: none!important;border: none;">
                                      <div class="card-body" style="padding-top: 1rem;">
                                          <span class="product-range" style="font-size: 1.1rem; font-weight: 500;">{{productsData.priceRange31}} - {{productsData.priceRange32}}<span style="font-size: 1rem; font-weight: lighter;"> {{productsData.unitSystemValue}}</span></span>
                                          <ul class="list-unstyled mt-3 mb-4">
                                              <li>{{productsData.unitPriceType}} {{productsData.priceRangeValue3}}</li>
                                          </ul>
                                      </div>
                                  </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                    <div class="col-sm-12 col-md-12 col-lg-12" style="padding-top: 1rem !important; height: 50% !important; text-align: left !important; overflow-x:auto;">
                        <p style="text-align: left;" *ngIf="!charCounter">{{productsData.productDescription}}</p>
                        <p style="text-align: left; font-size: 1.1vw;" id="product-description" *ngIf="charCounter">{{productsData.productDescription}}</p>
                    </div>
                  </div>
                </div>
                <div class="col-sm-12" style="z-index: 1!important;">
                   <!-- publicidad -->
                    <div class="publicityLog" >
                      <!-- <div id="my1" class="carousel carousel-fade img-details" data-bs-ride="carousel" data-bs-interval="3000" style="height:auto">
                           <div class="carousel-inner" id="carousel-h">
                              <div class="carousel-item {{publicity3.active}}" style="text-align: center;" *ngFor="let publicity3 of publicityListProduct1"> <img [src]="publicity3.publicityBanner" class="imgPublicity" (click)="openPublicity(publicity3.publicityLink)"> </div>
                             </div>

                        </div> -->
                        <section style="margin: 0 auto !important;">
                          <div class="carousel" id="carousel-h"  >
                            <ng-container *ngFor="let image of publicityListProduct1; index as i">
                              <div class="carousel-item" id="carousel-item--h" [ngClass]="{ 'active': i === activeIndexh }">
                                <img [src]="image.publicityBanner" (click)="openPublicity(image.publicityLink)">
                              </div>
                            </ng-container>
                          </div>
                        </section>
                     </div>
                    <!-- fin de publicidad -->
                </div>
                <div style="min-width: 100% !important; max-width: 100% !important; text-align-last: left; margin-bottom: 1rem; margin-top: 1rem;" *ngIf="!loading">
                  <h1 style="color: #004F9F; text-align: left; margin-bottom: 0% !important; font-size: 2rem;font-weight: 700;">Otros productos de <span *ngIf="userLog !== '' ">{{productsData.productCompany}}</span><span *ngIf="userLog == '' ">Inicie sesión para ver más información</span></h1>
                  <hr style="margin-top: 1rem; height: 2px;background-color: #F58626;opacity: 100%;margin-right: 3rem;">

                </div>
                <div class="row">
                  <div style="background-color: #FFFFFF;height: 5rem;position: absolute;width: 5%;left: 93%;margin-top: 10%;text-align: center;"  (click)="nextProducto()" *ngIf="this.productsListFinishedEnable">
                    <i class="fa fa-chevron-right" aria-hidden="true" id="nextPE"></i>
                  </div>
                  <div style="background-color: #FFFFFF;height: 5rem;position: absolute;width: 5%;left: 1%;margin-top: 10%;text-align: center;" id="prevPE" (click)="prevProducto()" *ngIf="this.productsListStartEnable">
                    <i class="fa fa-chevron-left" aria-hidden="true" id="prevPE"></i>
                  </div>
                  <div class="col-md-4" *ngFor="let products of productsList" style="padding: 1.5rem!important;">
                    <div class="card h-100"  style="border: none;padding: 0.5rem;cursor: pointer;box-shadow: 0 6px 20px rgba(71, 71, 71, 0.08), 0 0 6px rgba(71, 71, 71, 0.08);padding-bottom: 0%!important;"  (click)="viewProduct(products.productUUID)" >
                      <div style="width: 100%;padding: 1rem;height: 10rem;">
                        <img [src]='products.productMainPicture' alt="..." style="width: 100%;height: 10rem;">

                      </div>
                        <div class="card-body" style="margin-bottom: 0%!important;margin-top: 1.5rem!important;padding-left: 1rem; padding-right: 1rem;">
                          <div class="row">

                            <div class="col-sm-7">
                              <span style="color: #004F9F;font-size: 0.8rem;font-weight: 500;line-height: 0!important;">{{products.productName}}</span>
                            </div>
                            <div class="col-sm-5">
                              <span class="float-end" style="font-weight: 700;" *ngIf="1 * products.productPrice > 0 ">{{products.unitPriceType}} {{products.productPrice}}</span> 
                            </div>


                          </div>
                        </div>
                        <div class="col-sm-12" style="text-align: right;color: #9c9c9c;font-size: 0.7rem;bottom: 0;margin-bottom: 1rem;margin-right: 1rem;font-family: 'Arial';padding-left: 1rem; padding-right: 1rem;">
                          <small *ngIf="products.newCategory == null">{{products.productCategory}}</small>
                      <span class="hover top" (mouseenter)="mouseEvent(products.newCategory)" (mouseleave)="mouseLeave()" *ngIf="products.newCategory != null"><small>{{products.productCategory}}<span *ngIf="products.newCategory!= null"> +1</span></small>
                        <span class="text">{{categoryToShow}}</span>
                      </span>
                        </div>
                    </div>
                  </div>

              </div>

              <!-- productos similares -->

              <div style="min-width: 100% !important; max-width: 100% !important; text-align-last: left; margin-bottom: 1rem; margin-top: 1rem;" *ngIf="!loading">
                <h1 style="color: #004F9F; text-align: left; margin-bottom: 0% !important; font-size: 2rem;font-weight: 700;">Productos Similares</h1>
                <hr style="margin-top: 1rem; height: 2px;background-color: #F58626;opacity: 100%;margin-right: 3rem;">

              </div>
              <div class="row">
                <div style="background-color: #FFFFFF;height: 5rem;position: absolute;width: 5%;left: 93%;margin-top: 10%;text-align: center;"  (click)="nextProductoSimilar()" *ngIf="this.productsListFinishedEnableSimilar">
                  <i class="fa fa-chevron-right" aria-hidden="true" id="nextPE"></i>
                </div>
                <div style="background-color: #FFFFFF;height: 5rem;position: absolute;width: 5%;left: 1%;margin-top: 10%;text-align: center;" id="prevPE" (click)="prevProductoSimilar()" *ngIf="this.productsListStartEnableSimilar">
                  <i class="fa fa-chevron-left" aria-hidden="true" id="prevPE"></i>
                </div>
                <div class="col-md-4" *ngFor="let products of productsListSimilar" style="padding: 1.5rem!important;">
                  <div class="card h-100"  style="border: none;padding: 0.5rem;cursor: pointer;box-shadow: 0 6px 20px rgba(71, 71, 71, 0.08), 0 0 6px rgba(71, 71, 71, 0.08);padding-bottom: 0%!important;"  (click)="viewProduct(products.productUUID)" >
                    <div style="width: 100%;padding: 1rem;height: 10rem;">
                      <img [src]='products.productMainPicture' alt="..." style="width: 100%;height: 10rem;">

                    </div>
                      <div class="card-body" style="margin-bottom: 0%!important;margin-top: 1.5rem!important;padding-left: 1rem; padding-right: 1rem;">
                        <div class="row">

                          <div class="col-sm-7">
                            <span style="color: #004F9F;font-size: 0.8rem;font-weight: 500;line-height: 0!important;">{{products.productName}}</span>
                          </div>
                          <div class="col-sm-5">
                            <span class="float-end" style="font-weight: 700;" *ngIf="1 * products.productPrice > 0 ">{{products.unitPriceType}} {{products.productPrice}}</span> 
                          </div>


                        </div>
                      </div>
                      <div class="col-sm-12" style="text-align: right;color: #9c9c9c;font-size: 0.7rem;bottom: 0;margin-bottom: 1rem;margin-right: 1rem;font-family: 'Arial';padding-left: 1rem; padding-right: 1rem;">
                        <small *ngIf="products.newCategory == null">{{products.productCategory}}</small>
                      <span class="hover top" (mouseenter)="mouseEvent(products.newCategory)" (mouseleave)="mouseLeave()" *ngIf="products.newCategory != null"><small>{{products.productCategory}}<span *ngIf="products.newCategory!= null"> +1</span></small>
                        <span class="text">{{categoryToShow}}</span>
                      </span>
                      </div>
                  </div>
                </div>

            </div>

              </div>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
    <div class="col-sm-4 col-md-4 col-lg-3" style="padding-top: 2% !important; padding-right: 2%;">
      <mat-card style="border-radius: 10px !important;">
        <mat-card-content>
          <div class="col-sm-12 col-md-12 col-lg-12" style="padding: 1rem">
            <div class="col-sm-12 col-md-12 col-lg-12" style="margin-bottom: 0.5rem;">
              <div class="row">
                <div class="col-sm-10 col-md-10 col-lg-10" style="align-self: center !important; margin-bottom: 30px!important;">
                <span style="font-weight:600 !important; font-size: 1.4rem !important; color:#898989 !important;">Proveedor</span>
                </div>
                <div class="col-sm-10 col-md-10 col-lg-10" style="align-self: center !important;">
                  <span style="font-weight:bold !important; font-size: 1rem !important; color: #055285 !important;" id="company-name" (click)="navigateToCompanyInfo()" *ngIf="userLog !== '' ">{{productsData.productCompany}} </span>
                  <span style="font-weight:bold !important; font-size: 1rem !important; color: #055285 !important;" id="company-name" (click)="navigateToCompanyInfo()" *ngIf="userLog == '' ">Inicie sesión para ver más información</span>
                  <span class="square"  *ngIf="userLog !== '' "><img *ngIf="companyData.companyBadge === 1" src="../../../../assets/images/core/Insignia.png" height="35" width="30" /></span>
                </div>
              </div>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-12" style="margin-bottom: 0.5rem;">
              <span style=" font-size: 0.8rem !important; color: #000000; " *ngIf="userLog !== ''">{{companyData.companyAddress}}</span>
            </div>
            <!-- <div class="col-sm-12 col-md-12 col-lg-12" style="margin-bottom: 0.5rem;"> -->
              <!-- <button mat-button shape="round" size="tiny" style="background-color: #2ea0f9; font-weight: 800; color: white;" (click)="navigateToCompanyInfo()">Ver en directorio</button> -->
            <!-- </div> -->
            <div class="col-sm-12 col-md-12 col-lg-12" style="margin-bottom: 0.5rem;">
              <span style="font-weight: bold !important; font-size: 0.7rem !important; color:  #0078D7 !important;"><u>{{companyData.companyCategory}} </u>
                <span class="hover top" (mouseenter)="mouseEvent(productsData.newCategory)" (mouseleave)="mouseLeave()"><span *ngIf="productsData.newCategory !== null" style="color: #0078d7; font-weight: bolder; font-size: 0.9rem;"> +1</span><span class="text">{{categoryToShow}}</span></span></span>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-12">
                <div class="row">
                <!---
                  <div class="col-sm-2 col-md-2 col-lg-2" style="align-self: center;">
                    <span style="font-weight: bold !important; font-size: 0.9rem !important; color: #437da3 !important;">Puntuación </span>
                  </div>
                  <div class="col-sm-8 col-md-8 col-lg-8">
                     <ngx-star-rating [(ngModel)]="rating3" [id]="'rating3'"></ngx-star-rating >
                      <div class="rating"> <input #starFive type="radio" name="rating" value="5" id="5" (click)="saveValue(starFive.value)"><label for="5">☆</label> <input #starFour type="radio" name="rating" value="4" id="4" (click)="saveValue(starFour.value)"><label for="4">☆</label> <input #starThree type="radio" name="rating" value="3" id="3" (click)="saveValue(starThree.value)"><label for="3">☆</label> <input #starTwo type="radio" name="rating" value="2" id="2" (click)="saveValue(starTwo.value)"><label for="2">☆</label> <input #starOne type="radio" name="rating" value="1" id="1" (click)="saveValue(starOne.value)"><label for="1">☆</label>
                      </div>
                  </div>
                 -->
                </div>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-12" style="margin-bottom: 0.5rem; margin-top: 0.5rem;">
              <button mat-button shape="round" size="small" style="background-color: #055285; color: white; border-radius: 12px!important;" (click)="startChat()" *ngIf="sameUser">Contactar al vendedor</button>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
      <div class="col-sm-4 col-md-4 col-lg-4" style="margin-bottom: 1rem; margin-top: 1rem;width: 100%;">
        <!-- <img width="95%" height="90%" src="https://fs.easybanners.com/Templates/3426/TemplateIcon/3426.png" style="border-radius: 10px !important;"> -->
        <!-- publicidad -->
        <!-- <div style="width: 95%;border-radius: 10px !important;height:90%">
          <div id="my" class="carousel 1carousel-fade img-details" data-bs-ride="carousel" data-bs-interval="4000" style="height:auto">
              <div class="carousel-inner" id="carousel-v">
                 <div class="carousel-item {{publicity2.active}}" style="text-align: center;" *ngFor="let publicity2 of publicityListProduct2"> <img [src]="publicity2.publicityBanner" class="imgPublicityV" style="border-radius:10px;" (click)="openPublicity(publicity2.publicityLink)"> </div>
                </div>
           </div>
        </div> -->
        <section style="margin: 0 auto !important;" id="publicity-section--container">
          <div class="carousel" id="carousel-v"  >
            <ng-container *ngFor="let image of publicityListProduct2; index as i">
              <div class="carousel-item" id="carousel-item--v" [ngClass]="{ 'active': i === activeIndexv }">
                <img [src]="image.publicityBanner" (click)="openPublicity(image.publicityLink)">
              </div>
            </ng-container>
          </div>
        </section>
       <!-- fin de publicidad -->
      </div>
    </div>
  </div>
</div>
