<app-loading-wrapper-overlay *ngIf="loading"></app-loading-wrapper-overlay>
<app-public-carousel></app-public-carousel>
<main>
  <div class="row">
    <div class="col-sm-12 title">
      
        <h1 class="featuredProducts">{{this.principalTitle}}</h1>
      
    </div>
  </div>
  <div class="container-fluid bg-trasparent my-4 p-3" style="position: relative;padding-left: 10%!important;padding-right: 10%!important;" >
      <div class="row">
        <div class="col-md-3" *ngFor="let products of productsList" style="padding: 1.5rem!important;height: auto">
          <div class="card h-100"  style="border: none;padding: 0.5rem;cursor: pointer;box-shadow: 0 6px 20px rgba(71, 71, 71, 0.08), 0 0 6px rgba(71, 71, 71, 0.08);padding-bottom: 0%!important;"  (click)="viewProduct(products.productUUID)" >
            <div style="width: 100%;padding: 1rem;height: 10rem;">
              <img [src]='products.productMainPicture' alt="..." style="width: 100%;height: 10rem;">
            
            </div>
              <div class="card-body" style="margin-bottom: 0%!important;margin-top: 3px;">
                <div class="row">
                  
                  <div class="col-sm-7">
                    <span style="color: #004F9F;font-size: 0.8rem;font-weight: 500;line-height: 0!important;">{{products.productName}}</span>
                  </div>
                  <div class="col-sm-5">
                    <span class="float-end" style="font-weight: 700;" *ngIf=" 1 * products.productPrice > 0">{{products.unitPriceType}}. {{products.productPrice}}</span> 
                  </div>
                  
                  
                </div>
                  <!-- <div class="clearfix mb-3"> <span class="float-start price-hp" style="color: black;">{{products.productName}}</span> <span class="float-end">Q {{products.productPrice}}</span> </div>
                  <div class="product-details">
                    <div class="buttons d-flex flex-row">
                        <div class="cart"><i class="fa fa-star"></i></div> <button class="btn btn-success cart-button btn-block" (click)="viewProduct(products.productUUID)"><span class="dot"></span><i class="fa fa-eye"></i> Ver</button>
                    </div>
                    <div class="weight"> <small>{{products.productCategory}}</small> </div>
                  </div> -->
              </div>
              <div class="col-sm-12" style="text-align: right;color: #9c9c9c;font-size: 0.7rem;bottom: 0;margin-bottom: 1rem;padding-right: 1rem;font-family: 'Arial';padding-left: 1rem;">
                 <small *ngIf="products.newCategory == null">{{products.productCategory}}</small>
                      <span class="hover top" (mouseenter)="mouseEvent(products.newCategory)" (mouseleave)="mouseEvent2()" *ngIf="products.newCategory != null"><small>{{products.productCategory}}<span *ngIf="products.newCategory!= null"> +1</span></small>
                        <span class="text">{{categoryToShow}}</span>
                      </span>
              </div>
          </div>
      </div>
      <div class="col-md-12" style="z-index: 1!important;">
        <!-- publicidad -->
         <div class="publicityLog" >
           <div id="my1" class="carousel carousel-fade img-details" data-bs-ride="carousel" data-bs-interval="3000" style="height:auto">
                <div class="carousel-inner">
                   <div class="carousel-item {{publicity3.active}}" style="text-align: center;" *ngFor="let publicity3 of publicityListfirst"> <img [src]="publicity3.publicityBanner" class="imgPublicity"> </div>
                  </div>  
                  
             </div>
          </div>
         <!-- fin de publicidad -->
     </div>
      

     <!-- segunda fila despues del primer anuncio -->
     <div class="col-md-3" *ngFor="let products of productsList2" style="padding: 1.5rem!important;height: auto">
      <div class="card h-100"  style="border: none;padding: 0.5rem;cursor: pointer;box-shadow: 0 6px 20px rgba(71, 71, 71, 0.08), 0 0 6px rgba(71, 71, 71, 0.08);padding-bottom: 0%!important;"  (click)="viewProduct(products.productUUID)" >
        <div style="width: 100%;padding: 1rem;height: 10rem;">
          <img [src]='products.productMainPicture' alt="..." style="width: 100%;height: 10rem;">
        
        </div>
          <div class="card-body" style="margin-bottom: 0%!important;margin-top: 3px;">
            <div class="row">
              
              <div class="col-sm-7">
                <span style="color: #004F9F;font-size: 0.8rem;font-weight: 500;line-height: 0!important;">{{products.productName}}</span>
              </div>
              <div class="col-sm-5">
                <span class="float-end" style="font-weight: 700;" *ngIf=" 1 * products.productPrice > 0">{{products.unitPriceType}}. {{products.productPrice}}</span> 
              </div>
              
              
            </div>
              <!-- <div class="clearfix mb-3"> <span class="float-start price-hp" style="color: black;">{{products.productName}}</span> <span class="float-end">Q {{products.productPrice}}</span> </div>
              <div class="product-details">
                <div class="buttons d-flex flex-row">
                    <div class="cart"><i class="fa fa-star"></i></div> <button class="btn btn-success cart-button btn-block" (click)="viewProduct(products.productUUID)"><span class="dot"></span><i class="fa fa-eye"></i> Ver</button>
                </div>
                <div class="weight"> <small>{{products.productCategory}}</small> </div>
              </div> -->
          </div>
          <div class="col-sm-12" style="text-align: right;color: #9c9c9c;font-size: 0.7rem;bottom: 0;margin-bottom: 1rem;padding-right: 1rem;font-family: 'Arial';padding-left: 1rem;">
            <small *ngIf="products.newCategory == null">{{products.productCategory}}</small>
                      <span class="hover top" (mouseenter)="mouseEvent(products.newCategory)" (mouseleave)="mouseEvent2()" *ngIf="products.newCategory != null"><small>{{products.productCategory}}<span *ngIf="products.newCategory!= null"> +1</span></small>
                        <span class="text">{{categoryToShow}}</span>
                      </span>
          </div>
      </div>
  </div>
     <!-- fin de segunda fila despues del primer anuncio -->


     <div class="col-md-12" style="z-index: 1!important;">
      <!-- publicidad -->
       <div class="publicityLog" >
         <div id="my2" class="carousel carousel-fade img-details" data-bs-ride="carousel" data-bs-interval="3000" style="height:auto">
              <div class="carousel-inner">
                 <div class="carousel-item {{publicity3.active}}" style="text-align: center;" *ngFor="let publicity3 of publicityListSecond"> <img [src]="publicity3.publicityBanner" class="imgPublicity"> </div>
                </div>  
                
           </div>
        </div>
       <!-- fin de publicidad -->
   </div>
    

   <!-- tercer fila despues del segundo anuncio -->
   <div class="col-md-3" *ngFor="let products of productsList3" style="padding: 1.5rem!important;height: auto">
    <div class="card h-100"  style="border: none;padding: 0.5rem;cursor: pointer;box-shadow: 0 6px 20px rgba(71, 71, 71, 0.08), 0 0 6px rgba(71, 71, 71, 0.08);padding-bottom: 0%!important;"  (click)="viewProduct(products.productUUID)" >
      <div style="width: 100%;padding: 1rem;height: 10rem;">
        <img [src]='products.productMainPicture' alt="..." style="width: 100%;height: 10rem;">
      
      </div>
        <div class="card-body" style="margin-bottom: 0%!important;margin-top: 3px;">
          <div class="row">
            
            <div class="col-sm-7">
              <span style="color: #004F9F;font-size: 0.8rem;font-weight: 500;line-height: 0!important;">{{products.productName}}</span>
            </div>
            <div class="col-sm-5">
              <span class="float-end" style="font-weight: 700;" *ngIf=" 1 * products.productPrice > 0">{{products.unitPriceType}}. {{products.productPrice}}</span> 
            </div>
            
            
          </div>
            <!-- <div class="clearfix mb-3"> <span class="float-start price-hp" style="color: black;">{{products.productName}}</span> <span class="float-end">Q {{products.productPrice}}</span> </div>
            <div class="product-details">
              <div class="buttons d-flex flex-row">
                  <div class="cart"><i class="fa fa-star"></i></div> <button class="btn btn-success cart-button btn-block" (click)="viewProduct(products.productUUID)"><span class="dot"></span><i class="fa fa-eye"></i> Ver</button>
              </div>
              <div class="weight"> <small>{{products.productCategory}}</small> </div>
            </div> -->
        </div>
        <div class="col-sm-12" style="text-align: right;color: #9c9c9c;font-size: 0.7rem;bottom: 0;margin-bottom: 1rem;padding-right: 1rem;font-family: 'Arial';padding-left: 1rem;">
          <small *ngIf="products.newCategory == null">{{products.productCategory}}</small>
                      <span class="hover top" (mouseenter)="mouseEvent(products.newCategory)" (mouseleave)="mouseEvent2()" *ngIf="products.newCategory != null"><small>{{products.productCategory}}<span *ngIf="products.newCategory!= null"> +1</span></small>
                        <span class="text">{{categoryToShow}}</span>
                      </span>
        </div>
    </div>
</div>
   <!-- fin de tercer fila despues del segundo anuncio -->


      </div>
      <div class="row">
        <div class="col-sm-12" style="margin-top: 1rem;">
          <div class="row">
              <div class="col-sm-3">
                <span style="color: #636363;font-family: 'Lato';font-size: 1.1rem;">{{this.listText}}</span>
                
              </div>
              <div class="col-sm-6" >
                   <div class="row" *ngIf="productListDiv.length > 0 " style="justify-content: center!important;">
                    <div class="col-sm-2" style="text-align: center;border: none;margin: 0.5rem;padding: 0.6rem;cursor: pointer;color: #0078D7;font-family: 'Lato';font-size: 0.9rem;font-weight: 700;" (click)="prev()" *ngIf="this.actual.numero > 1">
                      <span>..Anterior</span>
                     </div> 
                       <div class="col-sm-2 card {{listado.color}}" *ngFor="let listado of productListDiv" style="text-align: center;border: none;margin: 0.5rem;width: 2.5rem;height: 2.5rem;padding: 0.6rem;cursor: pointer;box-shadow: 0 6px 20px rgba(71, 71, 71, 0.08), 0 0 6px rgba(71, 71, 71, 0.08);" (click)="changeListProduct(listado)">
                             <span>{{listado.numero}}</span>
                        </div>
                        <div class="col-sm-2" style="text-align: center;border: none;margin: 0.5rem;padding: 0.6rem;cursor: pointer;color: #0078D7;font-family: 'Lato';font-size: 0.9rem;font-weight: 700;" (click)="next()" *ngIf="nextavailable">
                          <span>Siguiente..</span>
                     </div>
                   </div>
              </div>
              <div class="col-sm-3" style="text-align: right;">
                 <span style="color: #636363;font-family: 'Lato';font-size: 1.1rem;">Mostrar<span style="cursor: pointer;" (click)="listProductSize(this.contador20)"> {{this.contador20}} </span>|<span style="cursor: pointer;" (click)="listProductSize(this.contador40)"> {{this.contador40}} </span>|<span style="cursor: pointer;" (click)="listProductSize(this.contador60)"> {{this.contador60}} </span>&nbsp;&nbsp;</span>
              </div>
          </div>
      </div>
      </div>
  </div>
</main>
<a href="https://wa.me/41508502" class="whatsapp" target="_blank" > <i class="whatsapp-icon"></i></a>
