import { ChatTableModel } from './../models/chatTableModel';
import { Injectable, NgZone } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthenticationDetails, CognitoUser, CognitoUserAttribute, CognitoUserPool } from 'amazon-cognito-identity-js';
import { environment } from 'src/environments/environment';
import { MasterChatTableModel } from '../models/masterChatTableModel';
import { Filter3Pipe } from '../pipes/filter3.pipe';
import * as S3 from 'aws-sdk/clients/s3';

const POOL_DATA = {
  UserPoolId: environment.UserPoolId,
  ClientId: environment.ClientId
};
const userPool = new CognitoUserPool(POOL_DATA);

@Injectable({
  providedIn: 'root'
})
export class MessageCenterService {
  private AWS = require('aws-sdk');
  constructor(
    private http: HttpClient,
  ) {}

  apiurl = environment.EnvironmentName;
  mainMessageTableGet(userUUID){
    const filter = userUUID;
    return this.http.get(environment.API_URL + this.apiurl+'-mainTableChat/' + filter).toPromise();
  }

  mainMessageTableTempGet(userUUID){
    const filter = userUUID;
    return this.http.get(environment.API_URL + this.apiurl+ '-mainTableTempChat/' + filter).toPromise();
  }

  mainMessageTableInsert(masterChatTableData: MasterChatTableModel){
    let params = JSON.stringify(masterChatTableData);
    const headers = new HttpHeaders().set('Content-Type', 'application/json');

    return this.http.post(environment.API_URL + this.apiurl+ '-mainTableChat', params, { headers}).toPromise();
  }

  chatTableInsert(chatTableModel: ChatTableModel){
    let params = JSON.stringify(chatTableModel);
    const headers = new HttpHeaders().set('Content-Type', 'application/json');

    return this.http.post(environment.API_URL + this.apiurl+ '-chatTable', params, { headers}).toPromise();
  }

  chatTableTempDelete(chatTableModel: ChatTableModel){
    let params = JSON.stringify(chatTableModel);
    const headers = new HttpHeaders().set('Content-Type', 'application/json');

    return this.http.post(environment.API_URL + this.apiurl+ '-chatTableTempDelete', params, { headers}).toPromise();
  }

  mainChatTableStatusUpdate(chatTableModel: ChatTableModel){
    let params = JSON.stringify(chatTableModel);
    const headers = new HttpHeaders().set('Content-Type', 'application/json');

    return this.http.post(environment.API_URL + this.apiurl + '-mainChatTableStatusUpdate', params, { headers}).toPromise();
  }
  mainChatTableQualifyUpdate(chatTableModel){
    let params = JSON.stringify(chatTableModel);
    const headers = new HttpHeaders().set('Content-Type', 'application/json');

    return this.http.post(environment.API_URL + this.apiurl+ '-mainChatTableQualifyUpdate', params, { headers}).toPromise();
  }

  chatTableStatusUpdate(chatTableModel: ChatTableModel){
    let params = JSON.stringify(chatTableModel);
    const headers = new HttpHeaders().set('Content-Type', 'application/json');

    return this.http.post(environment.API_URL + this.apiurl+ '-chatTableStatusUpdate', params, { headers}).toPromise();
  }

  chatTableInsertTemp(chatTableModel: ChatTableModel){
    let params = JSON.stringify(chatTableModel);
    const headers = new HttpHeaders().set('Content-Type', 'application/json');

    return this.http.post(environment.API_URL +this.apiurl+ '-chatTableTemp', params, { headers}).toPromise();
  }

  chatTableGet(chatUUID){
    const filter = chatUUID;
    return this.http.get(environment.API_URL +this.apiurl+ '-chatTable/' + filter).toPromise();
  }

  mainGetChatOld(chatUUID,chatUUID2, productUUID ){
    const filter = chatUUID;
    const filter2 = chatUUID2;
    const filter3 = productUUID;
    return this.http.get(environment.API_URL + this.apiurl+'-getChatOld/' + filter+'/two/'+filter2 + '/productUUID/'+ filter3).toPromise();
  }

  chatTableTempGet(chatUUID){
    const filter = chatUUID;
    return this.http.get(environment.API_URL + this.apiurl+'-chatTableTemp/' + filter).toPromise();
  }
  sendEmailPersonalized(funcionArgs: any) {
    this.AWS.config.update({
      accessKeyId: environment.accessKeyId,
      secretAccessKey: environment.secretAccessKey,
      region: environment.regionSES
    });
    const lambda = new this.AWS.Lambda({apiVersion: '2015-03-31'});

    var params = {
      // FunctionName: 'arn:aws:lambda:us-east-1:405522164029:function:cigmatchbackend-dev-sendEmailPersonalized',
      FunctionName: 'arn:aws:lambda:us-west-2:120259615165:function:cigmatchbackendprod-prod-sendEmailPersonalized',
      Payload: JSON.stringify(funcionArgs),
      InvocationType: 'RequestResponse'
      // Qualifier: "1"
     };
     
     return new Promise(async (resolve, reject)=>{
      await lambda.invoke(params,  (err, data) => {
        if(err){
          reject(err);
          return;
        }
        resolve(data);
      });
    });
  }

  uploadFileS3(file, companyUUID): Promise<any> {
    // this.companyProgress.next(0);
    const contentType = file.type;
    const bucket = new S3(
      {
        accessKeyId: environment.accessKeyId,
        secretAccessKey: environment.secretAccessKey,
        region: environment.region
      }
    );
    const params = {
      Bucket: environment.bucketFiles,
      Key: 'messageFiles' + '/' + companyUUID + '/' + file.name,
      Body: file,
      ContentType: contentType,
      ACL: 'public-read'
    };
    return new Promise((resolve, reject) => {
      bucket.upload(params).on('httpUploadProgress', (evt) => {
        const percent = (evt.loaded / evt.total) * 100;
        // this.companyProgress.next(percent);
      }).send((err, data) => {
        if (err) {
          return reject(err);
        }
        data.Location = environment.downloadObjectUrl + params.Key.replace(' ', '+');
        return resolve(data);
      });
    });
  }
}
