<app-loading-wrapper-overlay *ngIf="loading"></app-loading-wrapper-overlay>
<mat-card class="masterCard">
  <mat-tab-group mat-stretch-tabs>
    <mat-tab label="REGISTRO DE EMPRESA">
      <div class="col-sm-12" style="text-align: left !important;">
      <section class="buyerProfileSelect" style="margin-top: 50px; margin-left: 150px;">
        <mat-label style="margin-right: 12px;">Perfil comprador</mat-label>
        <mat-slide-toggle [(ngModel)] = "buyerProfile" [ngModelOptions]="{standalone: false}" (change)="buyerProfileActivate($event)"></mat-slide-toggle>
      </section>
      </div>
      <div class="col-sm-12" style="text-align: right !important; margin-top: 1rem;">
        <img src="../../../assets/images/edificios.png" style="width: 10rem !important;">
      </div>
      <div class="col-sm-12">
        <table class="example-full-width" cellspacing="0"><tr>
          <td><mat-form-field class="example-full-width" appearance="fill">
            <mat-label>Nombre de empresa</mat-label>
            <input matInput [(ngModel)]="company.companyName" [disabled]="buyerProfile">
            </mat-form-field></td>
            <td><mat-form-field class="example-full-width" appearance="fill">
              <mat-label>Dirección de empresa</mat-label>
              <input matInput [(ngModel)]="company.companyAddress" [disabled]="buyerProfile">
            </mat-form-field></td>
          </tr>
        </table>
        <table class="example-full-width" cellspacing="0"><tr>
          <td>
            <mat-form-field class="example-full-width" appearance="fill" >
              <mat-label>Categoría</mat-label>
              <mat-select (selectionChange)="listCategories($event)" [(ngModel)]="company.companyCategoryUUID" [disabled]="buyerProfile">
                <mat-option [value]="masterCat.masterCatUUID" *ngFor="let masterCat of masterCatList" >{{masterCat.masterCatName}}</mat-option>
              </mat-select>
            </mat-form-field>
          </td>
          <td>
            <mat-form-field class="example-full-width" appearance="fill">
              <mat-label>Subcategoría</mat-label>
              <mat-select [disabled]="subCatStatus" (selectionChange)="listSubCat($event)" [(ngModel)]="company.subCatUUID">
                <mat-option [value]="subcat.catUUID" *ngFor="let subcat of categoriesList">{{subcat.catName}}</mat-option>
              </mat-select>
            </mat-form-field>
          </td>
          <td>
            <mat-form-field class="example-full-width" appearance="fill">
              <mat-label>Item de subcategoría</mat-label>
              <mat-select [disabled]="familyCatStatus" (selectionChange)="itemFamily($event)" [(ngModel)]="company.familyItemUUID">
                <mat-option [value]="families.subCatUUID" *ngFor="let families of subcategoryList">{{families.subCatName}}</mat-option>
              </mat-select>
            </mat-form-field>
          </td>
          <td style= "padding-bottom: 24px; width: 1px !important;" (click)="addCategory()">
            <span class="hover top">
              <span class="addButton" style="cursor: pointer; width: 40px; height: 90px;">
                <i class="fas fa-plus" style="width: 20px; height: 20px;"></i>
              </span>
              <span class="text">
                Agregar otra categoría.
              </span>
            </span>
          </td>
          </tr>
        </table>
        <section class="new-table" *ngIf="categoryCant">
          <table class="example-full-width" cellspacing ="0">
            <tr>
              <td >
                <mat-form-field class="example-full-width" appearance="fill">
                  <mat-label>Categoría</mat-label>
                  <mat-select [formControl]="categoriaTwo" (selectionChange)="listOtherCategories($event)" [(ngModel)]="newCategory.productCategoryUUID" required [disabled]="buyerProfile">
                    <mat-option [value]="masterCat.masterCatUUID" *ngFor="let masterCat of masterCatList" (click)="addExtraCategory(masterCat.masterCatName)">
                      {{masterCat.masterCatName}}
                    </mat-option>
                  </mat-select>
                  <mat-error *ngIf="categoriaTwo.hasError('required')">Seleccione una categoría para continuar</mat-error>
                  <mat-hint>{{categoria.value?.sound}}</mat-hint>
                </mat-form-field>
              </td>
              <td>
                <mat-form-field class="example-full-width" appearance="fill">
                  <mat-label>Sub-categoría</mat-label>
                  <mat-select [formControl]="subcategoriaTwo" [disabled]="subCatStatusTwo" (selectionChange)="listOtherSubCat($event)" [(ngModel)]="newCategory.productSubCatUUID" required>
                    <mat-option [value]="subcat.catUUID" *ngFor="let subcat of categoriesListTwo" (click)="addExtraSubCat(subcat.catDescription)">
                      {{subcat.catName}}
                    </mat-option>
                  </mat-select>
                  <mat-error *ngIf="subcategoriaTwo.hasError('required')">Seleccione una subcategoría para continuar</mat-error>
                  <mat-hint>{{subcategoria.value?.sound}}</mat-hint>
                </mat-form-field>
              </td>
              <td>
                <mat-form-field class="example-full-width" appearance="fill">
                  <mat-label>Familia de sub-categoria</mat-label>
                  <mat-select [formControl]="itemsTwo" [disabled]="familyCatStatusTwo" [(ngModel)]="newCategory.itemFamilyUUID" (selectionChange)="itemOtherFamily($event)" required>
                    <mat-option [value]="families.subCatUUID" *ngFor="let families of subcategoryListTwo" (click)="addExtraFamily(families.subCatName)">
                      {{families.subCatName}}
                    </mat-option>
                  </mat-select>
                  <mat-error *ngIf="itemsTwo.hasError('required')">Relacione un grupo a la subcategoría</mat-error>
                  <mat-hint>{{items.value?.sound}}</mat-hint>
                </mat-form-field>
              </td>
          </table>
        </section>
        <table class="example-full-width" cellspacing="0"><tr>
          <td>
            <mat-form-field class="example-full-width" appearance="fill">
              <mat-label>Descripción de la empresa</mat-label>
              <textarea matInput placeholder="Descripción de la empresa" style="min-height: 110px;max-height: 110px;" maxlength="1000" [(ngModel)]="company.companyDescription" [disabled]="buyerProfile"></textarea>
              <span style="display: block;" class="char-counter"*ngIf="company.companyDescription != null" >{{charCounter | charCount: company.companyDescription}}/1000</span>
            </mat-form-field>
          </td>
          </tr>
        </table>
        <table class="example-full-width" cellspacing="0"><tr>
          <td><mat-form-field class="example-full-width" appearance="fill">
            <mat-label>Teléfono 1</mat-label>
            <input matInput [(ngModel)]="company.companyPhone1">
            </mat-form-field></td>
            <td><mat-form-field class="example-full-width" appearance="fill">
              <mat-label>NIT</mat-label>
              <input matInput [(ngModel)]="company.companyNIT">
            </mat-form-field></td>
          </tr>
        </table>
        <table class="example-full-width" cellspacing="0"><tr>
          <td><mat-form-field class="example-full-width" appearance="fill">
            <mat-label *ngIf="!buyerProfile">Correo empresarial</mat-label>
            <mat-label *ngIf="buyerProfile">Correo elctrónico</mat-label>
            <input matInput [(ngModel)]="company.companyEmail">
            </mat-form-field></td>
            <td><mat-form-field class="example-full-width" appearance="fill">
              <mat-label>URL empresarial</mat-label>
              <input matInput [(ngModel)]="company.companySite" [disabled]="buyerProfile">
            </mat-form-field></td>
          </tr>
        </table>
      </div>
    </mat-tab>
    <mat-tab label="REGISTRO DE USUARIO">
      <div class="col-sm-12" style="text-align: right !important; margin-top: 1rem;">
        <img src="../../../assets/images/userblacklogo.png" style="width: 17.8rem !important;">
      </div>
      <div class="col-sm-12">
        <table class="example-full-width" cellspacing="0"><tr>
          <td>
            <mat-form-field class="example-full-width" appearance="fill">
              <mat-label>País</mat-label>
              <mat-select [(ngModel)]="user.userCountry">
                <mat-option [value]="countryList.name" *ngFor="let countryList of countryList">{{countryList.name}}</mat-option>
              </mat-select>
            </mat-form-field>
          </td>
          <td>
            <mat-form-field class="example-full-width" appearance="fill">
              <mat-label>Rol de usuario</mat-label>
              <mat-select [(ngModel)]="user.userRole" [disabled]="buyerProfile">
                <mat-option [value]="roleList.code" *ngFor="let roleList of rolesList">{{roleList.name}}</mat-option>
              </mat-select>
            </mat-form-field>
          </td>
          </tr>
        </table>
        <table class="example-full-width" cellspacing="0"><tr>
          <td><mat-form-field class="example-full-width" appearance="fill">
            <mat-label>Correo Electrónico</mat-label>
            <input matInput [(ngModel)]="user.userEmail">
            </mat-form-field></td>
          <td><mat-form-field class="example-full-width" appearance="fill">
            <mat-label>Compañía</mat-label>
            <input matInput [(ngModel)]="company.companyName" disabled>
            </mat-form-field></td>
          </tr>
        </table>
        <table class="example-full-width" cellspacing="0"><tr>
          <td><mat-form-field class="example-full-width" appearance="fill">
            <mat-label>Primer Nombre</mat-label>
            <input matInput [(ngModel)]="user.userFirstName">
            </mat-form-field></td>
            <td><mat-form-field class="example-full-width" appearance="fill">
              <mat-label>Primer Apellido</mat-label>
              <input matInput [(ngModel)]="user.userLastName">
            </mat-form-field></td>
          </tr>
        </table>
        <div class="col-sm-12" style="margin: 2rem 0 0 0; text-align: center;"
        *ngIf="user.userCountry &&  user.userRole && user.userEmail && company.companyName && user.userFirstName && user.userLastName">
          <button mat-raised-button style="background-color: green; color: white;" (click)="saveCompany()"><mat-icon>save</mat-icon>Guardar</button>
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>
</mat-card>
