import { AuthService } from 'src/app/services/auth.service';
import { Component, KeyValueDiffers, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { UserService } from 'src/app/services/user.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { UserModel } from 'src/app/models/userModel';
import masterUserRoles from '../../../assets/data/masterUserRoles.json';

interface userRegisterModel {
  name,
  lastName,
  userEmail,
  company,
  nit,
  country,
  phone,
}

@Component({
  selector: 'app-self-register-request',
  templateUrl: './self-register-request.component.html',
  styleUrls: ['./self-register-request.component.scss']
})
export class SelfRegisterRequestComponent implements OnInit {

  name;
  lastName;
  userEmail;
  company;
  nit;
  country;
  phone;
  public partner:Boolean = false;
  public notPartner:Boolean = false;
  public activeForm:Boolean = true;
  public companyFormActivate: Boolean = false;
  public partnerString = "";
  public userParams= {} as UserModel;
  public loading: Boolean = false;

  register = {} as userRegisterModel;

  public userType: Boolean = false;
  constructor(
    private authService: AuthService, private userService : UserService, private http : HttpClient
  ) { }

  ngOnInit(): void {
    console.log(masterUserRoles);
    this.navigateToStart();
  }

  navigateToStart(){
    document.location.href = 'RegistroPropio#register-container';
  }
 async sendRegister(){
  this.loading = true;
  if (this.name == null || this.lastName == null) {
    Swal.fire(
      'Completar Datos',
      'Ingrese Su nombre',
      'error'
    )
  }else{
    if (this.userEmail== null) {
      Swal.fire(
        'Completar Datos',
        'Ingrese Su Correo Electrónico',
        'error'
      )
    }else{
      if (this.company == null) {
        Swal.fire(
          'Completar Datos',
          'Ingrese el nombre de su empresa',
          'error'
        )
      }else{
        if (this.nit == null) {
          Swal.fire(
            'Completar Datos',
            'Ingrese su número de nit',
            'error'
          )
        }else{
          if (this.country == null) {
            Swal.fire(
              'Completar Datos',
              'Ingrese Su Pais',
              'error'
            )
          }else{
            if (this.name == null) {
              Swal.fire(
                'Completar Datos',
                'Ingrese su número de teléfono',
                'error'
              )
            }else{
              this.sendRegisterAfter();
            }
          }
        }
      }
    }
  }
 }
 sendRegisterNotPartner(){
  this.loading = true;
  if (this.name == null || this.lastName == null) {
    Swal.fire(
      'Completar Datos',
      'Ingrese Su nombre',
      'error'
    )
  }else{
    if (this.userEmail== null) {
      Swal.fire(
        'Completar Datos',
        'Ingrese Su Correo Electrónico',
        'error'
      )
    }else{
      if (this.companyFormActivate && this.company == null) {
        Swal.fire(
          'Completar Datos',
          'Ingrese el nombre de su empresa',
          'error'
        )
      }else{
        if (this.companyFormActivate && this.nit == null) {
          Swal.fire(
            'Completar Datos',
            'Ingrese su número de nit',
            'error'
          )
        }else{
          if (this.country == null) {
            Swal.fire(
              'Completar Datos',
              'Ingrese Su Pais',
              'error'
            )
          }else{
            if (this.name == null) {
              Swal.fire(
                'Completar Datos',
                'Ingrese su número de teléfono',
                'error'
              )
            }else{
              this.sendRegisterAfter();
            }
          }
        }
      }
    }
  }
 }
  async sendRegisterAfter() {
    console.log(this.userEmail);
    const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    let str = this.userEmail.replace(/\s+/g, "");
    if(regex.test(str)){
      console.log(str);
      await this.checkExistentUser(str).then(
         (response: any)=>{
            console.log(response);
            if(!response){
 let request = {
      name: this.name,
      userLastName: this.lastName,
      userEmail: 'cigmatch@industriaguate.com', //remplace in QA
      company: this.company,
      nit: this.nit,
      country: this.country,
      phone: this.phone,
      emailRegister:this.userEmail,
      partner: this.partner
    }
    Swal.fire({
      title: 'Solicitud de registro',
      text: "Usted enviará sus datos para aprobacion.",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Enviar'
    }).then((result) => {
      console.log(request);
      if (result.isConfirmed) {
        console.log(request);
        this.saveUserInDb();
        this.authService.sendEmailNewRegister(request).then((response: any)=>{
          console.log(response);
          Swal.fire(
            'Enviado',
            'Solicitud enviada.',
            'success'
          )
          setTimeout(function(){
            window.location.reload();
            },3000);
      
        })
      }
    })
            }
            this.loading = false;
        }
      )
    }else{
      Swal.fire({
         title: 'Ingrese una dirección de correo electrónico válida',
         icon: 'error',
         showDenyButton : false,
         confirmButtonText : 'ok',
         confirmButtonColor: '#004f9f'
      })
    }
   
  }

  async checkExistentUser(value){
    let resp;
    await this.userService.oneUserBackend(value).then(
      (response: any) =>{
          resp = JSON.parse(response);
      }
    )
    console.log(resp);
    if(resp.records[1].length > 0){
      Swal.fire({
        title: 'Tu correo ya se registró en CIGMatch',
        text: 'Si tienes alguna duda puedes escribirnos en WhatsApp',
        icon: 'error'
      })
      return true
    }else{
      return false;
    }
  }

  searchPartnerNit(value){
   const param = {
      "socio": value
    }
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Basic ' + btoa('cigmatch:CgojfePT523SoJv')
  });
  this.http.post('https://socios.industriaguate.com/api/sociocheck', param, {headers: headers})
  .subscribe(data => {
     console.log(data);
    this.company = data["name"];
      // this.company = data[0].name
  },
  error => {
    this.company = null;
     Swal.fire({
        title: "Socio no encontrado",
        icon: 'error',
        confirmButtonText: 'Aceptar',
        confirmButtonColor: '#0078d7'
     })
  }
  );

  }


  partnerChange(value: String){
       if(value == 'partner'){
        this.partner = true;

       }else{
         this.notPartner = true;

       }
       
       this.activeForm = false;
  }

  notPartnerForm(){
      this.notPartner = true;
  }

  companyConfirm(value){
    console.log(value);
       this.companyFormActivate = value.checked;
  }


  saveUserInDb(){
    this.userParams.userFirstName = this.name,
    this.userParams.userCompanyName = this.company;
    this.userParams.userCountry = this.country;
    this.userParams.userEmail = this.userEmail;
    this.userParams.companyPhone = this.phone;
    this.userParams.companyNit = this.nit;
    this.userParams.userStatus = 0
    this.userParams.userLastName = this.lastName;
    console.log(this.userParams);
    if(this.partner){
      this.userParams.userRole = masterUserRoles[2].code;
    }else{
      this.userParams.userRole = masterUserRoles[5].code;
    }
   
       this.userService.saveUserOnTable(this.userParams).then(
        (response: any) => {
          console.log(response);
      //  window.location.reload();
          
        }
    
       )
  }
  }


