<app-loading-wrapper-overlay *ngIf="loading"></app-loading-wrapper-overlay>
<div class="col-sm-12 col-md-12 col-lg-12" (click)="subMenu=true" >
 
  <div class="search" >
    <div class="col-md-6" style="padding-top: 10px!important;">
        <div class="form"> <input type="text" class="form-control form-input" placeholder="Buscar solicitudes" [(ngModel)]="requestSearch" (keyup.enter)="searchRequest()"><span style="width: 40px; height: 100%!important; display: flex; border-left: transparent;" (click)="searchRequest()"><i class="fa fa-search" style="margin-top:-10px; margin-left:-20px ;"></i></span></div>
    </div>
</div>
    <!-- <img src="../../../assets/images/core/PortalDeSolicitudes.webp"> -->
    <div class="row">
      <!-- publicidad -->
<div class="col-sm-12" style="z-index: 1!important;" id="banner-container">
<div style="width: 92%;margin-left:56px!important;z-index: 1!important; height: 380px !important; max-height: 380px;">
 <app-request-carousel></app-request-carousel>
 </div>
</div>
<!-- fin de publicidad -->
  </div>
  
</div>
<div class="col-sm-12 col-md-12 col-lg-12" style="background: white; padding: 1rem !important;">
  <div class="row">
    <div class="col-sm-3 col-md-3 col-lg-3">
      <div class="container" style="background-color: white!important; width: 90%;border: none; max-width: 90%;margin-left: 2.3rem!important;">
        <b><h2 style="color: #004F9F;text-align: left;font-size:x-large;font-weight: bolder;">Categorías</h2></b>
        <hr style="height: 1.7px;background-color: #F58626;opacity: 80%;">
        <!-- <div class="col-sm-12 col-md-12 col-lg-12" style="padding: 0 1rem 1rem 1rem !important;">
          <div class="col-sm-12 col-md-12 col-lg-12 shadow p-3 bg-white rounded boxi">
            <h3>Categorías</h3> 
          </div>
        </div> -->
        <div class="row" id="containerCategories" >
          <div class="col-sm-12"  *ngFor="let masterCat of masterCatList" style="text-align: left!important;margin-top: 1.5rem!important;max-width: 75%;">
            <a class="menuCategories" (click)="listCategories(masterCat.masterCatUUID)"> {{ masterCat.masterCatName }}</a>
          </div>
          <div class="col-sm-12"style="text-align: left!important;margin-top: 1.5rem!important;margin-left: 3rem!important;max-width: 75%;">
            <a class="menuCategories" style="font-weight: bold!important;color: dimgrey;font-size: 21px;"> Todas las Categorías</a>
          </div>
          
        </div>
      
        
        <div class="col-sm-12 col-md-12 col-lg-12">
          <div class="row">
            <!-- publicidad -->
    <div class="col-sm-12" style="z-index: 1!important;">
      <div style="width: 100%;margin-left: 7%;z-index: 1!important;" >
        <div id="my12" class="carousel carousel-fade img-details" data-bs-ride="carousel" data-bs-interval="4000" style="height:auto">
             <div class="carousel-inner">
                <div class="carousel-item {{publicity1.active}}" style="text-align: center;" *ngFor="let publicity1 of categoryBannerV"> <img [src]="publicity1.publicityBanner" class="imgPublicity" (click)="openPublicity(publicity1.publicityLink)">
                </div>
               </div>  
               
          </div>
       </div>
  </div>
   <!-- fin de publicidad -->
        </div>
        </div>
      </div>
    </div>
    <div class="col-sm-9 col-md-9 col-lg-9" *ngIf="subMenu" style="padding-left: 2rem!important;padding-right: 3rem!important;">
          <!-- <div class="container shadow p-3 bg-white rounded boxi">
            <div class="row" style="height: 100% !important;">
              <div class="col-sm-12 col-md-12 col-lg-12" style="display: flex; padding: 1rem !important;">
                <div class="col-sm-9 col-md-9 col-lg-9">
                  <div class="col-sm-12 col-md-12 col-lg-12" style="margin: 5px 0 5px 0 !important;">
                    <h1 style="color: #055285;">Articulos</h1>
                  </div>
                  <div class="col-sm-12 col-md-12 col-lg-12" style="margin: 5px 0 5px 0 !important;">
                    <span>Descripcion</span>
                  </div>
                </div>
                <div class="col-sm-3 col-md-3 col-lg-3">
                  <div class="col-sm-12 col-md-12 col-lg-12" style="margin: 5px 0 5px 0 !important;">
                    <span>Presupuesto <span style="color: #055285; font-weight: bold;">Q. <span>1000</span></span></span>
                  </div>
                  <div class="col-sm-12 col-md-12 col-lg-12" style="margin: 5px 0 5px 0 !important;">
                    <span>Cantidad solicitada: <span> 200</span></span>
                  </div>
                  <div class="col-sm-12 col-md-12 col-lg-12" style="margin: 5px 0 5px 0 !important;">
                    <span>Fecha de publicación: <span> 08/02/2022 </span></span>
                  </div>
                </div>
              </div>

              

            </div>
          <div class="d-flex justify-content-between" style="align-items: center;">
              <div class="d-flex flex-row align-items-center">
                  <div class="position-relative"> </div>
              </div>

              <div class="ml-12">
                <h3 class="mb-0"></h3>
              </div>
          </div>
      </div> -->
      <h1 *ngIf="catExist">No hay productos de esta categoria</h1>
      <div class="row" style="width: 107%!important;margin-bottom: 0.5rem!important;">
        <div class="col-sm-9">
          <b><h2 style="color: #004F9F;text-align: left;font-size:x-large;margin-bottom: 1rem!important;font-weight: bolder;">Ultimas solicitudes agregadas</h2></b>
      
        </div>
        <div class="col-sm-3" style="justify-content: right!important;">
          <button mat-raised-button class="btn" (click)="navigate()">Generar Solicitud</button>
        </div>

      </div>
      <div  id="requestList"></div>
      <section class="request-list--container"  *ngIf="requestActivate">
      <div class="container shadow p-3 bg-white boxi" *ngFor="let request of requestList | paginate: { itemsPerPage: pagination, currentPage: request }" style="cursor: pointer; margin-bottom: 1.1rem!important;" (click)="navigateRequest(request.requestCode)"  >
        <div class="row" style="height: 100% !important;">
          <div class="col-sm-12 col-md-12 col-lg-12" style="display: flex; padding: 1rem !important;">
            <div class="col-sm-7 col-md-7 col-lg-7">
              <div class="col-sm-12 col-md-12 col-lg-12" style="margin: 5px 0 5px 0 !important;">
                <h1 style="color: #004F9F;">{{request.requestTitle}}</h1>
              </div>
              <div class="col-sm-12 col-md-12 col-lg-12" style="margin: 5px 0 5px 0 !important;">
                <span style="color: #0078D7;text-decoration: underline;font-size: x-large;" *ngIf="user">{{request.requestContactName}} <img src="../../../../assets/images/core/Insignia.png" style="width: 1.8rem;height: 2rem;margin-left: 1rem;" ></span>
                <span style="color: #0078D7;text-decoration: underline;font-size: x-large;" *ngIf="!user">{{message}}</span>
              </div>
              <div class="col-sm-12 col-md-12 col-lg-12" style="margin: 10px 0 20px 0 !important;">
                <span style="color: #898989;font-size: large;">Presupuesto <span>{{request.currency}}.<span>{{request.requestBudget}}</span></span></span>
              </div>
      
              <div class="col-sm-12 col-md-12 col-lg-12" style="margin: 5px 0 5px 0 !important;">
                <span style="color: #898989;font-size: large;">{{request.requestDetail}}</span>
              </div>
            </div>
            <div class="col-sm-5 col-md-5 col-lg-5" style="justify-content:right;">
              <div class="" style="width: 100%;">
              <div class="col-sm-12 col-md-12 col-lg-12" style="margin: 5px 0 5px 0 !important;color: #898989;text-align: right;">
                <span> {{request.requestDate}} </span>
              </div>
              <div class="col-sm-12 col-md-12 col-lg-12" style="margin: 5px 0 5px 0 !important;color: #898989;text-align: right;">
                <button mat-raised-button style="color: #FFFFFF;font-size: smaller!important;background-color: #004F9F;margin-top: 0.5rem!important;margin-bottom: 0.5rem!important;border-radius: 0.7rem;width: auto; max-width: 100%;white-space: nowrap; overflow: hidden; text-overflow: ellipsis;"><span style="margin: 0.8rem!important;width: 80%;max-width: 5rem!important;">{{request.categoryNameDes}}</span> </button>
               </div>
               <div class="col-sm-12 col-md-12 col-lg-12" style="margin: 5px 0 5px 0 !important;color: #898989;text-align: right;">
                <button mat-raised-button style="color: #FFFFFF;font-size: smaller!important;background-color: #0078D7;margin-top: 0.5rem!important;margin-bottom: 0.5rem!important;border-radius: 0.7rem;width: auto; max-width: 100%;white-space: nowrap; overflow: hidden; text-overflow: ellipsis;"><span style="margin: 0.8rem!important;width: 80%;max-width: 80%!important;">{{request.subCategoryNameDes}}</span> </button>
               
              </div>
              <div class="col-sm-12 col-md-12 col-lg-12" style="margin: 5px 0 5px 0 !important;color: #898989;text-align: right; width: 100%;">
                
                <span class="hover top" (mouseenter)="mouseEvent(request.newCategory)" (mouseleave)="mouseLeave()" ><span *ngIf="request.newCategory !== null" style="color:#0078D7; cursor: pointer; padding-right: 2px!important;">+1 </span>
                        <span class="text">{{categoryToShow}} </span>
                      </span>
                <button mat-raised-button style="color: #FFFFFF;font-size: smaller!important;background-color: #F58626;margin-top: 0.5rem!important;margin-bottom: 0.5rem!important;border-radius: 0.7rem;width: auto; max-width: 100%; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;"><span style="margin: 0.8rem!important;width: 80%;max-width: 80%!important;">{{request.masterCategoryNameDes}}</span> </button>
               
              </div>
            </div>
  
              
            </div>
            
          </div>

          <div class="col-sm-12 col-md-12 col-lg-12">
            <div class="row" style="font-size: smaller!important;">
                 
            </div>
          </div>

        </div>
  </div>
</section>
<section class="request-list--container"  *ngIf="requestActivate == false">
  <div class="container shadow p-3 bg-white boxi" *ngFor="let request of requestList " style="cursor: pointer; margin-bottom: 1.1rem!important;" (click)="navigateRequest(request.requestCode)">
    <div class="row" style="height: 100% !important;">
      <div class="col-sm-12 col-md-12 col-lg-12" style="display: flex; padding: 1rem !important;">
        <div class="col-sm-7 col-md-7 col-lg-7">
          <div class="col-sm-12 col-md-12 col-lg-12" style="margin: 5px 0 5px 0 !important;">
            <h1 style="color: #004F9F;">{{request.requestTitle}}</h1>
          </div>
          <div class="col-sm-12 col-md-12 col-lg-12" style="margin: 5px 0 5px 0 !important;">
            <span style="color: #0078D7;text-decoration: underline;font-size: x-large;" *ngIf="user">{{request.requestContactName}} <img src="../../../../assets/images/core/Insignia.png" style="width: 1.8rem;height: 2rem;margin-left: 1rem;" ></span>
            <span style="color: #0078D7;text-decoration: underline;font-size: x-large;" *ngIf="!user">{{message}}</span>
          </div>
          <div class="col-sm-12 col-md-12 col-lg-12" style="margin: 10px 0 20px 0 !important;">
            <span style="color: #898989;font-size: large;">Presupuesto <span>{{request.currency}}.<span>{{request.requestBudget}}</span></span></span>
          </div>
  
          <div class="col-sm-12 col-md-12 col-lg-12" style="margin: 5px 0 5px 0 !important;">
            <span style="color: #898989;font-size: large;">{{request.requestDetail}}</span>
          </div>
        </div>
        <div class="col-sm-5 col-md-5 col-lg-5" style="justify-content:right;">
          <div class="" style="width: 100%;">
          <div class="col-sm-12 col-md-12 col-lg-12" style="margin: 5px 0 5px 0 !important;color: #898989;text-align: right;">
            <span> {{request.requestDate}} </span>
          </div>
          <div class="col-sm-12 col-md-12 col-lg-12" style="margin: 5px 0 5px 0 !important;color: #898989;text-align: right;">
            <button mat-raised-button style="color: #FFFFFF;font-size: smaller!important;background-color: #004F9F;margin-top: 0.5rem!important;margin-bottom: 0.5rem!important;border-radius: 0.7rem;width: auto; max-width: 100%;white-space: nowrap; overflow: hidden; text-overflow: ellipsis;"><span style="margin: 0.8rem!important;width: 80%;max-width: 5rem!important;">{{request.categoryNameDes}}</span> </button>
           </div>
           <div class="col-sm-12 col-md-12 col-lg-12" style="margin: 5px 0 5px 0 !important;color: #898989;text-align: right;">
            <button mat-raised-button style="color: #FFFFFF;font-size: smaller!important;background-color: #0078D7;margin-top: 0.5rem!important;margin-bottom: 0.5rem!important;border-radius: 0.7rem;width: auto; max-width: 100%;white-space: nowrap; overflow: hidden; text-overflow: ellipsis;"><span style="margin: 0.8rem!important;width: 80%;max-width: 80%!important;">{{request.subCategoryNameDes}}</span> </button>
           
          </div>
          <div class="col-sm-12 col-md-12 col-lg-12" style="margin: 5px 0 5px 0 !important;color: #898989;text-align: right; width: 100%;">
            
            <span class="hover top" (mouseenter)="mouseEvent(request.newCategory)" (mouseleave)="mouseLeave()" ><span *ngIf="request.newCategory !== null" style="color:#0078D7; cursor: pointer; padding-right: 2px!important;">+1 </span>
                    <span class="text">{{categoryToShow}} </span>
                  </span>
            <button mat-raised-button style="color: #FFFFFF;font-size: smaller!important;background-color: #F58626;margin-top: 0.5rem!important;margin-bottom: 0.5rem!important;border-radius: 0.7rem;width: auto; max-width: 100%; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;"><span style="margin: 0.8rem!important;width: 80%;max-width: 80%!important;">{{request.masterCategoryNameDes}}</span> </button>
           
          </div>
        </div>

          
        </div>
        
      </div>

      <div class="col-sm-12 col-md-12 col-lg-12">
        <div class="row" style="font-size: smaller!important;">
             
        </div>
      </div>

    </div>
</div>
    
</section>
  <div class="container">
    <div class="row align-items-start">
      <div class="col">
        
      </div>
      <div class="col" style="font-weight: lighter;">
        <pagination-controls class="pagination" (pageChange)="request = $event" previousLabel="Anterior" nextLabel="Siguiente">  </pagination-controls>
      </div>
      <div class="col" style="text-align: end; font-weight:lighter">
       
        <span>Mostrar </span>
      <span [value] = 10 (click)="changePagination(6)" style="cursor: pointer;"> 6 </span>
      <span [value] = 10 (click)="changePagination(10)" style="cursor: pointer;">| 10 </span>
      <span [value]= 15 (click)="changePagination(15)" style="cursor: pointer;">| 15 </span>

      </div>
    </div>
  </div>
  
  

      <div class="col-sm-12 col-md-12 col-lg-12" style="text-align: center; align-self: flex-end;margin-top: 2rem !important;">
        <!-- <img width="1000" src="../../../assets/images/core/promo.jpg"> -->

        <div class="row">
            <!-- publicidad -->
    <div class="col-sm-12" style="z-index: 1!important; width: 100%;">
      <div style="width: 100%;z-index: 1!important;" >
      <div id="my13" class="carousel carousel-fade img-details" data-bs-ride="carousel" data-bs-interval="6000" style="height:auto; padding-left: 7%!important">
             <div class="carousel-inner-v" >
                <div class="carousel-item {{publicity2.active}}" style="text-align: center;" *ngFor="let publicity2 of categoryBannerH">
                  <img [src]="publicity2.publicityBanner" class="imgPublicity-v" (click)="openPublicity(publicity2.publicityLink)">
                 </div>
                 
               </div>  
          </div>
       </div>
  </div>
   <!-- fin de publicidad -->
        </div>
      </div>


  
    <!-- <div class="col-sm-12 col-md-12 col-lg-12" style="text-align: center; align-self: flex-end;margin-top: 2rem !important;">
      <img width="1000" src="../../../assets/images/core/promo.jpg">
    </div> -->

  </div>

  <div class="col-sm-9 col-md-9 col-lg-9" *ngIf="!subMenu" style="padding-left: 1.8rem!important;">
    <div class="row" style="margin-top: 0.9rem!important;margin-left: 1rem!important">
      <div *ngFor="let categories of categoriesList" class="col-sm-4" >
        <div style="margin-right: 3rem;">
          <h1  style="color: #004F9F;cursor: pointer;font-size: medium;font-weight: bold;margin-bottom: 0.9rem!important;margin-top: 0.9rem!important;font-size: larger;">{{ categories.catName }}</h1>
          <div *ngFor="let subcategories of categories.listSubCat">
            <h3  style="color: #808385;cursor: pointer;" (click)="listGetRequestSubCat(subcategories.subCatUUID)">{{ subcategories.subCatName }} </h3>
                   
             </div> 
        </div>
  </div>
    </div>
    


</div>

<div class="col-sm-5 col-md-5 col-lg-5" *ngIf="!subMenu">
  <div *ngFor="let subcategories of subcategoryList" class="container shadow p-3 bg-white rounded boxi" style="height: 11vh!important;">
   <div class="row" style="height: 100%!important;">
    <div class="col-sm-12 col-md-12 col-lg-12" style="display: flex; padding: 1rem !important;">
      <div class="col-sm-9 col-md-9 col-lg-9">
        <div class="col-sm-12 col-md-12 col-lg-12" style="margin: 5px 0 5px 0 !important;">
          <h1  style="color: #055285;cursor: pointer;" (click)="listGetRequestSubCat(subcategories.subCatUUID)">{{ subcategories.subCatName }} </h1>
          
          </div>

         </div>
      
        </div>
      </div>
    </div>
  </div>
  
</div>
