
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'usersFilter'
})
export class UsersFilterPipe implements PipeTransform {

  transform(value:any,  args:any): any {
   const result = [];
    for(let i of value){
      if(i.userFirstName.toLowerCase().indexOf(args.toLowerCase()) >-1){
        result.push(i);
      }else if(i.userLastName.toLowerCase().indexOf(args.toLowerCase()) >-1){
        result.push(i);
      }
      // }else if(i.userEmail.toLowerCase().indexOf(args.toLowerCase()) >-1){
      //   result.push(i);
      // }
    }
     return result;
  }

}
