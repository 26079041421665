import { Router } from '@angular/router';
import { Component, OnInit, ViewChild } from '@angular/core';
import { CategoryService } from 'src/app/services/category.service';
import { MasterCatModel } from 'src/app/models/msterCatModel';
import { SubCategoryModel } from 'src/app/models/subcategoryModel';
import { RequestModel} from 'src/app/models/requestModel';
import { RequestService } from 'src/app/services/request.service';
import { element } from 'protractor';
import { PublicityService } from 'src/app/services/publicity.service';
import { ProductsService } from 'src/app/services/products.service';
import { RequestFilterPipe } from 'src/app/pipes/request-filter.pipe';

@Component({
  selector: 'app-application-portal',
  templateUrl: './application-portal.component.html',
  styleUrls: ['./application-portal.component.scss']
})
export class ApplicationPortalComponent implements OnInit {
  masterCategory = {} as MasterCatModel;
  subcategories = {} as SubCategoryModel;
  request = {} as RequestModel;
  masterCatList;
  subcategoryList:[];
  requestList;
  masterCatForSave;
  categoriesList= new Array();
  subCatStatus = true;
  subMenu=true;
  catExist=false;
  subCatForSave;
  familyCatStatus;
  clases="col-sm-9 col-md-9 col-lg-9";
  publicityListProduct1;
  categoryBannerV;
  categoryBannerH;
  loading = false;
  pagination: Number = 6;
  public user :Boolean = true;
  public message = "Inicie sesión para ver más información"
  public categoryToShow = "...";
  public requestSearch;
  public newArrList;
  public requestActivate: Boolean = true;

  constructor(
    private categoryService: CategoryService,
    private requestService: RequestService,
    private router: Router,
    private publicityService:PublicityService,
    private productsService: ProductsService,
    private requestFilter : RequestFilterPipe
  ) { }

  async ngOnInit() {
        //publicidad
        this.loading = true;
        try {
          JSON.parse(localStorage.getItem('user')).userId;
         } catch (error) {
           this.user = false;
         }
await this.publicityService.getAllPublicity().then(
 
  (result: any) => {
    
    const DB = result.records[0][0];
    this.publicityListProduct1 = DB.filter(x => x.publicityPlace == "PBSP" && x.publicityStatus == "Activo");
    this.categoryBannerH = DB.filter(x => x.publicityPlace == "PBSH" && x.publicityStatus == "Activo");
    this.categoryBannerV = DB.filter(x => x.publicityPlace == "PBSV" && x.publicityStatus == "Activo");
   // await this.actv();   
    
  }
)
//fin de publicidad

    this.listMasterCategories();
    this.listAllRequest();
    this.checkOdd();
  }

  navigate(){
   
    if (!this.user) {
      this.router.navigate(['/Login'])
      .then(() => {
        window.location.reload();
      });
    }else{
      this.router.navigateByUrl('/NuevaSolicitud')
      .then(() => {
        window.location.reload();
      });
    }
  }
  navigateRequest(code){
    if (!this.user) {
      this.router.navigate(['/Login'])
      .then(() => {
        window.location.reload();
      });
    }else{
      var ruta = "VistaSolicitud/"+code
      this.router.navigateByUrl(ruta)
      .then(() => {
        window.location.reload();
      })
    }
   
  }

  actv(){
    var act = true;
    var slide=0;
    this.publicityListProduct1.forEach(element => {
      if (act) {
        element.active = "active";
        act = false;
      }else{
        element.active = "";
      }
    });

    var actH = true;
    var slideH=0;
    this.categoryBannerH.forEach(element => {
      if (actH) {
        element.active = "active";
        element.aria = true;
        actH = false;
      }else{
        element.active = "";
        element.aria = false;
      }
      element.slide = slideH;
      slideH++;
    });

    var actV = true;
    var slideV=0;
    this.categoryBannerV.forEach(element => {
      if (actV) {
        element.active = "active";
        element.aria = true;
        actV = false;
      }else{
        element.active = "";
        element.aria = false;
      }
      element.slide = slideV;
      slideV++;
    });
  }

  checkOdd(){
        if(this.categoryBannerH.length%2 == 1 && this.categoryBannerH.length>1){
          let len = this.categoryBannerH.length-1;
          let random = Math.floor((Math.random()*(len -1 +1))+1);
          this.categoryBannerH.push(this.categoryBannerH[random])
          this.actv();
      }if(this.publicityListProduct1.length%2 == 1 && this.publicityListProduct1.length>1){
          let len = this.publicityListProduct1.length -1;
          let random = Math.floor((Math.random()*(len -1 +1))+1);
          this.publicityListProduct1.push(this.publicityListProduct1[random]);
          this.actv();
      }if(this.categoryBannerV.length%2 == 1 && this.categoryBannerV.length>1){
           let len = this.categoryBannerV.length -1;
           let random = Math.floor((Math.random()*(len - 1+ 1))+ 1);
           this.categoryBannerV.push(this.categoryBannerV[random]);
           this.actv();
          }
     this.actv();
    
  }

  listMasterCategories(){

    this.categoryService.masterGetAll().then(
      response=> {
        const userDB: MasterCatModel = response.records[0][0];
        this.masterCatList = userDB;
    
      }
    )
  }
  openPublicity(url){
    window.open(url+"", "_blank");
  }
  
  async listGetRequestSubCat(UUID){
    if (!this.user) {
      this.router.navigate(['/Login'])
      .then(() => {
        window.location.reload();
      });
    }else{
    this.subMenu=true;
    this.requestList=[];
    await this.requestService.requestGetForSubCat(UUID).then(
      response=> {
       
        const userDB: RequestModel = JSON.parse(response).records;
        this.requestList = userDB;
       console.log(this.requestList);
        this.requestList.forEach(element => {
          element.requestDate = element.requestDateFormat
        });
        if(this.requestList.length==0){
          this.catExist=true;
        }else{
          this.catExist=false;
          
        }
       
      }
    )
    }
  }
  listAllRequest(){
   
    this.subMenu=true;
    this.requestService.requestGetAll().then(
      (response: any) => {
        const userDB: RequestModel = response.records[0][0];
        this.requestList = userDB;
        console.log(this.requestList);
        this.requestList.forEach(element => {
          element.requestDate = element.requestDateFormat
          if(element.requestDetail.length>= 150){
            var all ="";
            for(var i=0; i<150; i++){
              all = all+element.requestDetail.charAt(i);
            }
            all= all+"..."
            element.requestDetail = all;
            
         }
        });
        this.newArrList = this.requestList;
        this.subCatStatus = false;
        this.loading = false;
      }
    )
   
  }

  masterForSave(masterCatUUID){
    this.categoryService.mastercatGetOne(masterCatUUID).then(
      (response: any) => {
        const userDB = JSON.parse(response).records[0];
        this.masterCatForSave = userDB.masterCatName;
      }
    )
  }

  async listCategories(masterCatUUID){
    this.clases="col-sm-9 col-md-9 col-lg-9";
    this.subcategoryList = [];
    this.subMenu=false;
    this.categoriesList= [];
    this.masterForSave(masterCatUUID);
    var userDB;
    this.categoryService.categoryGetOne(masterCatUUID).then(
      (response: any) => {
       
        userDB = JSON.parse(response).records;
        this.listSubCatNew(userDB);
        this.subCatStatus = false;
        
        
        
        
      }
    )
    
   
  }
  async listSubCatNew(userDB){
    
    await userDB.forEach(async(element) =>{
      element.listSubCat= await this.listSubCat(element.catUUID);
    });
    this.categoriesList = new Array();
    this.categoriesList = userDB;
    
    
    
  }
  subcatforsave(subCatUUID){
    this.categoryService.categoryGetOneForSave(subCatUUID).then(
      (response: any) => {
        const userDB = JSON.parse(response).records[0];
        this.subCatForSave = userDB.catName;
      }
    )
  }

  async listSubCat(subcatUUID){
    this.clases="col-sm-4 col-md-4 col-lg-4";
    this.subcategoryList = [];
    this.subcatforsave(subcatUUID);
    var userDB;
    await this.categoryService.subCatGetOne(subcatUUID).then(
      (response: any) => {
        
        userDB = JSON.parse(response).records;
        // this.subcategoryList = userDB;
        // this.familyCatStatus = false;
        
        return userDB;
      }
      
    )
    return userDB;
  }

  changePagination(value){
      this.pagination = value;
    
  }

  mouseEvent(event){
    this.productsService.getOneProductCategory(event).then(
    (response: any)=>{
          this.categoryToShow = JSON.parse(response).records[0].productCategory;
    }
    )
  }

  mouseLeave(){
      this.categoryToShow = "...";
  }

  searchRequest(){
    document.location.href = "PortalDeSolicitudes#requestList"
   this.requestActivate = false;
    this.requestList =   this.requestFilter.transform(this.newArrList, this.requestSearch);
    if(this.requestSearch == ""){
      this.requestActivate = true;
    }
  }

}

