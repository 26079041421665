import { Component, OnInit } from '@angular/core';
import { CategoryModel } from 'src/app/models/categoryModel';
import { MasterCatModel } from 'src/app/models/msterCatModel';
import { SubCategoryModel } from 'src/app/models/subcategoryModel';
import { ViewChild, ElementRef } from '@angular/core';
import { CategoryService } from 'src/app/services/category.service';
import { v4 as uuidv4 } from 'uuid';
import Swal from 'sweetalert2/dist/sweetalert2.js';

@Component({
  selector: 'app-new-category',
  templateUrl: './new-category.component.html',
  styleUrls: ['./new-category.component.scss']
})
export class NewCategoryComponent implements OnInit {
public masterCat = {} as MasterCatModel
public subCategory = {} as CategoryModel
public familyCat = {} as SubCategoryModel  
public masterCatName
public subCategoryName
public familySubCatName
public masterCatUUID
public masterCatIcon
public principalimage: File = null
public loading: Boolean = false
public location
public subcatItems=[];
public items = [];
public newItem ='';
@ViewChild('labelImportPrincipal')
labelImportPrincipal: ElementRef

@ViewChild('famInput')
famInput: ElementRef
  constructor(private categoryService: CategoryService) { }

  ngOnInit(){

  }

  addSubCatToList(){
        if(this.subCategoryName.trim().length ===0){
           return;
        }
        let id = uuidv4();
        this.subcatItems.push({"subCatName":this.subCategoryName,"subCatuUUID":id});
        this.subCategoryName = '';
        id = "";

  }

  addFamCat(value){
      if(this.newItem.trim().length === 0){
        return;
      }

      this.items.push({subcatName:value, famcatName:this.newItem});
      this.newItem = '';
      this.famInput.nativeElement.value = "";
  }

  principalImage(files: FileList) {
    this.labelImportPrincipal.nativeElement.innerText = Array.from(files)
      .map(f => f.name)
      .join(', ');
      if(files[0].name != null){
        this.principalimage = files.item(0)
      }else{
           this.labelImportPrincipal.nativeElement.innerText = 'Subir imagen'
      }
   
  }

  async saveCategory(){
    this.loading = true;
    await this.categoryService.uploadFileS3(this.principalimage).then(
      (response: any) =>{
        this.location = response.Location
         this.saveMasterCategory()
      }
    )
  }

  saveMasterCategory(){
    this.masterCat.masterCatIcon = this.location
    this.masterCat.masterCatName = this.masterCatName.toUpperCase();
    this.masterCat.masterCatUUID =  uuidv4();
    this.categoryService.saveOnMasterCatTable(this.masterCat).then(
      (response: any) =>{
         if(response.statusCode == 200){
              this.saveSubCategory()
         }
      }
    )
  }

saveSubCategory(){
     this.subCategory.masterCatName = this.masterCat.masterCatName;
     this.subCategory.masterCatUUID = this.masterCat.masterCatUUID;
     for(let i=0; i<this.subcatItems.length; i++){
      this.subCategory.catDescription = this.subcatItems[i].subCatName
      this.subCategory.catName = this.subCategory.catDescription
      this.subCategory.catUUID = this.subcatItems[i].subCatuUUID
      this.categoryService.saveCatOnTable(this.subCategory).then(
        (response:any)=>{
          this.saveCatFam(this.subcatItems[i].subCatuUUID.toString(), this.items)
        }
      )
     }
           
}

saveCatFam(category, catFam){
      for(let i=0; i<catFam.length; i ++){
        if(catFam[i].subcatName.subCatuUUID == category){
          this.familyCat.catName = catFam[i].subcatName.subCatName;
          this.familyCat.catUUID = category;
          this.familyCat.subCatDescription = catFam[i].famcatName;
          this.familyCat.subCatName = catFam[i].famcatName;
          this.familyCat.subCatUUID = uuidv4();
          this.categoryService.saveOnSubCatTable(this.familyCat).then(
            (response: any)=>{
              Swal.fire({
                title: 'Nueva categoría guardada',
                icon:'success'
              })
            }
          )
        }
      }
}



  // testCategory(){
  //   this.subcatItems.forEach(item=>{
  //     console.log("itemForeach " + item)
  //     console.log(this.items)
  //     for(let i = 0; i<this.items.length; i++){
  //       if(item == this.items[i].subcatName){
  //         this.familySubCatName = this.items[i].famcatName 
  //         this.familyCat.catName = this.items[i].subcatName 
  //         this.familyCat.catUUID = this.subCategory.catUUID
  //         this.familyCat.subCatDescription = this.familySubCatName
  //         this.familyCat.subCatName = this.familySubCatName
  //         this.familyCat.subCatUUID = uuidv4();
  //         console.log(this.familyCat)
  //       }
    
  //     }
  //   });
  // }

  // saveSubCategoryGood(){
  //   this.subCategory.masterCatUUID = this.masterCat.masterCatUUID
  //   this.subCategory.masterCatName = this.masterCat.masterCatName
  //   this.subCategory.catUUID = uuidv4();
  //   for(let i = 0; i<this.items.length; i++){
  //     this.categoryService.saveCatOnTable(this.subCategory).then(
  //       (response: any)=>{

  //       }
  //     )
  //   }
  // }

  // saveSubCategory(){
  //     this.subCategory.catName = this.subCategoryName
  //     this.subCategory.catUUID = uuidv4();
  //     this.subCategory.catDescription = this.subCategoryName
  //     this.subCategory.masterCatUUID = this.masterCat.masterCatUUID
  //     this.subCategory.masterCatName = this.masterCat.masterCatName
  //     this.categoryService.saveCatOnTable(this.subCategory).then(
  //       (response: any)=>{
  //         if(response.statusCode == 200){
  //           for (let i = 0; i < this.items.length; i++) {
  //             this.saveFamilySubCat(this.items[i])
  //           }
  //         }
  //       }
  //     )
  // }

  // saveFamilySubCat(item){
  //   this.familySubCatName = item 
  //     this.familyCat.catName = this.subCategory.catName
  //     this.familyCat.catUUID = this.subCategory.catUUID
  //     this.familyCat.subCatDescription = this.familySubCatName
  //     this.familyCat.subCatName = this.familySubCatName
  //     this.familyCat.subCatUUID = uuidv4()
  //     this.categoryService.saveOnSubCatTable(this.familyCat).then(
  //       (response: any)=>{
  //          if(response.statusCode == 200){
  //           Swal.fire({
  //             title: 'Categoría agregada correctamente',
  //             icon: 'success'
  //           })
  //          }
  //          this.loading = false;
  //       }
  //     )
  // }

}
