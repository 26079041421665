import { Component, OnInit } from '@angular/core';
import { CategoryModel } from 'src/app/models/categoryModel';
import { MasterCatModel } from 'src/app/models/msterCatModel';
import { SubCategoryModel } from 'src/app/models/subcategoryModel';
import { UserModel } from 'src/app/models/userModel';
import { AuthService } from 'src/app/services/auth.service';
import { CategoryService } from 'src/app/services/category.service';
import { ProductsModel } from '../../models/productsModel';
import { ProductsService } from '../../services/products.service';
import { Router } from '@angular/router';
import { PublicityService } from 'src/app/services/publicity.service';
@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss']
})
export class ProductsComponent implements OnInit {
 images = [
    'https://lugarte.com/wp-content/uploads/2014/12/Untitled-11.jpg',
    'https://i.pinimg.com/550x/e3/03/e9/e303e9e5e848de386c0e9e20d726c71b.jpg'
 ]
 activeIndex = 0;
 activeIndexLogin = 0;
 activeIndexLogin2 =0;
 databs: Boolean = false;
  userRole: string;
  subcategories = {} as SubCategoryModel;
  masterCategory = {} as MasterCatModel;
  categories = {} as CategoryModel;
  categoriesList: [];
  masterCatList;
  subcategoryList;
  userLog=true;
  productsList;
  productsListLog;
  productListAll;
  pre=true;
  productListSearchAll;
  productListAddAll;
  productListSearch;
  productListAdd;
  loading = false;
  public showThis: Boolean = true;
  public totalProducts: Number;

  //Avanzar o retroceder con productos de Lo mas buscado
  productsListStartSimilar=0;
  productsListFinishedSimilar=4;
  productsListStartEnableSimilar=false;
  productsListFinishedEnableSimilar=false;

  //Avanzar o retroceder con productos Agregados Recientemente
  productsListStart=0;
  productsListFinished=4;
  productsListStartEnable=false;
  productsListFinishedEnable=false;


  //publicidad
  publicityListLogout;
  publicityListLoggin1;
  publicityListLoggin2;

  busqueda = "";
  public categoryToShow = "...";
  constructor(
    private productService: ProductsService,
    private categoryService: CategoryService,
    private publicityService: PublicityService,
    private router: Router,
    private authService: AuthService
  ) {
    
   }

   async ngOnInit(){
   try {
      JSON.parse(localStorage.getItem('user')).userId;
     } catch (error) {
       this.userLog = false;
     }
    
    let user: UserModel;
    this.authService.userRole.subscribe(userRoleApp => {
      this.userRole = userRoleApp;
    })
    if (localStorage.getItem('user')) {
      user = JSON.parse(localStorage.getItem('user'));
      this.userRole = user.userRole;
    }
    this.getAllProducts();
    this.listMasterCategories();


    //publicidad
    await this.publicityService.getAllPublicity().then(
      (result: any) => {
        const DB = result.records[0][0];
        this.publicityListLogout = DB.filter(x => x.publicityPlace == "I0T1" && x.publicityStatus == "Activo");
        this.publicityListLoggin1 = DB.filter(x => x.publicityPlace == "I0N1I" && x.publicityStatus == "Activo");
        this.publicityListLoggin2 = DB.filter(x => x.publicityPlace == "I0N1F" && x.publicityStatus == "Activo");
       console.log(this.publicityListLoggin2);

       
      }
    )
    setInterval(() => {
      this.activeIndex = (this.activeIndex + 1) % this.publicityListLogout.length;
      this.activeIndexLogin = (this.activeIndexLogin + 1) % this.publicityListLoggin1.length;
      this.activeIndexLogin2 = (this.activeIndexLogin2 +  1) % this.publicityListLoggin2.length;
      this.databs = true;
    }, 3000);    

   // this.checkOdd();
    //fin de publicidad
  }



  checkOdd(){
  this.loading = true;
    if(this.publicityListLogout.length%2 == 1 && this.publicityListLogout.length>1){
      let len = this.publicityListLogout.length -1;
      let random = Math.floor((Math.random()*(len - 1+ 1))+ 1);
      this.publicityListLogout.push(this.publicityListLogout[random]);
      this.actv();
     }
     if(this.publicityListLoggin1.length%2 == 1 && this.publicityListLoggin1.length>1){
      let len = this.publicityListLoggin1.length -1;
      let random = Math.floor((Math.random()*(len - 1+ 1))+ 1);
      this.publicityListLoggin1.push(this.publicityListLoggin1[random]);
      this.actv();
     }
     if(this.publicityListLoggin2.length%2 == 1 && this.publicityListLoggin2.length>1){
      let len = this.publicityListLoggin2.length -1;
      let random = Math.floor((Math.random()*(len - 1+ 1))+ 1);
      this.publicityListLoggin2.push(this.publicityListLoggin2[random]);
      this.actv();
     }

     this.actv();
  }


  actv(){

    var act = true;
        this.publicityListLogout.forEach(element => {
          if (act) {
            element.active = "active";
            act = false;
          }else{
            element.active = "";
          }
        });
        
        var act1 = true;
        this.publicityListLoggin1.forEach(element => {
          if (act1) {
            element.active = "active";
            act1 = false;
          }else{
            element.active = "";
          }
        }); 
        var act2 = true;
        this.publicityListLoggin2.forEach(element => {
          if (act2) {
            element.active = "active";
            act2 = false;
          }else{
            element.active = "";
          }
        }); 
this.loading = false;
  }
  openPublicity(url){
    window.open(url+"", "_blank");
  }

  search(){
    this.router.navigate(['/ProductosEncontrados/'+this.busqueda])
    .then(() => {
      window.location.reload();
    });
  }
  goLogin(){
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      const element = document.getElementById('productos');
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    });
  }
  getProductListSearch(userDB){
   this.loading = true;
    const filterO=[];
    var contador = 0;
   if (userDB.length>4) {
    this.productsListFinishedEnableSimilar = true;
     userDB.forEach(element => {
       if (contador<20) {
         filterO.push(element)
       }
       contador++;
      });
         const filterO2Log = [];
         filterO.sort(function() { return Math.random() - 0.5 });
         this.productListSearchAll = filterO;
         var contador1Log=0;
         filterO.forEach(element => {
          if (contador1Log<4) {
            filterO2Log.push(element)
          }
          contador1Log++;
         });
         this.productListSearch = filterO2Log;
         
         
    }else{
      this.productListSearch = userDB;
      this.productListSearchAll = userDB;
    }
    this.loading = false;
  }

  getProductListAdd(userDB){
   
   if (userDB.length>4) {
    this.productsListFinishedEnable = true;
         this.productListAddAll = userDB;
         const filterO2Log = [];
         var contador1Log=0;
         userDB.forEach(element => {
          if (contador1Log<4) {
            filterO2Log.push(element)
          }
          contador1Log++;
         });
         this.productListAdd = filterO2Log;
       
         
    }else{
      this.productListAdd = userDB;
      this.productListAddAll = userDB;
    }
  }
  productsD(){
    this.router.navigate(['/Productos/destacados'])
  .then(() => {
    window.location.reload();
  });

    
  }

  productsMasBuscados(){
    this.router.navigate(['/Productos/buscados'])
  .then(() => {
    window.location.reload();
  });

    
  }

  productsAdd(){
    this.router.navigate(['/Productos/agregados'])
  .then(() => {
    window.location.reload();
  });
  }
  getAllProducts(){
    this.loading = true;
    this.productService.productGetAll().then(
     response=> {
       
       const userDB= response.records[0][0];
       console.log(userDB);
       //Lo mas buscado
       this.productService.productGetSearch().then(
         responseP =>{
          const products= responseP.records[0][0];
          this.getProductListSearch(products);
         }
       )
       
       //Agregados Recientemente
       this.getProductListAdd(userDB);
       //Productos Destacados
       const filterO=[];
       var contador = 0;
      if (userDB.length>2) {
        userDB.forEach(element => {
          if (contador<10) {
            filterO.push(element)
          }
          contador++;
         });
         const filterO2 = [];
         const filterO2Log = [];
         filterO.sort(function() { return Math.random() - 0.5 });
         var contador1Log=0;
         filterO.forEach(element => {
          if (contador1Log<4) {
            filterO2Log.push(element)
          }
          contador1Log++;
         });
         this.productsListLog = filterO2Log;
         var contador1=0;
  
         filterO.forEach(element => {
          if (contador1<2) {
            filterO2.push(element)
          }
          contador1++;
         });
         this.productsList = filterO2;
      }else{
        this.productsList = userDB;
        this.productsListLog = userDB;
      }
       
       
       this.loading = false;
         this.totalProducts = response.records[0][0].length;
      }
     
   )

   }

   viewProduct(productUUID){
    this.loading = true;
    // this.router.navigate(['/VistaProducto/' + productUUID]);
    this.router.navigate(['/VistaProducto/' + productUUID])
  .then(() => {
    window.location.reload();
  });
  }

  listMasterCategories(){
    this.categoryService.masterGetAll().then(
      response=> {
        const userDB: MasterCatModel = response.records[0][0];
        this.masterCatList = userDB;
      }
    )
  }


  nextProductoSimilar(){

    this.productsListFinishedSimilar++;
    this.productsListStartSimilar++;
    if (this.productsListStartSimilar>0) {
      this.productsListStartEnableSimilar= true;
    }else{
      this.productsListStartEnableSimilar= true;
    }
    
    if (this.productListSearchAll.length >= this.productsListFinishedSimilar) {
      if (this.productListSearch.length == this.productsListFinishedSimilar) {
        this.productsListFinishedEnableSimilar = false;
      }
      var contador=0;
      const filterO=[];
      
      this.productListSearchAll.forEach(element => {
         if (contador >= this.productsListStartSimilar && contador < this.productsListFinishedSimilar) {
           filterO.push(element);
         }
       contador++;
       });
       this.productListSearch=filterO;
    }else{
      this.productsListFinishedEnableSimilar = false;
    }

  }

  prevProductoSimilar(){
    this.productsListFinishedSimilar--;
    this.productsListStartSimilar--;
    if (this.productsListStartSimilar>0) {
      this.productsListStartEnableSimilar= true;
    }else{
      this.productsListStartEnableSimilar= false;
    }
    if (this.productListSearchAll.length > this.productsListFinishedSimilar) {
      var contador=0;
      const filterO=[];
      this.productListSearchAll.forEach(element => {
         if (contador >= this.productsListStartSimilar && contador < this.productsListFinishedSimilar) {
           filterO.push(element);
         }
       contador++;
       });
       this.productListSearch=filterO;
       this.productsListFinishedEnableSimilar=true;
    }

  }


  //Agregados Recientemente
  nextProducto(){

    this.productsListFinished++;
    this.productsListStart++;
    if (this.productsListStart>0) {
      this.productsListStartEnable= true;
    }else{
      this.productsListStartEnable= true;
    }
    if (this.productListAddAll.length >= this.productsListFinished) {
      if (this.productListAddAll.length == this.productsListFinished) {
        this.productsListFinishedEnable = false;
      }
      var contador=0;
      const filterO=[];
      this.productListAddAll.forEach(element => {
         if (contador >= this.productsListStart && contador < this.productsListFinished) {
           filterO.push(element);
         }
       contador++;
       });
       this.productListAdd=filterO;
    }else{
      this.productsListFinishedEnable = false;
    }

  }
  prevProducto(){
    this.productsListFinished--;
    this.productsListStart--;
    if (this.productsListStart>0) {
      this.productsListStartEnable= true;
    }else{
      this.productsListStartEnable= false;
    }
    if (this.productListAddAll.length > this.productsListFinished) {
      var contador=0;
      const filterO=[];
      this.productListAddAll.forEach(element => {
         if (contador >= this.productsListStart && contador < this.productsListFinished) {
           filterO.push(element);
         }
       contador++;
       });
       this.productListAdd=filterO;
       this.productsListFinishedEnable=true;
    }

  }
closeW(){
  this.showThis = false;
}

mouseEvent(event){
  this.productService.getOneProductCategory(event).then(
    (response:any) => {
        this.categoryToShow = JSON.parse(response).records[0].productCategory
        console.log(this.categoryToShow);
    }
  )
}

mouseEvent2(){
     this.categoryToShow = "...";
}

}
