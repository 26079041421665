<app-loading-wrapper-overlay *ngIf="loading"></app-loading-wrapper-overlay>
<body id="register-container">
  <div class="user-type--container">
      
  </div>
<div class="background-image" >
  <div class="register-section" style="margin-top: 250px;">
<div class="col-sm-12 col-md-12 col-lg-12" >
  <div class="row" >
    <div class="col-sm-6 col-md-6 col-lg-6 col-12 containerTitle" >
      <span class="titleRegister">A pocos pasos de <br> llevar <span style="color: #F58626;">tu negocio</span> <br> al siguiente nivel</span>
    </div>
  </div>
 
</div>
<div class="col-sm-12 col-md-12 col-lg-12">
  <div class="row">
    <!-- <div class="col-sm-6 col-md-6 col-lg-6" style="align-self: center;">
      <div class="container d-flex align-items-center">
        <div class="card text-center">
            <p class="ribbon ribbon-top-right"><span>Gratuito</span></p>
            <p class="py-4 fw-bold" style="font-size: xx-large;">Socio CIG <img src="../../../assets/images/core/Insignia.png" style="width: 4rem;"></p>
            <P class="fs-10 mb-1" style="font-size: 16px;"><span>Usuarios </span><span class="fw-bold"> Ilimitados</span></P>
            <P class="fs-10 mb-1" style="font-size: 16px;"><span>Centro de mensajes</span> </P>
            <P class="fs-10 mb-1" style="font-size: 16px;"><span>Publicacion de hasta 30 productos</span></P>
            <P class="fs-10 mb-1" style="font-size: 16px;"><span>Publicación de hasta 10 solicitudes</span></P>
            <P class="fs-10 mb-1" style="font-size: 16px;"><span>Perfil de empresa en el directorio</span></P>
        </div>
      </div>
    </div> -->
    <div class="col-sm-6 col-md-6 col-lg-6 col-12 partner-select--container" *ngIf="activeForm">
      <div class="text-container">Elija su tipo de usuario</div>
      <button class="partner-change" (click)="partnerChange('partner')">Socio</button>
      <button class="partner-not--change" (click)="partnerChange('notPartner')">No socio</button>
    </div>

    <div class="col-sm-6 col-md-6 col-lg-6 col-12" *ngIf="notPartner">
      <div class="wrapper">

            <div class="h5 font-weight-bold text-center mb-3" style="color: white;">SOLICITUD DE REGISTRO</div>

            <div class="form-group d-flex align-items-center">
                <div class="icon"><span class="far fa-user"></span></div> <input autocomplete="off" type="text" class="form-control" placeholder=" Primer nombre" [(ngModel)]="name" style="font-size: medium;font-weight: bold;color: #898989;">
            </div>
            <div class="form-group d-flex align-items-center">
              <div class="icon"><span class="far fa-user"></span></div> <input autocomplete="off" type="text" class="form-control" placeholder=" Apellido" [(ngModel)]="lastName" style="font-size: medium;font-weight: bold;color: #898989;">
          </div>
            <div class="form-group d-flex align-items-center">
                <div class="icon"><span class="far fa-envelope"></span></div> <input autocomplete="off" type="email" class="form-control" placeholder=" Correo" [(ngModel)]="userEmail" style="font-size: medium;font-weight: bold;color: #898989;">
            </div>
                
            <div class="form-group d-flex align-items-center">
              <div class="icon"><span class="fas fa-phone"></span></div> <input autocomplete="off" type="text" class="form-control" placeholder=" Teléfono" [(ngModel)]="phone" style="font-size: medium;font-weight: bold;color: #898989;">
          </div>
              
          <div class="form-group d-flex align-items-center">
            <div class="icon"><span class="far fa-flag"></span></div> <input autocomplete="off" type="text" class="form-control" placeholder=" País" [(ngModel)]="country" style="font-size: medium;font-weight: bold;color: #898989;">
          </div>
          <div class="align-items-center select-container">
            <div class="select-text">
              Cuenta con una empresa
            </div>
            <div class="select">
              <mat-slide-toggle [(ngModel)]="companyGet" (change)="companyConfirm($event)" ></mat-slide-toggle>
            </div>
          </div>
          <section class = "companyFormActivate" *ngIf="companyFormActivate">
   
            <div class="form-group d-flex align-items-center">
                <div class="icon"><span class="fas fa-building"></span></div> <input autocomplete="off" type="text" class="form-control" placeholder=" Empresa" [(ngModel)]="company" style="font-size: medium;font-weight: bold;color: #898989;">
            </div>
            <div class="form-group d-flex align-items-center">
              <div class="icon"><span class="far fa-address-card"></span></div> <input autocomplete="off" type="text" class="form-control" placeholder=" NIT" [(ngModel)]="nit" style="font-size: medium;font-weight: bold;color: #898989;">
            </div>


          </section>
         
        
            <div class="terms mb-2" style="font-size: medium;font-weight: bold;color: white;">Al llenar la solicitud entraras a la lista de aprobación</div>
            <ul class="p-0 social-links">
                <li (click)="sendRegisterNotPartner()"><a>Enviar solicitud</a></li>
            </ul>

    
      </div>
    </div>
    <div class="col-sm-6 col-md-6 col-lg-6 col-12" *ngIf="partner">
      <div class="wrapper">

            <div class="h5 font-weight-bold text-center mb-3" style="color: white;">SOLICITUD DE REGISTRO</div>

            <div class="form-group d-flex align-items-center">
                <div class="icon"><span class="far fa-user"></span></div> <input autocomplete="off" type="text" class="form-control" placeholder=" Nombre" [(ngModel)]="name" style="font-size: medium;font-weight: bold;color: #898989;">
            </div>
            <div class="form-group d-flex align-items-center">
              <div class="icon"><span class="far fa-user"></span></div> <input autocomplete="off" type="text" class="form-control" placeholder=" Apellido" [(ngModel)]="lastName" style="font-size: medium;font-weight: bold;color: #898989;">
          </div>
            <div class="form-group d-flex align-items-center">
                <div class="icon"><span class="far fa-envelope"></span></div> <input autocomplete="off" type="email" class="form-control" placeholder=" Correo" [(ngModel)]="userEmail" style="font-size: medium;font-weight: bold;color: #898989;">
            </div>
         <div class="form-group d-flex align-items-center">
              <div class="icon"><span class="far fa-address-card"></span></div> <input autocomplete="off" type="text" class="form-control" placeholder=" NIT" [(ngModel)]="nit" (change)="searchPartnerNit(nit)"style="font-size: medium;font-weight: bold;color: #898989;">
            </div>
            <div class="form-group d-flex align-items-center">
                <div class="icon"><span class="fas fa-building"></span></div> <input autocomplete="off" type="text" class="form-control" placeholder=" Empresa" [(ngModel)]="company" style="font-size: medium;font-weight: bold;color: #898989;" disabled>
            </div>
            
            <div class="form-group d-flex align-items-center">
              <div class="icon"><span class="far fa-flag"></span></div> <input autocomplete="off" type="text" class="form-control" placeholder=" País" [(ngModel)]="country" style="font-size: medium;font-weight: bold;color: #898989;">
            </div>
            <div class="form-group d-flex align-items-center">
                <div class="icon"><span class="fas fa-phone"></span></div> <input autocomplete="off" type="text" class="form-control" placeholder=" Teléfono" [(ngModel)]="phone" style="font-size: medium;font-weight: bold;color: #898989;">
            </div>
            <div class="terms mb-2" style="font-size: medium;font-weight: bold;color: white;">Al llenar la solicitud entraras a la lista de aprobación</div>
            <ul class="p-0 social-links">
                <li (click)="sendRegister()"><a>Enviar solicitud</a></li>
            </ul>

    
      </div>
    </div>
  </div>
</div>
</div>
</div>
</body>
