import { Injectable, NgZone } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { newCategoryModel } from '../models/newCatModel';
import { AuthenticationDetails, CognitoUser, CognitoUserAttribute, CognitoUserPool } from 'amazon-cognito-identity-js';
import { CompanyModel } from '../models/companyModel';
import * as S3 from 'aws-sdk/clients/s3';

const POOL_DATA = {
  UserPoolId: environment.UserPoolId,
  ClientId: environment.ClientId
};
const userPool = new CognitoUserPool(POOL_DATA);
@Injectable({
  providedIn: 'root'
})
export class CompanyService {

  constructor(
    private http: HttpClient,
  ) {}

  saveCompanyOnTable(company: CompanyModel): Promise<any> {
    let params = JSON.stringify(company);
    const headers = new HttpHeaders().set('Content-Type', 'application/json');

    return this.http.post(environment.API_URL + 'cigmatchbackend-company', params, { headers}).toPromise();
  }

  saveNewCategoryCompany(category: newCategoryModel ): Promise<any>{
    let params = JSON.stringify(category);
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.post(environment.API_URL + 'cigmatchbackend-insertNewCategory', params, {headers}).toPromise();
 }

 getOneProductCategory(categoryUUID){
  const filter = categoryUUID;
  return this.http.get(environment.API_URL + 'cigmatchbackend-getOneProductCategory' + '/one/' + filter).toPromise();
}

getOneCompanyCategory(categoryUUID){
  const filter = categoryUUID;
  return this.http.get(environment.API_URL +'cigmatchbackend-getOneProductCategory' + '/one/' + filter).toPromise();
}

  updateCompanyOnTable(company: CompanyModel): Promise<any> {
    let params = JSON.stringify(company);
    const headers = new HttpHeaders().set('Content-Type', 'application/json');

    return this.http.post(environment.API_URL + 'cigmatchbackend-companyUpdate', params, { headers}).toPromise();
  }

  addCompanyBadge(company: CompanyModel): Promise<any> {
    let params = JSON.stringify(company);
    const headers = new HttpHeaders().set('Content-Type', 'application/json');

    return this.http.post(environment.API_URL + 'cigmatchbackend-companyAddBadge', params, { headers}).toPromise();
  }
  addCompanyBadges(company): Promise<any> {
    console.log(company);
    let params = JSON.stringify(company);
    const headers = new HttpHeaders().set('Content-Type', 'application/json');

    return this.http.post(environment.API_URL + 'cigmatchbackend-companyAddBadges', params, { headers}).toPromise();
  }


  companyGetAll(): Promise<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');

    return this.http.get(environment.API_URL + 'cigmatchbackend-company', { headers}).toPromise();
  }

  companyGetOne(companyBussinesCode){
    const filter = companyBussinesCode;
    return this.http.get(environment.API_URL + 'cigmatchbackend-companyGetOne' + '/one/' + filter).toPromise();
  }

  companySelfGetOne(companyBussinesCode){
    const filter = companyBussinesCode;
    return this.http.get(environment.API_URL + 'cigmatchbackend-companyGetSelfUsers' + '/one/' + filter).toPromise();
  }

  getCompanyByDate(values: any){
    const param1 = values.param1;
    const headers = new HttpHeaders().set('Content-type','application/json');
    return this.http.get(environment.API_URL +'cigmatchbackend-'+ 'getCompanyByDate/'+param1,{headers}).toPromise();
  }

  updateNewCompany(values: any){
     const params = JSON.stringify(values);
     const headers = new HttpHeaders().set('Content-type','application/json');
     return this.http.post(environment.API_URL +'cigmatchbackend-'+ 'updateNewCompany', params,{headers}).toPromise();
  }

  uploadFileS3(file, companyUUID, productUUID): Promise<any> {
    // this.companyProgress.next(0);
    const contentType = file.type;
    const bucket = new S3(
      {
        accessKeyId: environment.accessKeyId,
        secretAccessKey: environment.secretAccessKey,
        region: environment.region
      }
    );
    const params = {
      Bucket: environment.bucketFiles,
      Key: 'products' + '/' + companyUUID + '/' + productUUID + '/' + file.name,
      Body: file,
      ContentType: contentType
    };
    return new Promise((resolve, reject) => {
      bucket.upload(params).on('httpUploadProgress', (evt) => {
        const percent = (evt.loaded / evt.total) * 100;
        // this.companyProgress.next(percent);
      }).send((err, data) => {
        if (err) {
          return reject(err);
        }
        data.Location = environment.cloudFrontUrl + params.Key.replace(' ', '+');
        return resolve(data);
      });
    });
  }


}
