<app-loading-wrapper-overlay *ngIf="loading"></app-loading-wrapper-overlay>

<div class="row" style="margin: 5rem;margin-top: 3.0rem;margin-bottom: 3.0rem;" #oneview id="oneview"  *ngIf="companyData != null && companyData != undefined">
  <div class="col-sm-12">
    <mat-card class="targetProduct" style="width: 86%;">
      <mat-card-content>
        <div class="row">
             <div class="col-sm-12" >
             
              <span (click)="edit = true" *ngIf="edit === false && userRole === 'Master'" style="cursor: pointer;"><img src="../../../../assets/images/edit.png" style="width: 45px; position: relative;left: 93%;margin-top: -3rem;" ></span>
              <span (click)="update()" *ngIf="edit === true && userRole === 'Master'" style="cursor: pointer;"><i class="fa fa-check" aria-hidden="true" style="font-size: 2rem; position: relative;left: 93%;margin-top: -3rem;" ></i></span>
              <span (click)="edit = true" *ngIf="edit === false && itsMyBusinessProfile" style="cursor: pointer;"><img src="../../../../assets/images/edit.png" style="width: 45px; position: relative;left: 93%;margin-top: -3rem;" ></span>
              <span (click)="update()" *ngIf="edit === true && itsMyBusinessProfile" style="cursor: pointer;"><i class="fa fa-check" aria-hidden="true" style="font-size: 2rem; position: relative;left: 93%;margin-top: -3rem;" ></i></span>      
            <img src="../../../../assets/images/core/menu/user_add_21977.png" (click)="addUser()" style="width: 3rem;cursor: pointer;position: relative;left: 95%;margin-top: -3rem;">
              </div>
              <div class="col-sm-2">
                <label class="custom-file-label" #labelImportP3 for="importFile3"></label>
                <img src="../../../../assets/images/core/menu/profile.png" style="width: 100%;opacity: 70%;" *ngIf="imageProfile && companyData.companyIcon === null">
                <img [src]="companyData.companyIcon" style="width: 100%;opacity: 70%;" *ngIf="imageProfile && companyData.companyIcon != null && companyData.companyIcon != undefined">
                
                <!-- <span style="position: relative;left: 90%;font-size:x-large;margin-top: -25px!important;cursor: pointer;"> <i class="fa fa-camera" aria-hidden="true"></i></span> -->
                 
                <button  mat-icon-button style="position: absolute;top: 65%;left: 17%;"  *ngIf="edit">
                  <mat-icon  for="importFile3" (click)="importFile3.click()" style="font-size: 4rem;"><div class="upload_arrow"></div></mat-icon>
                  <input nbInput type="file" class="custom-file-input" #importFile3 id="importFile3" accept="image/x-png,image/gif,image/jpeg" (change)="image3($event.target.files)" hidden>
                </button>
              </div>
              <div class="col-sm-1">
  
              </div>
              <div class="col-sm-7">
                 <div class="row">
                 
                    <div class="col-sm-12">
                      <h1 style="color: #004F9F;font-weight: bolder;font-size: xx-large;">{{companyData.companyName}}<img *ngIf="companyData.companyBadge === 1" src="../../../../assets/images/core/Insignia.png" style="width: 2.3rem;height: 2.5rem;margin-left: 0.8rem;margin-top: -10px!important;">
                      
                        <img *ngIf="companyData.companyBadge === 0 && userRole === 'Master'" src="../../../../assets/images/core/Insignia.png" style="width: 2.3rem;height: 2.5rem;margin-left: 0.8rem;margin-top: -10px!important;opacity: 30%;"> &nbsp;<span *ngIf="companyData.companyBadge === 0 && userRole === 'Master'" (click)="addBadge(1)" style="cursor: pointer;font-size: 0.8rem;font-weight:100;"><i class="fa fa-plus" aria-hidden="true" style="font-size: 0.9rem; position: absolute;margin-left: 17px!important;" ></i>Agregar</span>
                        <span *ngIf="companyData.companyBadge === 1 && userRole === 'Master'" (click)="addBadge(0)" style="font-size: 0.8rem;font-weight:100;color: rgb(125, 26, 26);cursor: pointer;"><i class="fa fa-minus" aria-hidden="true" style="font-size: 1.2rem; position: absolute;margin-left: 17px!important;" ></i>Eliminar</span>
                      </h1>
                    </div>
                    <div class="col-sm-12">
                        <h2 style="color: #0078D7;font-weight:300;font-size:x-large;text-decoration: underline; cursor: pointer;">{{companyData.companyCategory}}<span class="extra-category" *ngIf="showExtraCategory" (click)="showNewCategory2()"> +1</span></h2>
                    </div>
                    <div class="col-sm-12" *ngIf ="catShow" >
                      <h2 style="color: #0078D7;font-weight:300;font-size:x-large;text-decoration: underline; cursor: pointer;">{{extraCategory}}</h2>
                  </div>
                    <div class="col-sm-12">
                      <h3 style="color: #F58629;font-weight:400;font-size:1.3rem;margin-top: -10px!important;">{{date}}</h3>
                  </div>
                  <div class="col-sm-12">
                    <span style="color: #898989;font-weight:600;font-size:0.8rem;margin-top: -10px!important;" *ngIf="!edit">{{companyData.companyDescription}}</span>

                    <mat-form-field class="example-full-width" appearance="none" id="text-area" *ngIf="edit">
                      <mat-label><div style="padding-bottom: 20px;font-size: medium;">Descripción de la empresa</div> </mat-label>
                      <textarea matInput placeholder="Descripción de la empresa" [(ngModel)]="companyData.companyDescription" style="min-height: 110px;max-height: 110px;background-color: #f3eeee;" maxlength="1000"></textarea>
                      <span style="display: block;" class="char-counter"*ngIf="companyData.companyDescription != null" >{{charCounter | charCount: companyData.companyDescription}}/1000</span>
                    </mat-form-field>

                </div>
                 </div>
              </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>


<div class="row" style="margin: 5rem;margin-top: 0rem!important;">
  <div class="col-sm-5" style="padding-right: 1.9rem!important; margin-left: 7%!important;">
   
    <mat-card  class="target" style="min-height: 20rem!important; height: auto !important;">
      <section #bigDiv style="margin-bottom: 40px;">
      <mat-card-content>
        <div class="row">
          <section class="info-container" *ngIf="userLog">
            <div class="col-sm-12">
              <h3 style="color: #004F9F;font-weight:800;font-size:1.4rem;" >Información de contacto:</h3>
              <span (click)="edit = true" *ngIf="edit === false && userRole === 'Master'" style="cursor: pointer;"><img src="../../../../assets/images/edit.png" style="width: 30px; position: relative;left: 93%;margin-top: -5rem;" ></span>
              <span (click)="update()" *ngIf="edit === true && userRole === 'Master'" style="cursor: pointer;"><i class="fa fa-check" aria-hidden="true" style="font-size: 1.3rem; position: relative;left: 93%;top: -47px" ></i></span>
              <span (click)="edit = true" *ngIf="edit === false && itsMyBusinessProfile" style="cursor: pointer;"><img src="../../../../assets/images/edit.png" style="width: 30px; position: relative;left: 93%;margin-top: -5rem;" ></span>
              <span (click)="update()" *ngIf="edit === true && itsMyBusinessProfile" style="cursor: pointer;"><i class="fa fa-check" aria-hidden="true" style="font-size: 1.3rem; position: relative;left: 93%;top: -47px" ></i></span>
            </div>
            <div class="col-sm-12" >
              <span style="color: #898989;font-weight:700;font-size:1.0rem;" >Correo Electrónico: <span style="color: #0078D7;font-weight:600;font-size:1.0rem;" *ngIf="!edit"><a href="mailto:{{companyData.companyEmail}}">{{companyData.companyEmail}}</a></span></span>
              <mat-form-field *ngIf = "edit" appearance="none">
                <textarea matInput placeholder="Correo Electronico" class="inputs" [(ngModel)]="companyData.companyEmail" ></textarea>
              </mat-form-field>
            </div>
            <div class="col-sm-12" style="margin-top: 1rem;">
              <span style="color: #898989;font-weight:700;font-size:1.0rem;">Teléfono: <span style="color: #0078D7;font-weight:600;font-size:1.0rem;" *ngIf ="!edit"><a href="tel:{{companyData.companyPhone1}}">{{companyData.companyPhone1}}</a></span></span>
              <mat-form-field *ngIf = "edit" appearance="none">
                <textarea matInput placeholder="Teléfono" class="inputs" [(ngModel)]="companyData.companyPhone1" ></textarea>
              </mat-form-field>
            </div>
            <div class="col-sm-12" style="margin-top: 1rem;">
              <span style="color: #898989;font-weight:700;font-size:1.0rem;">Sitio Web: <span style="color: #0078D7;font-weight:600;font-size:1.0rem; cursor: pointer;" *ngIf ="!edit" (click)="navigateToUrl(companyData.companySite)"><u>{{companyData.companySite}}</u></span></span>
               <mat-form-field *ngIf = "edit" appearance="none">
                <textarea matInput placeholder="Sitio Web" class="inputs" [(ngModel)]="companyData.companySite" ></textarea>
              </mat-form-field> 
            </div>
            <div class="col-sm-12" style="margin-top: 1rem;">
              <span style="color: #898989;font-weight:700;font-size:1.0rem;">Dirección: <span style="color: #0078D7;font-weight:600;font-size:1.0rem;" *ngIf ="!edit">{{companyData.companyAddress}}</span></span>
               <mat-form-field *ngIf = "edit" appearance="none">
                <textarea matInput placeholder="Dirección" class="inputs" [(ngModel)]="companyData.companyAddress" ></textarea>
              </mat-form-field>
            </div>
          </section>
          <section class="info-container" *ngIf="!userLog">
            <div class="col-sm-12">
              <h3 style="color: #004F9F;font-weight:800;font-size:1.4rem;" >Información de contacto:</h3>
            </div>
            <div class="col-sm-12">
              <h2 style="color: #f58626;font-weight:800;font-size:1.2rem; cursor:pointer;" (click)="navigateToLogin()">Para ver la información completa se debe de registrar</h2>
            </div>
          </section>
        </div>
      </mat-card-content>
    </section>
    </mat-card>

  </div>
  
  <div class="col-sm-5" style="padding-left:1.9rem!important;margin-right: 7%!important;margin-left: 2%; " *ngIf="this.userRole != 'UsuarioNoSocio'">
    
      <mat-card class="target" style="min-height: 20rem!important; height: auto !important;">
        <section #smallDiv style=" margin-bottom:  40px;">
        <mat-card-content>
          <div class="row">
            <section class="info-container" *ngIf="userLog" >
            <div class="col-sm-12">
              <h3 style="color: #004F9F;font-weight:800;font-size:1.4rem;">Información de Compañía en CIG:</h3>
              <span (click)="edit = true" *ngIf="edit === false && userRole === 'Master'" style="cursor: pointer;"><img src="../../../../assets/images/edit.png" style="width: 30px; position: relative;left: 93%;margin-top: -5rem;" ></span>
              <span (click)="update()" *ngIf="edit === true && userRole === 'Master'" style="cursor: pointer;"><i class="fa fa-check" aria-hidden="true" style="font-size: 1.3rem; position: relative;left: 93%;top: -47px;" ></i></span>
            </div>
            <div class="col-sm-12">
              <span *ngIf="!edit">
              <span style="color: #898989;font-weight:700;font-size:1.0rem;">Gremial(es): <span style="color: #0078D7;font-weight:600;font-size:1.0rem;"  *ngFor="let grem of gremList">
              <br>{{grem}}
              </span></span>
            </span>
            
              <mat-form-field *ngIf = "edit" appearance="none">
                <mat-label>Gremiales</mat-label>
                <section class="myBusiness" *ngIf="!itsMyBusinessProfile">
                <mat-select [(ngModule)]="gremCig" multiple style="width: 400px!important;" (selectionChange)="updateGrem($event)" style="background-color: #f3eeee!important; width: 400px; height: auto; padding-top: 20px; font-weight: 700;">
                   <mat-option *ngFor="let gremials of cigDataList" [value]="gremials.name" style="background-color: #f3eeee!important;">{{gremials.name}}</mat-option>
                </mat-select>
                </section>
              </mat-form-field>
              
            </div>
            <div class="col-sm-12" style="margin-top: 1rem;">
              <span style="color: #898989;font-weight:700;font-size:1.0rem;">Categorías: <span style="color: #0078D7;font-weight:600;font-size:1.0rem;" *ngIf="!edit">{{companyData.companyCategory}} <br>{{extraCategory}}</span>
            </span>
              <!-- <mat-form-field *ngIf = "edit" appearance="none">
                <textarea matInput placeholder="Categorías" class="inputs" [(ngModel)]="companyData.companyCategory"></textarea>
              </mat-form-field> -->
            </div>
            <div class="col-sm-12" style="margin-top: 1rem;">
              <span style="color: #898989;font-weight:700;font-size:1.0rem;">Directorio Industrial: <span style="color: #0078D7;font-weight:600;font-size:1.0rem;cursor: pointer;" *ngIf="!edit" (click)="navigateToUrl(companyData.companyDirectory)">{{companyData.companyDirectory}}</span></span>
              <br>
              <section class="myBusiness" *ngIf="!itsMyBusinessProfile">
              <mat-form-field *ngIf = "edit" appearance="none" style="margin-top: -10px!important;">
               <textarea matInput placeholder="Directorio empresarial" class="inputs" [(ngModel)]="companyData.companyDirectory"></textarea>
              </mat-form-field>
            </section>
            </div>
          </section>
          <section class="info-container" *ngIf="!userLog">
            <div class="col-sm-12">
              <h3 style="color: #004F9F;font-weight:800;font-size:1.4rem;">Información de Compañía en CIG:</h3>
            </div>
            <div class="col-sm-12">
              <h2 style="color: #f58626;font-weight:800;font-size:1.2rem;cursor:pointer;" (click)="navigateToLogin()">Para ver la información completa se debe de registrar</h2>
            </div>
          </section>
        </div>
        </mat-card-content>
      </section>
      </mat-card>
  </div>
</div>


<div class="row" style="margin: 5rem;margin-top: 0rem!important;" *ngIf="this.userRole != 'UsuarioNoSocio'">
  <div class="col-sm-5" style="padding-right: 1.9rem!important; margin-left: 7%!important;">
    <mat-card class="target" style="height: 20rem!important;padding-top: 1rem!important;">
      <mat-card-content>
        <div class="row">
            <div class="col-sm-12" style="text-align: center;">
              <h3 style="color: #004F9F;font-weight:800;font-size:1.8rem;">Insignias Industriales</h3>
            </div>
            <div class="col-sm-12">
              <div class="row" style="justify-content: center!important;" *ngIf="userRole != 'Master'">
                <div class="col-sm-3" *ngFor="let badge of badgeList" >
                  
                  <img [src]="badge.url" style="width: 5.0rem;height: 5.5rem;"> 
                </div>
                
                
              </div>
              
              <div class="row" style="justify-content: center!important;" *ngIf="userRole === 'Master'">
                <div class="col-sm-3" *ngFor="let badge of badgeListMaster" style="max-width: 100%!important;">
                  
                  <img [src]="badge.url" [style]="badge.style"> 
                  <span *ngIf="userRole === 'Master' && badge.add === 0" (click)="addBadges(badge)"><i class="fa fa-plus" aria-hidden="true" style="font-size: 0.9rem; cursor: pointer;" ></i></span>
                  <span *ngIf="userRole === 'Master' && badge.add === 1" (click)="deleteBadges(badge)" ><i class="fa fa-minus" aria-hidden="true" style="font-size: 0.9rem;cursor: pointer;" ></i></span>
                </div>
                
                
              </div>

            </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
  
  <div class="col-sm-5" style="padding-left:1.9rem!important;margin-right: 7%!important;margin-left: 2%; ">
      <mat-card class="target" style="height: 20rem!important;padding-top: 1rem!important;">
        <mat-card-content>
          <div class="row">
            <div class="col-sm-12">
              <h3 style="color: #004F9F;font-weight:800;font-size:1.4rem;">Valoraciones</h3>
            </div>
            <div class="col-sm-5" style="margin-top: 1rem!important;">
              <div class="rating"> <input #starFive type="radio" name="rating" value="5" id="5"><label for="5" style="color: #FFD600;">★</label> 
                <input #starFour type="radio" name="rating" value="4" id="4"><label for="4" style="color: #FFD600;">★</label> 
                <input #starThree type="radio" name="rating" value="3" id="3" ><label for="3" style="color: #FFD600;">★</label> 
                <input #starTwo type="radio" name="rating" value="2" id="2"><label for="2" style="color: #FFD600;">★</label> 
                <input #starOne type="radio" name="rating" value="1" id="1" ><label for="1" style="color: #FFD600;">★</label>
              </div>
              
              
              </div>
              <div class="col-sm-5" style="margin-top: 1rem!important; margin-left: 0.5rem;">
                <progress id="file" max="100" [value]="promQ5"  style="height: 1.8rem!important;border-radius: none!important;margin-top: -0.2rem!important;width: 100%;"> </progress>
              </div>
              <div class="col-sm-1" style="margin-top: 1rem!important; margin-left: 0.5rem;">
                  <span style="color: #0078D7;font-weight: 700;font-size: medium;">{{companyData.Q5}}</span>
              </div>

              <div class="col-sm-5" style="margin-top: 1rem!important;">
                <div class="rating"> <input #starFive type="radio" name="rating" value="5" id="5"><label for="5">☆</label> 
                  <input #starFour type="radio" name="rating" value="4" id="4"><label for="4" style="color: #FFD600;">★</label> 
                  <input #starThree type="radio" name="rating" value="3" id="3" ><label for="3" style="color: #FFD600;">★</label> 
                  <input #starTwo type="radio" name="rating" value="2" id="2"><label for="2" style="color: #FFD600;">★</label> 
                  <input #starOne type="radio" name="rating" value="1" id="1" ><label for="1" style="color: #FFD600;">★</label>
                </div>
                </div>

                <div class="col-sm-5" style="margin-top: 1rem!important; margin-left: 0.5rem;">
                  <progress id="file" max="100" [value]="promQ4"  style="height: 1.8rem!important;border-radius: none!important;margin-top: -0.2rem!important;width: 100%;"> </progress>
                </div>
                <div class="col-sm-1" style="margin-top: 1rem!important; margin-left: 0.5rem;">
                    <span style="color: #0078D7;font-weight: 700;font-size: medium;">{{companyData.Q4}}</span>
                </div>

                <div class="col-sm-5" style="margin-top: 1rem!important;">
                  <div class="rating"> <input #starFive type="radio" name="rating" value="5" id="5"><label for="5">☆</label> 
                    <input #starFour type="radio" name="rating" value="4" id="4"><label for="4">☆</label> 
                    <input #starThree type="radio" name="rating" value="3" id="3" ><label for="3" style="color: #FFD600;">★</label> 
                    <input #starTwo type="radio" name="rating" value="2" id="2"><label for="2" style="color: #FFD600;">★</label> 
                    <input #starOne type="radio" name="rating" value="1" id="1" ><label for="1" style="color: #FFD600;">★</label>
                  </div>
                  </div>

                  <div class="col-sm-5" style="margin-top: 1rem!important; margin-left: 0.5rem;">
                    <progress id="file" max="100" [value]="promQ3"  style="height: 1.8rem!important;border-radius: none!important;margin-top: -0.2rem!important;width: 100%;"> </progress>
                  </div>
                  <div class="col-sm-1" style="margin-top: 1rem!important; margin-left: 0.5rem;">
                      <span style="color: #0078D7;font-weight: 700;font-size: medium;">{{companyData.Q3}}</span>
                  </div>

                  <div class="col-sm-5" style="margin-top: 1rem!important;">
                    <div class="rating"> <input #starFive type="radio" name="rating" value="5" id="5"><label for="5">☆</label> 
                      <input #starFour type="radio" name="rating" value="4" id="4"><label for="4">☆</label> 
                      <input #starThree type="radio" name="rating" value="3" id="3" ><label for="3">☆</label> 
                      <input #starTwo type="radio" name="rating" value="2" id="2"><label for="2" style="color: #FFD600;">★</label> 
                      <input #starOne type="radio" name="rating" value="1" id="1" ><label for="1" style="color: #FFD600;">★</label>
                    </div>
                    </div>

                    <div class="col-sm-5" style="margin-top: 1rem!important; margin-left: 0.5rem;">
                      <progress id="file" max="100" [value]="promQ2"  style="height: 1.8rem!important;border-radius: none!important;margin-top: -0.2rem!important;width: 100%;"> </progress>
                    </div>
                    <div class="col-sm-1" style="margin-top: 1rem!important; margin-left: 0.5rem;">
                        <span style="color: #0078D7;font-weight: 700;font-size: medium;">{{companyData.Q2}}</span>
                    </div>

                    <div class="col-sm-5" style="margin-top: 1rem!important;">
                      <div class="rating"> <input #starFive type="radio" name="rating" value="5" id="5"><label for="5">☆</label> 
                        <input #starFour type="radio" name="rating" value="4" id="4"><label for="4">☆</label> 
                        <input #starThree type="radio" name="rating" value="3" id="3" ><label for="3">☆</label> 
                        <input #starTwo type="radio" name="rating" value="2" id="2"><label for="2">☆</label> 
                        <input #starOne type="radio" name="rating" value="1" id="1" ><label for="1" style="color: #FFD600;">★</label>
                      </div>

                      </div>

                      <div class="col-sm-5" style="margin-top: 1rem!important; margin-left: 0.5rem;">
                        <progress id="file" max="100" [value]="promQ1" style="height: 1.8rem!important;border-radius: none!important;margin-top: -0.2rem!important;width: 100%;"> </progress>
                      </div>
                      <div class="col-sm-1" style="margin-top: 1rem!important; margin-left: 0.5rem;">
                          <span style="color: #0078D7;font-weight: 700;font-size: medium;">{{companyData.Q1}}</span>
                      </div>


        </div>
        </mat-card-content>
      </mat-card>
  </div>
</div>

<!-- <div style="min-width: 100% !important; max-width: 100% !important; text-align: center !important; margin-bottom: 1rem; margin-top: 1rem; text-align: center;" *ngIf="!loading">
  <img width="60%" height="auto" src="../../../../assets/images/core/nestle-banner.jpg">
</div> -->

<div class="row">
  <div class="col-sm-12">
    <!-- publicidad -->
    <div>
      <!-- <div id="my" class="carousel 1carousel-fade img-details" data-bs-ride="carousel" data-bs-interval="4000" style="height:auto">
        <div class="carousel-inner">
            <div class="carousel-item {{publicity1.active}}" style="text-align: center;" *ngFor="let publicity1 of publicityMyProfile"> <img [src]="publicity1.publicityBanner" class="imgPublicity" (click)="openPublicity(publicity1.publicityLink)"> </div>
          </div>   
      </div> -->
      <div class="carousel"  >
        <ng-container *ngFor="let image of publicityMyProfile; index as i">
          <div class="carousel-item" [ngClass]="{ 'active': i === activeIndex }">
            <img [src]="image.publicityBanner" (click)="openPublicity(image.publicityLink)">
          </div>
        </ng-container>
      </div>
    </div>
    <!-- fin de publicidad -->
  </div>
</div>


<div style="min-width: 84% !important; max-width: 84% !important; text-align-last: left; margin-bottom: 1rem; margin-top: 3.5rem;margin-left: 9%!important;margin-right: 7%!important;" *ngIf="!loading && this.userRole != 'UsuarioNoSocio'">
  <h1 style="color: #004F9F; text-align: left; margin-bottom: 0% !important; font-size: 2rem;font-weight: 700;">Productos de {{companyData.companyName}}</h1>
  <hr style="margin-top: 1rem; height: 2px;background-color: #F58626;opacity: 100%;margin-right: 3rem;">

    
      <div class="row">
        <div style="background-color: #FFFFFF;height: 5rem;position: absolute;width: 5%;left: 93%;margin-top: 10%;text-align: center;"  (click)="nextProduct()" *ngIf="this.productsListFinishedEnable">
          <i class="fa fa-chevron-right" aria-hidden="true" id="nextPE"></i>
        </div>
        <div style="background-color: #FFFFFF;height: 5rem;position: absolute;width: 5%;left: 1%;margin-top: 10%;text-align: center;" id="prevPE" (click)="prevProduct()" *ngIf="this.productsListStartEnable">
          <i class="fa fa-chevron-left" aria-hidden="true" id="prevPE"></i>
        </div>
        <div class="col-md-4" *ngFor="let products of productList" style="padding: 1.5rem!important;">
          <div class="card h-100" id="cards" style="border: none;padding: 0.5rem;cursor: pointer;box-shadow: 0 6px 20px rgba(71, 71, 71, 0.08), 0 0 6px rgba(71, 71, 71, 0.08);padding-bottom: 0%!important;"  (click)="viewProduct(products.productUUID)" >
            <div style="width: 100%;padding: 1rem;height: 10rem;">
              <img [src]='products.productMainPicture' alt="..." style="width: 100%;height: 10rem;">
            
            </div>
              <div class="card-body" style="margin-bottom: 0%!important;margin-top: 1.5rem!important;">
                <div class="row">
                  
                  <div class="col-sm-8">
                    <span style="color: #004F9F;font-size: 0.8rem;font-weight: 500;line-height: 0!important;">{{products.productName}}</span>
                  </div>
                  <div class="col-sm-4" >
                    <span class="float-end" style="font-weight: 700;" *ngIf=" 1 * products.productPrice > 0">{{products.unitPriceType}}{{products.productPrice}}</span> 
                  </div>
                  
                  
                </div>
              </div>
              <div class="col-sm-12" style="text-align: right;color: #9c9c9c;font-size: 0.7rem;bottom: 0;margin-bottom: 1rem;margin-right: 1rem;font-family: 'Arial';">
                <small>{{products.productCategory}}</small>
              </div>
          </div>
        </div>
        

    </div>

  
</div>

<!-- 
<div class="col-sm-12 col-md-12 col-lg-12 headerBusiness">
  <div class="row" style="padding: 5rem; align-items: center;">
    <div class="col-sm-auto col-md-auto col-lg-auto" style="text-align: right;">
      <h1 class="companyName">{{companyData.companyName}}</h1>
    </div>
    <div class="col-sm-1 col-md-1 col-lg-1">
      <img src="../../../../assets/images/core/Insignia_blanca.png" style="max-width: 4rem;">
    </div>
    <div class="col-sm-12 col-md-12 col-lg-12" style="padding-left: 4.3rem;">
      <h2 style="color: white;">{{companyData.companyCategory}}</h2>
    </div>
  </div>
</div>
<div style="background-color: #e6e6e6 !important; height: 85vh !important; margin: 1rem;">
  <div class="row">
    <div class="col-sm-1 col-md-1 col-lg-1"></div>
    <div class="col-sm-4 col-md-4 col-lg-4" style="padding: 2rem;">
      <mat-card style="height: 30rem !important; border-radius: 50px !important;">
        <mat-card-content>
          <div class="col-sm-12 col-md-12 col-lg-12" style="text-align: center;">
            <img src="../../../../assets/images/core/CIG-Match-1 (1).png" style="width: 10rem;">
          </div>
          <div class="col-sm-12 col-md-12 col-lg-12" style="text-align: center;">
            <div class="row" style="align-items: center;">
              <div class="col-sm-4 col-md-4 col-lg-4" style="text-align-last: end;">
                <img src="../../../../assets/images/core/Insignia.png" style="width: 4rem;">
              </div>
              <div class="col-sm-6 col-md-6 col-lg-6" style="text-align: left;">
                <span style="font-size: x-large;font-weight: 600;color: #055285;">{{companyData.companyName}}</span>
              </div>
            </div>
          </div>
          <div class="col-sm-12 col-md-12 col-lg-12" style="padding-top: 3rem;">
            <span style="font-size: larger;font-weight: 800;color: #055285;">Descripción</span>
          </div>
          <div class="col-sm-12 col-md-12 col-lg-12">
            <span>{{companyData.companyDescription}}</span>
          </div>
          <div class="col-sm-12 col-md-12 col-lg-12" style="padding-top: 3rem;">
            <span style="font-size: larger;font-weight: 800;color: #055285;">Categoría</span>
          </div>
          <div class="col-sm-12 col-md-12 col-lg-12">
            <span>{{companyData.companyCategory}}</span>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
    <div class="col-sm-6 col-md-6 col-lg-6" style="padding: 2rem;">
      <mat-card style="height: 30rem !important; border-radius: 50px !important;">
        <mat-card-content>
          <div class="col-sm-12 col-md-12 col-lg-12" style="text-align-last: center;">
            <span style="font-size: x-large;font-weight: 800;color: #055285;">OPINIONES DE LOS CLIENTES</span>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
  <div class="col-sm-12 col-md-12 col-lg-12">
    <div class="col-sm-12 col-md-12 col-lg-12" style="text-align: center;font-size: xx-large;">
      <span style="text-transform: uppercase;color: #055285; font-weight: 400;">PRODUCTOS PUBLICADOS {{companyData.companyName}}</span>
    </div>

  </div>
</div> -->
