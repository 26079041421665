<div id="carouselExampleIndicators"class="carousel slide carousel-fade" data-bs-ride="true" >
    <div class="carousel-indicators" *ngIf="publicityListProduct1 != undefined">
      <button #myButton id="myButton"  value="refresh" type="button" data-bs-target="#carouselExampleIndicators" *ngFor="let publicity3 of publicityListProduct1;let index = index; first as isFirst" [attr.data-bs-slide-to]="index" [ngClass]="isFirst ? 'design active':'design'" [attr.aria-current]="isFirst ? 'true' : 'false'" ></button>

    </div>
    <!-- Inner -->
    <div class="carousel-inner">
    
      <div [ngClass]="isFirst ? 'carousel-item active' : 'carousel-item'"  *ngFor="let publicity3 of publicityListProduct1;first as isFirst"><a href="{{publicity3.publicityLink}}"><img [src]="publicity3.publicityBanner" class="carouselImage d-block w-100"></a></div>
     
      
    </div>
  </div>
