<div class="col-sm-12">
    <div class="row">
        <div class="container">
            <div class="col"><h1>Se le envió una notificación de su solicitud a otros usuarios ¿Desea envíarsela a otros usuarios que no pertenezcan a la categoría seleccionada?</h1></div>
            <div class="col-sm-12">
                <div class="form-container">
                    <form [formGroup]="contactEmails" type="submit" style="width: 90% ;">
                        <mat-form-field appearance = "outline">
                            <mat-label>Usuarios</mat-label>
                            <mat-select formControlName="email" multiple >
                                <mat-option *ngFor="let contact of companyResults"  class="select" [value]="contact"  (click)="selectThisContact()">
                                    <span *ngIf="contact.familyItemUUID == catFamSelected" style="color:#8a8a8a">{{contact.companyName}}</span>
                                    <span *ngIf="contact.familyItemUUID != catFamSelected" style="color:#000000">{{contact.companyName}}</span>
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </form>
                </div>
                
            </div>
            <div class="col-sm-12">
                <div class="row">
                    <div class="col-sm-6">
                        <div class="button-container">
                            <button class="accept" (click)="sendMessages()">Aceptar</button>
                        </div>
                       
                    </div>
                    <div class="col-sm-6">
                        <div class="button-container">
                            <button class="cancel" (click)="cancel()">Cancelar</button>
                        </div>
                        
                    </div>
                </div>
               
                  
            </div>
        </div>
    </div>
</div>
