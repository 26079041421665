<app-loading-wrapper-overlay *ngIf="loading"></app-loading-wrapper-overlay>
<div class="row">
    <div class="col-sm-5" style="overflow-y: auto;height: 40rem;overflow-x: hidden;" >

<div class="col-md-12" style="padding-left: 15%;padding-right: 15%;margin-bottom: 1rem;">
  <div class="form"> <input type="text" class="form-control form-input" placeholder="¿Qué estás buscando?" [(ngModel)]="this.busqueda" on-keyup="$event.keyCode == 13 && this.search()"></div>

</div>
<div class="col-md-12 " *ngFor="let messages of mainMessageInfo" style="margin: 0!important;width: 100%;">
    <div class="shadow p-3 bg-white" (click)="loadMessages(messages)"   id="chats-container" >
        <div class="d-flex justify-content-between" style="align-items: center;margin: 0!important;width: 100%;" >
          <div class="row">
            <div class="col-sm-12">

              <h3 class="mb-0" style="color: #004F9F;font-weight: 500;font-size: 0.8rem;text-align: right;">{{ messages.lastDate | date :'d/MM/YYYY, h:mm a'}}</h3>

           </div>
           
            <div class="col-sm-3 d-flex flex-row align-items-center" style="margin-top: -2rem!important;">
              <div class="position-relative"> <img src="../../../../../assets/images/core/menu/1485477097-avatar_78580.png" width="110" class="rounded-circle">  </div>
          </div>

          <div class="col-sm-8" style="margin-top: 0rem;">
            <div class="row">
              <div class="col-sm-12" style="color: #004F9F;font-size: 1.5rem;font-weight: 700;"> <span *ngIf="userlocaldata.userId === messages.chatUser1UUID">{{messages.chatUserReceiver}}</span><span *ngIf="userlocaldata.userId != messages.chatUser1UUID">{{messages.chatUserSender}}</span></div>
              <div class="col-sm-12" style="color: #0078D7;font-size: 1.2rem;margin-top: 5px;"> <span>{{messages.productName}}</span></div>
              <div class="col-sm-12" style="color: #898989;font-size: 1.1rem;margin-top: 5px;"> <span *ngIf="messages.messageStatus == 'recibido' && messages.messageUserReceiver === userlocaldata.userId" style="font-weight: bold; color: black;">{{messages.message}}</span>
                <span *ngIf="messages.messageStatus != 'recibido'|| messages.messageUserReceiver != userlocaldata.userId">{{messages.message}}</span></div>
              
            </div>
          </div>
          <div class="col-sm-1" style="justify-content: right!important;" (click)="delete(messages.chatUUID)">
            <img src="../../../../../assets/images/core/menu/delete_message.png" width="20px" style="opacity: 60%;">
           </div>

          </div>





        </div>
    </div>
</div>


</div>
<div class="col-sm-7">
<div class="row">
  <div class="col-sm-12" *ngIf="this.codeChat == ''" style="align-items: center;padding: 2rem;padding-bottom: 0rem;padding-top: 10%;opacity: 50%;">
    <img src="../../../../../assets/images/core/menu/que-es-networking.jpg" style="width: 100%;">
    <h1 style="color: #898989;margin-top: -1rem;text-align: center;font-size: 2rem;font-weight: bolder;">Bienvenido al centro de mensajes</h1>
</div>
</div>

<div class="row"  *ngIf="this.codeChat != ''">
  <div class="col-sm-12" *ngIf="!contact">
    <div class="row" style="border-bottom: 2px solid #ebebeb;overflow-y: hidden; height: auto !important;"> <!--6rem-->
      <div class="col-sm-12" id="product-info--container">
        <div class="row">
          <div class="col-sm-2">
            <img [src]="productsData.productMainPicture" style="width: 100%;">
          </div>
          <div class="col-sm-5">
  
            <div style="cursor: pointer;" (click) ="viewCompany(data)"><span style="color: #004F9F;font-size: 1.5rem;font-weight: 700;" >{{productsData.productName}}</span></div>
            <div style="margin-top: 1rem!important;cursor: pointer;" (click) ="viewCompanyD()"><span style="color: #0078D7;font-size: 1.2rem;font-weight: 100;text-decoration: underline;cursor: pointer;"  >{{data.chatUserReceiver}}</span></div>
            <div  style="color: #898989;font-size: 0.8rem;margin-top: 0.5rem;" *ngIf=" 1 * productsData.productPrice > 0"> <span>{{productsData.unitPriceType}}{{productsData.productPrice}}</span></div>
        </div>
        <div class="col-sm-4" style="text-align: right;" *ngIf = "userCompare">
          <div style="margin-top: 3rem;"><span><button mat-button style="width: 70%; background-color:#F5F5F5;" (click)="qualifyRequest()">Solicitar calificación</button></span></div>
      </div>
        </div>
       
         </div>
        
     </div>

  </div>

  <div class="col-sm-12" *ngIf="contact">
    <div class="row" style="border-bottom: 2px solid #ebebeb;height: 6rem!important;overflow-y: hidden;">
      <div class="col-sm-12" id="product-info--container">
        <div class="row">
          <div class="col-sm-2">
            <img src="../../../../../assets/images/core/avatarMessage.png" style="width: 100%;">
          </div>
          <div class="col-sm-9">
            <div style="color: #004F9F;font-size: 1.5rem;font-weight: 700;margin-top: 2rem!important;cursor: pointer;" (click) ="viewCompany(data)"> <span *ngIf="userlocaldata.userId === data.chatUser1UUID">{{data.chatUserReceiver}}</span><span *ngIf="userlocaldata.userId != data.chatUser1UUID">{{data.chatUserSender}}</span></div>
          </div>
          
        </div>
       
         </div>
        
     </div>

  </div>

  <div class="col-sm-12" style="height: 32rem!important;overflow-y: auto; padding: 1rem;" id="ventanachat">

  
 
    <!-- <div class="media-body">
      <p *ngIf="actualUser !== chatData.chatUser_Sender && messages.chatMessageR !== null">{{messages.chatMessageR + " - "}} <time datetime="2018" style="color: black;">{{messages.chatMessage_DATE | date:'short'}}</time></p>
      <p *ngIf="actualUser === chatData.chatUser_Sender && messages.chatMessage !== null">{{messages.chatMessage + " - "}} <time datetime="2018" style="color: black;">{{messages.chatMessage_DATE | date:'short'}}</time></p> -->
      <!-- <p *ngIf="actualUser !== chatData.chatUser_Receiver && messages.chatMessageR !== null">{{messages.chatMessageR + " - "}} <time datetime="2018" style="color: black;">{{messages.chatMessage_DATE | date:'short'}}</time></p> -->
    <!-- </div>
    <div class="media media-chat media-chat-reverse">
      <div class="media-body" style="float: right;">
        <time datetime="2018" style="color: black;text-align: right;">{{messages.chatMessage_DATE | date: 'd/MM/YYYY, h:mm:ss a'}}</time>
        <p *ngIf="actualUser !== chatData.chatUser_Receiver && messages.chatMessage !== null">{{messages.chatMessage + " - "}}</p> -->
        <!-- <p *ngIf="actualUser !== chatData.chatUser_Sender && messages.chatMessage !== null">{{messages.chatMessage + " - "}} <time datetime="2018" style="color: black;">{{messages.chatMessage_DATE | date:'short'}}</time></p> -->
      <!-- </div>
    </div> -->
    <div *ngFor="let messages of messagesData">
    <div class="row" style="margin-top: 1.3%;margin-left: 0.5rem;border-color: black!important;justify-content: left!important;" *ngIf="actualUser !== messages.chatUser_Sender && messages.chatMessageR !== null">
      <div class="col-sm-1">
        <img src="../../../../../assets/images/core/menu/1485477097-avatar_78580.png" width="50" class="rounded-circle" style="margin-left: -10px;"> 
      </div>
      <div class="col-sm-9" class="sms2"  style="max-width: 60%!important;">
        <div class="row"> 
             <div class="col-sm-12">
              <span style="width: auto;max-width: 100%;">{{messages.chatMessageR}} <span *ngIf="messages.chatDocument !=null"><a href="{{messages.chatDocument}}" target="_blank" style="text-decoration: none; color:inherit" download ><i class="fas fa-file-download" style="width: 25px; height:25px; cursor:pointer; text-decoration: none;"></i></a></span></span>
             </div>
             <div class="col-sm-12" style="text-align: right;margin-bottom: -10px;">
              <time datetime="2018" style="color: #ffffff;font-weight: lighter;font-size: smaller;">{{messages.chatMessage_DATE | date:'d/MM/YYYY, h:mm a'}}</time>
             </div>
        </div>
       
      </div>
    </div>


    <div class="row" style="margin-top: 1.3%;margin-left: 0.5rem;border-color: black!important;justify-content: left!important;" *ngIf="actualUser == messages.chatUser_Receiver && messages.chatMessage !== null">
      <div class="col-sm-1">
        <img src="../../../../../assets/images/core/menu/1485477097-avatar_78580.png" width="50" class="rounded-circle" style="margin-left: -10px;"> 
      </div>
      <div class="col-sm-9" class="sms2"  style="max-width: 60%!important;">
        <div class="row"> 
             <div class="col-sm-12">
              <span style="width: auto;max-width: 100%;">{{messages.chatMessage}} <span *ngIf="messages.chatDocument !=null"><a href="{{messages.chatDocument}}" target="_blank" style="text-decoration: none; color:inherit" download ><i class="fas fa-file-download" style="width: 25px; height:25px; cursor:pointer; text-decoration: none;"></i></a></span></span>
             </div>
             <div class="col-sm-12" style="text-align: right;margin-bottom: -10px;">
              <time datetime="2018" style="color: #ffffff;font-weight: lighter;font-size: smaller;">{{messages.chatMessage_DATE | date:'d/MM/YYYY, h:mm a'}}</time>
             </div>
        </div>
       
      </div>
    </div>
    

    <div class="row" style="margin-top: 1.3%;border-color: black!important;justify-content: right!important;">
      
      <div class="col-sm-9" class="sms" *ngIf="actualUser !== messages.chatUser_Receiver && messages.chatMessage !== null" style="max-width: 60%!important;">
        <div class="row"> 
             <div class="col-sm-12">
              <span style="width: auto;max-width: 100%;">{{messages.chatMessage}}<span *ngIf="messages.chatDocument !=null"><a href="{{messages.chatDocument}}" target="_blank" style="text-decoration: none; color:inherit" download ><i class="fas fa-file-download" style="width: 25px; height:25px; cursor:pointer; text-decoration: none;"></i></a></span></span>
             </div>
             <div class="col-sm-12" style="text-align: right;justify-content: right!important;margin-bottom: -10px;">
              <time datetime="2018" style="color: #fff;font-weight: lighter;font-size: smaller;">{{messages.chatMessage_DATE | date:'d/MM/YYYY, h:mm a'}}</time>
             </div>
        </div>
       
      </div>
    </div>


    <div class="row" style="margin-top: 1.3%;border-color: black!important;justify-content: right!important;">
      
      <div class="col-sm-9" class="sms" *ngIf="actualUser === messages.chatUser_Sender && messages.chatMessageR !== null" style="max-width: 60%!important;">
        <div class="row"> 
             <div class="col-sm-12">
              <span style="width: auto;max-width: 100%;">{{messages.chatMessageR}}<span *ngIf="messages.chatDocument !=null"><a href="{{messages.chatDocument}}" target="_blank" style="text-decoration: none; color:inherit" download ><i class="fas fa-file-download" style="width: 25px; height:25px; cursor:pointer; text-decoration: none;"></i></a></span></span>
             </div>
             <div class="col-sm-12" style="text-align: right;justify-content: right!important;margin-bottom: -10px;">
              <time datetime="2018" style="color: #fff;font-weight: lighter;font-size: smaller;">{{messages.chatMessage_DATE | date:'d/MM/YYYY, h:mm a'}}</time>
             </div>
        </div>
       
      </div>
    </div>
    </div>
    <div class="row" style="margin-top: 1.3%;margin-left: 0.5rem;border-color: black!important;justify-content: left!important;" *ngIf="userlocaldata.userId === userRequest && chatStatus === 'qualifying'">
      <div class="col-sm-1">
        <img src="../../../../../assets/images/core/menu/1485477097-avatar_78580.png" width="50" class="rounded-circle" style="margin-left: -10px;"> 
      </div>
          <div class="col-sm-9" class="sms2"  style="max-width: 60%!important;">
            <div class="row"> 
                 <div class="col-sm-12">
                  <span style="width: auto;max-width: 100%;">
                    <div class="rating">  
                      <input #starFive type="radio"  id="5" (click)="confirmation(5)" ><label for="5">☆</label>  
                      <input #starFour type="radio"   id="4" (click)="confirmation(4)" ><label for="4">☆</label> 
                      <input #starThree type="radio"  id="3" (click)="confirmation(3)" ><label for="3">☆</label>
                      <input #starTwo type="radio"  id="2" (click)="confirmation(2)"><label for="2">☆</label>
                      <input #starOne type="radio"  id="1"  (click)="confirmation(1)"><label for="1">☆</label> 
                  </div>
                  </span>
                 </div>
                 <div class="col-sm-12" style="text-align: right;justify-content: right!important;margin-bottom: -10px;">
                  <time datetime="2018" style="color: #fff;font-weight: lighter;font-size: smaller;"></time>
                 </div>
            </div>
        </div>
        </div>
</div>
<div class="col-sm-12" *ngIf ="data.chatStatus != 'qualifying'">
  <div class="publisher bt-1 border-light" *ngIf="emojiShow">
    <div class="emoji-selector--container">
      <section class="emoji-container" *ngFor="let emoji of emojisList">
        <div class="emoji" (click)="addEmoji($event)">{{emoji.emoji}}</div>
      </section>
  </div>
  </div>
  <div class="publisher bt-1 border-light"> <img class="avatar avatar-xs" src="https://img.icons8.com/color/36/000000/administrator-male.png" alt="...">
    <span style="cursor: pointer;" *ngIf="imageStatus" (click)="clearImageInput()"><i class="fas fa-times"></i></span>
    <input class="publisher-input" type="text" placeholder="Escribe tu mensaje ..." [(ngModel)]="localChatMessage" #chatTextInput on-keyup="$event.keyCode == 13 && sendMessage() || $event.keyCode != 13 && sendMessageTemp()" [attr.disabled]="inputDisabled">
    <label class="custom-file-label" #labelImportPrincipal for="imagenPrincipal" style="display: none;">Subir imagen</label>
    <button  mat-icon-button>
      <!-- <mat-icon  for="imagenPrincipal" (click)="imagenPrincipal.click()" style="font-size: 4rem;"><div class="upload_arrow"></div></mat-icon> -->
      <span (click)="imagenPrincipal.click()"><i class="fa fa-paperclip file-browser"></i></span>
      <input type="file" class="custom-file-input" #imagenPrincipal id="imagenPrincipal" accept="image/x-png,image/gif,image/jpeg,application/pdf,.csv" (change)="getImage($event.target.files)" hidden>
    </button>
 
    <!-- <div class="image-upload">
      <label for="file-input" #imagenPrincipal>
        <input id="file-input" type="file" (click)="getImage()"/>
        <i class="fa fa-paperclip file-browser"></i>
        <img src="https://icons.iconarchive.com/icons/dtafalonso/android-lollipop/128/Downloads-icon.png"/>
      </label>
    
      
    </div>
   -->
    <!-- <span class="publisher-btn file-group" (click)="sendFile()"> <i class="fa fa-paperclip file-browser"></i>
    <input type="file"accept="image/png, image/jpeg"> </span> -->
    <span (click)="emojiBarShow()"><a class="publisher-btn" data-abc="true"><i class="fa fa-smile"></i></a></span>

    <a style="padding: 0;margin-left: 0.2rem;" class="publisher-btn text-info" data-abc="true" (click)="sendMessage()"><img src="../../../../../../assets/images/core/sendMessage.png" alt="Enviar" style="width: 1.55rem;height: 1.55rem;margin: 0;border: 0;"></a>
  </div>
</div>
<div class="col-sm-12" *ngIf = "data.chatStatus == 'qualifying'">
    <div class="publisher bt-1 border-light">
        <div class="row" style="justify-content: center;">
               <div class="col-sm-8" style="text-align: center!important;">
                  <span class="publisher-input" style="font-size: medium;font-weight: 600;color: #004F9F;">
                      Esta conversación está pendiente de calificación, por lo que ya no podrá responder.
                  </span>
               </div>
        </div>

  </div>

</div>
<!-- <div class="row" style="margin-top: -50px; height:auto;">
<div class="col-sm-12" *ngIf ="data.chatStatus == 'qualifying'">
  <div class="publisher bt-1 border-light" style="width: 103%;">
   <form [formGroup] = "qualifyPoints"> 
  <div class="row" style="margin-top: 15px;">
    <div class="col-sm-6"> 
    <div class="rating">  
         <input #starFive type="radio" [value]="5" id="5" formControlName="rating" ><label for="5">☆</label>  
         <input #starFour type="radio"  [value]="4" id="4" formControlName="rating" ><label for="4">☆</label> 
         <input #starThree type="radio" [value]="3" id="3" formControlName="rating" ><label for="3">☆</label>
         <input #starTwo type="radio"  [value]="2" id="2" formControlName="rating"><label for="2">☆</label>
         <input #starOne type="radio" [value]="1" id="1"  formControlName="rating"><label for="1">☆</label> 
     </div>
    </div>
     <div class="col-sm-6" style="text-align: right;"> 
      <div style="width: 100%;"><span><button mat-button style="width: 70%; background-color:#bab9b9;" (click)="chatQualification()">Enviar calificación</button></span></div> 
    </div>
  </div>
   </form> 
  </div>
</div>
</div> -->
</div>
</div>
</div>