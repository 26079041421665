import { Component, OnInit,ViewChild, ElementRef } from '@angular/core';
import { PublicityService } from 'src/app/services/publicity.service';
@Component({
  selector: 'app-request-carousel',
  templateUrl: './request-carousel.component.html',
  styleUrls: ['./request-carousel.component.scss']
})
export class RequestCarouselComponent implements OnInit {
  publicityListProduct1;
  @ViewChild('myButton') myButton: ElementRef;
  constructor(private publicityService: PublicityService) {
    setTimeout(() => {
      this.triggerClick();
    }, 5000);
   }

 async ngOnInit() {
    await this.publicityService.getAllPublicity().then(
  
      (result: any) => {
        
        const DB = result.records[0][0];
        this.publicityListProduct1 = DB.filter(x => x.publicityPlace == "PBSP" && x.publicityStatus == "Activo");
      
      }
    )
  }

  triggerClick(){
    let button: HTMLElement = this.myButton.nativeElement as HTMLElement;
    button.click();
  }

}
