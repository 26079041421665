import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter2'
})
export class Filter2Pipe implements PipeTransform {

  transform(value:any, args:any):any {
     const result = []; 
     var companyName = args.companyCategory;
    for(const icon of value){
         if(icon.masterCatName == companyName){
           result.push(icon);
         }
  
    }
      return result;
     
  }

  }

