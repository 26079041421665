import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'requestFilter'
})
export class RequestFilterPipe implements PipeTransform {

  transform(value:any, args:any):any {
    var result = [];
      if(value != undefined){
         for(const request of value){
           if(request.requestTitle.normalize('NFD').replace(/[\u0300-\u036f]/g,"").toLowerCase().indexOf(args.replace(/[\u0300-\u036f]/g,"").toLowerCase()) > -1){
             result.push(request);
           }else if(request.requestDetail.normalize('NFD').replace(/[\u0300-\u036f]/g,"").toLowerCase().indexOf(args.replace(/[\u0300-\u036f]/g,"").toLowerCase()) > -1){
                 result.push(request);
           }
         }
      }
    
     return result;
  }

}
