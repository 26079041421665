import { Component, OnInit } from '@angular/core';
import { CategoryModel } from 'src/app/models/categoryModel';
import { MasterCatModel } from 'src/app/models/msterCatModel';
import { SubCategoryModel } from 'src/app/models/subcategoryModel';
import { CategoryService } from 'src/app/services/category.service';
import Swal from 'sweetalert2';
import { PublicityService } from './../../services/publicity.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CategoriesFilterPipe } from 'src/app/pipes/categories-filter.pipe';

@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.scss']
})
export class CategoriesComponent implements OnInit {
  loading = false;
  categoriesList: CategoryModel;
  categoriesList1: CategoryModel;
  masterCatList;
  masterCatListSearch: any = [];
  subCatList: SubCategoryModel;
  masterCatIconSelect = "";
  masterCatNameSelect="";
  busqueda="";
  busquedaP=false;
  cat;
  cat1;
  publicityCategory;
  public userLog : Boolean = true;
  public activeIndex = 0;

  constructor(
    private categoriesService: CategoryService,
    private publicityService: PublicityService,
    private router: Router,
    private route: ActivatedRoute,
    private categoriesFilter: CategoriesFilterPipe
  ) { }

  async ngOnInit(){
    try {
      JSON.parse(localStorage.getItem('user')).userId;
     } catch (error) {
       this.userLog = false;
     }
    this.route.paramMap.subscribe(async (params: any) => {
      const searchP = params.params.search;
      if (searchP !== undefined) {
        this.busqueda = searchP;
        this.busquedaP = true;
        await this.getAllMasterCat();
        
        
      } else {
        this.getAllMasterCat();
      }
    });

    //publicidad
    await this.publicityService.getAllPublicity().then(
      (result: any) => {
        const DB = result.records[0][0];
        this.publicityCategory = DB.filter(x => x.publicityPlace == "CAT01" && x.publicityStatus == "Activo");

      }
    )
    //fin de publicidad
    setInterval(() => {
      this.activeIndex = (this.activeIndex + 1) % this.publicityCategory.length;
    }, 3000);    
    
     this.checkOdd();
    this.getAllMasterCat();
   
  }
  searchProduct(subCatUUID){
    try{
      JSON.parse(localStorage.getItem('user')).userId;
      this.router.navigate(['/Productos/'+subCatUUID])
    }catch(e){
        console.log(e);
        this.router.navigate(['/Login/'])
    }    
     
  }
  openPublicity(url){
    window.open(url+"", "_blank");
  }
  moveToCat(event) {
    this.masterCatIconSelect= event.masterCatIcon;
    this.masterCatNameSelect= event.masterCatName;
    this.categoriesList = event.category;
    this.cat = event;
    this.masterCatListSearch = [];
    document.location.href = "Categorías#subCategoria";
  }

  async search(){
    this.masterCatNameSelect = "";
    this.masterCatIconSelect = "";
 this.masterCatListSearch = [];
    var catsListTemp;
    this.masterCatListSearch = await this.categoriesFilter.transform(this.busqueda, this.masterCatList);
    catsListTemp = this.masterCatListSearch;

    let array = [];
    for(let key in catsListTemp){
       if(catsListTemp.hasOwnProperty(key)){
         array.push(catsListTemp[key]);
       }
    }
    this.masterCatListSearch = array;
    if (this.masterCatListSearch.length<= 0) {
          this.busquedaP = false;
          Swal.fire({
            position: 'top-end',
            icon: 'error',
            title: 'No se encontró resultados que coincidan con la búsqueda',
            showConfirmButton: false,
            timer: 1500
          })
          document.location.href = "Categorías#busqueda";
        }else{
          if (this.busquedaP === true) {
            document.location.href = "Categorías/"+this.busqueda+"#busqueda";
            this.busquedaP = false;
          }else{
            document.location.href = "Categorías#busqueda";
          }
        }
  //   this.cat = this.cat1;
  //   this.masterCatIconSelect="";
  //   this.masterCatNameSelect ="";
  //   this.categoriesList = this.categoriesList1;

  //   if (this.busqueda == "" || this.busqueda== " ") {
  //     this.masterCatListSearch = [];
  //   }else{
    
  //   var masterCatLisTemp = [];
  //   var categoryListTemp = [];
  //   var subcategoriesListTemp=[];
  //   var masterCatListSearch1 = this.masterCatList;
  //   masterCatListSearch1.forEach(element => {
  //       element.category.forEach(element1 => {

  //         element1.subCategory.forEach(element2 => {
  //           console.log(element2);
  //           var a = element2.subCatName.match(this.busqueda+"");
            
  //           if (a) {
  //             subcategoriesListTemp.push(element2);
  //           }
  //         });
  //         element1.subCategory = subcategoriesListTemp;
  //         if (element1.subCategory.length > 0) {
  //           categoryListTemp.push(element1);
  //         }
  //         subcategoriesListTemp=[];

  //       });
  //       element.category = categoryListTemp;
  //       if (element.category.length > 0) {
  //         masterCatLisTemp.push(element);
  //       }
  //       categoryListTemp = [];
      
  //   });
    
  //   await this.getAllMasterCat();
  //   this.masterCatListSearch = [];
  //   this.masterCatListSearch = masterCatLisTemp;
  //   console.log(this.masterCatListSearch);
  //   if (this.masterCatListSearch.length<= 0) {
  //     this.busquedaP = false;
  //     Swal.fire({
  //       position: 'top-end',
  //       icon: 'error',
  //       title: 'No se encontró resultados que coincidan con la búsqueda',
  //       showConfirmButton: false,
  //       timer: 1500
  //     })
  //     document.location.href = "Categorías#busqueda";
  //   }else{
  //     if (this.busquedaP === true) {
  //       document.location.href = "Categorías/"+this.busqueda+"#busqueda";
  //       this.busquedaP = false;
  //     }else{
  //       document.location.href = "Categorías#busqueda";
  //     }
      
  //   }
  // }
  
  
  }

  checkOdd(){
     if(this.publicityCategory.length%2 ==1 && this.publicityCategory.length>1){
       let len = this.publicityCategory.length-1;
       let random = Math.floor((Math.random()*(len -1 +1)) + 1);
       this.publicityCategory.push(this.publicityCategory[random])
     }
     this.actv();
  }

  actv(){
    var act = true;
    this.publicityCategory.forEach(element => {
      if (act) {
        element.active = "active";
        act = false;
      }else{
        element.active = "";
      }
    });
  }




  getAllMasterCat(){
    this.loading = true;
    this.categoriesService.masterGetAll().then(
      response=> {
        const userDB = response.records[0][0];
        userDB.forEach(element => {
          element.category = JSON.parse(element.category);
        });
        this.masterCatList = userDB;
        console.log(this.masterCatList);
        if (this.busquedaP == true) {
          this.search();
        }
        // this.search();
        this.loading = false;
      }
    )
  }


  prueba(){
    console.log("in");
  }

}
