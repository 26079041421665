// import { SesService } from 'src/app/services/sesService';
import { Injectable, Input, NgZone } from '@angular/core';
import { environment } from 'src/environments/environment';
import * as AmazonCognitoIdentity from 'amazon-cognito-identity-js';
import {
  CognitoUserPool,
  CognitoUser,
  AuthenticationDetails,
  CognitoUserAttribute
} from 'amazon-cognito-identity-js';
import { Observable, Subject } from 'rxjs';
import { Router } from '@angular/router';
import { UserModel } from '../models/userModel';
// import { UserFotgotPasswordViewComponent } from '../pages/auth/user-fotgot-password-view/user-fotgot-password-view.component';
import Auth from '@aws-amplify/auth';
import { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth/lib/types";
import { Hub, ICredentials } from '@aws-amplify/core';
import Swal from 'sweetalert2';
import { CognitoIdentityServiceProvider } from 'aws-sdk';

const POOL_DATA = {
  UserPoolId: environment.UserPoolId,
  ClientId: environment.ClientId
};
const userPool = new CognitoUserPool(POOL_DATA);
const loginErrorMessage = 'Usuario y/o Contraseña incorrecta';

@Injectable()
export class AuthService {
  cognitoIdentityServiceProvider = new CognitoIdentityServiceProvider({
    region: environment.region,
    accessKeyId: environment.accessKeyId,
    secretAccessKey: environment.secretAccessKey
  })
  public static SIGN_IN = 'signIn';
  public static SIGN_OUT = 'signOut';
  public static GOOGLE = CognitoHostedUIIdentityProvider.Google;
  public loggedIn: boolean;
  private authStates: Subject<CognitoUser | any> = new Subject<CognitoUser | any>();
  authState: Observable<CognitoUser | any> = this.authStates.asObservable();
  public userRole = new Subject<any>();

  AWS = require('aws-sdk');
  authStatusChanged = new Subject<boolean>();
  userProfileChanged = new Subject<boolean>();
  succedded = false;

  constructor(
    private ngZone: NgZone,
    private router: Router,
    // private sesService: SesService,
  ) {
    this.hubUserFromFederation();
  }

  hubUserFromFederation() {
    Hub.listen('auth', (data) => {
      const { channel, payload } = data;
      if (channel === 'auth') {
        if (payload.event === 'cognitoHostedUI') {
          this.getUser(payload.data.username).then(
            (auth: any) => {
              const userBackend = auth.UserAttributes;
              const sub = auth.Username;
              const username = userBackend.find((x) => x.Name === 'email');
              const email = userBackend.find((x) => x.Name === 'email');
              const name = userBackend.find((x) => x.Name === 'name');
              const picture = userBackend.find((x) => x.Name === 'picture');
              const userRole = userBackend.find((x) => x.Name === 'custom:role');
              const company = userBackend.find((x) => x.Name === 'custom:company');
              const theme = userBackend.find((x) => x.Name === 'custom:theme');

              this.succedded = true;
              const userLogged = {
                userId: sub,
                email: email.Value,
                username: sub.Value,
                picture: picture.Value,
                name: name.Value,
                userRole: userRole.Value,
                password: undefined,
                userStatus: undefined,
                company: {
                  companyCode: company.Value,
                  companyName: undefined,
                  companyNameForFilter: undefined,
                },
                theme: theme.Value,
              };
              localStorage.setItem('user', JSON.stringify(userLogged));
              this.authStatusChanged.next(true);
              // const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
              // const status: NbComponentStatus = 'success';
              // this.toastrService.show('', 'Bienvenido ' + name.Value,
              //   { position, status });
              this.navigate(['']);

            }).catch(
              (err) => {
                console.error(err);
              });
        }
        this.authStates.next(payload.event);
      }
    });
  }

  navigate(commands: any[]): void {
    this.ngZone.run(() => this.router.navigate(commands)).then();
  }

  getAuthenticatedUser() {
    return userPool.getCurrentUser();
  }

  getAuthenticatedUserSession() {
    let sessionReturn: any;

    userPool.getCurrentUser().getSession(
      (err, session) => {
        if (err) {
          // const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
          // const status: NbComponentStatus = 'danger';
          // this.toastrService.show(
          //   '',
          //   'Error en Session Actual',
          //   { position, status });
        }
        sessionReturn = session;
      }
    );
    return sessionReturn;

  }

  signIn(username: string, password: string): any {
    const authData = {
      Username: username,
      Password: password
    };
    const authDetails = new AuthenticationDetails(authData);
    const userData = {
      Username: username,
      Pool: userPool
    };
    const cognitoUser = new CognitoUser(userData);
    const that = this;
    this.succedded = false;
    cognitoUser.authenticateUser(authDetails, {
      onSuccess(result: any) {
        that.succedded = true;
        that.authStatusChanged.next(true);
        that.userRole.next(result.idToken.payload['custom:role']);
        Swal.fire({
          position: 'top-end',
          imageUrl: '../../../assets/images/core/CIG_M-lanczos3.png',
          imageWidth: 100,
          imageHeight: 50,
          text: 'Bienvenido ' + result.idToken.payload.name,
          showConfirmButton: false,
          timer: 2000,
          width: 200,
          heightAuto: false,
          })
        // that.router.navigate(['']);
        that.router.navigate([''])
        .then(() => {
          window.location.reload();
        });
        const userLogged = {
          userId: result.idToken.payload.sub,
          email: result.idToken.payload.email,
          username: result.idToken.payload['cognito:username'],
          name: result.idToken.payload.name,
          userRole: result.idToken.payload['custom:role'],
          password: undefined,
          userStatus: undefined,
          company: {
            companyCode: result.idToken.payload['custom:company'],
            companyName: undefined,
            companyNameForFilter: undefined,
          },
          // userRoleName: undefined,
        };
        localStorage.setItem('user', JSON.stringify(userLogged));
      },
      onFailure(err: any) {
        that.authStatusChanged.next(false);
        Swal.fire({
          position: 'top-end',
          icon: 'error',
          title: 'Usuario o contraseña incorrecta',
          showConfirmButton: false,
          timer: 1500
        })
        // const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
        // const status: NbComponentStatus = 'danger';
        // that.toastrService.show(
        //   '',
        //   loginErrorMessage,
        //   { position, status });
      }
    });

    this.authStatusChanged.next(this.succedded);
  }

  isAuthenticated(): Observable<boolean> {
    const user = this.getAuthenticatedUser();
    const obs = new Observable<boolean>(observer => {
      if (!user) {
        observer.next(false);
      } else {
        user.getSession((err, session) => {
          if (err) {
            // console.error(err);
            observer.next(false);
          }
          if (session.isValid()) {
            observer.next(true);
          } else {
            observer.next(false);
          }
        });
      }
      observer.complete();
    });
    return obs;
  }

  async logout() {
    this.signOut();
    this.authStatusChanged.next(false);
    await localStorage.clear();
    setTimeout(function(){
      window.location.reload();
    },500);
  }

  initAuth() {
    this.isAuthenticated().subscribe(auth => this.authStatusChanged.next(auth));
  }

  async socialSignIn(provider: CognitoHostedUIIdentityProvider): Promise<ICredentials> {
    const user = await Auth.federatedSignIn({
      provider: CognitoHostedUIIdentityProvider.Google
    });
    console.log(user);
    return user;
	}

  signOut(): Promise<any> {
    return Auth.signOut()
      .then(() => this.loggedIn = false);
  }

  signUp(user) {
    // const userLogged: User = JSON.parse(localStorage.getItem('user'));
    const attrList: CognitoUserAttribute[] = [];
    const emailAttribute = {
      Name: 'email',
      Value: user.userEmail
    };
    const nameAttribute = {
      Name: 'name',
      Value: user.userFirstName
    };
    const roleAttribute = {
      Name: 'custom:role',
      Value: user.userRole
    };
    const companyAttribute = {
      Name: 'custom:company',
      Value: user.userCompanyName
    };
    
    attrList.push(new CognitoUserAttribute(emailAttribute));
    attrList.push(new CognitoUserAttribute(nameAttribute));
    attrList.push(new CognitoUserAttribute(roleAttribute));
    attrList.push(new CognitoUserAttribute(companyAttribute));
    const params = {
      ClientId: environment.ClientId,
			Username: user.userEmail,
			UserAttributes: attrList,
			Password: user.userPassword,
    }
    return new Promise((resolve, reject) => {
			this.AWS.config.update({
				region: environment.region,
				accessKeyId: environment.accessKeyId,
				secretAccessKey: environment.secretAccessKey
			});
			const cognitoidentityserviceprovider = new this.AWS.CognitoIdentityServiceProvider();
			cognitoidentityserviceprovider.signUp(params, (err, data) => {
				if (err) {
					reject(err);
				} else {
					resolve(data);
				}
			});
		});
  }

  async getUsers() {
    let paginationListUser = { Users: new Array() };
    let params = {
      UserPoolId: environment.UserPoolId,
      PaginationToken: undefined,
    };

    return new Promise(async (resolve, reject) => {
      const firstPaging: any = await this.cognitoPagination(params);
      if (firstPaging.PaginationToken === undefined) {
        resolve(firstPaging);
      } else {
        firstPaging.Users.forEach(user => {
          paginationListUser.Users.push(user);
        });
        params.PaginationToken = firstPaging.PaginationToken;
        while (params.PaginationToken !== undefined) {
          const firstPaging2: any = await this.cognitoPagination(params);
          if (firstPaging2.PaginationToken === undefined) {
            params.PaginationToken = undefined;
            firstPaging2.Users.forEach(user => {
              paginationListUser.Users.push(user);
            });
            resolve(paginationListUser);
          } else {
            params.PaginationToken = firstPaging2.PaginationToken;
            firstPaging2.Users.forEach(user => {
              paginationListUser.Users.push(user);
            });
          }
        }
      }
    });
  }

  cognitoPagination(params) {
    return new Promise((resolve, reject) => {
      this.AWS.config.update({
        region: environment.region,
        accessKeyId: environment.accessKeyId,
        secretAccessKey: environment.secretAccessKey
      });
      const cognitoidentityserviceprovider = new this.AWS.CognitoIdentityServiceProvider();
      cognitoidentityserviceprovider.listUsers(params, (err, data) => {
        if (err) {
          reject(err);
        } else {
          resolve(data);
        }
      });
    });

  }

  getUser(username) {
    const params = {
      UserPoolId: environment.UserPoolId,
      Username: username,
    };

    return new Promise((resolve, reject) => {
      this.AWS.config.update({
        region: environment.region,
        accessKeyId: environment.accessKeyId,
        secretAccessKey: environment.secretAccessKey
      });
      const cognitoidentityserviceprovider = new this.AWS.CognitoIdentityServiceProvider();
      cognitoidentityserviceprovider.adminGetUser(params, (err, data) => {
        if (err) {
          reject(err);
        } else {
          resolve(data);
        }
      });
    });
  }

  updateUser(user: UserModel) {
    const attrList: CognitoUserAttribute[] = [];
    const emailAttribute = {
      Name: 'email',
      Value: user.userEmail
    };
    const roleAttribute = {
      Name: 'custom:role',
      Value: user.userRole.toString(),
    };
    const nameAttribute = {
      Name: 'name',
      Value: user.userFirstName
    };
    const emailVerified = {
      Name: 'email_verified',
      Value: 'true'
    };
    attrList.push(new CognitoUserAttribute(emailAttribute));
    attrList.push(new CognitoUserAttribute(roleAttribute));
    attrList.push(new CognitoUserAttribute(nameAttribute));
    attrList.push(new CognitoUserAttribute(emailVerified));
    const params = {
      UserPoolId: environment.UserPoolId,
      Username: user.userCognitoCode,
      UserAttributes: attrList,
    };

    return new Promise((resolve, reject) => {
      this.AWS.config.update({
        region: environment.region,
        accessKeyId: environment.accessKeyId,
        secretAccessKey: environment.secretAccessKey
      });
      const cognitoidentityserviceprovider = new this.AWS.CognitoIdentityServiceProvider();
      cognitoidentityserviceprovider.adminUpdateUserAttributes(params, (err, data) => {
        if (err) {
          reject(err);
        } else {
          resolve(data);
        }
      });
    });
  }

  disableUser(username) {
    const params = {
      UserPoolId: environment.UserPoolId,
      Username: username,
    };
    return new Promise((resolve, reject) => {
      this.AWS.config.update({
        region: environment.region,
        accessKeyId: environment.accessKeyId,
        secretAccessKey: environment.secretAccessKey
      });
      const cognitoidentityserviceprovider = new this.AWS.CognitoIdentityServiceProvider();
      cognitoidentityserviceprovider.adminDisableUser(params, (err, data) => {
        if (err) {
          reject(err);
        } else {
          resolve(data);
        }
      });
    });
  }

  enableUser(username) {
    const params = {
      UserPoolId: environment.UserPoolId,
      Username: username,
    };
    return new Promise((resolve, reject) => {
      this.AWS.config.update({
        region: environment.region,
        accessKeyId: environment.accessKeyId,
        secretAccessKey: environment.secretAccessKey
      });
      const cognitoidentityserviceprovider = new this.AWS.CognitoIdentityServiceProvider();
      cognitoidentityserviceprovider.adminEnableUser(params, (err, data) => {
        if (err) {
          reject(err);
        } else {
          resolve(data);
        }
      });
    });
  }

  resendCode(userEmail) {
    const userData = {
      Username: userEmail,
      Pool: userPool
    };
    const cognitUser = new CognitoUser(userData);

    return new Promise((resolve, reject) => {
      cognitUser.resendConfirmationCode((err, result) => {
        if (err) {
          reject(err);
        } else {
          resolve(result);
        }
      });
    });
  }

  confirmUser(username: string, code: string) {
    const userData = {
      Username: username,
      Pool: userPool
    };
    const cognitUser = new CognitoUser(userData);

    return new Promise((resolve, reject) => {
      cognitUser.confirmRegistration(code, true, (err, result) => {
        if (err) {
          reject(err);
        } else {
          resolve(result);
        }
      });
    });
  }

  resend(username: string,) {
    const userData = {
      Username: username,
      Pool: userPool
    };
    const cognitUser = new CognitoUser(userData);

    return new Promise((resolve, reject) => {
      cognitUser.resendConfirmationCode((err, result) => {
        if (err) {
          alert(err);
        }
        resolve(result);
      });
    });
  }

  forgotPassword(username: string) {
    const that = this;
    const userData = {
      Username: username,
      Pool: userPool
    };
    const cognitoUser = new CognitoUser(userData);
    cognitoUser.forgotPassword({
      onSuccess(result) {
        that.router.navigateByUrl('/login');
      },
      onFailure(err) {
      },
      inputVerificationCode() {
      }
    });
    return true;
  }

  userForgotPasswordSendCode(userName: any){
    const userData = {
      Username: userName,
      Pool: userPool
    };
    const cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);
    return new Promise((resolve, reject) => {
      cognitoUser.forgotPassword({
        onSuccess: (data) => {
          console.log('Código de verificación enviado: ', data);
          resolve(data);
          return true;
        },
        onFailure: (err) => {
          console.error('Error al enviar código de verificación: ', err);
           this.resendConfirmationEmail(userName);
          reject(err);
          return false;
        },
      });
    })
  }

  userConfirmWithoutCode(userName: any){
    const userData = {
       UserPoolId: POOL_DATA.UserPoolId,
       Username: userName
    }

    return new Promise((resolve, reject) => {
      this.cognitoIdentityServiceProvider.adminConfirmSignUp(userData, (err, data ) => {
        if(err){
              Swal.fire({
                icon: 'error',
                title: 'El usuario ya se verificó'
              })
          reject(err);
        }else{       
          console.log(data);  
          Swal.fire({
            icon: 'success',
            title: 'Usuario verificado'
          })
          resolve(data);
 
        }
      })
    })
  }

  userConfirmUpdatePassword(code: any, pass: any, userName: any){
   const userPool = new CognitoUserPool(POOL_DATA); // Se crea una instancia del User Pool
   const userData = { Username: userName, Pool: userPool };
   const cognitoUser = new CognitoUser(userData);
   return new Promise((resolve, reject) => {
     cognitoUser.confirmPassword(code, pass, {
       onSuccess: () => {
         console.log('Contraseña cambiada con éxito'); // Se muestra un mensaje de éxito en la consola
         resolve(true);
       },
       onFailure: (err) => {
         console.error('Error al cambiar la contraseña', err); // Se muestra un mensaje de error en la consola
         Swal.fire({
           title: 'Error, código inválido',
           icon: 'error',
         })
         reject(err);
       }
     });
   });
 }

  resendConfirmationEmail(userName: any){
    const userData = {
      Username: userName,
      Pool: userPool
    };
    const cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);
    return new Promise((resolve, reject) => {
      cognitoUser.resendConfirmationCode((err, result) =>{ 
        if(err){
          console.log(err);
        }else{
          resolve(result);
        }
      })
    })
  }

  getUsersFromApp(userPoolId) {
    const params = {
      UserPoolId: userPoolId,
      AttributesToGet: [
        'name',
        'custom:role',
        'email',
        'custom:company',
        'sub'
      ],
    };

    return new Promise((resolve, reject) => {
      this.AWS.config.update({
        region: environment.region,
        accessKeyId: environment.accessKeyId,
        secretAccessKey: environment.secretAccessKey
      });
      const cognitoidentityserviceprovider = new this.AWS.CognitoIdentityServiceProvider();
      cognitoidentityserviceprovider.listUsers(params, (err, data) => {
        if (err) {
          reject(err);
        } else {
          resolve(data);
        }
      });
    });
  }

  updateUserRekognitionImage(studentImage, student) {
    const attrList: CognitoUserAttribute[] = [];
    const rekognitionImageAttribute = {
      Name: 'custom:rekognitionImage',
      Value: studentImage
    };
    attrList.push(new CognitoUserAttribute(rekognitionImageAttribute));
    const params = {
      UserPoolId: environment.UserPoolId,
      Username: student.userId,
      UserAttributes: attrList,
    };
    return new Promise((resolve, reject) => {
      this.AWS.config.update({
        region: environment.region,
        accessKeyId: environment.accessKeyId,
        secretAccessKey: environment.secretAccessKey
      });
      const cognitoidentityserviceprovider = new this.AWS.CognitoIdentityServiceProvider();
      cognitoidentityserviceprovider.adminUpdateUserAttributes(params, (err, data) => {
        if (err) {
          reject(err);
        } else {
          resolve(data);
        }
      });
    });
  }

  sendEmail(funcionArgs: any) {
    this.AWS.config.update({
      accessKeyId: environment.accessKeyId,
      secretAccessKey: environment.secretAccessKey,
      region: environment.regionSES
    });
    const lambda = new this.AWS.Lambda({apiVersion: '2015-03-31'});

    var params = {
      // FunctionName: 'arn:aws:lambda:us-east-1:405522164029:function:cigmatchbackend-qa-userSendCredentials',
      // FunctionName: 'arn:aws:lambda:us-east-1:405522164029:function:cigmatchbackend-dev-userSendCredentials'
      FunctionName: 'arn:aws:lambda:us-west-2:120259615165:function:cigmatchbackendprod-prod-userSendCredentials',
      Payload: JSON.stringify(funcionArgs),
      InvocationType: 'RequestResponse'
      // Qualifier: "1"
     };
     
     return new Promise(async (resolve, reject)=>{
      await lambda.invoke(params,  (err, data) => {
        if(err){
          reject(err);
          return;
        }
        resolve(data);
      });
    });
  }

  sendEmailUpdateCredentials(funcionArgs: any) {
    this.AWS.config.update({
      accessKeyId: environment.accessKeyId,
      secretAccessKey: environment.secretAccessKey,
      region: environment.regionSES
    });
    const lambda = new this.AWS.Lambda({apiVersion: '2015-03-31'});

    var params = {
      // FunctionName: 'arn:aws:lambda:us-east-1:405522164029:function:cigmatchbackend-qa-userSendCredentials',
      FunctionName: 'arn:aws:lambda:us-west-2:120259615165:function:cigmatchbackendprod-prod-userSendCredentialsUpdate',
      Payload: JSON.stringify(funcionArgs),
      InvocationType: 'RequestResponse'
      // Qualifier: "1"
     };
     
     return new Promise(async (resolve, reject)=>{
      await lambda.invoke(params,  (err, data) => {
        if(err){
          reject(err);
          return;
        }
        resolve(data);
      });
    });
  }

  sendEmailNewMessage(funcionArgs: any) {
    console.log(funcionArgs);
    this.AWS.config.update({
      accessKeyId: environment.accessKeyId,
      secretAccessKey: environment.secretAccessKey,
      region: environment.regionSES
    });
    const lambda = new this.AWS.Lambda({apiVersion: '2015-03-31'});

    var params = {
      // FunctionName: 'arn:aws:lambda:us-east-1:405522164029:function:cigmatchbackend-dev-userSendCredentials',
      FunctionName: 'arn:aws:lambda:us-west-2:120259615165:function:cigmatchbackendprod-prod-userNewMessage',
      Payload: JSON.stringify(funcionArgs),
      InvocationType: 'RequestResponse'
      // Qualifier: "1"
     };
     
     return new Promise(async (resolve, reject)=>{
      await lambda.invoke(params,  (err, data) => {
        if(err){
          reject(err);
          return;
        }
        resolve(data);
      });
    });
  }

  sendEmailNewRegister(funcionArgs: any) {
    console.log(funcionArgs);
    this.AWS.config.update({
      accessKeyId: environment.accessKeyId,
      secretAccessKey: environment.secretAccessKey,
      region: environment.regionSES
    });
    const lambda = new this.AWS.Lambda({apiVersion: '2015-03-31'});

    var params = {
      // FunctionName: 'arn:aws:lambda:us-east-1:405522164029:function:cigmatchbackend-dev-userNewOwnRegister',
       FunctionName: 'arn:aws:lambda:us-west-2:120259615165:function:cigmatchbackendprod-prod-userNewOwnRegister',
      Payload: JSON.stringify(funcionArgs),
      InvocationType: 'RequestResponse'
      // Qualifier: "1"
     };
     
     return new Promise(async (resolve, reject)=>{
      await lambda.invoke(params,  (err, data) => {
        if(err){
          reject(err);
          return;
        }
        resolve(data);
      });
    });
  }

  sendRequest(funcionArgs: any) {
    this.AWS.config.update({
      accessKeyId: environment.accessKeyId,
      secretAccessKey: environment.secretAccessKey,
      region: environment.regionSES
    });
    const lambda = new this.AWS.Lambda({apiVersion: '2015-03-31'});
    var params = {
      FunctionName: 'arn:aws:lambda:us-west-2:120259615165:function:cigmatchbackendprod-prod-userRegister',
      Payload: JSON.stringify(funcionArgs),
      InvocationType: 'RequestResponse'
      // Qualifier: "1"
     };
     return new Promise(async (resolve, reject)=>{
      await lambda.invoke(params,  (err, data) => {
        if(err){
          reject(err);
          return;
        }
        resolve(data);
      });
    });
  }

}
