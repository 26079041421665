import { Injectable, NgZone } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UserModel } from '../models/userModel';
import { AuthenticationDetails, CognitoUser, CognitoUserAttribute, CognitoUserPool } from 'amazon-cognito-identity-js';
import { environment } from 'src/environments/environment';


const POOL_DATA = {
  UserPoolId: environment.UserPoolId,
  ClientId: environment.ClientId
};
const userPool = new CognitoUserPool(POOL_DATA);

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private AWS = require('aws-sdk');
  constructor(
    private http: HttpClient,
  ) {}

  saveUserOnTable(user: UserModel): Promise<any> {
    let params = JSON.stringify(user);
    const headers = new HttpHeaders().set('Content-Type', 'application/json');

    return this.http.post(environment.API_URL + 'cigmatchbackend-user', params, { headers}).toPromise();
  }

  getAllUsers(): Promise<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    
    return this.http.get(environment.API_URL + 'cigmatchbackend-user', { headers}).toPromise();
  }

  oneUserBackend(userEmail){
    const filter = userEmail;
    return this.http.get(environment.API_URL + 'cigmatchbackend-userNoLog' + '/one/' + filter).toPromise();
  }

  oneUser(userID){
    const filter = userID;
    return this.http.get(environment.API_URL + 'cigmatchbackend-user' + '/one/' + filter).toPromise();
  }
  updateUserBackend(user: UserModel): Promise<any> {
		let params = JSON.stringify(user);
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.post(environment.API_URL + 'cigmatchbackend-userUpdate', params, { headers}).toPromise();
	}

  getUsersWithCategory(categoryUUID) {
    const filter = categoryUUID;
    return this.http.get(environment.API_URL + 'cigmatchbackend-userGetEmail' + '/one/' + filter ).toPromise();
	}

  getAllMasterUsers(): Promise<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.get(environment.API_URL + 'cigmatchbackend-userGetMasters', { headers}).toPromise();
  }

  getCountProducts(userUUID: any): Promise<any>{
       const filter = userUUID;
       return this.http.get(environment.API_URL + 'cigmatchbackend-getCountProducts' + '/one/'+ filter).toPromise();
  }

  getUserReport(): Promise<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.get(environment.API_URL + 'cigmatchbackend-getUserReport/', { headers}).toPromise();
  }

  getUserRequest(): Promise<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.get(environment.API_URL + 'cigmatchbackend-getRequestUser', { headers}).toPromise();
  }


  getCompanyByNitRequest(companyNit: any): Promise<any>{
    const filter = companyNit
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.get(environment.API_URL + 'cigmatchbackend-getCompanyByNitRequest' +'/one/' + filter).toPromise();
  }

  updateRequestUser(user: UserModel): Promise<any> {
		let params = JSON.stringify(user);
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.post(environment.API_URL + 'cigmatchbackend-updateRequestUser', params, { headers}).toPromise();
	}

  getUserByDate(values: any){
    const param1 = values.param1;
    const headers = new HttpHeaders().set('Content-type','application/json');
    return this.http.get(environment.API_URL +'cigmatchbackend-'+ 'getUserByDate/'+param1,{headers}).toPromise();
  }

  // getUserRegisterInfo(userNit: any):Promise<any>{
  //       let param = JSON.stringify(userNit);
  //       const auth = 'Basic ' + btoa('cigmatch' + ':' + 'CgojfePT523SoJv');
  //       const HeadersT = new HttpHeaders().set('Content-Type', 'application/json');
  //       // Headers = Headers.append('Authorization', 'Basic' + btoa(auth));
  //       // Headers.append('Content-Type', 'application/json');
   
  //       const httpOptions  = {
  //         headers: new HttpHeaders({
  //           'Content-Type':  'application/json',
  //           'Authorization': auth
  //         })
  //       }
  //       console.log(httpOptions );
  //       return this.http.post(environment.API_URL + 'cigmatchbackend-userUpdate', param, { HeadersT}).toPromise();





  //       let params = JSON.stringify(user);
  //       const headers = new HttpHeaders().set('Content-Type', 'application/json');
  //       return this.http.post(environment.API_URL + 'cigmatchbackend-userUpdate', params, { headers}).toPromise();
  // }


  async changePasswordCognito(username, previousPassword, proposedPassword): Promise<any> {
    const POOL_DATA = {
			UserPoolId: environment.UserPoolId,
			ClientId: environment.ClientId
		};
    const userPool = new CognitoUserPool(POOL_DATA);
    const authData = {
      Username: username,
      Password: previousPassword
    };
    const authDetails = new AuthenticationDetails(authData);
    const userData = {
      Username: username,
      Pool: userPool
    };

    const cognitoUser = new CognitoUser(userData);
    await cognitoUser.authenticateUser(authDetails, {
      onSuccess(result: any) {
        console.log(result);
        const accessToken: any = result;
        console.log(accessToken);
        const params = {
          AccessToken: accessToken.accessToken.jwtToken,
          PreviousPassword: previousPassword,
          ProposedPassword: proposedPassword
        };
        
        return new Promise((resolve, reject) => {
          let AWS = require('aws-sdk');
          AWS.config.update({
            region: environment.region,
            accessKeyId: environment.accessKeyId,
            secretAccessKey: environment.secretAccessKey
          });
          
          const cognitoidentityserviceprovider = new AWS.CognitoIdentityServiceProvider();
          cognitoidentityserviceprovider.changePassword(params, (err, data) => {
            if (err) {
              reject(err);
            } else {
              resolve(data);
            }
          });
        });

      },
      onFailure(err: any) {
        console.log(err);
        return err;
      }
    });

	
	}

	async getAccessToken(username, password) {
    const POOL_DATA = {
			UserPoolId: environment.UserPoolId,
			ClientId: environment.ClientId
		};
    const userPool = new CognitoUserPool(POOL_DATA);
    const authData = {
      Username: username,
      Password: password
    };
    const authDetails = new AuthenticationDetails(authData);
    const userData = {
      Username: username,
      Pool: userPool
    };

    const cognitoUser = new CognitoUser(userData);
    await cognitoUser.authenticateUser(authDetails, {
      onSuccess(result: any) {
        console.log(result);
        return result;

      },
      onFailure(err: any) {
        console.log(err);
        return err;
      }
    });

	}
  userSendEmailRequest(funcionArgs: any) {
    console.log(funcionArgs);
    this.AWS.config.update({
      accessKeyId: environment.accessKeyId,
      secretAccessKey: environment.secretAccessKey,
      region: environment.regionSES
    });
    const lambda = new this.AWS.Lambda({apiVersion: '2015-03-31'});

    var params = {
      // FunctionName: 'arn:aws:lambda:us-east-1:405522164029:function:cigmatchbackend-dev-userSendEmailRequest',
      FunctionName: 'arn:aws:lambda:us-west-2:120259615165:function:cigmatchbackendprod-prod-userSendEmailRequest',
      Payload: JSON.stringify(funcionArgs),
      InvocationType: 'RequestResponse'
      // Qualifier: "1"
     };
     
     return new Promise(async (resolve, reject)=>{
      await lambda.invoke(params,  (err, data) => {
        if(err){
          reject(err);
          return;
        }
        resolve(data);
      });
    });
}

}