import { BusinessDirectoryComponent } from './views/business-directory/business-directory.component';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AuthService } from './services/auth.service';
import { AuthGuard } from './services/auth-guard.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { HeaderComponent } from './core/header/header.component';
import { FooterComponent } from './core/footer/footer.component';
import { PublicCarouselComponent } from './core/public-carousel/public-carousel.component';
import { ProductsComponent } from './views/products/products.component';
import { LoginComponent } from './core/login/login.component';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import {MatDatepickerModule} from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatBadgeModule } from '@angular/material/badge';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { ProductsCardsComponent } from './views/products/products-cards/products-cards.component';
import { ProductsCardsComponentOption } from './views/products/products-cards-option/products-cards-option.component';
import { CategoriesComponent } from './views/categories/categories.component';
import { MatDividerModule } from '@angular/material/divider';
import { CreatProductComponent } from './views/products/creat-product/creat-product.component';
import { MatSelectModule } from '@angular/material/select';
import { NewCategoryComponent } from './views/new-category/new-category.component';
import { MatTabsModule } from '@angular/material/tabs';
import { RegisterComponent } from './views/register/register.component';
import { PublicityDashboardComponent } from './views/publicity-dashboard/publicity-dashboard.component';
import { ProductOneViewComponent } from './views/products/product-one-view/product-one-view.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { NgxPaginationModule } from 'ngx-pagination';
import { MatFileUploadModule } from 'angular-material-fileupload';
import { NewPublicityComponent } from './views/publicity-dashboard/new-publicity/new-publicity.component';
import { MatDialogModule } from '@angular/material/dialog';
import { AutoConfirmComponent } from './core/auto-confirm/auto-confirm.component';
import { CompanySelfUserCreatComponent } from './views/companyViews/company-self-user-creat/company-self-user-creat.component';
import { CompanyUsersInfoComponent } from './views/companyViews/company-users-info/company-users-info.component';
import { UserProfileComponent } from './core/user-profile/user-profile.component';
import { LoadingWrapperOverlayComponent } from './core/loading-wrapper-overlay/loading-wrapper-overlay.component';
import { LoadingWrapperComponent } from './core/loading-wrapper/loading-wrapper.component';
import { LoadingComponent } from './core/loading/loading.component';
import { MatSidenavModule } from '@angular/material/sidenav';
import { AnswerPendingComponent } from './core/user-profile/messageCenterViews/answer-pending/answer-pending.component';
import { SentMessagesComponent } from './core/user-profile/messageCenterViews/sent-messages/sent-messages.component';
import { StoreMessagesComponent } from './core/user-profile/messageCenterViews/store-messages/store-messages.component';
import { DeletedMessagesComponent } from './core/user-profile/messageCenterViews/deleted-messages/deleted-messages.component';
import { ChatDialogComponent } from './core/user-profile/messageCenterViews/answer-pending/chat-dialog/chat-dialog.component';
import { BusinessDirectoryOneViewComponent } from './views/business-directory/business-directory-one-view/business-directory-one-view.component';
import { SelfRegisterRequestComponent } from './core/self-register-request/self-register-request.component';
import { ApplicationPortalComponent } from './views/application-portal/application-portal.component';
import { CreateApplicationComponent } from './views/application-portal/create-application/create-application.component';
import { ApplicationRequestComponent } from './views/application-portal/aplication-request/application-request.component';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import { MyProfile } from './core/user-profile/my-profile/my-profile.component';
import { ContactsComponent } from './core/user-profile/messageCenterViews/contacts/contacts.component';
import { FilterPipe } from './pipes/filter.pipe';
import { Filter2Pipe } from './pipes/filter2.pipe';
import { Filter3Pipe } from './pipes/filter3.pipe';
import { UsersFilterPipe } from './pipes/users-filter.pipe';
import { EmailFilterPipe } from './pipes/email-filter.pipe';
import { FinishedMessagesComponent } from './core/user-profile/messageCenterViews/finished-messages/finished-messages.component';
import { CharCountPipe } from './pipes/char-count.pipe';
import { RoleFilterPipe } from './pipes/role-filter.pipe';
import { BusinessFilterPipe } from './pipes/business-filter.pipe';
import { PendingApplicationPortalComponent } from './views/application-portal/pending-application-portal/pending-application-portal.component';
import { PendingApplicationViewComponent } from './views/application-portal/pending-application-portal/pending-application-view/pending-application-view.component';
import { RequestCarouselComponent } from './core/request-carousel/request-carousel.component';
import { RequestFilterPipe } from './pipes/request-filter.pipe';
import { HelpSectionComponent } from './views/help-section/help-section.component';
import { VideoTutorialsComponent } from './views/help-section/video-tutorials/video-tutorials.component';
import { CategoriesFilterPipe } from './pipes/categories-filter.pipe';
import { ShowVideoTutorialsComponent } from './views/help-section/video-tutorials/show-video-tutorials/show-video-tutorials.component';
import { ProductsFoundComponent } from './views/products/products-found/products-found.component';
import { EmailSendingComponent } from './core/user-profile/email-sending/email-sending.component';
import { EditProductDialogComponent } from './core/user-profile/my-profile/edit-product-dialog/edit-product-dialog.component';
import {CommonModule} from '@angular/common';
import { PrivateDashboardComponent } from './views/private-dashboard/private-dashboard.component';
import { UserRegisterDashboardComponent } from './views/register/user-register-dashboard/user-register-dashboard.component';
import { SendEmailsDialogComponent } from './views/application-portal/create-application/send-emails-dialog/send-emails-dialog.component';
import { PasswordRecoverComponent } from './core/login/password-recover/password-recover.component';
@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    PublicCarouselComponent,
    ProductsComponent,
    LoginComponent,
    LoadingComponent,
    LoadingWrapperComponent,
    LoadingWrapperOverlayComponent,
    ProductsCardsComponent,
    ProductsCardsComponentOption,
    CategoriesComponent,
    BusinessDirectoryComponent,
    CreatProductComponent,
    NewCategoryComponent,
    RegisterComponent,
    PublicityDashboardComponent,
    ProductOneViewComponent,
    NewPublicityComponent,
    AutoConfirmComponent,
    CompanySelfUserCreatComponent,
    CompanyUsersInfoComponent,
    UserProfileComponent,
    MyProfile,
    AnswerPendingComponent,
    FinishedMessagesComponent,
    SentMessagesComponent,
    ContactsComponent,
    StoreMessagesComponent,
    DeletedMessagesComponent,
    ChatDialogComponent,
    BusinessDirectoryOneViewComponent,
    SelfRegisterRequestComponent,
    ApplicationPortalComponent,
    CreateApplicationComponent,
    ApplicationRequestComponent,
    FilterPipe,
    Filter2Pipe,
    Filter3Pipe,
    UsersFilterPipe,
    EmailFilterPipe,
    CharCountPipe,
    RoleFilterPipe,
    BusinessFilterPipe,
    PendingApplicationPortalComponent,
    PendingApplicationViewComponent,
    RequestCarouselComponent,
    RequestFilterPipe,
    HelpSectionComponent,
    VideoTutorialsComponent,
    CategoriesFilterPipe,
    ShowVideoTutorialsComponent,
    ProductsFoundComponent,
    EmailSendingComponent,
    EditProductDialogComponent,
    PrivateDashboardComponent,
    UserRegisterDashboardComponent,
    SendEmailsDialogComponent,
    PasswordRecoverComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    HttpClientModule,
    FormsModule,
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatButtonModule,
    MatBottomSheetModule,
    MatDividerModule,
    MatSelectModule,
    MatBadgeModule,
    MatTabsModule,
    MatSlideToggleModule,
    MatTableModule,
    MatSidenavModule,
    MatPaginatorModule,
    NgxPaginationModule,
    MatFileUploadModule,
    MatDialogModule,
    MatButtonToggleModule,
    CommonModule,
    MatDatepickerModule,
    MatNativeDateModule
  ],
  providers: [AuthService, AuthGuard, FilterPipe,RequestFilterPipe, CategoriesFilterPipe,MatDatepickerModule],
  bootstrap: [AppComponent],
  entryComponents: [NewPublicityComponent]
})
export class AppModule { }
