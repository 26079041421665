<app-loading-wrapper-overlay *ngIf="loading"></app-loading-wrapper-overlay>
<!-- <div class="row" style="height: 5rem !important;">
  <div class="col-sm-1 col-md-1 col-lg-1"></div>
  <div class="col-sm-11 col-md-11 col-lg-11" style="padding-top: 1.5rem;">
    <font style="color: white; font-size: 3rem;">Categorías</font>
  </div>
</div> -->
<div class="row">
  <div class="col-sm-11 col-md-11 col-lg-11 classMargen" style="margin-left: 2.5rem;">
    <h1 class="title">Diversidad de Categorías</h1>
   <hr style="height: 2.0px;background-color: #0075D7;opacity: 80%;">
 </div>
</div>
<div class="row" style="justify-content: center!important;" >
  <div class="search">
    <div class="col-md-12">
        <div class="form"> <mat-icon class="search" style="cursor: pointer;" (click)="search()">search</mat-icon> <input type="text" class="form-control form-input" placeholder="¿Qué estás buscando?" [(ngModel)]="this.busqueda" on-keyup="$event.keyCode == 13 && this.search()"></div>
    </div>
</div>
</div>
<div class="row" style="min-width: 100%; max-width: 100%; height: 100%; background-color: white; justify-content: center;">
  
  <!-- <div class="parent categoriesD">
    <mat-card class="child" *ngFor="let masterCat of masterCatList" (click)="moveToCat(masterCat)" style="box-shadow: none!important;">
      <div class="col-sm-12 col-md-12 col-lg-12">
        <div class="row">
          <div class="col-sm-4 col-md-4 col-lg-4" style="border: 2px solid #5f5f5f; border-radius: 50%;width: 8rem;height: 6rem;text-align: center; padding: 10px;">
            <img [src]="masterCat.masterCatIcon" style="width: 4rem !important;">
          </div>
          <div class="col-sm-5 col-md-5 col-lg-5 categorias">
            <span style="line-height: 1.3; font-weight: bold;">{{masterCat.masterCatName}}</span>
          </div>
        </div>
      </div>
    </mat-card>
  </div> -->
  <div class="row parent" style="margin-left: 15rem;margin-right: 15rem;">
    <div class="col-sm-1">

    </div>
    <div class="col-sm-10">
        <div class="row" style="justify-content: center!important;">
          <mat-card class="col-sm-4" *ngFor="let masterCat of masterCatList" style="box-shadow: none!important;">
            <div class="col-sm-12 col-md-12 col-lg-12">
              <div class="row">
                <div class="col-sm-4 col-md-4 col-lg-4" style="box-shadow: 0 6px 25px rgba(15, 15, 15, 0.08), 0 0 10px rgba(71, 71, 71, 0.08);border-radius: 50%;width: 6.6rem;height: 6.6rem;text-align: center; padding: 16px;justify-content: center;">
                  <img [src]="masterCat.masterCatIcon" style="width: 4rem !important;opacity: 70%; cursor: pointer;" (click)="moveToCat(masterCat)">
                </div>
                <div class="col-sm-1 col-md-1 col-lg-1">
                </div>
                <div class="col-sm-7 col-md-7 col-lg-7 categorias">
                  <span style="line-height: 1.3; font-weight: 600;color: #898989; cursor: pointer;" (click)="moveToCat(masterCat)">{{masterCat.masterCatName}}</span>
                </div>
              </div>
            </div>
          </mat-card>
        </div>
    </div>
    <div class="col-sm-1">

    </div>

    
  </div>

  <!-- <div style="min-width: 100% !important; max-width: 100% !important; text-align: center !important; margin-bottom: 1rem; margin-top: 1rem; text-align: center;" *ngIf="!loading"> -->
    <!-- <img width="60%" height="auto" src="../../../../src/assets/images/core/nestle-banner.jpg"> -->

    
  <!-- </div> -->

  <div class="col-sm-12 publicity" style="margin-bottom: 50px;" >
    <!-- publicidad -->
    <div style="margin: 0 auto;">
      <!-- <div id="my" class="carousel 1carousel-fade img-details" data-bs-ride="carousel" data-bs-interval="4000" style="height:auto">
        <div class="carousel-inner">
            <div class="carousel-item {{publicity1.active}}" style="text-align: center;" *ngFor="let publicity1 of publicityCategory"> <img [src]="publicity1.publicityBanner" class="imgPublicity" (click)="openPublicity(publicity1.publicityLink)"> </div>
          </div>   
      </div> -->
      <div class="carousel"  >
        <ng-container *ngFor="let image of publicityCategory; index as i">
          <div class="carousel-item" [ngClass]="{ 'active': i === activeIndex }">
            <img [src]="image.publicityBanner" (click)="openPublicity(image.publicityLink)">
          </div>
        </ng-container>
      </div>
    </div>
    <!-- fin de publicidad -->
  </div>
  <div class="row" style="min-width: 100% !important; max-width: 100% !important; justify-content: center;" id="subCategoria">
    <div class="col-sm-8 col-md-8 col-lg-8">
      <mat-card style="background-color: #f5f5f5;padding: 1.5rem;box-shadow: 0 6px 20px rgba(32, 32, 32, 0.08), 0 0 6px rgba(71, 71, 71, 0.08);margin-bottom: 3rem;" *ngIf="this.masterCatNameSelect != ''">
        <div class="row">
          <div class="col-sm-4 col-md-4 col-lg-4" style="width: 4rem;height: 4rem;">
            <img [src]="this.masterCatIconSelect" style="width: 4rem !important;opacity: 90%;">
          </div>
          <div class="col-sm-7 col-md-7 col-lg-7 categorias" style="margin-left: 1rem!important;">
            <span style="line-height: 1.3; font-weight: 700;color: #004F9F;font-size: 1.6rem;">{{this.masterCatNameSelect}}</span>
          </div>
          <div class="col-sm-11 col-md-11 col-lg-11">
            <hr style="margin-top: 0.5rem;background-color: #0078D7;height: 2px;opacity: 80%;">
          </div>
        </div>
        <mat-card style="margin: 20px 0 20px 0;box-shadow: none!important;background-color: #FFFFFF;">
         
          <mat-card *ngFor="let categories of categoriesList" style="margin: 20px 0px 20px 0;box-shadow: none!important;">
            <mat-card-header style="margin-bottom: 25px;margin-left: -30px;">
              <span style="font-size:large;color: #004F9F;font-weight: 600;">{{categories.catName}}</span>
            </mat-card-header>
            <!-- <mat-divider class="divider"></mat-divider> -->
            
            <mat-card-content style="display: grid; grid-template-columns: repeat(3, 1fr); gap: 3px; grid-column-gap: 12px">
              <span *ngFor="let subcats of categories.subCategory" style="opacity: 90%;cursor: pointer;" (click)="searchProduct(subcats.subCatUUID)">{{subcats.subCatName}}</span>
            </mat-card-content>
          </mat-card>
        </mat-card>
        
      </mat-card>
      
    </div>
  </div>


  

    <!-- busqueda -->
    <div class="row" style="min-width: 100% !important; max-width: 100% !important; justify-content: center;" id="busqueda">
      <div class="col-sm-8 col-md-8 col-lg-8">
        <mat-card style="background-color: #f5f5f5;padding: 1.5rem;box-shadow: 0 6px 20px rgba(32, 32, 32, 0.08), 0 0 6px rgba(71, 71, 71, 0.08);margin-bottom: 3rem;" *ngIf="this.masterCatListSearch.length > 0" >
          <div class="row">
            <div class="col-sm-4 col-md-4 col-lg-4" style="width: 4rem;height: 4rem;">
              <i class="fa fa-search fa-4x" style="width: 4rem !important;opacity: 90%;"></i>
            </div>
            
            <div class="col-sm-7 col-md-7 col-lg-7 categorias" style="margin-left: 1rem!important;">
              <span style="line-height: 1.3; font-weight: 700;color: #004F9F;font-size: 1.6rem;">Resultados de tu búsqueda</span>
            </div>
            <div class="col-sm-11 col-md-11 col-lg-11">
              <hr style="margin-top: 0.5rem;background-color: #0078D7;height: 2px;opacity: 80%;">
            </div>
          </div>
          <mat-card style="margin: 20px 0 20px 0;box-shadow: none!important;background-color: #FFFFFF;">
            <mat-card *ngFor="let mastercategories of masterCatListSearch" style="margin: 20px 0 20px 0;box-shadow: none!important;">
              <mat-card-header style="margin-bottom: 25px;margin-left: -30px;">
                <span style="font-size:large;color: #004F9F;font-weight: 600;" [style.background]="mastercategories.masterCatName.toLowerCase().indexOf(busqueda.toLowerCase()) ? 'white': 'yellow'">{{mastercategories.masterCatName}}</span>
              </mat-card-header>
            <mat-card *ngFor="let categories of mastercategories.category" style="margin: 20px 0 20px 0;box-shadow: none!important;">
              <mat-card-header style="margin-bottom: 25px;margin-left: -30px;">
                <span style="font-size:large;color: #004F9F;font-weight: 600;" [style.background]="categories.catName.toLowerCase().indexOf(busqueda.toLowerCase()) ? 'white': 'yellow'">{{categories.catName}}</span>
              </mat-card-header>
              <!-- <mat-divider class="divider"></mat-divider> -->
              <mat-card-content style="display: grid; grid-template-columns: repeat(3, 1fr); gap: 3px;">
                <span *ngFor="let subcats of categories.subCategory" style="opacity: 90%;cursor: pointer;" (click)="searchProduct(subcats.subCatUUID)" [style.background]="subcats.subCatName.toLowerCase().indexOf(busqueda.toLowerCase()) ? 'white': 'yellow'">{{subcats.subCatName}}</span>
              </mat-card-content>
            </mat-card>
          </mat-card>
          </mat-card>
          
        </mat-card>
        
      </div>
    </div>

    <!-- fin de busqueda -->

