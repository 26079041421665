import { Component, Input, OnInit, Output, ViewChild, ElementRef, HostListener, AfterViewInit } from '@angular/core';
import { AuthService } from './services/auth.service';
import { NavigationEnd, Router } from '@angular/router';
import { Location } from '@angular/common';
import { environment } from 'src/environments/environment';
import { UserModel } from './models/userModel';
import { UserService } from './services/user.service';
import {filter} from 'rxjs/operators';
import { HttpClient  } from '@angular/common/http';
import { PrivateDashboardServiceService } from './services/private-dashboard-service.service';
declare var gtag;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit {
  userRole: string;
  loading = false;
  isLogged: boolean;
  public ipUserAddress = '';
  headders: any = new Headers();
  api_key= "77f27d4c8f1440acabda983f730591c0"
  private actualRouteNavigate: any;
  public viewRouteToSend;
  events;
  
  @ViewChild('chatWindow')
  chatWindow: ElementRef;

  @Input() userEmail;
 
  @HostListener('window:beforeunload', ['$event'])
  onbeforeUnload(event: BeforeUnloadEvent){
    sessionStorage.removeItem('windowChat');
  }

  constructor(
    private authService: AuthService,
    private router: Router,
    private location: Location,
    private userservice: UserService,
    private http: HttpClient,
    private dashboard: PrivateDashboardServiceService
 
  ) {
    const navEndEvents$ = this.router.events
    .pipe(
       filter(event => event instanceof NavigationEnd)
    );

    
    //add environment.idGoogleTag
    navEndEvents$.subscribe((event: NavigationEnd) => {
      gtag('config', 'G-T03HPQSH2W', {
        'page_path': event.urlAfterRedirects
      });
      this.events++;
      console.log(this.events);
      console.log(event.urlAfterRedirects);

    });

  }

  async ngOnInit() {
   //Obtiene ruta y se guarda en viewRouteToSend que sera utilzada hasta luego de obtener la ip pública
    //Para manejar tiempos de carga.
    this.router.events.subscribe(event => {
      this.actualRouteNavigate = sessionStorage.getItem
      if(event instanceof NavigationEnd){
        if(this.actualRouteNavigate !== event.urlAfterRedirects){
          this.viewRouteToSend = event.urlAfterRedirects;
        }else{
          console.log('0');
        }
      }
   })
   // console.log(localStorage.getItem('ipPublicUserAddress'));
   // if(localStorage.getItem('ipPublicUserAddress') == null){
      this.ipAddress();
   // }


 

  
    let user: UserModel;
    this.authService.authStatusChanged.subscribe(authenticated => {
      this.isLogged = authenticated;
      if (!authenticated) {
        const pathUrl = this.location.path().split('/');
        if (pathUrl[1] !== 'userAutoConfirm') {
          // this.router.navigate(['']);
        }
      }
    });
    this.authService.userRole.subscribe(userRoleApp => {
      this.userRole = userRoleApp;
    })
    if (localStorage.getItem('user')) {
      user = JSON.parse(localStorage.getItem('user'));
      this.userRole = user.userRole;
    }
    this.authService.initAuth();
    
  }

  ngAfterViewInit() {
    var route;
    var chatSession = sessionStorage.getItem('windowChat');
    console.log(chatSession);
    this.router.events.subscribe(event => {
      if(event instanceof NavigationEnd){
        route = event.urlAfterRedirects;
        if(chatSession == null && chatSession !== 'closed' && route !== '/Login' && route !== '/'){
          let chat = sessionStorage.getItem('windowChat');
          setTimeout(() => {
            this.chatWindow.nativeElement.style.display = "block";
            console.log(chat);
          },5500)
    
        }
      }
    })
  }

  authUser(userEmail){
    this.userservice.oneUserBackend(userEmail).then(
      (result: any) => {
      }
    )
  }

  ipAddress(){
    // http://icanhazip.com/
    // http://api.ipify.org/?format=json
    // http://ip-api.com/json
    this.headders.append("Content-type", "application/json;charset=utf-8");
    this.http.get('https://ipgeolocation.abstractapi.com/v1/?api_key='+this.api_key, this.headders).subscribe(
      (response: any) =>{
        this.ipUserAddress =  response.ip_address 
        console.log(this.ipUserAddress);
        localStorage.setItem('ipPublicUserAddress', this.ipUserAddress);
        this.viewRoute(this.viewRouteToSend);
      }
    ); 
    //    return null;
    }

  async  viewRoute(value: any){
      var userName = "";
      var routeName = "";
      if(value != '/Dashboard' && value !=  '/AdministracionDePublicidad' && value != '/nuevoUsuario' && value != '/misUsuarios' && value != '/productosPendientes' &&value !=  '/vistaProductosPendientes' && value != '/EnviarCorreos' && value != '/Dashboard' && value != '/validacion' ){
        if(localStorage.getItem('user')){
          userName = JSON.parse(localStorage.getItem('user')).email;
      }

     var userIp =  localStorage.getItem('ipPublicUserAddress');

         if (value == '/Categor%C3%ADas'){
           routeName = '/Categorias';
         }else{
            routeName = value;
            sessionStorage.setItem('actualRouteNavigate', routeName);
         }

         let params = {
            'userIp': userIp,
            'userName': userName,
            'routeName': routeName

         }

         this.sendInsertRouteVisited(params);
         console.log(params);
    

    }
      }
      
  async sendInsertRouteVisited(values){
      await this.dashboard.insertRouteVisited(values).then(
        (resp: any)=>{
          console.log(resp);
        }
      )
    }

    openChat(){
      if(this.chatWindow.nativeElement.style.display == "none"){
        this.chatWindow.nativeElement.style.display = "block";
      }else{
        this.chatWindow.nativeElement.style.display = "none";
      }
    }

    closeChat(){
      if(this.chatWindow.nativeElement.style.display == "block"){
        this.chatWindow.nativeElement.style.display = "none";
        sessionStorage.setItem('windowChat', 'closed');
      }
    }
}
