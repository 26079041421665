import { UserService } from './../../../../services/user.service';
import { MessageCenterService } from './../../../../services/messageCenter.service';
import { ChatDialogComponent } from './chat-dialog/chat-dialog.component';
import { UserModel } from 'src/app/models/userModel';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MasterChatTableModel } from 'src/app/models/masterChatTableModel';
import { ProductsModel } from 'src/app/models/productsModel';
import { ChatTableModel } from 'src/app/models/chatTableModel';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { v4 as uuidv4 } from 'uuid';
import { IntelligentTieringAccessTier } from 'aws-sdk/clients/s3';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { ProductsService } from 'src/app/services/products.service';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import emojis from '../../../../../assets/data/emojis.json';
@Component({
  selector: 'app-answer-pending',
  host: {'window:beforeunload':'doSomething'},
  templateUrl: './answer-pending.component.html',
  styleUrls: ['./answer-pending.component.scss']
})
export class AnswerPendingComponent implements OnInit {
  userData = {} as UserModel;
  mainMessageInfo = [];
  emojisList = emojis;
  busqueda="";
  codeChat ="";
loading = false;
  userlocaldata = {userId:""};
  messagesData = [];
  messagesDataL;
  data;
  chatData = {} as ChatTableModel;
  localChatMessage;
  actualUser;
  contact = false;
  productsData = {} as ProductsModel;
  qualificationState:boolean = false;
  qualification;
  userCompare: Boolean = false;
  messageReceived: Boolean = false;
  userRequest: String = "";
  chatStatus:String = "";
  public inputDisable:String = "";
  public imageStatus:boolean = false;
  public emojiShow: boolean = false;

  @ViewChild('labelImportPrincipal')
  labelImportPrincipal: ElementRef;
  principalimage: File = null;

  constructor(
    
    public dialog: MatDialog,
    private messageCenterService: MessageCenterService,
    private userService: UserService,
    private router: Router,
    private productsService: ProductsService,
    private authService: AuthService
  ) { };
    

  onbeforeunload = function(e) {
    this.mensaje();
  };
  doSomething(){
    this.mensaje();
  }
  ngOnInit(){
    if (localStorage.getItem('user')) {
      this.userlocaldata = JSON.parse(localStorage.getItem('user'));
      const userUUID = JSON.parse(localStorage.getItem('user')).userId;
      this.messageCenterService.mainMessageTableGet(userUUID).then((data: any) => {
        const messageDataDB = JSON.parse(data).records;
       console.log(messageDataDB);
        messageDataDB.forEach(element => {
          if (element.message != null && element.message != undefined) {
            if (element.chatStatus == "qualifying") {
              element.message = "Solicitud de Calificación"
            }else{
              if (element.message.length>=38) {
                var message = "";
                for (let index = 0; index < 33; index++) {
                  message = message+element.message.charAt(index);
                }
                message = message+"....."
                element.message = message;
              }
            }
           
          }else if (element.chatMessageR != null && element.chatMessageR != undefined) {
            if (element.chatMessageR.length>=38) {
              var message = "";
              for (let index = 0; index < 33; index++) {
                message = message+element.chatMessageR.charAt(index);
              }
              message = message+"....."
              element.chatMessageR = message;
            }
          }
         
          if (element.chatStatus != "delete" && element.lastDate != null && element.lastDate != undefined && element.chatStatus != "finished") {   
                this.mainMessageInfo.push(element);
          }
          
        });
      })
    }
  }


  openChat(event) {
    
    const dialogRef = this.dialog.open(ChatDialogComponent,
      {
        data: event
      });

    dialogRef.afterClosed().subscribe(result => {
      // (`Dialog result: ${result}`);
    });
  }
  search(){

  }
  loadMessages(data){
    this.data = data;
    console.log(data);
    if(this.data.chatUser2UUID != undefined){
      localStorage.setItem("chatUser2UUID", this.data.chatUser2UUID);
    }
    if (this.data.chatStatus) {
      this.contact = true;
    }else{
      this.contact = false;
      if (this.data.productUUID!=undefined && this.data.productUUID != null) {
        this.getSingleProduct(this.data.productUUID);
      }
    }
    this.userRequest = data.chatUser1UUID;
    this.chatStatus = data.chatStatus;
    
    
    this.codeChat =data.chatUUID;
    if (data.messageUserReceiver === this.userlocaldata.userId) {
      
    this.updateMessages(this.codeChat,"check");
    }
    this.messagesData = [];
    this.userlocaldata = JSON.parse(localStorage.getItem('user'));
    // if (data.productData != undefined) {
    //   this.productsData = data.productData;
      
    // }
    this.messageCenterService.chatTableGet(data.chatUUID).then(
      (response: any) => {
        const userDB = JSON.parse(response).records;
        
        userDB.forEach(element => {
          (element);
          this.messagesData.push(element);
          this.actualUser = this.userlocaldata.userId;
          this.messagesDataL = element;
          sinjQuery();

          
        });
        
        sinjQuery();
        if (data.empty == undefined) {
          
          if (userDB.length === 0){
            Swal.fire({
              icon: 'error',
              title: 'Sin mensajes',
              text: 'No tienes mensajes crea un mensaje para iniciar.',
            })
            
          }
        }
        sinjQuery();
        
      }
      
    )
    setTimeout(() => {
      sinjQuery();
    }, 1000);
   
      this.chargeObject();
      this.qualifyPointers();
  }

  chargeObject(){
       
  }
  getSingleProduct(productUUID){
    this.loading = true;
    this.productsService.productGetOne(productUUID).then(
      (resultUsers: any) => {
       
        const userDB = JSON.parse(resultUsers).records[0];
       
        if (userDB != undefined && userDB != null) {
          this.productsData = userDB;
        }else{
        
          this.productsData.productName = this.data.productName;
          this.productsData.productMainPicture= "../../../../../assets/images/core/avatarMessage.png";
          this.data.chatUserReceiver = "";
          this.productsData.productPrice = 0;

        }
        
        this.loading = false;
        
      });
  }
  viewCompany(dataLink){
    if (dataLink.chatStatus == "contact") {
      this.router.navigate(['/VistaEmpresa/' + dataLink.productUUID])
  .then(() => {
    window.location.reload();
  });
    }else{
      
    this.productsService.productGetOne(dataLink.productUUID).then(
      (resultUsers: any) => {
       
        const userDB = JSON.parse(resultUsers).records[0];
       
        if (userDB != undefined && userDB != null) {
          this.router.navigate(['/VistaProducto/' + dataLink.productUUID])
  .then(() => {
    window.location.reload();
  });
        }else{
         

        }
        
        this.loading = false;
        
      });
     
    }
  }

  viewCompanyD(){
    this.router.navigate(['/VistaEmpresa/' + this.productsData.productCompanyUUID])
    .then(() => {
      window.location.reload();
    });
    
  }

  async sendMessage(){
    if(this.principalimage != null){
      await this.sendImage();
    }
    this.loading = true;
    const that = this;
    let localData = JSON.parse(localStorage.getItem('user'));
    this.chatData.chatUUID = this.data.chatUUID;
    this.chatData.messageUUID = uuidv4();
    this.chatData.chatUser_Sender = localData.userId;
    
    if (this.data.chatUser2UUID === localData.userId) {
    this.chatData.chatUser_Receiver = this.data.chatUser1UUID;
    }else{  
    this.chatData.chatUser_Receiver = this.data.chatUser2UUID;
    }
    this.chatData.chatFile = localData.userId;
        this.chatData.chatMessage = this.localChatMessage;
        this.chatData.chatMessage_status = localData.userId;
        this.messageCenterService.chatTableTempDelete(this.chatData).then(
          response => {});
        this.chatData.chatMessage_status = "recibido";
        console.log(this.chatData);
        this.messageCenterService.chatTableInsert(this.chatData).then(
          response => {
            this.chatData.chatMessage = '';
            this.localChatMessage = "";
            this.loadMessages(this.chatData);
            if (localStorage.getItem('user')) {
              this.mainMessageInfo = [];
              const userUUID = JSON.parse(localStorage.getItem('user')).userId;
              this.messageCenterService.mainMessageTableGet(userUUID).then((data: any) => {
                const messageDataDB = JSON.parse(data).records;
                messageDataDB.forEach(element => {
                  if (element.message != null && element.message != undefined) {
                    
                    if (element.message.length>=45) {
                      var message = "";
                      for (let index = 0; index < 40; index++) {
                        message = message+element.message.charAt(index);
                      }
                      message = message+"....."
                      element.message = message;
                    }
                  }else if (element.chatMessageR != null && element.chatMessageR != undefined) {
                    if (element.chatMessageR.length>=45) {
                      var message = "";
                      for (let index = 0; index < 40; index++) {
                        message = message+element.chatMessageR.charAt(index);
                      }
                      message = message+"....."
                      element.chatMessageR = message;
                    }
                  }
                  
        
                  if (element.chatStatus != "delete" && element.lastDate != null && element.lastDate != undefined && element.chatStatus != "finished") {   
                    this.mainMessageInfo.push(element);
              }
                  
                });
              })
            }
            this.loading = false;
            setTimeout(function(){
           
            },1600)
        })
      this.userService.oneUser(this.chatData.chatUser_Receiver).then(
        (response: any)=>{
                      
                  let params = {
                    userName: "",
                    userPassword: "",
                    userEmail: JSON.parse(response).records[0].userEmail,
                    cigURLSite: "",
                  }
                
                  this.authService.sendEmailNewMessage(params).then(res =>{
                    this.loading = false;
                   
                    setTimeout(function(){
                      this.loading = false;
                      this.location.reload();
                    },1600);
                   
                  });
                    }
                  );
    
    this.loading = false;
  }
   
 async sendImage(){
    var codeUUID = uuidv4();
    this.imageStatus = false;
    await  this.messageCenterService.uploadFileS3(this.principalimage, codeUUID).then(
        (result: any)=>{
          this.chatData.chatDocument = result.Location;
          console.log(result);
          console.log(result.Location);
          console.log(this.chatData.chatDocument);
        }
      )
  }

  sendMessageTemp(){
    
    let localData = JSON.parse(localStorage.getItem('user'));
    this.chatData.chatUUID = this.data.chatUUID;
    this.chatData.messageUUID = uuidv4();
    this.chatData.chatUser_Sender = localData.userId;
    this.chatData.chatMessage_status = localData.userId;
    
    if (this.data.chatUser2UUID === localData.userId) {
    this.chatData.chatUser_Receiver = this.data.chatUser1UUID;
    }else{  
    this.chatData.chatUser_Receiver = this.data.chatUser2UUID;
    }
    this.chatData.chatFile = localData.userId;
      
        this.chatData.chatMessage = this.localChatMessage;
        this.messageCenterService.chatTableInsertTemp(this.chatData).then(
          response => {
            // Swal.fire({
            //   position: 'top-end',
            //   icon: 'success',
            //   title: 'Mensaje enviado',
            //   showConfirmButton: false,
            //   timer: 1500
            // })
            
        })
    this.loading = false;
  }

  updateMessages(uuid,status){
    var chat= {} as ChatTableModel;
       let localData = JSON.parse(localStorage.getItem('user'));
       chat.chatUUID = uuid;
       chat.chatMessage_status = status
        this.messageCenterService.chatTableStatusUpdate(chat).then(
          response => {
            
          });
  }

  delete(uuid){
    Swal.fire({
      title: 'Enviar Chat',
      text: "¿Está Seguro de Eliminar este Chat?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Eliminar'
    }).then((result) => {
      if (result.isConfirmed) {
        this.loading = true;
       var chat= {} as ChatTableModel;
       let localData = JSON.parse(localStorage.getItem('user'));
       chat.chatUUID = uuid;
       chat.chatMessage_status = "delete"
      
        this.messageCenterService.mainChatTableStatusUpdate(chat).then(
          response => {
            Swal.fire({
              position: 'top-end',
              icon: 'success',
              title: 'Mensaje Eliminado Correctamente',
              showConfirmButton: false,
              timer: 1500
            })
            setTimeout(function(){
              this.loading = false;
              window.location.reload();
            },800)
          });
      }
    })
  }

  qualifyRequest(){
      var chatQualify = {} as ChatTableModel;
      const chatUUID = this.data.chatUUID;
      this.qualificationState = true;
      
      chatQualify.chatUUID = chatUUID;
      chatQualify.chatMessage_status = "qualifying";
           this.messageCenterService.mainChatTableStatusUpdate(chatQualify).then(
         response =>{
              
              Swal.fire({
                position: 'center',
                icon: 'success',
                text: 'Solicitud enviada'
              })
              setTimeout(function(){
                this.loading = false;
                window.location.reload();
              },800)
         }
       )
  }

   qualifyPointers(){
    
       if(this.userlocaldata.userId == localStorage.getItem("chatUser2UUID")){
          this.userCompare = true;
       }
       else{
         this.userCompare = false;
         
       }
  }

  confirmation(value){
         Swal.fire({
             position:'center',
             icon: 'question',
             text:'¿Seguro que quiere enviar una calificación de '+ value +'?',
             showCancelButton: true,
             confirmButtonText: 'Sí',
             cancelButtonText:'No',
         }).then((result) =>{
            if(result.isConfirmed){
                  this.chatQualification(value);
            }else{
               Swal.fire({
                   position: 'center',
                   icon: 'error',
                   text: 'Envío cancelado'
                  
               })
               setTimeout(() => {
                  location.reload();
               }, 1600);
                 
            }
         })

         
  }

  getImage(files: FileList){
    this.labelImportPrincipal.nativeElement.innerText = Array.from(files)
    .map(f => f.name)
    .join(', ');
    if(files[0].name != null){
      this.principalimage = files.item(0);
      this.localChatMessage = this.principalimage.name;
      this.inputDisable = "disabled";
      this.imageStatus = true;
    }else{
         this.labelImportPrincipal.nativeElement.innerText = 'Subir imagen'
    }
    console.log(files);
  }

  clearImageInput(){
       this.principalimage = null;
       this.localChatMessage = "";
       this.imageStatus = false;
       console.log("input");
  }

  addEmoji(value){
       
       if(this.localChatMessage === undefined){
        this.localChatMessage = ""+(value).target.innerHTML;
       }else{
        this.localChatMessage = this.localChatMessage + (value).target.innerHTML;
       }
  }

  emojiBarShow(){
     this.emojiShow = this.emojiShow ? false: true;
     console.log(this.emojiShow);
  }

  chatQualification(value){
  var chatQualify = {} as ChatTableModel;
  const chatQualification = value;
  const chatUUID = this.data.chatUUID;
  chatQualify.chatUUID = chatUUID;
  chatQualify.chatQualification = chatQualification;
  chatQualify.chatMessage_status = "finished";

  this.messageCenterService.mainChatTableQualifyUpdate(chatQualify).then(
    response =>{
      
      
      this.messageCenterService.mainChatTableStatusUpdate(chatQualify).then(
        response =>{
          
          Swal.fire({
            position: 'center',
            icon: 'success',
            text:'Calificación enviada',
          })
          setTimeout(function(){
            this.loading = false;
            window.location.reload();
          },800)
        }
          
      );
    }
      
  );

 
    this.messageCenterService.mainChatTableStatusUpdate(chatQualify).then(
      response =>{
        
        Swal.fire({
          position: 'center',
          icon: 'success',
          text:'Calificación enviada',
        })
        setTimeout(function(){
          this.loading = false;
          window.location.reload();
        },800)
      }
        
    );
  }

  mensaje(){
    Swal.fire({
      icon: 'error',
      title: 'Sin mensajes',
      text: 'No tienes mensajes crea un mensaje para iniciar.',
    })
  }

}
function sinjQuery()
{
    // //Obtengo el div
    // var e = document.getElementById('ventanachat');
    // //Le añado otro mensaje
    // e.innerHTML += '<div class="chatMessage"></div>';
    // //Llevo el scroll al fondo
    var objDiv = document.getElementById("ventanachat");
    
    objDiv.scroll({
      top:1000
    });
   
    objDiv.scrollTop = objDiv.scrollHeight;
}

window.onbeforeunload = function(){
  this.mensaje();
  
}

