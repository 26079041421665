import { Component, OnInit, Inject } from '@angular/core';
import { CompanyService } from 'src/app/services/company.services';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef,MAT_DIALOG_DATA } from '@angular/material/dialog';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-send-emails-dialog',
  templateUrl: './send-emails-dialog.component.html',
  styleUrls: ['./send-emails-dialog.component.scss']
})
export class SendEmailsDialogComponent implements OnInit {
public companyResults;
public companySelect;
public contactSelected = [];
public requestTitle;
public lastInsertID;
public catFamSelected;
  constructor(private company: CompanyService, 
    private formGroup : FormBuilder, 
    private userService : UserService,
    private dialog: MatDialogRef<SendEmailsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  contactEmails =this.formGroup.group({
    email : new FormControl('')
  })
  

  ngOnInit() {
       this.company.companyGetAll().then(
        response =>{
           this.companyResults = response.records[0][0];
           this.companyResults = this.companyResults.filter(x => x.companyName != null && x.companyName != 'Usuario externo');
           console.log(this.companyResults);
        }
       )
       this.catFamSelected = this.data.subCatUUID;
  }


  selectThisContact(){
    console.log(this.contactEmails);
    this.contactSelected = [];
    this.contactSelected.push(this.contactEmails.value.email);
}

async sendMessages(){
      console.log(this.contactSelected);
      var vasd = this.contactSelected[0][0].companyEmail;
    for(let companyEmails of this.contactSelected[0]){
        let email = {
          userEmail: companyEmails.companyEmail,
          title: this.data.title,
          requestId: this.data.code,
     
        }
        
        this.userService.userSendEmailRequest(email).then(
          response => { 
            console.log(response);
          }
        )
       }

       Swal.fire({
        icon:'success',
        title:'Solicitud Enviada !'
      })
      setTimeout(function(){
       window.location.reload();
      },2500);
}

cancel(){
   this.dialog.close();
    Swal.fire({
            icon:'success',
            title:'Solicitud Enviada !'
          })
          setTimeout(function(){
           window.location.reload();
          },1000);
          
}

}
