import { Component, OnInit, Inject } from '@angular/core';
import { MessageCenterService } from 'src/app/services/messageCenter.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ProductsModel } from 'src/app/models/productsModel';
import { ChatTableModel } from 'src/app/models/chatTableModel';
import { v4 as uuidv4 } from 'uuid';
import Swal from 'sweetalert2/dist/sweetalert2.js';
@Component({
  selector: 'app-store-messages',
  templateUrl: './store-messages.component.html',
  styleUrls: ['./store-messages.component.scss']
})
export class StoreMessagesComponent implements OnInit {
  loading = false
  userlocaldata;
  messagesData = [];
  messagesDataL;
  chatData = {} as ChatTableModel;
  localChatMessage;
  actualUser;
  messageP1= "Me interesa este producto para poder llegar a un acuerdo";
  messageP2="Hola ¿Sigue disponible?";
  productsData = {} as ProductsModel;
  constructor( private messageCenterService: MessageCenterService,
    private chatService: MessageCenterService,
    private dialogRef: MatDialogRef<StoreMessagesComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { 
    
  }


  
  async ngOnInit(){
    
    await this.loadMessages();
  }

  loadMessages(){
    this.loading = true;
    this.userlocaldata = JSON.parse(localStorage.getItem('user'));
    console.log(this.data);
    if (this.data.productData != undefined) {
      this.productsData = this.data.productData;
      
      console.log(this.productsData);
      
    }
    this.messageCenterService.chatTableGet(this.data.chatUUID).then(
      (response: any) => {
        const userDB = JSON.parse(response).records;
        
        userDB.forEach(element => {
          (element);
          this.messagesData.push(element);
          this.actualUser = this.userlocaldata.userId;
          this.messagesDataL = element;

          this.loading = false;
        });
        if (this.data.empty == undefined) {
          
          if (userDB.length === 0){
            Swal.fire({
              icon: 'error',
              title: 'Sin mensajes',
              text: 'No tienes mensajes crea un mensaje para iniciar.',
            })
            
          }
        }
        this.loading = false;
        
      }
    )
  }

  sendMessage1(m){
    if (m==1) {
      this.localChatMessage=this.messageP1;
      this.sendMessage();
    } else if (m==2) {
      this.localChatMessage=this.messageP2;
      this.sendMessage();
    }
  }
  closeDialog(){
    
    const that = this;
    that.dialogRef.close();
  }
  sendMessage(){
    this.loading = true;
    
    if (this.data.createChat != undefined) {

      this.chatService.mainMessageTableInsert(this.data).then(
        messageResponse => {
          Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: 'Mensaje creado ve a mensajes para verlo.',
            showConfirmButton: false,
            timer: 1500
          })
        }
      );
    }
    const that = this;
    let localData = JSON.parse(localStorage.getItem('user'));
    this.chatData.chatUUID = this.data.chatUUID;
    this.chatData.messageUUID = uuidv4();
    this.chatData.chatUser_Sender = localData.userId;
    this.chatData.chatUser_Receiver = this.data.chatUser2UUID;
    this.chatData.chatFile = localData.userId;
    if (this.messagesData.length === 0){
      this.chatData.chatMessage = this.localChatMessage;
      this.messageCenterService.chatTableInsert(this.chatData).then(
        response => {
          this.chatData.chatMessage = '';
          Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: 'Mensaje enviado',
            showConfirmButton: false,
            timer: 1500
          });
          setTimeout(function(){
            that.loading = false;
            that.dialogRef.close();
          },1600)
      })
    } else if (this.messagesData.length !== 0){
      if (this.messagesDataL.chatUser_Sender === localData.userId) {
        this.chatData.chatMessage = this.localChatMessage;
        this.messageCenterService.chatTableInsert(this.chatData).then(
          response => {
            this.chatData.chatMessage = '';
            Swal.fire({
              position: 'top-end',
              icon: 'success',
              title: 'Mensaje enviado',
              showConfirmButton: false,
              timer: 1500
            })
            setTimeout(function(){
              that.loading = false;
              that.dialogRef.close();
            },1600)
        })
      } else if (this.messagesDataL.chatUser_Sender !== localData.userId) {
        this.chatData.chatMessageR = this.localChatMessage;
        this.messageCenterService.chatTableInsert(this.chatData).then(
          response => {
            this.chatData.chatMessage = '';
            Swal.fire({
              position: 'top-end',
              icon: 'success',
              title: 'Mensaje enviado',
              showConfirmButton: false,
              timer: 1500
            })
            setTimeout(function(){
              that.loading = false;
              that.dialogRef.close();
            },1600)
        })
      }
    }
  }
}
