import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import roles from '../../../../assets/data/masterUserRoles.json';
import { CompanyModel } from 'src/app/models/companyModel';
import { CategoryService } from 'src/app/services/category.service';
import { MasterCatModel } from 'src/app/models/msterCatModel';
import { SubCategoryModel } from 'src/app/models/subcategoryModel';
import { CategoryModel } from 'src/app/models/categoryModel';
import { FormControl, Validators } from '@angular/forms';
import { newCategoryModel } from 'src/app/models/newCatModel';
import { UserModel } from '../../../models/userModel';
import { AuthService } from '../../../services/auth.service'
import { CompanyService } from 'src/app/services/company.services';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { v4 as uuidv4 } from 'uuid';
@Component({
  selector: 'app-user-register-dashboard',
  templateUrl: './user-register-dashboard.component.html',
  styleUrls: ['./user-register-dashboard.component.scss']
})
export class UserRegisterDashboardComponent implements OnInit {

  constructor(private usService: UserService, 
    private categoryService: CategoryService, 
    private authService: AuthService, 
    private companyService: CompanyService) { }
  public userList;
  public rolesList = roles ;
  public userInfo: Boolean = false;
  public indexState;
  public companyIndexState;
  public companyExists: Boolean = false;
  public companyState: boolean = false;
  public companyInfo = {} as CompanyModel;
  public userNotPartner = {} as UserModel;
  public showRequest: Boolean = false;
  public newCompany;
  public newCompanyInfo = {} as CompanyModel;
  masterCatList;
  subcategories = {} as SubCategoryModel;
  masterCategory = {} as MasterCatModel;
  categories = {} as CategoryModel;
  public newCategory = {} as newCategoryModel;
  categoriesList: [];
  subcategoryList;
  subCatStatus = true;
  familyCatStatus = true;
  masterCatForSave;
  subCatForSave;
  familyCatForSave;
  userDataToShow;
  public localUser;
  public newUserList;
  public userIndexState;
  public newUserInfo = {} as UserModel;
  loading = false;
  user = {} as UserModel;
  company = {} as CompanyModel;
  selectUser = new FormControl();

  categoria = new FormControl('', Validators.required);
  subcategoria = new FormControl('', Validators.required);
  items = new FormControl('', Validators.required);

  async ngOnInit(){
    if (localStorage.getItem('user')) {
      this.localUser = JSON.parse(localStorage.getItem('user'));
    }

    this.usService.getUserRequest().then(
      (response: any) =>{
        this.userList = JSON.parse(response).records[0][0];
        if(this.userList.length >0){
           this.showRequest = true;
        }
      }
    )

    await this.usService.oneUser(this.localUser.userId).then(
      (response: any) => {
        const userDB = JSON.parse(response).records[0];
        this.userDataToShow = userDB;
        this.loading = false;
      }
    );

    var param = {
       param1: '2023-04-20'
    }
    await this.companyService.getCompanyByDate(param).then(
       (response: any) =>{
           console.log(response);
           this.newCompany = response
           this.newCompany = response.rows.filter(x => x.companyName !== 'Usuario externo');
       }
    )

    await this.usService.getUserByDate(param).then(
       (response: any) =>{
           this.newUserList = response.rows.filter(x=> x.userCognitoCode !== null);
       }
    )
    

    this.listMasterCategories();
  }


  changeState(value, user){
    this.companyInfo.companyAddress = "";
    this.companyInfo.companyDescription = "";
    this.companyInfo.companyEmail = "";
    this.companyInfo.companySite = "";
    this.companyExists = false;
 console.log(user);
    this.indexState = value;
    this.userInfo = true;
     //replace '-' before consult
     var transformNit = "";
     console.log(user.companyNit);
    if(user.companyNit != null){
       transformNit = user.companyNit.replace('-', '');
      console.log(transformNit);
   
    this.usService.getCompanyByNitRequest(transformNit).then(
       (response : any) =>{
         console.log(JSON.parse(response));
         const resp = JSON.parse(response).records[0];
         if(resp){
           this.companyExists = true;
           this.companyInfo = JSON.parse(response).records[0];
           this.user = user;
           this.user.userCompanyUUID = this.companyInfo.companyBussinesCode;
           this.user.userCreationUser = this.localUser.userCognitoCode;
           console.log(this.companyExists);
         }
       }
    )
  }else{
      console.log("La empresa del usuario no existe en la base de datos")
  }
  }

  listMasterCategories(){
    this.categoryService.masterGetAll().then(
      response=> {
        const userDB: MasterCatModel = response.records[0][0];
        this.masterCatList = userDB;
      }
    )
  }

  masterForSave(masterCatUUID){
    this.categoryService.mastercatGetOne(masterCatUUID).then(
      (response: any) => {
      const userDB = JSON.parse(response).records[0];
      this.masterCatForSave = userDB.masterCatName;
      }
    )
  }

  listCategories(masterCatUUID){
    console.log(masterCatUUID);
    this.masterForSave(masterCatUUID);
    this.categoryService.categoryGetOne(masterCatUUID).then(
      (response: any) => {
        const userDB = JSON.parse(response).records;
        this.categoriesList = userDB;
        this.subCatStatus = false;
      }
    )
  }

  subcatforsave(subCatUUID){
    this.categoryService.categoryGetOneForSave(subCatUUID).then(
      (response: any) => {
        const userDB = JSON.parse(response).records[0];
        this.subCatForSave = userDB.catName;
      }
    )
  }

  listSubCat(subcatUUID){
    this.subcatforsave(subcatUUID);
    this.categoryService.subCatGetOne(subcatUUID).then(
      (response: any) => {
        const userDB = JSON.parse(response).records;
        this.subcategoryList = userDB;
        this.familyCatStatus = false;
      }
    )
  }

  itemFamily(familyUUID){
    console.log(familyUUID);
    this.categoryService.subCatGetOneForSave(familyUUID).then(
      (response: any) => {
        const userDB = JSON.parse(response).records[0];
        this.familyCatForSave = userDB.subCatName;
      }
    )
  }

  updateUser(value){
    if(this.companyExists){
    this.saveUser();
    }else if(!this.companyExists && value.companyNit == null){
      this.userNotPartner = value;
      this.saveCompanyNotPartner();
    }else if(!this.companyExists && value.companyNit != null){
      this.userNotPartner = value;
     this.userNotPartnerBusiness();
    }
  
    // if(this.companyExists)
 }


 async saveUser() {
  console.log(this.user);
  this.user.userPassword = this.generatePassword();
  if (this.user.userPassword.length >= 8){
    const UserSub: any = await this.authService.signUp(this.user);
    this.user.userCognitoCode = UserSub.UserSub;
    this.user.userStatus = "1";
   // this.user.userCreationUser = this.localUser.email;
   // this.user.userUpdateUser = this.localUser.email;
    this.user.userStatus = 1;
  console.log(this.user);
    this.usService.updateRequestUser(this.user).then(
       response =>{
        if (response.result === true) {
                Swal.fire({
                  icon: 'success',
                  title: 'Registro Éxitoso',
                  text: 'Usuario registrado Correctamente',
                })
                this.loading = false;
                window.location.reload();
       }else{
        Swal.fire({
          icon: 'error',
          title: 'Ocurrió un error',
        })
       }
      }
    )

  } else {
    Swal.fire({
       title:'errror',
       text: 'Ha ocurrido un error en la creación del usuario'
    })
  }
  
}

generatePassword() {
  // No cambiar los var loquea el generador.
  const length = 5;
  const lowercase = 'abcdefghijklmnopqrstuvwxyz';
  const uppercase = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  const numbers = '0123456789';
  const punctuation = '!@#$%&';
  let retVal = '';
  for (var i = 0, n = lowercase.length; i < length; ++i) {
    retVal += lowercase.charAt(Math.floor(Math.random() * n));
    // tslint:disable-next-line:no-shadowed-variable
    for (var i = 0, n = uppercase.length; i < length; ++i) {
      retVal += uppercase.charAt(Math.floor(Math.random() * n));
      // tslint:disable-next-line:no-shadowed-variable
      for (var i = 0, n = numbers.length; i < length; ++i) {
        retVal += numbers.charAt(Math.floor(Math.random() * n));
        // tslint:disable-next-line:no-shadowed-variable
        for (var i = 0, n = punctuation.length; i < length; ++i) {
          retVal += punctuation.charAt(Math.floor(Math.random() * n));
        }
      }
    }
  }
  return retVal;
}

saveCompanyNotPartner(){
   this.companyInfo.companyBussinesCode = uuidv4();
   this.companyInfo.companyName = 'Usuario externo';
   this.companyInfo.companyAddress = 'Usuario externo';
   this.companyInfo.companyDescription = 'Usuario externo';
   this.companyInfo.companyNIT = this.userNotPartner.companyNit;
   this.companyInfo.companyPhone1 = this.userNotPartner.companyPhone;
   this.companyInfo.companyEmail = this.userNotPartner.userEmail;
   this.userNotPartner.userCompanyName = this.companyInfo.companyName;
   this.userNotPartner.userCompanyUUID = this.companyInfo.companyBussinesCode;
   this.companyInfo.companyBadge = 0;
   this.companyInfo.directoryActive = 0;
   console.log(this.userNotPartner);
   console.log(this.companyInfo);
     this.companyService.saveCompanyOnTable(this.companyInfo).then(
      (response: any) =>{
         console.log(response);
         this.saveUserNotPartner(this.userNotPartner);
      }
     )
}

userNotPartnerBusiness(){
  this.companyInfo.companyBussinesCode = uuidv4();
  this.companyInfo.companyName = this.userNotPartner.userCompanyName;
  this.companyInfo.companyNIT = this.userNotPartner.companyNit;
  this.companyInfo.companyPhone1 = this.userNotPartner.companyPhone;
  this.userNotPartner.userCompanyName = this.companyInfo.companyName;
  this.userNotPartner.userCompanyUUID = this.companyInfo.companyBussinesCode;
  this.companyInfo.companyEmail = this.userNotPartner.userEmail;
  this.companyInfo.companyBadge = 0;
  this.companyInfo.directoryActive = 0;
  console.log(this.userNotPartner);
  console.log(this.companyInfo);
  this.companyService.saveCompanyOnTable(this.companyInfo).then(
    (response: any) =>{
       console.log(response);
       this.saveUserNotPartner(this.userNotPartner);
    }
   )
}

async saveUserNotPartner(user: any){
  user.userPassword = this.generatePassword();
  if (user.userPassword.length >= 8){
    const UserSub: any = await this.authService.signUp(user);
    user.userCognitoCode = UserSub.UserSub;
    user.userStatus = 1;
  console.log(user);
    this.usService.updateRequestUser(user).then(
       response =>{
        if (response.result === true) {
                Swal.fire({
                  icon: 'success',
                  title: 'Registro Éxitoso',
                  text: 'Usuario registrado Correctamente',
                })
                this.loading = false;
                window.location.reload();
       }else{
        Swal.fire({
          icon: 'error',
          title: 'Ocurrió un error',
        })
       }
      }
    )

  } else {
    Swal.fire({
       title:'errror',
       text: 'Ha ocurrido un error en la creación del usuario'
    })
  }
  
}

changeCompanyState(value: any, company: any){
  this.companyIndexState = value;
  this.newCompanyInfo = this.company;
  // this.companyState = true;
   console.log(this.companyState);
   console.log(company);
}

changeUserState(value: any, user: any){
   this.userIndexState = value;
   this.newUserInfo = user;
   console.log(this.newUserInfo);
}

updateCompany(value: any){
  console.log(value, this.masterCatForSave, this.subCatForSave, this.familyCatForSave);
  if(this.subCatForSave != null && this.familyCatForSave != null){
    value.companyCategory = this.masterCatForSave;
    value.subCat = this.subCatForSave;
    value.familyItem = this.familyCatForSave;
    value.directoryActive = 1;
  }
  this.newCompanyInfo = value;
  console.log(this.newCompanyInfo);
  this.companyService.updateNewCompany(this.newCompanyInfo).then(
    (response: any) =>{
      if (response.result === true) {
        Swal.fire({
          icon: 'success',
          title: 'Registro Éxitoso',
          text: 'Empresa actualizada',
        })
        this.loading = false;
        window.location.reload();
}else{
Swal.fire({
  icon: 'error',
  title: 'Ocurrió un error',
});
}
    });
}

updateNewUser(value: any){
 console.log(value);
 this.usService.updateRequestUser(value).then(
  (response: any) =>{
    if (response.result === true) {
      this.authService.updateUser(value).then(
          (response: any) => {
            Swal.fire({
              icon: 'success',
              title: 'Registro Éxitoso',
              text: 'Usuario actualizado',
            })
            this.loading = false;
            window.location.reload();
          }
      )
}else{
Swal.fire({
icon: 'error',
title: 'Ocurrió un error',
});
}
  }
 )
}

verificateUser(value){
console.log(value);
//userConfirmWithoutCode
console.log(value.userEmail);
this.authService.userConfirmWithoutCode(value.userEmail).then(
  (response: any) =>{
    console.log(response);
  }
)
}

sendCredentials(value){
  var email = value.userEmail;
  this.usService.oneUserBackend(email).then(
    (resultUsers: any) =>{
      if(typeof(resultUsers) ==='string'){
        resultUsers = JSON.parse(resultUsers);
      }else{
          throw resultUsers.errorMessage;
      }
       const userDB: UserModel = resultUsers.records[1][0];
       const psd = userDB.userPassword;
       
     let params = {
       userName: email,
       userEmail: email,
       userPassword : psd
     }
     this.authService.sendEmail(params);
        Swal.fire({
           position: 'center',
           icon: 'success',
           title:'Credenciales enviadas',
           showConfirmButton: false,
           timer: 1500
        })
    }
  ).catch((err) =>{
      Swal.fire({
         icon: 'error',
         title: 'Ooops..',
         text:'Algo salio mal o el correo es incorrecto'
      })
  })
}

async getUserByDatePicker(){
  console.log(this.selectUser)
  let date = new Date(this.selectUser.value)
  let year = date.getFullYear();
  let month = (date.getMonth() + 1).toString().padStart(2, "0");
  let day = date.getDate().toString().padStart(2, "0");
  let lastDate = year+"-"+month+"-"+day
  console.log(lastDate)
  var param = {
    param1: lastDate
  }
 await this.usService.getUserByDate(param).then(
    (response: any) =>{
        this.newUserList = response.rows.filter(x=> x.userCognitoCode !== null);
    }
  )
}



}
