
import { UserService } from '../../../../services/user.service';
import { MessageCenterService } from '../../../../services/messageCenter.service';
import { ChatDialogComponent } from '../answer-pending/chat-dialog/chat-dialog.component';
import { UserModel } from 'src/app/models/userModel';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MasterChatTableModel } from 'src/app/models/masterChatTableModel';
import { ProductsModel } from 'src/app/models/productsModel';
import { ChatTableModel } from 'src/app/models/chatTableModel';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { v4 as uuidv4 } from 'uuid';
import { CompanyService } from 'src/app/services/company.services';

@Component({
  selector: 'app-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.scss']
})
export class ContactsComponent implements OnInit {
  userData = {} as UserModel;
  mainMessageInfo = [];
  busqueda="";
  codeChat ="";
loading = false;
  userlocaldata;
  messagesData = [];
  messagesDataL;
  data;
  chatData = {} as ChatTableModel;
  localChatMessage;
  actualUser;
  productsData = {} as ProductsModel;
  

  constructor(
    
    public dialog: MatDialog,
    private messageCenterService: MessageCenterService,
    private userservice: UserService,
    private companyService: CompanyService
    
  ) { }
  onbeforeunload = function(e) {
    this.mensaje();
  };
  doSomething(){
    this.mensaje();
  }
  async ngOnInit(){
    this.loading = true;

    if (localStorage.getItem('user')) {
      const userUUID = JSON.parse(localStorage.getItem('user')).userId;
      
      await this.messageCenterService.mainMessageTableGet(userUUID).then((data: any) => {
        const messageDataDB = JSON.parse(data).records;
        messageDataDB.forEach(async (element) => {
          if (element.message != null && element.message != undefined) {
            if (element.message.length>=30) {
              var message = "";
              for (let index = 0; index < 25; index++) {
                message = message+element.message.charAt(index);
              }
              message = message+"....."
              element.message = message;
            }
          }else if (element.chatMessageR != null && element.chatMessageR != undefined) {
            if (element.chatMessageR.length>=30) {
              var message = "";
              for (let index = 0; index < 25; index++) {
                message = message+element.chatMessageR.charAt(index);
              }
              message = message+"....."
              element.chatMessageR = message;
            }
          }
          if (element.chatStatus === "contact" && element.chatUser1UUID === userUUID) {
          
            var company = {} as any;
    

            var companyDB;
               await this.userservice.oneUser(element.chatUser2UUID).then(
                  (userData: any) => {
                    const userDB = JSON.parse(userData).records[0];
                    
                    this.companyService.companyGetOne(userDB.userCompanyUUID).then(
                      (companyData: any) => {
                        
                        companyDB = JSON.parse(companyData).records[0];
                        element.productName = companyDB.familyItem;
                       
                        this.mainMessageInfo.push(element);
                        
                      }
                    )
                  }
                )
               
                
          }
          
        });
        
      })
      this.loading = false;
    }


   
  }

  async addUser(){
  
    

   
  }


  openChat(event) {
    const dialogRef = this.dialog.open(ChatDialogComponent,
      {
        data: event
      });

    dialogRef.afterClosed().subscribe(result => {
      // (`Dialog result: ${result}`);
    });
  }
  search(){

  }
  loadMessages(data){
    this.data = data;
    this.codeChat =data.chatUUID;
    this.messagesData = [];
    this.userlocaldata = JSON.parse(localStorage.getItem('user'));
    if (data.productData != undefined) {
      this.productsData = data.productData;
      
    }
    this.messageCenterService.chatTableGet(data.chatUUID).then(
      (response: any) => {
        const userDB = JSON.parse(response).records;
        
        userDB.forEach(element => {
          (element);
          this.messagesData.push(element);
          this.actualUser = this.userlocaldata.userId;
          this.messagesDataL = element;
          sinjQuery();

          
        });
        sinjQuery();
        if (data.empty == undefined) {
          
          if (userDB.length === 0){
            // Swal.fire({
            //   icon: 'error',
            //   title: 'Sin mensajes',
            //   text: 'No tienes mensajes crea un mensaje para iniciar.',
            // })
            
          }
        }
        sinjQuery();
        
      }
      
    )
    setTimeout(() => {
      sinjQuery();
    }, 1000);
   
  
  }
async reloadChat(userUUID){
  await this.messageCenterService.mainMessageTableGet(userUUID).then((data: any) => {
    const messageDataDB = JSON.parse(data).records;
    messageDataDB.forEach(async (element) => {
      if (element.message != null && element.message != undefined) {
        if (element.message.length>=30) {
          var message = "";
          for (let index = 0; index < 25; index++) {
            message = message+element.message.charAt(index);
          }
          message = message+"....."
          element.message = message;
        }
      }else if (element.chatMessageR != null && element.chatMessageR != undefined) {
        if (element.chatMessageR.length>=30) {
          var message = "";
          for (let index = 0; index < 25; index++) {
            message = message+element.chatMessageR.charAt(index);
          }
          message = message+"....."
          element.chatMessageR = message;
        }
      }
      if (element.chatStatus === "contact" && element.chatUser1UUID === userUUID) {
      
        var company = {} as any;


        var companyDB;
           await this.userservice.oneUser(element.chatUser2UUID).then(
              (userData: any) => {
                const userDB = JSON.parse(userData).records[0];
                
                this.companyService.companyGetOne(userDB.userCompanyUUID).then(
                  (companyData: any) => {
                    
                    companyDB = JSON.parse(companyData).records[0];
                    element.productName = companyDB.familyItem;
                    
                    this.mainMessageInfo.push(element);
                    
                  }
                )
              }
            )
           
            
      }
      
    });
    
  })
}
  sendMessage(){
    this.loading = true;
    const that = this;
    let localData = JSON.parse(localStorage.getItem('user'));
    this.chatData.chatUUID = this.data.chatUUID;
    this.chatData.messageUUID = uuidv4();
    this.chatData.chatUser_Sender = localData.userId;
    
   
    if (this.data.chatUser2UUID === localData.userId) {
    this.chatData.chatUser_Receiver = this.data.chatUser1UUID;
    }else{  
    this.chatData.chatUser_Receiver = this.data.chatUser2UUID;
    }
    this.chatData.chatFile = localData.userId;
    
    

    
        
    this.chatData.chatMessage = this.localChatMessage;
    this.chatData.chatMessage_status = localData.userId;
    this.messageCenterService.chatTableTempDelete(this.chatData).then(
      response => {});
    this.chatData.chatMessage_status = "recibido";
    this.messageCenterService.chatTableInsert(this.chatData).then(
      response => {
        this.chatData.chatMessage = '';
        // Swal.fire({
        //   position: 'top-end',
        //   icon: 'success',
        //   title: 'Mensaje enviado',
        //   showConfirmButton: false,
        //   timer: 1500
        // })
        this.localChatMessage = "";
        this.loadMessages(this.data);
        if (localStorage.getItem('user')) {
          this.mainMessageInfo = [];
          const userUUID = JSON.parse(localStorage.getItem('user')).userId;
          this.messageCenterService.mainMessageTableGet(userUUID).then((data: any) => {
            const messageDataDB = JSON.parse(data).records;
            messageDataDB.forEach(element => {
              if (element.message != null && element.message != undefined) {
                if (element.message.length>=45) {
                  var message = "";
                  for (let index = 0; index < 40; index++) {
                    message = message+element.message.charAt(index);
                  }
                  message = message+"....."
                  element.message = message;
                }
              }else if (element.chatMessageR != null && element.chatMessageR != undefined) {
                if (element.chatMessageR.length>=45) {
                  var message = "";
                  for (let index = 0; index < 40; index++) {
                    message = message+element.chatMessageR.charAt(index);
                  }
                  message = message+"....."
                  element.chatMessageR = message;
                }
              }
              
    
              this.mainMessageInfo.push(element);
              
            });
          })
        }
        this.loading = false;
        setTimeout(function(){
       
        },1600)
    })
  
    this.loading = false;
  }

  sendMessageTemp(){
    
    let localData = JSON.parse(localStorage.getItem('user'));
    this.chatData.chatUUID = this.data.chatUUID;
    this.chatData.messageUUID = uuidv4();
    this.chatData.chatUser_Sender = localData.userId;
    this.chatData.chatMessage_status = localData.userId;
    
    if (this.data.chatUser2UUID === localData.userId) {
    this.chatData.chatUser_Receiver = this.data.chatUser1UUID;
    }else{  
    this.chatData.chatUser_Receiver = this.data.chatUser2UUID;
    }
    this.chatData.chatFile = localData.userId;
      
        this.chatData.chatMessage = this.localChatMessage;
        this.messageCenterService.chatTableInsertTemp(this.chatData).then(
          response => {
            // Swal.fire({
            //   position: 'top-end',
            //   icon: 'success',
            //   title: 'Mensaje enviado',
            //   showConfirmButton: false,
            //   timer: 1500
            // })
            
        })
    this.loading = false;
  }

  delete(uuid){
    Swal.fire({
      title: 'Enviar Chat',
      text: "¿Está Seguro de Eliminar este Chat?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Eliminar'
    }).then((result) => {
      if (result.isConfirmed) {
        this.loading = true;
       var chat= {} as ChatTableModel;
       let localData = JSON.parse(localStorage.getItem('user'));
       chat.chatUUID = uuid;
       chat.chatMessage_status = "delete"
      
        this.messageCenterService.mainChatTableStatusUpdate(chat).then(
          response => {
            Swal.fire({
              position: 'top-end',
              icon: 'success',
              title: 'Mensaje Eliminado Correctamente',
              showConfirmButton: false,
              timer: 1500
            })
            setTimeout(function(){
              this.loading = false;
              window.location.reload();
            },800)
          });
      }
    })
  }


  mensaje(){
    // Swal.fire({
    //   icon: 'error',
    //   title: 'Sin mensajes',
    //   text: 'No tienes mensajes crea un mensaje para iniciar.',
    // })
  }

}
function sinjQuery()
{
    // //Obtengo el div
    // var e = document.getElementById('ventanachat');
    // //Le añado otro mensaje
    // e.innerHTML += '<div class="chatMessage"></div>';
    // //Llevo el scroll al fondo
    var objDiv = document.getElementById("ventanachat");
    
    objDiv.scroll({
      top:1000
    });
   
    objDiv.scrollTop = objDiv.scrollHeight;
}

window.onbeforeunload = function(){
  this.mensaje();
  
}

           

